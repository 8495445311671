import axios from 'axios';
import { error_message } from '../components/Toast/index.jsx';

const baseUrl = getEnvironmentApiUrl();

const Api = axios.create({
    baseURL: baseUrl
    
    // Local
    // baseURL: 'https://localhost:5050/',
    // Thrive Dev
    // baseURL: 'https://thrivebat-dev.azurewebsites.net/api/'
    // Thrive QA
    // baseURL: 'https://thrivebat-qa.azurewebsites.net/api/'
    // Thrive DEV BAT
    // baseURL: 'https://bat-thrive-dev.azurewebsites.net/api/'
    // Thrive QA BAT
    // baseURL: 'https://bat-thrive-qa.azurewebsites.net/api/'
    // Thrive QANEW BAT
    // baseURL: 'https://bat-thrive-qanew.azurewebsites.net/api/'
    // Thrive STAGING BAT
    // baseURL: 'https://bat-thrive-staging.azurewebsites.net/api/'
    // Thrive PRD BAT
    // baseURL: 'https://bat-thrive.azurewebsites.net/api/'
});



export const exceptionNotificationAPI = (error) => {
    console.error(error);

    if (/500/.test(error.message)) {
        error_message('Failed to connect Web Service (500).');
    } else if (/404/.test(error.message)) {
        error_message('Route Not Found (404).');
    } else if (/401/.test(error.message)) {
        sessionStorage.clear();
        localStorage.removeItem('@Thrive:token');
        localStorage.removeItem('@Thrive:user');
        localStorage.removeItem('@Thrive:userRoles');
        localStorage.removeItem('@Thrive:userName');

        window.location.href = window.location.origin + '/signIn'
    } else if (/400/.test(error.message)) {
        let notifications = error.response.data.notifications;
        if (notifications && notifications.length > 0) {
            notifications.forEach((not) => {
                error_message(not.message);
            })
        }
    } else {
        error_message(error.message);
    }
}

function getEnvironmentApiUrl () {
    if(process.env.NODE_ENV === "development"){
        return 'https://localhost:5050/';
    }

    var actualURL = window.location.host;

    if(actualURL.startsWith("bat")){
        var splitURL = actualURL.split("-");       

        if(splitURL.length > 2) {
            if(splitURL[2].startsWith("dev")){
                return 'https://bat-thrive-dev.azurewebsites.net/api/';
            }
            else if(splitURL[2].startsWith("qa")){
                return 'https://bat-thrive-qa.azurewebsites.net/api/'
            }
            else if(splitURL[2].startsWith("qanew")){
                return 'https://bat-thrive-qanew.azurewebsites.net/api/'
            }
            else if(splitURL[2].startsWith("staging")){
                return 'https://bat-thrive-staging.azurewebsites.net/api/'
            }            
        }
        else {
            return 'https://bat-thrive.azurewebsites.net/api/'
        }
        

    }
    else if(actualURL.startsWith("qa")){
        return 'https://qa.thrivebat.com/api/'
    }
    else if(actualURL.startsWith("thrivebat")){
        return 'https://thrivebat.com/api/'
    }


}


export default Api;
