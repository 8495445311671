import moment from "moment";
import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { FaArrowRight } from "react-icons/fa";
import { FiUpload } from "react-icons/fi";
import NumberFormat from "react-number-format";
import Api, { exceptionNotificationAPI } from "../../services/api";
import Button from "../Button";
import GetTypeInput from "../FormInput/set";
import { error_message, info_message, success_message } from "../Toast";
// import ModalBody from 'react-bootstrap/ModalBody';
import { Header, ContentTitle, CommentTextArea, ModalBody } from './styles';

export default function ModalCompareDataFSM({ showModalCompareDataFSM, setShowModalCompareDataFSM, companyID, reportYearID, moduleID, versionID, kPIsToCompare }) {
    const [selectedFSMValues, setSelectedFSMValues] = useState([]);
    const [result, setResult] = useState("");

    async function SelectFSMValue(kpi, id) {
        var FSMItem = selectedFSMValues.find(x => x.fsmIntegrationDataID == id);

        if (FSMItem != null) {
            var selectedFSMValuesFiltered = selectedFSMValues.filter(x => x.fsmIntegrationDataID != id);

            setSelectedFSMValues(selectedFSMValuesFiltered);
        }
        else {
            var newCols = [];

            newCols = selectedFSMValues.filter(x => x.fsmIntegrationDataID != id);
            newCols = [...newCols, kpi];


            setSelectedFSMValues(newCols);
        }

    }

    async function UnselectAll() {
        setSelectedFSMValues([]);
    }

    async function SelectAll() {
        var AllKPIs = [];
        setSelectedFSMValues([]);

        AllKPIs = kPIsToCompare;
        setSelectedFSMValues(AllKPIs)
    }

    async function ReplaceFSMData(companyID, reportYearID, moduleID, versionID) {
        try {
            Api.post(`FSMIntegrationData/ReplaceThriveValuesFromFSM?reportYearID=${reportYearID}&companyID=${companyID}&moduleID=${moduleID}&thriveValueVersionID=${versionID ?? ""}`, selectedFSMValues).then((result) => {
                if (result && result.data) {
                    console.log(result.data.response)
                    setShowModalCompareDataFSM(false)
                    window.location.reload();
                }

            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
        catch (error) {
            exceptionNotificationAPI(error);
        }
    }

    return (
        <Modal
            show={showModalCompareDataFSM}
            onHide={() => {
                setShowModalCompareDataFSM(false);
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalHeader closeButton>
                <ModalTitle id="contained-modal-title-vcenter">
                    <Header>Comparison FSM Data - Thrive Data</Header>
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <div style={{margin: "0.5rem 0", display: 'flex'}}>
                    <Button style={{margin: "0 1rem"}} onClick={() => SelectAll()}>Select all</Button>
                    <Button onClick={() => UnselectAll()}>Unselect all</Button>
                </div>
                {kPIsToCompare != null && result == "" ?
                <>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" class="col-sm-1">Select to replace</th>
                                <th scope="col" class="col-sm-1">KPI RefNumber</th>
                                <th scope="col" class="col-sm-6">Description</th>
                                <th scope="col" class="col-sm-2">Thrive Value</th>
                                <th scope="col" class="col-sm-1"></th>
                                <th scope="col" class="col-sm-2">FSM Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {kPIsToCompare.length > 0 ? kPIsToCompare.map((kpi) => {
                                var unitMeasure = GetTypeInput(kpi.unitMeasureID, kpi.unitMeasureType);

                                return (<tr>
                                    <td>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id={kpi.fsmIntegrationDataID} checked={selectedFSMValues.find(x => x.fsmIntegrationDataID == kpi.fsmIntegrationDataID) != null ? true : false} onChange={(e) => SelectFSMValue(kpi, e.target.id)} />
                                        </div>
                                    </td>
                                    <td>{kpi.kpiRefNumber}</td>
                                    <td>{kpi.kpiDescription}</td>
                                    <td>{kpi.valueThrive}</td>
                                    <td><FaArrowRight style={{ marginRight: "2px" }} size={10} /></td>
                                    <td>{formatValuesInTooltip(kpi.valueFSM, unitMeasure.decimalSeparator, unitMeasure.decimalPrecision, unitMeasure.suffix)}</td>
                                </tr>)
                            })
                            :
                            <tr>
                                <td colSpan={6}>There is no data to list here</td>
                            </tr>
                        }
                        </tbody>
                    </table>

                <div style={{margin: "1rem 0 2rem"}}>
                    <Button onClick={() => ReplaceFSMData(companyID, reportYearID, moduleID, versionID)}>Confirm and Replace</Button>
                </div>
                </>
                :
                <div style={{margin: "1rem 0 2rem"}}>
                    <div>{result}</div>
                </div>
                }
            </ModalBody>
        </Modal>
    );
}

const formatValuesInTooltip = (value, decimalSeparator, decimalScale, suffix) => {
    return (
        value != null && typeof (value) == "number" ?
            <NumberFormat
                value={value}
                thousandsGroupStyle="thousand"
                decimalSeparator={decimalSeparator}
                decimalScale={decimalScale}
                suffix={suffix}
                displayType="text"
                type="text"
                thousandSeparator={true}
                allowNegative={true}
                allowLeadingZeros={false}
                allowEmptyFormatting={true}
            />
            :
            <>{value}</>
    )
}
