import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { Header, ContentTitle, CommentTextArea, VideoModalBody } from './styles';
import './video-react.css';
import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton
  } from 'video-react';

const VideoPlayer = ({ showVideoModal, setShowVideoModal, url }) => {
    const [userObject, setUserObject] = useState(JSON.parse(localStorage.getItem('@Thrive:user')));

    useEffect(() => {
        // document.querySelector(".video-react-video").parentElement.style="padding:0";
    }, []);

    return (<>
        <Modal
            className="modal-video"
            show={showVideoModal}
            onHide={() => {
                setShowVideoModal(false);
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalHeader closeButton/>            
            <VideoModalBody className='video-body'>
                <Player className="w-100" autoPlay>
                    <source src={url} />
                    <ControlBar>
                        <ReplayControl seconds={10} order={1.1} />
                        <ForwardControl seconds={30} order={1.2} />
                        <CurrentTimeDisplay order={4.1} />
                        <TimeDivider order={4.2} />
                        <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                        <VolumeMenuButton/>
                    </ControlBar>
                </Player>
            </VideoModalBody>
        </Modal>

    </>
    );
}

export default VideoPlayer;