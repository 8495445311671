import React from 'react';
import TableCrud from '../../components/TableCrud';

const VolumeTypeIndex = () => {
    const columns = [
        {
            label: "Name",
            name: "name"
        }
    ]

    return (
        <TableCrud title={"Volume Type"} table={"volumeType"} columns={columns}/>
    );
}

export default VolumeTypeIndex;
