import moment from "moment";
import React from "react";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
// import ModalBody from 'react-bootstrap/ModalBody';
import { Header, ContentTitle, CommentTextArea, ModalBody } from './styles';

export default function ModalCommentTabs({ show, setIdEditing, setRefNumber, setModalTitle, setModalComment,
    setModalCommentator, setShow, setRow, modalTitle, modalCommentator,
    modalComment, idEditing, isEditable, refNumber, row, subSectionID,
    setComment, version, allVersions = null }) {
    return (
        <Modal
            show={show}
            onHide={() => {
                setIdEditing(null);
                setRefNumber(null);
                setModalTitle(null);
                setModalComment(null);
                setModalCommentator(null);
                setShow(false);
                setRow(null);
            }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalHeader closeButton>
                <ModalTitle id="contained-modal-title-vcenter">
                    <Header>{modalTitle}</Header>
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                {modalCommentator != null && version != null &&
                    <>
                        {console.log(version)}
                        <ContentTitle>Comment - v{version != null ? version.version : " - "} {modalCommentator != "" ? `- ${modalCommentator}` : ""}
                            <span style={{ float: 'right' }}>{version != null && version.approverGlobal != null && version.dateApproverGlobal != null ? moment(version.dateApproverGlobal, "DD/MM/yyyy").format("MM/DD/yyyy") : version != null && version.approverLocal != null && version.dateApproverLocal != null ? moment(version.dateApproverLocal, "DD/MM/yyyy").format("MM/DD/yyyy") : ""}</span>
                        </ContentTitle>
                        <CommentTextArea
                            name="commentText"
                            id="commentText"
                            rows="10"
                            defaultValue={modalComment}
                            disabled={idEditing && isEditable ? false : true}
                            className={idEditing && isEditable ? "" : "block-comment"}
                            onChange={(e) => setComment(idEditing, refNumber, e.target.value, row, subSectionID)}
                        ></CommentTextArea>
                        {parseInt(version.version) > 1 ? <hr /> : ""}
                    </>
                }
                {allVersions != null &&
                    allVersions.map((vsn) => {
                        return <>
                            {vsn.commentApproverLocal != null && vsn.commentApproverGlobal == null ?
                                <>
                                    <ContentTitle>Comment - v{vsn.version} - {vsn.approverLocalFullName}
                                        <span style={{ float: 'right' }}>{vsn.dateApproverLocal != null ? moment(vsn.dateApproverLocal, "DD/MM/yyyy").format("MM/DD/yyyy") : ""}</span>
                                    </ContentTitle>
                                    <CommentTextArea
                                        name="commentText"
                                        id="commentText"
                                        rows="10"
                                        defaultValue={vsn.commentApproverLocal}
                                        disabled={true}
                                        className={"block-comment all-versions"}
                                    >
                                    </CommentTextArea>
                                    {parseInt(vsn.version) > 1 ? <hr /> : ""}
                                </>
                                :
                                <>
                                    <ContentTitle>Comment - v{vsn.version} - {vsn.approverGlobalFullName}
                                        <span style={{ float: 'right' }}>{vsn.dateApproverGlobal != null ? moment(vsn.dateApproverGlobal, "DD/MM/yyyy").format("MM/DD/yyyy") : ""}</span>
                                    </ContentTitle>
                                    <CommentTextArea
                                        name="commentText"
                                        id="commentText"
                                        rows="10"
                                        defaultValue={vsn.commentApproverGlobal}
                                        disabled={true}
                                        className={"block-comment all-versions"}
                                    ></CommentTextArea>
                                    {parseInt(vsn.version) > 1 ? <hr /> : ""}
                                </>
                            }
                        </>
                    })

                }
            </ModalBody>
        </Modal>
    );
}