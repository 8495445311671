import styled from 'styled-components';
import Collapse from 'react-bootstrap/Collapse';

export const Container = styled.div`
    select{
        animation: flipInX;
        animation-duration: 1s;
        border-radius: 4px;
        cursor: pointer;
        padding: 0 6px ;
        border: 1px solid #ECECF3;
        color: #7F7F8A;
        height: 40px;

        option{
            background-color: #F6F8FA;
            color: black;
            cursor: pointer;
        }

        &:focus-visible{
            outline: none;
        }

        &:disabled{
            background-color: #e9ecef;
            border-radius: 5px;
            border: 1px solid #ced4da;
            cursor: auto;
        }
    }

    label{
        animation: flipInX;
        animation-duration: 1s;
    }

    .form-check{
        color: #7F7F8A;
        animation: flipInX;
        animation-duration: 1s;
    }

    input{
        &:focus{
            box-shadow: none;
        }
    }

    button{
        border-radius: 5px;
        color: #fff;
        margin-right: 19px;
        font-size: 13px;
        cursor: pointer;
        font-weight: bold;
        display: inline-block !important;
        vertical-align: middle !important;
        animation: flipInX;
        animation-duration: 1s;

        &:hover{
            opacity: 0.8;
        }
    }

    .apply-btn{
        background-color: #112A61;
        border: 1px solid #112A61;
        padding-top: 10px;
        padding-bottom: 10px;
        cursor: pointer;
    }
`;

export const Table = styled.table`
    font-size: 13px;
    width: 100%;
    animation: fadeIn;
    animation-duration: 1s;

    thead{
        background-color: #112A61;
        height: 40px;
        z-index: 2;

        tr{
            th{
                font-weight: bold;
                color: #FFFFFFFF;
                padding: 14px;
            }

            th:first-child{
                border-radius: 8px 0px 0px 0px;
            }

            th:last-child{
                border-radius: 0px 8px 0px 0px;
            }
        }

        &.sticky-top{
            top: -1px!important;
        }
    }

    tbody:first-child{
        font-weight: bold;
    }

    tbody{
        background-color: #FFFFFFFF;
        box-shadow: 0px 3px 20px #0000000d;

        tr{
            border: 1px solid #ECECF3;
            border-left: #FFFFFF;
            border-right: #FFFFFF;

            &.shadow-box{
                box-shadow: 0 4px 2px -2px gray;
            }

            td{
                padding: 14px;

                &:last-child{
                    svg{
                        float: right;

                        &:hover{
                            opacity: 0.5;
                            cursor: pointer;
                        }
                    }
                }
            }

            &:last-child{
                td:first-child{
                    border-radius: 0px 0px 0px 8px;
                }

                td:last-child{
                    border-radius: 0px 0px 8px 0px;
                }

                border-bottom: #F6F8FA;
            }

            .read-only{
                text-align: left;
                background-color: transparent;
                border: 0;
            }

            .value-change{
                color: #4DAE49!important;
            }

            svg{
                color: #112A61;
                animation: flipInX;
                animation-duration: 2s;
            }

            &.sticky-top{
                top: -1px!important;
                background-color: #F6F8FA;
                font-weight: bold;
            }

            .badge{
                color: #fff!important;
                background-color: #112A61;
                border-radius: 10rem;
                color: #112A61;
                animation: flipInX;
                animation-duration: 1s;
            }
        }
    }
`;
export const CollapseStyled = styled(Collapse)`
    vertical-align: 'middle';
    background-color: rgb(250 250 250);

    animation: fadeIn !important;
    animation-duration: 1s !important;
`

