import React from "react";
import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import Form from 'react-bootstrap/Form'
import Button from '../Button';
import { Header, ContentTitle, CommentText, CancelButton} from './styles';
import { useHistory } from "react-router";

export default function ReadConsentModal(props) {
    const history = useHistory();
    const role = localStorage.getItem('@Thrive:userRoles');

    const handleCancel = () => {
        props.setModalReadShow(false)
        props.userObject.isFirstLogin = false;                
        localStorage.setItem('@Thrive:user', JSON.stringify(props.userObject));
    }  

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader>
          <ModalTitle id="contained-modal-title-vcenter">
            <Header>{"Welcome to Thrive Digital!"}</Header>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <ContentTitle style={{margin: '1rem 0 2rem'}}>This is your first access as a local approver for {props.userObject?.company?.name != null ? props.userObject?.company?.name : "Global"} and it is mandatory for you to read the historical data before approving/rejecting information. Please select the last crop year approved and confirm reading at the end of each module.</ContentTitle> 
            <Button id="confirmBtn" style={{ marginTop: '2rem' }} type="submit" onClick={() => handleCancel()}>Confirm and Continue</Button>
        </ModalBody>        
      </Modal>
    );
}