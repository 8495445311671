import moment from "moment";
import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { FaArrowRight } from "react-icons/fa";
import { FiUpload } from "react-icons/fi";
import NumberFormat from "react-number-format";
import Api, { exceptionNotificationAPI } from "../../services/api";
import Button from "../Button";
import GetTypeInput from "../FormInput/set";
import { success_message } from "../Toast";
// import ModalBody from 'react-bootstrap/ModalBody';
import { Header, ModalBody } from './styles';
import { transform } from "typescript";
import { margin } from "polished";

export default function ModalRejectPartial({showModalRejectPartial, setShowModalRejectPartial, ssksToReject, moduleID, selectedCompanyID, selectedReportYearID, tobaccoTypeID, loadData, setLoading, setShowFixedHeader, setLoadingSection, userRoles}) {

    const [selectedSskpis, setSelectedSskpis] = useState([]);
    async function UnselectAll() {
        setSelectedSskpis([]);
    }

    async function SelectAll() {
        var AllSsks = [];
        setSelectedSskpis([]);

        AllSsks = ssksToReject;
        setSelectedSskpis(AllSsks)
    }

    async function SelectToReject(ssk, id) {

        var SskpiItem = selectedSskpis.find(x => x.subSectionKpiID == ssk.subSectionKpiID && x.rowIndex == ssk.rowIndex && x.columnIndex == ssk.columnIndex);

        if (SskpiItem != null) {
            var selectedSSksFiltered = selectedSskpis.filter(x => x.subSectionKpiID != ssk.subSectionKpiID || x.rowIndex != ssk.rowIndex || x.columnIndex != ssk.columnIndex);;
            setSelectedSskpis(selectedSSksFiltered);
        }
        else {
            var newArray = [];
            newArray = selectedSskpis;
            newArray = [...newArray, ssk];
            setSelectedSskpis(newArray);
        }
    }

    async function RejectSelectedSskpis(){
        setLoading(true);

        let rejectedSskList = []

        selectedSskpis.forEach((ssk) => {
            rejectedSskList.push(
                {
                    "subSectionKPIID": ssk.subSectionKpiID,
                    "columnIndex": ssk.columnIndex,
                    "rowIndex": ssk.rowIndex,
                    "rejectedInThriveValueVersionID": ssk.rejectedInVersion
                }
            );
        });

        const objToRequest = {
            moduleID: moduleID,
            selectedCompanyID: selectedCompanyID,
            commentApprover: "Partial Reject",
            reportYearID: selectedReportYearID,
            tobaccoTypeID: tobaccoTypeID != null ? tobaccoTypeID : null,
            rejectedSskList: rejectedSskList
        }



        await Api.post(`ThriveValue/reject`, objToRequest).then((result) => {
            success_message("The data was rejected successfully!");
            loadData();
            if (setShowFixedHeader != null)
                setShowFixedHeader(false);
        }).catch((error) => {
            exceptionNotificationAPI(error);
            setLoading(false);
            if(setLoadingSection != null) {
                setLoadingSection(false);
            }
            if (setShowFixedHeader != null)
                setShowFixedHeader(false);
        });

    }


    return (

        <Modal
            show={showModalRejectPartial}
            onHide={() => {
                setShowModalRejectPartial(false);
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalHeader closeButton>
                <ModalTitle id="contained-modal-title-vcenter">
                    <Header>{userRoles.includes("RoleAdmin")  ? 'Partial reactivation' : 'Partial approval / rejection'}</Header>
                </ModalTitle>
            </ModalHeader>
            <ModalBody>

                <div style={{margin: "0.5rem 0"}}>{userRoles.includes("RoleAdmin") ? 'Please, select below all the KPIs to reactivate. Reactivated KPIs will be reopened for edition and need to go through the approval process again.' : 'Please, select below all the KPIs to reject. Rejected KPIs will be reopened for edition and need to go through the approval process again. Note that all the others that are not selected will be automatically closed for edition.'}</div>
                <div style={{borderBottom: "var(--bs-modal-header-border-width) solid", marginTop: "1rem", marginBottom:"1rem", borderColor: "#d2d2d2"}}></div>

                <div style={{marginTop:"1rem", marginBottom:"1rem"}} >
                    <Button style={{marginRight: "1rem"}} onClick={() => SelectAll()}>Select all</Button>
                    <Button onClick={() => UnselectAll()}>Unselect all</Button>
                </div>
                <>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col" className="col-sm-1">Select KPIs to reject</th>
                                <th scope="col" className="col-sm-1">KPI Number</th>
                                <th scope="col" className="col-sm-5">KPI Description</th>
                                <th scope="col" className="col-sm-3">Column</th>
                                <th scope="col" className="col-sm-2">Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ssksToReject?.length > 0 ? ssksToReject.map((ssk) => {
                            var unitMeasure = GetTypeInput(ssk.unitMeasure.unitMeasureID, ssk.unitMeasure.type);
                                return (<tr>
                                    <td style={{verticalAlign: "middle"}}>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" checked={selectedSskpis.find(x => x == ssk) != null ? true : false} className="custom-control-input" onChange={(e) => SelectToReject(ssk, e.target.id)} />
                                        </div>
                                    </td>
                                    <td style={ssk.isHighlight ? {color:"#FBBA00"}:{}}>{ssk.isBold ? <b>{ssk.refNumber}</b> : ssk.refNumber}</td>
                                    <td>{ssk.isBold ? <b>{ssk.kpiDescription}</b> : ssk.kpiDescription}</td>
                                    <td>{ssk.isBold ? <b>{ssk.columnDesc}</b> : ssk.columnDesc}</td>
                                    <td>
                                        {ssk.answer != null && !(typeof ssk.answer === 'string') ?
                                            ssk.isBold ?
                                            <b>
                                                <NumberFormat
                                                    value={ssk.answer}
                                                    thousandsGroupStyle="thousand"
                                                    decimalSeparator={unitMeasure.decimalSeparator}
                                                    decimalScale={unitMeasure.decimalPrecision}
                                                    suffix={unitMeasure.suffix}
                                                    displayType="text"
                                                    type="text"
                                                    thousandSeparator={true}
                                                    allowNegative={true}
                                                    allowLeadingZeros={false}
                                                    allowEmptyFormatting={true}
                                                />
                                            </b> :
                                                <NumberFormat
                                                value={ssk.answer}
                                                thousandsGroupStyle="thousand"
                                                decimalSeparator={unitMeasure.decimalSeparator}
                                                decimalScale={unitMeasure.decimalPrecision}
                                                suffix={unitMeasure.suffix}
                                                displayType="text"
                                                type="text"
                                                thousandSeparator={true}
                                                allowNegative={true}
                                                allowLeadingZeros={false}
                                                allowEmptyFormatting={true}
                                            />
                                        : ssk.answer != null ? ssk.isBold ? <b>{ssk.answer}</b> : ssk.answer : ""
                                        }

                                    </td>
                                </tr>)
                            })
                            :
                            <tr>
                                <td colSpan={6}>There is no data to list here</td>
                            </tr>
                        }
                        </tbody>
                    </table>


                </>
                <div style={{margin: "0.5rem 0"}}>
                    <Button style={{margin: "0 1rem", float: "right", 'text-transform':"none"}} onClick={() => RejectSelectedSskpis()}> {userRoles.includes("RoleAdmin") ? 'REACTIVATE SELECTED KPIs' : 'REJECT SELECTED KPIs'}</Button>
                </div>

            </ModalBody>
        </Modal>
    );
}

