import styled from 'styled-components';

export const Card = styled.div`
    border-radius: 8px;
    padding: 1rem!important;
    background-color: #FFFFFF;

    .pb-3 {
        padding-bottom: 1rem!important;
        position: relative;
    }

    .avn-skeleton > * {
        animation: avnSkeleton 0.125s ease-out forwards;
    }

    .avn-skeleton-header .avn-skeleton-text:nth-child(4n + 1) {
        width: 55%;
    }

    .avn-skeleton-text:nth-child(4n + 1) {
        width: 40%;
        height: 0.9rem;
        padding: 0.05rem 0 0.05rem 0;
    }

    @keyframes avnSkeletonLoader {
        0% {
          background-image: linear-gradient(to right,  rgba(255, 255, 255, 0.65) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%)
        }

        50% {
            background-image: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(255, 255, 255, 0.65) 50%, rgba(0, 0, 0, 0) 100%)
        }
      
        100% {
          background-image: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0, 0, 0, 0) 50%,  rgba(255, 255, 255, 0.65) 100%)
        }
    }

    .avn-skeleton-text {
        display: inline-block;
        position: relative;
        background-color: rgba(0, 0, 0, 0.15);
        background-repeat: no-repeat;
        animation: avnSkeletonLoader 0.6s ease-in-out infinite;
        border-radius: 10px;
    }

    .avn-skeleton-loader {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.2rem;
        background-color: rgba(0, 0, 0, 0.15);
        background-repeat: no-repeat;
        animation: avnSkeletonLoader 0.8s ease-in-out infinite;
        border-radius: 10px;
    }
    
    .pt-3 {
        padding-top: 1rem!important;
    }

    .avn-skeleton-text:nth-child(4n + 1) {
        width: 40%;
        height: 0.9rem;
        padding: 0.05rem 0 0.05rem 0;
    }

    .avn-skeleton-text:nth-child(4n + 2) {
        width: 80%;
        height: 0.6rem;
        padding: 0.15rem 0 0.15rem 0;
    }

    .avn-skeleton-text:nth-child(4n + 3) {
        width: 85%;
        height: 0.9rem;
        padding: 0.05rem 0 0.05rem 0;
    }

    .avn-skeleton-text:nth-child(4n + 4) {
        width: 70%;
        height: 0.6rem;
        padding: 0.15rem 0 0.15rem 0;
    }
`