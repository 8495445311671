import styled from 'styled-components';
import { options } from 'toastr';

export const Container = styled.div`

    .dashboard-body{
        margin-top: 32px;
        .dashboard-body-filters{
            margin-bottom: 28px;
            div{
                select{
                    border-radius: 2px;
                    cursor: pointer;
                    padding: 0 6px ;
                    border: 2px solid #d5d5e0;
                    color: #000000;
                    height: 30px;
                    width: 100%;
                    font-weight: bold;
                    box-shadow: 0px 3px 20px #0000000D;
                    option{
                        background-color: #F6F8FA;
                        color: #000000;
                        cursor: pointer;
                    }
                    &:focus-visible{
                        outline: none;
                    }
                }
            }
        }
    }

    .dashboard-title{
        display: flex;
        margin-top: 4rem;
        justify-content: space-between;
        align-items: center;
        h3{
            color: #112A61;
            font-weight: bolder;
            padding-left: 1rem;
        }
    }

    .form-rules{
        padding: 10px;
        margin-top: 18px;
        display: block;
        border-radius: 6px;
    }

    .form-existent{
        padding: 10px;
        margin-top: 18px;
        display: block;
        border-radius: 6px;
    }

    .text {
        color: #102a63;
    }

    tr:first-child td:first-child { border-top-left-radius: 8px; }
    tr:first-child td:last-child { border-top-right-radius: 8px; }

    tr:last-child td:first-child { border-bottom-left-radius: 8px; }
    tr:last-child td:last-child { border-bottom-right-radius: 8px; }

    tr:first-child td:first-child { border-bottom-left-radius: 8px; }
    tr:first-child td:last-child { border-bottom-right-radius: 8px; }

    tr:last-child td:first-child { border-top-left-radius: 8px; }
    tr:last-child td:last-child { border-top-right-radius: 8px; }

    .form {
        transform:translate(8%,-24%);
        padding-left: 10px;
    }

    .value {
        transform:translate(2%,-24%);
        padding-left: 0rem;
        display: flex;
    }

    .checkbox {
        width: 17px;
        height: 17px;
        background-color: #fff;
        border: 1px solid #c5c5c5;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;

        margin-right: 10px;
        cursor: pointer;
    }
        input[type="checkbox"] {
        appearance: none;
    }

    input[type="checkbox"]:checked::before {
    content: "V";
    color: #9da09d;
    font-weight: bolder;
    }

    .discussion{
        margin-top: 15px;
        h5{
            color: #000000;
            font-weight: bolder;
            font-size: 15px;
        }
        textarea{
            border: 1px solid #cfcfcf;
            border-radius: 4px;
        }
    }

    textarea:focus{
    box-shadow: 0 0 0 0;
    border: 1px solid #cfcfcf;
    outline: 0;
}


    .options{
        background: #fff;
        margin-bottom: 10px;
        tr{
            td{
                padding: 7px 15px;
            }
        }
    }

    .form-title{
        width: 100%;
        span{
            font-weight: bold;
            color: #102A63;
            padding-right: 3rem;
        }
    }

    .form-title p{
        font-size: small;
        color: #102a63;
        padding-top: 3px;
        margin-bottom: 0px;
    }

    .icon{
        margin: 8px 20px -8px 5px;
    }

    .blue-dark{
        background: #102a63;
        color: #fff;
        border: none;
        border-radius: 8px;
        height: 35px;
        width: 160px;
        padding: 5px;
        font-weight: bolder;
    }

    .gray{
        background: #b0b0bb;
        color: #fff;
        border: none;
        border-radius: 8px;
        height: 35px;
        width: 160px;
        padding: 5px;
        font-weight: bolder;
    }

    .buttons {
    display: flex;
    }

    .buttons-container{
        margin-right: 10px;
    }

    .buttons button {
    margin-left: 15px;
    }

    .no-event{
        color: #102a63;
        font-weight: bolder;
        display: flex;
        justify-content: center;
        height: 70px;
        width: 100%;
        align-items: center;
        display: flex;
        border-radius: 6px;
    }
`;

export const CustomCard = styled.div`
  background-color: #ffffff; /* Set your background color */
  border: 1px solid #eaeaea; /* Set your border style */
  border-radius: 8px; /* Set your border radius */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Set your box shadow */

  .header {
    background-color: #ffffff; /* Set header background color */
    padding: 16px; /* Set header padding */
  }

  h2 {
    margin: 0; /* Reset margin for h1 inside the header */
    color: #333333; /* Set header text color */
  }

  .body {
    padding: 16px; /* Set body padding */
  }

  p {
    margin: 0; /* Reset margin for p inside the body */
    color: #666666; /* Set body text color */
  }

  .filters-section{
    margin-left: 5px;
    margin-bottom: 1rem;
  }

  select{
        border-radius: 4px;
        cursor: pointer;
        margin: 16px;
        border: 1px solid #ECECF3;
        color: #7F7F8A;
        height: 30px;
        width: 100%;

        option{
            background-color: #F6F8FA;
            color: black;
            cursor: pointer;
        }

        &:focus-visible{
            outline: none;
        }
    }
`;
