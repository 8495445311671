import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/auth';

import DashboardGlobal from '../../components/DashboardGlobal';
import DashboardLocal from '../../components/DashboardLocal';

import TableLoading from '../../components/TableLoading';

const Dashboard = () => {
    const { user } = useAuth();
    const [userObject, setUserObject] = useState(null);

    useEffect(() => {
        setUserObject(typeof (user) == "string" ? JSON.parse(user) : user);
    }, []);

    const redirectAdditionalDataVolume = () => {
        window.location.href = window.location.origin + '/additionalData'
    }

    const redirectToS3 = () => {
        window.location.href = window.location.origin + '/s3EmissionsSimulator'
    }

    return (
        <>
            {
                userObject ?
                    userObject.roleID == '3E4E8EDC-E73B-403E-BD25-62BCB520B352' || userObject.roleID == 'BA8DE81B-A61C-4FB2-9034-84FA3D92B45E' ?
                        redirectAdditionalDataVolume()
                        :
                        userObject.roleID == 'B1B18EDC-E73B-403E-BD25-D712BD29339D' ?
                            redirectToS3()
                            :
                            userObject.companyID === null || (userObject.groupID !== null && userObject.roleID == 'EA179266-31C9-4634-9D37-0A56A1BA79CF') ?
                                <DashboardGlobal user={userObject} />
                                :
                                <DashboardLocal />

                    :
                    <TableLoading />
            }
        </>
    );
}

export default Dashboard;