import { Container, Grid } from '@material-ui/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FiArrowLeft } from 'react-icons/fi';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import Api, { exceptionNotificationAPI } from '../../../../services/api';
import { setNumber, setText } from '../../../../services/simulation_functions';
import FormInput from '../../../FormInput';
import GetTypeInput from '../../../FormInput/set';
import ModalSimulation from '../../../ModalSimulations';
import PleaseRead from '../../../PleaseRead';
import { info_message } from '../../../Toast';
import { DivLeftButtons, DivRightButtons, FooterTable, HeaderData, HeaderTable, Section } from './styles';

const SimulationVolume = ({ stageData, stageColumns, stage1Block, setStage1Block, setStage2Block, setAllValues2, name, setName, typeSimulation, simpleGroup, values, setValues, rows, setRows, companies, actualCompanies, continueCuringSimulations, goTo }) => {
    const history = useHistory();
    const formRef = useRef(null);

    const [download, setDownload] = useState(false);
    const [jumpCompanyID, setJumpCompanyID] = useState(null);
    const [jump, setJump] = useState(false);
    const [selectedCompanie, setSelectedCompanie] = useState(null);
    const [newCompanies, setNewCompanies] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [groups, setGroups] = useState();
    const [suppliers, setSuppliers] = useState();

    useEffect(() => {
        var userObject = JSON.parse(localStorage.getItem('@Thrive:user'));
        if (userObject.companyID != null) {
            setSuppliers([{ value: userObject.companyID, text: "My Company" }]);
            setJumpCompanyID(userObject.companyID);
            setGroups([]);
        } else if (userObject.groupID != null) {
            getSuppliersByGroup(userObject.groupID);
            setGroups([{ value: userObject.groupID, text: "My Group" }]);
        } else {
            getGroups();
            getSuppliers();
        }

        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Add smooth scrolling animation
        });
    }, []);

    const getGroups = async () => {
        Api.get('Group/getAll').then((result) => {
            if (result && result.data) {
                const options = result.data.response.filter(x => x.enableSimulation).map(item => ({ value: item.groupID, text: item.name }));

                setGroups(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const getSuppliersByGroup = async (id) => {
        Api.get('Group/get?id=' + id).then((result) => {
            if (result && result.data) {
                const options = result.data.response.companies.map(item => ({ value: item.companyID, text: item.name }));

                setSuppliers(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const getSuppliers = async () => {
        Api.get('Company/getAll').then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.companyID, text: item.name }));

                setSuppliers(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    async function addCompanieToData() {
        if (selectedCompanie === null) {
            info_message("Please select one company");
            return;
        }

        setNewCompanies([...newCompanies, selectedCompanie.value]);

        const updatedData = stageData.stageSections.flatMap((ss) =>
            ss.stageColumns.map((sc) => {
                const newValue = {
                    companyID: selectedCompanie.value,
                    row: rows,
                    refNumber: sc.refNumber,
                    stageColumn: sc,
                    stageColumnID: sc.stageColumnID,
                    textValue: ["1001"].includes(sc.refNumber) ? "" + selectedCompanie.value : null,
                    numberValue: ["1002", "1003", "1004", "1007"].includes(sc.refNumber) ? 0 : null,
                };
                return newValue;
            })
        );

        setRows(rows + 1);
        setSelectedCompanie(null);
        setValues([...values, ...updatedData]);
    }

    function getExcel() {
        var userObject = JSON.parse(localStorage.getItem('@Thrive:user'));
        setDownload(true);
        Api.get(`Simulation/ExcelImport/${userObject.selectedReportYearID}`).then((r) => {
            var binaryString = window.atob(r.data.response);
            var bytes = new Uint8Array(binaryString.length);
            var bytesMapped = bytes.map((byte, i) => binaryString.charCodeAt(i));
            var blob = new Blob([bytesMapped], { type: "application/octet-stream" });
            var link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.download = "S3_Excel_Template.xlsx";
            link.target = "_blank";
            link.click();

            setDownload(false);
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    function handleFileChange(e) {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (evt) => {
            const data = evt.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0]; // Assuming the first sheet is the one you want to read
            const sheet = workbook.Sheets[sheetName];

            const range = XLSX.utils.decode_range(sheet['!ref']);
            range.e.c = Math.min(7, range.e.c); // Set the end column index to 7 (column H)

            const excelData = XLSX.utils.sheet_to_json(sheet, {
                header: 1,
                range, // Use the range option to capture the first 8 columns
                defval: '', // Set the default value for missing cells to an empty string
            });
            var newValues = [...values];
            var newCompaniesList = [...newCompanies];
            var newRows = rows;

            excelData.map((row) => {
                while (row.length < 8) row.push('');

                var findCompanie = actualCompanies.find(x => x.name == row[0]);

                const value4 = row[4] !== '' ? row[4] : null;
                const value6 = row[6] !== '' ? row[6] : null;
                const value5 = row[5] !== '' ? row[5] : null;
                const value7 = row[7] !== '' ? row[7] : null;

                const sumFCVDFC = (value4 != null && value6 != null) ? (value4 + value6) : (value4 != null && value6 == null) ? value4 : (value6 != null && value4 == null) ? value6 : null;
                const others = (value5 != null && value7 != null) ? (value5 + value7) : (value5 != null && value7 == null) ? value5 : (value7 != null && value5 == null) ? value7 : null;

                if (findCompanie != null) {
                    var oldValues = newValues.filter((x) => x.companyID != findCompanie.companyID);
                    var unifiedValues = newValues.filter((x) => x.companyID == findCompanie.companyID);

                    const ref1005Index = unifiedValues.findIndex((x) => x.refNumber == "1005");
                    if (ref1005Index != -1)
                        unifiedValues[ref1005Index].numberValue = sumFCVDFC;

                    const ref1006Index = unifiedValues.findIndex((x) => x.refNumber == "1006");
                    if (ref1006Index != -1)
                        unifiedValues[ref1006Index].numberValue = others;

                    newValues = oldValues.concat(unifiedValues);
                } else {
                    findCompanie = companies.find(x => x.name == row[0]);
                    if (findCompanie != null && (sumFCVDFC || others)) {
                        newCompaniesList.push(findCompanie.companyID);

                        const updatedData = stageData.stageSections.flatMap((ss) =>
                            ss.stageColumns.map((sc) => {
                                const newValue = {
                                    companyID: findCompanie.companyID,
                                    row: newRows,
                                    refNumber: sc.refNumber,
                                    stageColumn: sc,
                                    stageColumnID: sc.stageColumnID,
                                    textValue: ["1001"].includes(sc.refNumber) ? "" + findCompanie.companyID : null,
                                };
                                newValue.numberValue = null;
                                if (["1002", "1003", "1004", "1007"].includes(sc.refNumber))
                                    newValue.numberValue = 0;
                                if (sc.refNumber == "1005")
                                    newValue.numberValue = sumFCVDFC;
                                if (sc.refNumber == "1006")
                                    newValue.numberValue = others;

                                return newValue;
                            })
                        );

                        newRows = newRows + 1;
                        newValues = newValues.concat(updatedData);
                    }
                }
                return row;
            });

            setNewCompanies(newCompaniesList);
            setRows(newRows);
            setValues(newValues);
        };

        reader.readAsBinaryString(file);
    };

    useEffect(() => {
        if (jump) {
            const companies = values.filter(x => ["1005", "1006"].includes(x.refNumber) && x.numberValue != null && x.numberValue != "");
            const companyIDs = companies.map(company => company.companyID);

            if (simpleGroup && simpleGroup.some(x => x.companyID != null))
                simpleGroup.forEach(x => companyIDs.push(x.companyID));

            const distinctCompanyIDs = [...new Set(companyIDs)];

            const companiesArray = distinctCompanyIDs.map(x => {
                const findCompanie = suppliers.find(s => s.companyID == x);
                return { value: x, label: findCompanie?.name };
            });

            if (simpleGroup && simpleGroup.some(x => x.groupID != null))
                continueCuringSimulations({ value: 2 }, simpleGroup.map(x => ({ value: x.groupID, label: x.groupName })), [], jump);
            else
                continueCuringSimulations({ value: 1 }, [], companiesArray, jump);
        }
    }, [jump])

    function normaliseValue(value, decimals = 2) {
        if (!value) return 0;
        const parsed = Number(value).toFixed(decimals);
        if (isNaN(parsed)) return 0;
        return parseFloat(parsed);
    }

    return (
        <>
            <div style={{ backgroundColor: "#fff", height: 100, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                <Container className="container">
                    <Section>
                        <div className="row">
                            <div className="col-1">
                                <h2>
                                    <FiArrowLeft color={"#FFFFFF"} className="icon-left" size={10} onClick={() => history.push("/s3EmissionsSimulator")} />
                                </h2>
                            </div>
                            <div className="col-11">
                                <input
                                    style={{ backgroundColor: "#fff" }}
                                    autoComplete={"off"}
                                    defaultValue={name}
                                    onChange={(e) => setName(e.target.value)}
                                    type={"text"}
                                    disabled={stage1Block}
                                    placeholder="Simulation Name"
                                />
                            </div>
                        </div>
                    </Section>
                </Container>
            </div>
            <Container>
                <div style={{ marginLeft: -10, marginRight: -10 }}>
                    <PleaseRead maxWidth="100%" guidance={'Welcome! \n' +
                        'You are in our first stage of simulation. \n' +
                        'To get started, please follow the steps below: \n' +
                        '- Insert a title for your simulation in the top of the page \n' +
                        '- Make sure you have selected a year with prior information reported \n' +
                        '- The S3 simulator enables to simulate curing fuels and volume purchased - if you want to simulate only curing fuels, please go to the bottom of the page and click in “Save and continue for curing simulation” \n' +
                        '- If you want to simulate volume purchased, add the new volumes to be considered in the "Simulated volume purchased" table - this means volume purchased by BAT \n' +
                        '- If you leave a field blank, the system will automatically consider flat volume (meaning that to reset a company´s volume to zero, you need to add 0 in the "Simulated volume purchased" fields) \n' +
                        '- If you do not want to run a simulation for curing, after adjusting the volumes, click on "Save and see results" \n' +
                        'For global users: \n' +
                        '- If you want to add volume to a company that is not in the table, go to the bottom of the page and click on "Select company" and "Add company" \n' +
                        '- To upload volumes via excel, please first export the Excel template, adjust and upload it. \n' +
                        '- Select “Single” to adjust curing fuels company by company or select “Group” if you want the system to sum all curing fuels of the companies belonging to the group. \n' +
                        'E.g. for BAT group: Choosing simple, you need to select all the companies one by one to adjust the curing fuels (the system will open one page per company applying the specific efficiency per curing fuel). By selecting group, the system will sum the consumption of the similar curing fuels of all BAT companies and present a single page with an average efficiency per curing fuel. \n' +
                        '- Select the companies or group and continue to the second stage \n' +
                        '*To create a specific group that is not available or to add different suppliers to the list, contact the GLCC Data Analytics team'} style={{ margin: -10 }} />
                </div>
                <Section>
                    <Grid container spacing={1} direction='row'>
                        {!stage1Block && <Grid item xs={12}>
                            <DivRightButtons style={{ width: "100%" }}>
                                <button type="button" style={{ padding: 10, margin: "0rem 0rem 0rem 1rem" }} disabled={download || stage1Block} onClick={() => getExcel()} className="btn btn-custom">Excel Template</button>
                                <>
                                    <div className="upload-btn-wrapper">
                                        <button className="btn btn-custom" style={{ cursor: "pointer", padding: 10, margin: "0rem 0rem 0rem 1rem", height: 43 }} disabled={stage1Block}>Upload Excel</button>
                                        <input type="file" onChange={handleFileChange} disabled={stage1Block} style={{ cursor: "pointer", height: 43 }} />
                                    </div>
                                </>
                            </DivRightButtons>
                        </Grid>}
                    </Grid>
                    <Form onSubmit={() => { }} ref={formRef}>
                        <Grid container spacing={1} direction='row'>
                            {stageData.stageSections && stageData.stageSections.map((ss, is) => {
                                return <Grid item xs={is == 0 ? 7 : 5} style={{ marginTop: 10 }} key={ss.stageSectionID}>
                                    <Grid item container className="grid-header" direction='row'>
                                        <span>{ss.description}</span>
                                    </Grid>
                                    <Grid item container direction='row' style={{ position: "sticky", top: 0, zIndex: 10 }}>
                                        {ss.stageColumns && ss.stageColumns.map((sc, ih) => {
                                            return <Grid item xs={ih == 0 && is == 0 ? 6 : (is != 0 ? is == 1 ? 4 : 3 : 2)} key={sc.stageColumnID}>
                                                <HeaderTable style={{ justifyContent: ih == 0 && is == 0 ? "flex-start" : "center" }} key={ss.stageColumnID}>
                                                    <label>{sc.name}</label>
                                                </HeaderTable>
                                            </Grid>
                                        })}
                                    </Grid>
                                    <Grid item container direction='row' style={{ background: "#fff", borderRadius: "0 0 6px 6px", paddingBottom: 5 }}>
                                        {[...Array(rows)].map((r, row) => {
                                            return <Grid item container spacing={1} direction='row' key={row} style={{ position: "relative", margin: "1px 0px" }}>
                                                {
                                                    ss.stageColumns && ss.stageColumns.map((sc, ih) => {
                                                        var unitMeasure = GetTypeInput(sc.unitMeasureID, sc.unitMeasure.type);
                                                        var isNumber = (unitMeasure.inputType == "number" || unitMeasure.inputType == "decimal") ? true : false;
                                                        var calculation = sc.inputCalc == "Calculation" ? sc.simpleCalculation : null;
                                                        var isCalculation = false;
                                                        var companyID = null;
                                                        var groupID = null;
                                                        var valueItem = values.find(x => x.refNumber == sc.refNumber && x.row == row);

                                                        // TODO: verificar forma melhor de fazer essa verificação
                                                        const changed = sc.refNumber == 1007 && values.some(x => (x.refNumber == 1005 || x.refNumber == 1006) && x.row == row && x.numberValue != null);

                                                        if (calculation != null && sc.inputCalc == "Calculation") {
                                                            isCalculation = true;
                                                        }

                                                        var findValue = null;
                                                        var tooltipValue = null;

                                                        if (valueItem != null) {
                                                            companyID = valueItem?.companyID;
                                                            if (sc.unitMeasureID == 6) {
                                                                var findC = actualCompanies.find(x => x.companyID == valueItem?.companyID);
                                                                if (findC == null) {
                                                                    findC = companies.find(x => x.companyID == valueItem?.companyID);
                                                                }
                                                                findValue = findC.name;
                                                                tooltipValue = findValue;
                                                            } else {
                                                                findValue = valueItem?.numberValue;
                                                                tooltipValue = normaliseValue(findValue, unitMeasure.decimalPrecision)
                                                            }
                                                        }

                                                        return <OverlayTrigger
                                                            delay={{ hide: 0, show: 100 }}
                                                            overlay={(props) => <Tooltip {...props}>{tooltipValue}</Tooltip>}
                                                            placement={"bottom"}
                                                            key={sc.stageColumnID}
                                                        >
                                                            <Grid item xs={ih == 0 && is == 0 ? 6 : (is != 0 ? is == 1 ? 4 : 3 : 2)}>
                                                                {
                                                                    isCalculation && isNumber ?
                                                                        <HeaderData style={{ textAlign: ih == 0 && is == 0 ? "left" : "center", justifyContent: ih == 0 && is == 0 ? "flex-start" : "center", backgroundColor: changed ? "#FFFFCC" : "#D9F3D7" }}>
                                                                            <NumberFormat
                                                                                name={sc.name + is + ih}
                                                                                id={sc.name + is + ih}
                                                                                autoComplete="off"
                                                                                className="read-only-total"
                                                                                disabled
                                                                                title={""}
                                                                                thousandsGroupStyle="thousand"
                                                                                value={findValue != null ? findValue : ""}
                                                                                decimalSeparator={unitMeasure.decimalSeparator != "" ? unitMeasure.decimalSeparator : "."}
                                                                                decimalScale={unitMeasure.decimalPrecision}
                                                                                suffix={findValue != null ? unitMeasure.suffix : ""}
                                                                                displayType="text"
                                                                                type="text"
                                                                                thousandSeparator={true}
                                                                                allowNegative={false}
                                                                                allowLeadingZeros={false}
                                                                                allowEmptyFormatting={true}
                                                                            />
                                                                        </HeaderData>
                                                                        :
                                                                        isNumber && !isCalculation ?
                                                                            <NumberFormat
                                                                                name={sc.name + is + ih}
                                                                                id={sc.name + is + ih}
                                                                                autoComplete="off"
                                                                                thousandsGroupStyle="thousand"
                                                                                value={findValue != null ? findValue : ""}
                                                                                decimalSeparator={unitMeasure.decimalSeparator != "" ? unitMeasure.decimalSeparator : "."}
                                                                                decimalScale={unitMeasure.decimalPrecision}
                                                                                suffix={unitMeasure.suffix}
                                                                                displayType="input"
                                                                                type="text"
                                                                                thousandSeparator={true}
                                                                                allowNegative={false}
                                                                                allowLeadingZeros={false}
                                                                                allowEmptyFormatting={false}
                                                                                style={{ borderRadius: 6, textAlign: "center" }}
                                                                                disabled={stage1Block}
                                                                                onValueChange={(valuesToSet, sourceInfo) => {
                                                                                    setNumber({
                                                                                        'stageColumn': sc,
                                                                                        'stageSection': ss,
                                                                                        'ref': sc.refNumber,
                                                                                        'companyID': companyID,
                                                                                        'groupID': groupID,
                                                                                        'value': (valuesToSet.formattedValue != "" ? parseFloat(valuesToSet.floatValue) : ""),
                                                                                        'row': row,
                                                                                        'totalRows': rows,
                                                                                        'values': values,
                                                                                        'setValues': setValues,
                                                                                        'stageColumns': stageColumns,
                                                                                        'uniqueValue': true
                                                                                    })
                                                                                }}
                                                                            />
                                                                            :
                                                                            isNumber ?
                                                                                <FormInput
                                                                                    name={sc.name + is + ih}
                                                                                    id={sc.name + is + ih}
                                                                                    inputType={"number"}
                                                                                    value={findValue}
                                                                                    autoComplete="off"
                                                                                    style={{ borderRadius: 6, textAlign: "center" }}
                                                                                    disabled={stage1Block}
                                                                                    onChange={(f) => {
                                                                                        setText({
                                                                                            'stageColumn': sc,
                                                                                            'stageSection': ss,
                                                                                            'ref': sc.refNumber,
                                                                                            'value': f.target.value,
                                                                                            'companyID': companyID,
                                                                                            'groupID': groupID,
                                                                                            'row': row,
                                                                                            'totalRows': rows,
                                                                                            'values': values,
                                                                                            'setValues': setValues,
                                                                                            'stageColumns': stageColumns,
                                                                                        })
                                                                                    }}
                                                                                />
                                                                                :
                                                                                <FormInput
                                                                                    name={sc.name + is + ih}
                                                                                    id={sc.name + is + ih}
                                                                                    autoComplete="off"
                                                                                    value={findValue != null ? findValue : ""}
                                                                                    type={"text"}
                                                                                    style={{ backgroundColor: changed ? '#FFFFCC' : '#D9F3D7', borderRadius: 6 }}
                                                                                    className={"read-only-total"}
                                                                                    disabled={true}
                                                                                    isSimulation={true}
                                                                                />
                                                                }
                                                            </Grid>
                                                        </OverlayTrigger>
                                                    })
                                                }
                                            </Grid>
                                        })}
                                    </Grid>
                                </Grid>
                            })}

                            <Grid item container spacing={1} direction='row'>
                                {
                                    stageData.stageSections && stageData.stageSections.map((ss, is) => {
                                        return <Grid item xs={is == 0 ? 7 : 5} key={ss.stageSectionID}>
                                            <Grid item container spacing={1} direction='row'>
                                                {
                                                    ss.stageColumns && ss.stageColumns.map((sc, ih) => {
                                                        var value = null;
                                                        try {
                                                            value = values.filter(x => x.refNumber == sc.refNumber)?.map(x => { return x.numberValue != null && x.numberValue != "" ? parseFloat(x.numberValue) : 0 }).reduce((a, b) => a + b);
                                                        } catch { }
                                                        return <Grid item xs={ih == 0 && is == 0 ? 6 : (is != 0 ? is == 1 ? 4 : 3 : 2)} key={sc.stageColumnID}>
                                                            <FooterTable style={{ justifyContent: ih == 0 && is == 0 ? "flex-start" : "center" }}>
                                                                <label>{is == 0 && ih == 0 ? "Total" : value ? value.toLocaleString(undefined, { maximumFractionDigits: 0 }) : 0}</label>
                                                            </FooterTable>
                                                        </Grid>
                                                    })
                                                }
                                            </Grid>
                                        </Grid>
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Form>
                </Section>
                <div style={{ display: "flex", marginTop: 20 }}>
                    <DivLeftButtons>
                        {stage1Block && <>
                            <button type="button" style={{ padding: 10, margin: "0rem 1rem 0rem 0rem" }} onClick={() => {
                                Swal.fire({
                                    title: 'Unlock changes',
                                    text: "Data already provided in subsequent stages may be modified, do you wish to proceed?",
                                    icon: 'warning',
                                    showDenyButton: true,
                                    confirmButtonColor: '#112A61',
                                    denyButtonColor: '#B0B0BB',
                                    denyButtonText: 'Cancel',
                                    confirmButtonText: 'Yes',
                                    showCloseButton: true
                                }).then(async (result) => {
                                    if (result.isConfirmed) {
                                        setStage1Block(false);
                                        setStage2Block(false);
                                    }
                                });
                            }} className="btn btn-custom">Edit</button>
                        </>}
                        {!stage1Block && companies.length > 0 && <>
                            <Select
                                isClearable={true}
                                placeholder="Select one companie..."
                                options={companies.filter(x => !newCompanies.includes(x.companyID)).map(x => ({ value: x.companyID, label: x.name }))}
                                value={selectedCompanie}
                                onChange={(e) => setSelectedCompanie(e)}
                            />
                            <button type="button" style={{ padding: 10, margin: "0rem 1rem 0rem 1rem" }} onClick={() => { addCompanieToData() }} className="btn btn-custom">Add Company</button>
                        </>}
                    </DivLeftButtons>
                    <DivRightButtons>
                        {stage1Block && <>
                            <button type="button" style={{ padding: 10, margin: "0rem 1rem 0rem 1rem" }} onClick={() => goTo(2)} className="btn btn-custom">Continue for curing simulations</button>
                            <button type="button" style={{ padding: 10, margin: "0rem 0rem 0rem 1rem" }} onClick={() => goTo(3)} className="btn btn-custom">See results</button>
                        </>}
                        {!stage1Block && <>
                            <button type="button" style={{ padding: 10, margin: "0rem 1rem 0rem 1rem" }} onClick={() => jumpCompanyID != null ? continueCuringSimulations({ value: 1 }, [], [{ value: jumpCompanyID, label: "My Company" }], false) : setShowModal(true)} className="btn btn-custom">Save and continue for curing simulations</button>
                            <button type="button" style={{ padding: 10, margin: "0rem 0rem 0rem 1rem" }} onClick={() => setJump(true)} className="btn btn-custom">Save and see results</button>
                        </>}
                    </DivRightButtons>
                </div>
                <ModalSimulation show={showModal} onHide={() => setShowModal(false)} typeSimulation={typeSimulation} jump={jump} simpleGroup={simpleGroup} groups={groups} suppliers={suppliers} companies={[...actualCompanies?.map(x => x.companyID), ...newCompanies]} continueCuringSimulations={continueCuringSimulations} />
            </Container >
        </>
    )
}

export default SimulationVolume;