import React from 'react';
import TableCrud from '../../components/TableCrud';

const CompanyIndex = () => {
    const columns = [
        {
            label: "Name",
            name: "name"
        },
        {
            label: "Currency Type",
            name: "currencyType.description"
        },
        {
            label: "Active",
            name: "isActive"
        },
        {
            label: "Show in Dashboard",
            name: "showInDashboard"           
        },
        {
            label: "Adjust ESG Year",
            name: "isESGYear"           
        },
        {
            label: "Is FSM Supplier",
            name: "isFSMSupplier"           
        },
    ]

    return (
        <TableCrud title={"Supplier"} table={"company"} columns={columns}/>
    );
}

export default CompanyIndex;
