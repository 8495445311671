import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    .div-buttons{
        display: flex;
        justify-content: center;
        align-items: center;
    }


    button{
        background-color: #102A63;
        color: #fff;
        font-size: 18px;
        padding: 10px 50px 10px 50px;

        &:hover{
            opacity: 0.8;
        }
    }

    input, select, textarea{
        animation: flipInX;
        animation-duration: 1s;
        border-radius: 4px;
        cursor: pointer;
        padding: 0 6px ;
        border: 1px solid #ECECF3;
        color: #7F7F8A;
        width: 100%;
        height: 40px;
        
        option{
            background-color: #F6F8FA;
            color: black;
            cursor: pointer;
        }

        &:focus-visible{
            outline: none;
        }

        &:disabled{
            background-color: #e9ecef;
            border-radius: 5px;
            border: 1px solid #ced4da;
            cursor: auto;
        }

        &::placeholder {
            color: #e2e2e2;
          }
      
          &:focus{
            box-shadow: 0;
          }
      
          &:active{
            box-shadow: 0;
          }
      
          &:focus-visible{
            box-shadow: 0;
            outline: none;
          }
      
          &:disabled{
            background-color: #e9ecef;
          }
      
          option{
            background-color: #FFFFFF;
          }
    }
    


    label{
        font-weight: bold;
        font-size: 18px;
    }
`;

export const Header = styled.div`
    font-size: 14px;
    margin-bottom:0;
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
`;

export const ContentTitle = styled.div`
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #112A61;
    padding: 1rem;
    font-weight: 500;
`;

export const CommentText = styled.div`
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #000000;
    padding: 0rem 1rem 2rem; 
    font-style: italic; 
`;

export const CommentTextArea = styled.textarea`
    background: #F6F8FA 0% 0% no-repeat padding-box;
    border: 1px solid #D3D2E4;
    border-radius: 4px;
    resize: vertical;
    width: 95%;
    margin: 0.5rem 1rem;
    position: relative;
`;

