import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { MdDelete, MdEdit, MdRemoveRedEye, MdSearch } from 'react-icons/md';
import Swal from 'sweetalert2';
import Button from '../../../components/Button';
import TableLoading from '../../../components/TableLoading';
import Api, { exceptionNotificationAPI } from '../../../services/api';
import { Card, Container } from './styles';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment';

const SimulationS3EIndex = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [nameFilter, setNameFilter] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [simulations, setSimulations] = useState(null);

  useEffect(() => {
    getMyS3Simulations(null, "");
  }, []);

  function filter(a, b) {
    getMyS3Simulations(a, b);
  }

  async function getMyS3Simulations(a, b) {
    setLoading(true);
    var userObject = JSON.parse(localStorage.getItem('@Thrive:user'));
    Api.post(`Simulation/GetMyS3Simulations`, { reportYearID: userObject.selectedReportYearID, date: a, name: b })
      .then(result => {
        if (result && result.data) {
          setSimulations(result.data.response);
          setLoading(false);
        }
      })
      .catch(error => {
        exceptionNotificationAPI(error);
        setLoading(false);
      });
  }

  async function newS3() {
    history.push(`/s3EmissionsSimulator/new`);
  }

  async function deleteSim(id) {
    Swal.fire({
      title: 'Delete',
      text: "The selected simulation will be deleted, do you wish to proceed? This action cannot be undone.",
      icon: 'warning',
      showDenyButton: true,
      confirmButtonColor: '#112A61',
      denyButtonColor: '#B0B0BB',
      denyButtonText: 'Cancel',
      confirmButtonText: 'Yes',
      showCloseButton: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        Api.post(`Simulation/deleteSimulation/` + id, null)
          .then(result => {
            if (result && result.data) {
              getMyS3Simulations(dateFilter, nameFilter);
            }
          })
          .catch(error => {
            exceptionNotificationAPI(error);
            setLoading(false);
          });
      }
    })
  }

  return (
    <Container className="container">
      <div style={{ marginTop: '2rem' }}>
        {loading && <TableLoading />}

        {!loading && <>
          <div className="row mb-5">
            <div className="col-12">
              <h3 style={{ marginBottom: 0 }}>Filter</h3>
            </div>
            <div className="col-4">
              <input
                autoComplete={"off"}
                onChange={(x) => setNameFilter(x.target.value)}
                type={"text"}
                defaultValue={nameFilter}
                placeholder={"Simulation Name"}
              />
            </div>
            <div className="col-4">
              <DatePicker
                isClearable={true}
                onChange={(x) => setDateFilter(x)}
                onSelect={(x) => setDateFilter(x)}
                selected={dateFilter}
                dateFormat="dd-MMMM-yyyy"
                placeholderText={"Date"}
              />
            </div>
            <div className="col-2">
              <Button type="button" onClick={() => filter(dateFilter, nameFilter)} className="btn btn-primary" style={{ marginTop: 2, minWidth: 10 }}><MdSearch size={20} /></Button>
              {((dateFilter != null && dateFilter != "") || (nameFilter != null && nameFilter != "")) && <Button type="button" onClick={() => { setDateFilter(null); setNameFilter(""); filter(null, ""); }} className="btn btn-primary" style={{ marginTop: 2, minWidth: 10 }}>Clear</Button>}
            </div>
            <div className="col-2">
              <Button type="button" onClick={() => newS3()} className="btn btn-primary" style={{ float: "right", marginTop: 2, backgroundColor: "#00B050" }}>New S3 Simulation</Button>
            </div>
          </div>

          {simulations != null &&
            <div className="mt-5">
              {(!simulations || (simulations != null && simulations.length == 0)) && <div>There is no data to list here</div>}
              {simulations.map((s) => {
                return <Card className="show" key={s.simulationVersionID}>
                  <div className='card'>
                    <div className="card-row">
                      <div className='card-body'>
                        <div className="col-6">
                          {s.name}
                        </div>
                        <div className="col-6">
                          {moment(s.created).format("DD - MMMM - YYYY")}
                        </div>
                      </div>
                      <div className="card-row-buttons-body">
                        {<button title="See" className="card-row-button version-button" disabled={!s.isFinalVersion} style={{ backgroundColor: s.isFinalVersion ? "#102A63" : "#cfcfcf" }} onClick={() => s.isFinalVersion && history.push(`/s3EmissionsSimulator/edit/${s.simulationVersionID}/3`)}>
                          <MdRemoveRedEye size={20} />
                        </button>}
                        {<button title="Delete" className="card-row-button version-button" onClick={() => deleteSim(s.simulationVersionID)}>
                          <MdDelete size={20} />
                        </button>}
                        {<button title="Edit" className="card-row-button-last" onClick={() => history.push(`/s3EmissionsSimulator/edit/${s.simulationVersionID}`)}>
                          <MdEdit size={20} />
                        </button>}
                      </div>
                    </div>
                  </div>
                </Card>
              })}
            </div>
          }
        </>}
      </div>
    </Container >
  );
};

export default SimulationS3EIndex;
