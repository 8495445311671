import styled, { css } from 'styled-components';

export const Div = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  h1 {
    color: #102a63;
    font-weight: bold;
  }

  h4 {
    color: #102a63;
    font-weight: bold;
  }

  h5 {
    color: #102a63;
  }

  .border-div {
    width: 100%;
    border: 1px solid #000;
  }

  .button-export {
    background-color: #102a63;
    color: #fff;
    font-size: 14px;
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;

  h4 {
    color: #102a63;
  }
`;

export const DivData = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  text-align: right;
  padding-left: 10px;
  padding-right: 10px;
  animation: flipInX;
  animation-duration: 1s;
  
  label{
    color: #102a63;
    font-size: 12px;
  }

  h4 {
    color: #102a63;
  }
`;

export const Card = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;

  label{
    color: #102a63;
    font-size: 14px;
  }

  h4 {
    color: #102a63;
    font-weight: bold;
    text-decoration: underline;
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  button {
    background-color: #102a63;
    color: #fff;
    font-size: 14px;
  }

  h4 {
    color: #102a63;
  }
`;

export const Section = styled.div`
  padding-top: 10px;

  h4 {
    color: #102a63;
    font-weight: bold;
  }

  .tab{
        color: #102961;
        cursor: pointer;

        .tab-header {
            display: flex;
            min-height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            text-decoration: uppercase;
            font-weight: bold;

            span{
                opacity: 0.5
            }
        }
        
        .tab-bar{
            background: #FFFFFFFF 0% 0% no-repeat padding-box;
            border-radius: 8px 8px 0 0;
            height: 4px;
        }

        &:hover{
            span{
                color: #102a63;
                opacity: 1!important;
            }

            .tab-bar{
                background: #102a63 0% 0% no-repeat padding-box;
                border-radius: 8px 8px 0 0;
                height: 4px;

                animation: zoomIn;
                animation-duration: 1s;
            }
        }
    }

    .tab-active {
        span{
            color: #102a63;
            opacity: 1!important;
        }

        .tab-bar{
            background: #102a63 0% 0% no-repeat padding-box;
            border-radius: 8px 8px 0 0;
        }
    }

    h2 {
        font-weight: bold !important;
        font-size: 18px;
        display: flex;
        align-items: center;
        
        .icon-left {
            cursor: pointer;
            background-color:#B0B0BB; 
            border-radius: 50%; 
            width: 35px; 
            height: 35px; 
            opacity: 0.5; 
            margin-right: 10px;
            padding: 5px;
    
            &:hover{
                opacity: 1;
                animation: pulse;
                animation-duration: 1s;
            }
        }
      }

  .grid-header {
    border-radius: 6px 6px 0 0; 
    background-color: #102a63;
    color: #fff;
  }

  .grid-header span {
    font-size: 16px;
    padding: 10px;
    font-weight: 900;
  }

  .border-div {
    width: 100%;
    border: 1px solid #000;
  }
`;

export const HeaderTable = styled.div`
  background-color: #ececf3;
  height: 44px;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  animation: flipInX;
  animation-duration: 1s;

  label {
    color: #102a63;
    font-weight: bold;
    font-size: 12px;
  }
`;

export const HeaderData = styled.div`
  background-color: #D9F3D7;
  height: 35px;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  animation: flipInX;
  animation-duration: 1s;

  input {
    border-radius: 6px !important;
  }
  
  label {
    color: #000;
    font-weight: bold;
    font-size: 12px;
  }
`;

export const FooterTable = styled.div`
  background-color: #102a63;
  height: 35px;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  animation: flipInX;
  animation-duration: 1s;

  label {
    color: #fff;
    font-weight: bold;
  }
`;

export const DivLeftButtons = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-start;
  align-items: center;

  button {
    background-color: #102a63;
    color: #fff;
    font-size: 14px;
  }
`;

export const DivRightButtons = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
  align-items: center;

  button {
    background-color: #102a63;
    color: #fff;
    font-size: 14px;
  }
`;

