import React, { useState } from 'react';

import { useEffect } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { Spreadsheet } from 'react-spreadsheet';
import Swal from 'sweetalert2';
import Button from '../../components/Button';
import TableLoading from '../../components/TableLoading';
import { error_message, info_message, success_message } from '../../components/Toast/index';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { Container, ContainerHeader } from './styles';

import optionsLogo from '../../assets/icons/options.svg';

const EmissionEfficiencyFormIndex = () => {
    const columns = ["Fuel for Curing", "Efficiency", " "]

    //Crop Year Select Variables
    const [company, setCompanies] = useState([]);
    const [selectedCompanyID, setSelectedCompanyID] = useState(null);
    const [rowLabels, setRowLabels] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [dataSheet, setDataSheet] = useState([[], [],]);

    const removeRow = (id) => {
        Swal.fire({
            title: 'Are you sure, you want delete the register?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonColor: '#102A63',
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                Api.post(`EmissionEfficiency/deleteEmissionEfficiency?id=${id}`).then((result) => {
                    if (selectedCompanyID != null) {
                        loadDataByCropYear(selectedCompanyID);
                    }
                })
            }
        })
    }

    const InputNumberEdit = ({ cell, onChange }) => {
        var rowID = cell.className.split("-");

        return (
            <div style={{ padding: '0.5rem 1rem', textAlign: "center" }} onClick={() => removeRow(rowID[1])}>
                <FaTrashAlt />
            </div>
        );
    };

    const InputOptions = ({ cell, onChange }) => {
        return (
            <div style={{ padding: '0.5rem 1rem' }}>
                <img src={optionsLogo} alt="" />
            </div>
        );
    };

    useEffect(() => {
        setIsLoading(true);
        loadCompanies();
    }, [])

    useEffect(() => {
        if (selectedCompanyID != null) {
            setIsLoading(true);
            loadDataByCropYear(selectedCompanyID)
        }
    }, [selectedCompanyID])

    const loadCompanies = (userAux) => {
        Api.get(`company/getAll`).then((result) => {
            if (result && result.data) {
                var companyOptions = result.data.response.map(item => ({ value: item.companyID, label: item.name }));

                companyOptions = [{ value: 0, label: "Global" }, ...companyOptions];

                setCompanies(companyOptions);
                setSelectedCompanyID(companyOptions[0].value);
                setIsLoading(false)
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const onChangeCompanyID = (value) => {
        setSelectedCompanyID(value);
    }

    const loadDataByCropYear = (selectedCompanyID) => {
        setIsLoading(true);
        if (selectedCompanyID != null) {
            Api.get(`EmissionEfficiency/GetAllEfficiencyByCompany?companyID=${selectedCompanyID}`).then((result) => {
                if (result && result.data) {
                    var resultList = result.data.response;
                    var newLine = [
                        { value: "" }, { value: "" }, { value: "" }
                    ]
                    resultList.push(newLine);
                    setIsLoading(false);

                    try {
                        resultList.map((row) => {
                            var newRow = row.map((col) => {
                                var newCol = { ...col };
                                return newCol;
                            })
                            return newRow;
                        })

                        var rowLabel = [];
                        try {
                            resultList.map((row) => {
                                var id = row[0].value;
                                row[0] = { value: row[1].value };
                                row[1] = { value: row[2].value != "" || row[2].value.includes("-") ? row[2].value + "" : "" };
                                row[2] = { readOnly: false, className: 'remove-' + id, onClick: () => removeRow(id) };
                                row[2].DataViewer = InputOptions;
                                row[2].DataEditor = InputNumberEdit;
                                rowLabel.push(id);
                            })
                        } catch { }
                        setRowLabels(rowLabel);
                        setDataSheet(resultList);
                    } catch (e) {
                        console.log(e)
                    }
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
    }

    const save = () => {
        var objAdditionalData = {
            companyID: selectedCompanyID == null ? 0 : selectedCompanyID,
            data: dataSheet,
            IDs: rowLabels
        }
        setIsLoading(true);

        Api.post(`EmissionEfficiency/newEmissionEfficiency`, objAdditionalData).then((result) => {
            if (result && result.data) {
                if (result.data.response == true) {
                    loadDataByCropYear(selectedCompanyID);
                    success_message("The data was saved!");
                }
                else {
                    setIsLoading(false);
                    info_message("No data to saved!");
                }
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const onChangeTable = (e) => {
        try {
            setDataSheet(e);
        } catch { }
    }

    const onBlurTable = () => {
        try {
            var nesw = dataSheet.map((row) => {
                var newRow = row.map((col) => {
                    var newCol = { ...col };
                    return newCol;
                })
                return newRow;
            })

            try {
                nesw.map((row) => {
                    var value1 = row[1].value != "" ? row[1].value : "";
                    row[0] = { value: row[0].value };
                    row[1] = { value: value1.includes(" - ") || value1 == " " || value1 == "-" || value1 == null || value1 == undefined ? "" : value1 };
                })
            } catch { }
            setDataSheet(nesw);
        } catch (e) {
            console.log(e)
        }
    }

    const deleteAll = () => {
        Swal.fire({
            title: 'Are you sure, you want delete all?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonColor: '#102A63',
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                Api.post(`EmissionEfficiency/deleteAll?companyID=${selectedCompanyID}`).then((result) => {
                    if (result.data.response == true) {
                        if (selectedCompanyID != null) {
                            success_message("All values was deleted successfully!")
                            setTimeout(() => {
                                loadDataByCropYear(selectedCompanyID);
                            }, 2000)
                        }
                    } else {
                        error_message("Some error was happened. Please try again or contact the administrator.")
                    }
                })
            }
        })
    }

    return (
        <Container className="container">
            <ContainerHeader style={{ margin: '2rem 0' }}>
                <h3>Simulation Data - Emission Efficiency Form</h3>
                <div>
                    <Button style={{ marginRight: '15px' }} onClick={() => save()}>Save</Button>
                    <Button style={{ marginRight: '15px' }} onClick={() => deleteAll()}>Delete All</Button>
                </div>
            </ContainerHeader>
            <div className="row mt-2">
                <div className="col-md-6 mt-2">
                    <label className='label'>Select Company:</label>
                    <select className="col-md-6 mx-4 mt-2" onChange={(x) => onChangeCompanyID(x.target.value)} value={selectedCompanyID ? selectedCompanyID : ""}>
                        {
                            company && company.map(company => {
                                return <option key={company.value} value={company.value}>{company.label}</option>
                            })
                        }
                    </select>
                </div>
            </div>
            {!isLoading ?
                <Spreadsheet data={dataSheet} rowLabels={rowLabels} columnLabels={columns} onBlur={() => onBlurTable()} onChange={(e) => onChangeTable(e)} />
                :
                <TableLoading />
            }
        </Container>
    )
}

export default EmissionEfficiencyFormIndex;
