import { Container, Grid } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { FaLongArrowAltRight } from "react-icons/fa";
import { FiArrowLeft } from 'react-icons/fi';
import { MdFileDownload, MdTrendingDown, MdTrendingUp } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import TableLoading from '../../../../components/TableLoading';
import Api, { exceptionNotificationAPI } from '../../../../services/api';
import PleaseRead from '../../../PleaseRead';
import { Card, DivData, HeaderTable, Section } from './styles';

const SimulationResult = ({ typeSimulation, simpleGroup, fuelTypesList, stage1Values, stage2Values, goTo, reportYear }) => {
    const { id } = useParams();

    const formRef = useRef(null);
    const [totalsOldBaseline, setTotalsOldBaseline] = useState({ category: "", reportable: 0, biogenic: 0, removals: 0, total: 0 });
    const [totalsOld, setTotalsOld] = useState({ category: "", reportable: 0, biogenic: 0, removals: 0, total: 0 });
    const [totalsNew, setTotalsNew] = useState({ category: "", reportable: 0, biogenic: 0, removals: 0, total: 0 });

    const [totalsFuelOldBaseline, setTotalsFuelOldBaseline] = useState({ category: "", reportable: 0, biogenic: 0, removals: 0, total: 0 });
    const [totalsFuelOld, setTotalsFuelOld] = useState({ name: "", fcvdfc: 0, others: 0, total: 0 });
    const [totalsFuelNew, setTotalsFuelNew] = useState({ name: "", fcvdfc: 0, others: 0, total: 0 });

    const [hideGlobal, setHideGlobal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [actual, setActual] = useState(null);
    const [simulated, setSimulated] = useState(null);

    const [options, setOptions] = useState(null);
    const [resultData, setResultData] = useState(null);
    const [resultDataFuels, setResultDataFuels] = useState(null);
    const [resultDataBaseline, setResultDataBaseline] = useState(null);
    const [resultDataFuelsBaseline, setResultDataFuelsBaseline] = useState(null);
    const [dataActual, setDataActual] = useState(null);
    const [dataActualScope1, setDataActualScope1] = useState(null);
    const [dataFuelActual, setDataFuelActual] = useState(null);
    const [dataSimulated, setDataSimulated] = useState(null);
    const [dataSimulatedScope1, setDataSimulatedScope1] = useState(null);
    const [dataFuelSimulated, setDataFuelSimulated] = useState(null);

    const [resultData2, setResultData2] = useState(null);

    function normaliseValue(value, decimals = 2) {
        if (!value) return 0;
        const parsed = Number(value).toFixed(decimals);
        if (isNaN(parsed) || !isFinite(parsed)) return "-";
        return parseFloat(parsed);
    }

    useEffect(() => {
        var userObject = JSON.parse(localStorage.getItem('@Thrive:user'));

        var hide = false;
        if (userObject.companyID || userObject.groupID) {
            setHideGlobal(true);
            hide = true;
        }

        Api.get('Simulation/SummaryData/' + id).then((result) => {
            if (result && result.data) {
                const uniqueIdNameArray = result.data.response.emissions.reduce((acc, obj) => {
                    const id = obj.companyID ?? obj.groupID;
                    const name = obj.companyName ?? obj.groupName;

                    const company = obj.companyID != null;
                    const existingItem = acc.find((item) => item.id === id && item.name === name && item.company === company);
                    if (!existingItem && (hide && id == 0 ? false : true)) { acc.push({ id, name, company }); }

                    return acc;
                }, []);
                setOptions(uniqueIdNameArray);
                setResultData(result.data.response.emissions.filter(x => hide ? x.companyID != 0 : true));
                setResultDataFuels(result.data.response.fuels);
                setResultDataBaseline(result.data.response.baselineEmissions.filter(x => hide ? x.companyID != 0 : true));
                setResultDataFuelsBaseline(result.data.response.baselineFuels);
                setResultData2(result.data.response.fuels.filter(x => x.companyID != 0).map(x => {
                    return {
                        company: x.name,
                        volumeOld: x.volumeFrom,
                        volumeActually: x.volumeTo,
                        oldFuels: x.fuels.filter(x2 => x2.type == 1).map(x2 => ({ description: x2.fuel, value: normaliseValue(x2.perc, 2) })),
                        newFuels: x.fuels.filter(x2 => x2.type == 2).map(x2 => ({ description: x2.fuel, value: normaliseValue(x2.perc, 2) }))
                    }
                }));
                setLoading(false);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
            setLoading(false);
        });

        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Add smooth scrolling animation
        });
    }, []);

    useEffect(() => {
        if (resultData != null && resultData.length > 0 && resultDataFuels != null && resultDataBaseline != null && resultDataFuelsBaseline != null && dataSimulated == null && dataActual == null) {
            if (hideGlobal) {
                if (resultData[0].companyID)
                    changeActual([{ value: resultData[0].companyID, label: resultData[0].companyName, company: true }]);
                else
                    changeActual([{ value: resultData[0].groupID, label: resultData[0].groupName, company: false }]);
            }
            else
                changeActual([{ value: 0, label: "Global", company: true }]);
        }
    }, [resultData, resultDataFuels, resultDataBaseline, resultDataFuelsBaseline])

    function changeActual(id) {
        setLoading(true);

        if (id.length > 0) {
            const ultimoObjeto = id[id.length - 1];

            // Verifique o valor do último objeto
            if (ultimoObjeto.value == 0) {
                const globalOption = id.find(x => x.value == 0);
                id = [globalOption];
            } else {
                id = id.filter(x => x.value != 0);
            }
        }

        setActual(id);

        var d = resultData.filter(x => x.type == 1 && x.scope == 3 && id != null && id.length > 0 && ((id.some(item => item.company && item.value === x.companyID) || (id.some(item => !item.company && item.value === x.groupID)))))
            .reduce((accumulator, x) => {
                var category = x.emission;
                var existingCategory = accumulator.find(item => item.category == category);
                if (!existingCategory) accumulator.push({ category, reportable: 0, biogenic: 0, removals: 0, total: 0 });
                existingCategory = accumulator.find(item => item.category == category);
                existingCategory.reportable += normaliseValue(x.reportableEmissionsCO2e, 0);
                existingCategory.biogenic += normaliseValue(x.biogenicEmissionsCO2e, 0);
                existingCategory.removals += normaliseValue(x.ghgRemovalsEmissionsKgCO2e, 0);
                existingCategory.total += normaliseValue(x.total, 0);
                return accumulator;
            }, []);
        setDataActual(d);

        var total = { category: "Total", reportable: 0, biogenic: 0, removals: 0, total: 0 };
        for (var category in d) {
            total.reportable += d[category].reportable;
            total.biogenic += d[category].biogenic;
            total.removals += d[category].removals;
            total.total += d[category].total;
        };
        setTotalsOld(total);

        var dScope1 = resultData.filter(x => x.type == 1 && x.scope == 1 && id != null && id.length > 0 && ((id.some(item => item.company && item.value === x.companyID) || (id.some(item => !item.company && item.value === x.groupID)))))
            .reduce((accumulator, x) => {
                var category = x.emission;
                var existingCategory = accumulator.find(item => item.category == category);
                if (!existingCategory) accumulator.push({ category, reportable: 0, biogenic: 0, removals: 0, total: 0 });
                existingCategory = accumulator.find(item => item.category == category);
                existingCategory.reportable += normaliseValue(x.reportableEmissionsCO2e, 0);
                existingCategory.biogenic += normaliseValue(x.biogenicEmissionsCO2e, 0);
                existingCategory.removals += normaliseValue(x.ghgRemovalsEmissionsKgCO2e, 0);
                existingCategory.total += normaliseValue(x.total, 0);
                return accumulator;
            }, []);
        setDataActualScope1(dScope1);

        //BASELINE
        var d = resultDataBaseline.filter(x => x.type == 1 && id != null && id.length > 0 && ((id.some(item => item.company && item.value === x.companyID) || (id.some(item => !item.company && item.value === x.groupID)))))
            .reduce((accumulator, x) => {
                var category = x.emission;
                var existingCategory = accumulator.find(item => item.category == category);
                if (!existingCategory) accumulator.push({ category, reportable: 0, biogenic: 0, removals: 0, total: 0 });
                existingCategory = accumulator.find(item => item.category == category);
                existingCategory.reportable += normaliseValue(x.reportableEmissionsCO2e, 0);
                existingCategory.biogenic += normaliseValue(x.biogenicEmissionsCO2e, 0);
                existingCategory.removals += normaliseValue(x.ghgRemovalsEmissionsKgCO2e, 0);
                existingCategory.total += normaliseValue(x.total, 0);
                return accumulator;
            }, []);

        var total = { category: "Total", reportable: 0, biogenic: 0, removals: 0, total: 0 };
        for (var category in d) {
            total.reportable += d[category].reportable;
            total.biogenic += d[category].biogenic;
            total.removals += d[category].removals;
            total.total += d[category].total;
        };
        setTotalsOldBaseline(total);

        var d = resultData.filter(x => x.type == 2 && x.scope == 3 && id != null && id.length > 0 && ((id.some(item => item.company && item.value === x.companyID) || (id.some(item => !item.company && item.value === x.groupID)))))
            .reduce((accumulator, x) => {
                var category = x.emission;
                var existingCategory = accumulator.find(item => item.category === category);
                if (!existingCategory) accumulator.push({ category, reportable: 0, biogenic: 0, removals: 0, total: 0 });
                existingCategory = accumulator.find(item => item.category === category);
                existingCategory.reportable += normaliseValue(x.reportableEmissionsCO2e, 0);
                existingCategory.biogenic += normaliseValue(x.biogenicEmissionsCO2e, 0);
                existingCategory.removals += normaliseValue(x.ghgRemovalsEmissionsKgCO2e, 0);
                existingCategory.total += normaliseValue(x.total, 0);
                return accumulator;
            }, []);
        setDataSimulated(d);
        setSimulated(id);

        total = { category: "Total", reportable: 0, biogenic: 0, removals: 0, total: 0 };
        for (var category in d) {
            total.reportable += d[category].reportable;
            total.biogenic += d[category].biogenic;
            total.removals += d[category].removals;
            total.total += d[category].total;
        };
        setTotalsNew(total);

        var dScope1 = resultData.filter(x => x.type == 2 && x.scope == 1 && id != null && id.length > 0 && ((id.some(item => item.company && item.value === x.companyID) || (id.some(item => !item.company && item.value === x.groupID)))))
            .reduce((accumulator, x) => {
                var category = x.emission;
                var existingCategory = accumulator.find(item => item.category === category);
                if (!existingCategory) accumulator.push({ category, reportable: 0, biogenic: 0, removals: 0, total: 0 });
                existingCategory = accumulator.find(item => item.category === category);
                existingCategory.reportable += normaliseValue(x.reportableEmissionsCO2e, 0);
                existingCategory.biogenic += normaliseValue(x.biogenicEmissionsCO2e, 0);
                existingCategory.removals += normaliseValue(x.ghgRemovalsEmissionsKgCO2e, 0);
                existingCategory.total += normaliseValue(x.total, 0);
                return accumulator;
            }, []);
        setDataSimulatedScope1(dScope1);

        var d = resultDataFuels.filter(x => id != null && id.length > 0 && ((id.some(item => item.company && item.value === x.companyID) || (id.some(item => !item.company && item.value === x.groupID)))))
            .reduce((accumulator, x) => {
                var existingCategory = accumulator.find(item => item.name === x.name);
                if (!existingCategory) accumulator.push({ name: x.name, fcvdfc: 0, others: 0, total: 0 });
                existingCategory = accumulator.find(item => item.name === x.name);
                existingCategory.fcvdfc += normaliseValue(x.volumeFromFCVDFC, 0);
                existingCategory.others += normaliseValue(x.volumeFromOthers, 0);
                existingCategory.total += normaliseValue(x.volumeFrom, 0);
                return accumulator;
            }, []);
        setDataFuelActual(d);

        var total = { name: "Total", fcvdfc: 0, others: 0, total: 0 };
        for (var name in d) {
            total.fcvdfc += d[name].fcvdfc;
            total.others += d[name].others;
            total.total += d[name].total;
        };
        setTotalsFuelOld(total);

        // BASELINE
        var d = resultDataFuelsBaseline.filter(x => id != null && id.length > 0 && ((id.some(item => item.company && item.value === x.companyID) || (id.some(item => !item.company && item.value === x.groupID)))))
            .reduce((accumulator, x) => {
                var existingCategory = accumulator.find(item => item.name === x.name);
                if (!existingCategory) accumulator.push({ name: x.name, fcvdfc: 0, others: 0, total: 0 });
                existingCategory = accumulator.find(item => item.name === x.name);
                existingCategory.fcvdfc += normaliseValue(x.volumeFromFCVDFC, 0);
                existingCategory.others += normaliseValue(x.volumeFromOthers, 0);
                existingCategory.total += normaliseValue(x.volumeFromFCVDFC + x.volumeFromOthers, 0);
                return accumulator;
            }, []);

        var total = { name: "Total", fcvdfc: 0, others: 0, total: 0 };
        for (var name in d) {
            total.fcvdfc += d[name].fcvdfc;
            total.others += d[name].others;
            total.total += d[name].total;
        };
        setTotalsFuelOldBaseline(total);

        var d = resultDataFuels.filter(x => id != null && id.length > 0 && ((id.some(item => item.company && item.value === x.companyID) || (id.some(item => !item.company && item.value === x.groupID)))))
            .reduce((accumulator, x) => {
                var existingCategory = accumulator.find(item => item.name === x.name);
                if (!existingCategory) accumulator.push({ name: x.name, fcvdfc: 0, others: 0, total: 0 });
                existingCategory = accumulator.find(item => item.name === x.name);
                existingCategory.fcvdfc += normaliseValue(x.volumeFromFCVDFC, 0);
                existingCategory.others += normaliseValue(x.volumeFromOthers, 0);
                existingCategory.total += normaliseValue(x.volumeFrom, 0);
                return accumulator;
            }, []);
        setDataFuelSimulated(d);

        var total = { name: "Total", fcvdfc: 0, others: 0, total: 0 };
        for (var name in d) {
            total.fcvdfc += d[name].fcvdfc;
            total.others += d[name].others;
            total.total += d[name].total;
        };
        setTotalsFuelOld(total);

        var d = resultDataFuels.filter(x => id != null && id.length > 0 && ((id.some(item => item.company && item.value === x.companyID) || (id.some(item => !item.company && item.value === x.groupID)))))
            .reduce((accumulator, x) => {
                var existingCategory = accumulator.find(item => item.name === x.name);
                if (!existingCategory) accumulator.push({ name: x.name, fcvdfc: 0, others: 0, total: 0 });
                existingCategory = accumulator.find(item => item.name === x.name);
                existingCategory.fcvdfc += normaliseValue(x.volumeToFCVDFC, 0);
                existingCategory.others += normaliseValue(x.volumeToOthers, 0);
                existingCategory.total += normaliseValue(x.volumeTo, 0);
                return accumulator;
            }, []);
        setDataFuelSimulated(d);

        var total = { name: "Total", fcvdfc: 0, others: 0, total: 0 };
        for (var name in d) {
            total.fcvdfc += d[name].fcvdfc;
            total.others += d[name].others;
            total.total += d[name].total;
        };
        setTotalsFuelNew(total);

        setLoading(false);
    }

    function calculatePercentageDifference(oldValue, newValue, color = null) {
        oldValue = normaliseValue(oldValue, 2);
        newValue = normaliseValue(newValue, 2);

        var total = normaliseValue(((newValue - oldValue) / oldValue) * 100, 2);
        return <span style={{ color: color ? color : total <= 0 ? "#289128" : "#ff0d00" }}>{total != 0 ? total < 0 ? <MdTrendingDown /> : <MdTrendingUp /> : null} {total} {total != "-" && "%"}</span>;
    }

    function exportExcel() {
        Api.get(`Simulation/ExcelExport/${id}`).then((r) => {
            var binaryString = window.atob(r.data.response);
            var bytes = new Uint8Array(binaryString.length);
            var bytesMapped = bytes.map((byte, i) => binaryString.charCodeAt(i));
            var blob = new Blob([bytesMapped], { type: "application/octet-stream" });
            var link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.download = "S3_Excel_Export.xlsx";
            link.target = "_blank";
            link.click();
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    return (
        <>
            <div style={{ backgroundColor: "#fff", minHeight: 100, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                <Container className="container">
                    <Section>
                        <div className="row">
                            <div className="col-2">
                                <h2>
                                    <FiArrowLeft color={"#FFFFFF"} className="icon-left" size={10} onClick={() => goTo(1)} /> Volume
                                </h2>
                            </div>
                            <div className="col-2">
                                <h2>
                                    <FiArrowLeft color={"#FFFFFF"} className="icon-left" size={10} onClick={() => goTo(2)} /> Curing Fuels
                                </h2>
                            </div>
                            <div className="col-8">
                                <button type="button" style={{ padding: 10, margin: "-0.5rem 0rem 0rem 1rem", float: "right" }} onClick={() => exportExcel()} className="btn btn-custom">Export Excel <MdFileDownload size={24} /></button>
                            </div>
                        </div>
                    </Section>
                </Container>
            </div>
            <Container>
                <div style={{ marginLeft: -10, marginRight: -10 }}>
                    <PleaseRead guidance={'Results are available in this stage! \n \n ' +
                        '- In the summary you will find the list of changes applied to this simulation\n ' +
                        '- An export excel is available in the top right side of this page\n ' +
                        'For global users:\n ' +
                        '- Use the filter to see global or local impacts\n ' +
                        '- If you select more than one company, the system will sum the emissions and present the variation compared to actual data'} style={{ margin: -10 }} />
                </div>
                <Section>
                    <Grid container spacing={1} direction='row'>
                        <Grid item xs={12} style={{ paddingTop: 5 }}>
                            <Grid item style={{ width: "100%" }}>
                                <Select
                                    placeholder="Select one companie..."
                                    options={options && options.map(x => ({ value: x.id, label: x.name, company: x.company }))}
                                    value={actual}
                                    disabled={resultData == null}
                                    isMulti={true}
                                    onChange={(e) => { changeActual(e); }}
                                    styles={{ control: (baseStyles, state) => ({ ...baseStyles, width: '100%', border: '0px', }) }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} style={{ paddingTop: 15 }}>
                            <h6>In some cases data is not comparable because calculation is becoming more accurate, and also, could have different sources (Thrive, STP)</h6>
                            <div className="border-div" style={{ width: "100%", border: "1px solid rgba(230, 230, 230, 0.44)", marginBottom: "0.5rem" }} />
                        </Grid>

                        <Grid item xs={6} style={{ marginBottom: 20 }}>
                            <Grid item container direction='row' style={{ background: "#fff", borderRadius: "0 0 6px 6px", marginTop: 10 }}>
                                <Grid item container className="grid-header" direction='row' style={{ backgroundColor: "#D9F3D7" }}>
                                    <span style={{ color: "#102a63" }}>{reportYear} Emissions by category (ton CO2e) - Scope 3</span>
                                </Grid>

                                {["Category", "Reportable", "Biogenic", "Removals", "Total"].map((label, index) => (
                                    <Grid item style={{ width: index == 0 ? "24%" : "19%" }} key={index}>
                                        <HeaderTable>
                                            <label>{label}</label>
                                        </HeaderTable>
                                    </Grid>
                                ))}

                                {loading &&
                                    <Grid item style={{ width: "100%", height: "100%" }}>
                                        <TableLoading />
                                    </Grid>
                                }

                                <Grid item container direction='row' style={{ paddingBottom: 5, paddingTop: 5 }}>
                                    {
                                        dataActual?.length > 0 &&
                                        dataActual?.map((e, i) => (
                                            <Grid key={i} item container direction='row' style={{ paddingBottom: 7.5, paddingTop: 7.5, borderBottom: "1px solid #e6e6e670" }}>
                                                {["category", "reportable", "biogenic", "removals", "total"].map((label, index) => (
                                                    <Grid item style={{ width: index == 0 ? "24%" : "19%", height: "100%" }} key={label}>
                                                        <DivData>
                                                            <label style={{ fontWeight: index == 0 || index == 4 ? "bold" : "500" }}>{e[label].toLocaleString()}</label>
                                                        </DivData>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        ))
                                    }
                                </Grid>

                                {dataActual?.length > 0 && <>
                                    <div className="border-div" style={{ width: "97%", marginBottom: "0.5rem", marginLeft: "2%", marginRight: "1%" }} />
                                    <Grid item container direction='row' style={{ paddingBottom: 15, paddingTop: 5 }}>
                                        {["category", "reportable", "biogenic", "removals", "total"].map((label, index) => (
                                            <Grid item style={{ width: index == 0 ? "24%" : "19%", height: "100%" }} key={label}>
                                                <DivData>
                                                    <label style={{ fontWeight: index == 0 || index == 4 ? "bold" : "500" }}>{totalsOld[label].toLocaleString()}</label>
                                                </DivData>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </>}
                            </Grid>
                        </Grid>

                        <Grid item xs={6} style={{ marginBottom: 20 }}>
                            <Grid item container direction='row' style={{ background: "#fff", borderRadius: "0 0 6px 6px", marginTop: 10 }}>
                                <Grid item container className="grid-header" direction='row' style={{ backgroundColor: "#FFE292" }}>
                                    <span style={{ color: "#102a63" }}>Simulated emissions by category (ton CO2e) - Scope 3</span>
                                </Grid>

                                {["Category", "Reportable", "Biogenic", "Removals", "Total"].map((label, index) => (
                                    <Grid item style={{ width: index == 0 ? "24%" : "19%" }} key={index}>
                                        <HeaderTable>
                                            <label>{label}</label>
                                        </HeaderTable>
                                    </Grid>
                                ))}

                                {loading &&
                                    <Grid item style={{ width: "100%", height: "100%" }}>
                                        <TableLoading />
                                    </Grid>
                                }

                                <Grid item container direction='row' style={{ paddingBottom: 5, paddingTop: 5 }}>
                                    {
                                        dataSimulated?.length > 0 &&
                                        dataSimulated?.map((e, i) => (
                                            <Grid key={i} item container direction='row' style={{ paddingBottom: 7.5, paddingTop: 7.5, borderBottom: "1px solid #e6e6e670" }}>
                                                {["category", "reportable", "biogenic", "removals", "total"].map((label, index) => (
                                                    <Grid item style={{ width: index == 0 ? "24%" : "19%", height: "100%" }} key={label}>
                                                        <DivData>
                                                            <label style={{ fontWeight: index == 0 || index == 4 ? "bold" : "500" }}>{e[label].toLocaleString()}</label>
                                                        </DivData>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        ))
                                    }
                                </Grid>

                                {dataSimulated?.length > 0 && <>
                                    <div className="border-div" style={{ width: "97%", marginBottom: "0.5rem", marginLeft: "2%", marginRight: "1%" }} />
                                    <Grid item container direction='row' style={{ paddingBottom: 15, paddingTop: 5 }}>
                                        {["category", "reportable", "biogenic", "removals", "total"].map((label, index) => (
                                            <Grid item style={{ width: index == 0 ? "24%" : "19%", height: "100%" }} key={label}>
                                                <DivData>
                                                    <label style={{ fontWeight: index == 0 || index == 4 ? "bold" : "500" }}>{totalsNew[label].toLocaleString()}</label>
                                                </DivData>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </>}
                            </Grid>
                        </Grid>

                        <Grid item xs={6} style={{ marginBottom: 20, position: "relative" }}>
                            {totalsOld != null && totalsNew != null && <>
                                <div style={{ position: "absolute", transform: "rotate(-90deg)", left: -25, top: 140 }}>
                                    <p style={{ marginBottom: 0, fontWeight: 900, fontSize: 12, color: "#65687f" }}>{reportYear}</p>
                                </div>
                                <Grid item container direction='row' style={{ background: "#fff", borderRadius: "0 0 6px 6px", marginTop: 10 }}>
                                    <Grid item container className="grid-header" direction='row' style={{ backgroundColor: "#D9F3D7" }}>
                                        <span style={{ color: "#102a63" }}>Total emissions - Scope 3</span>
                                    </Grid>

                                    {["", "Total", "Biogenic", "Reportable"].map((label, index) => (
                                        <Grid item style={{ width: "25%" }} key={index}>
                                            <HeaderTable>
                                                <label>{label}</label>
                                            </HeaderTable>
                                        </Grid>
                                    ))}

                                    {loading &&
                                        <Grid item style={{ width: "100%", height: "100%" }}>
                                            <TableLoading small={true} />
                                        </Grid>
                                    }

                                    {!loading &&
                                        <>
                                            <Grid item container direction='row' style={{ paddingBottom: 7.5, paddingTop: 7.5, borderBottom: "1px solid #e6e6e670" }}>
                                                <Grid item style={{ width: "25%", height: "100%", textAlign: "left" }}>
                                                    <DivData>
                                                        <label style={{ fontWeight: "bold" }}>Ton CO2e</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label>{normaliseValue(totalsOld.total, 0).toLocaleString()}</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label>{normaliseValue(totalsOld.biogenic + totalsOld.removals, 0).toLocaleString()}</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label>{normaliseValue(totalsOld.reportable, 0).toLocaleString()}</label>
                                                    </DivData>
                                                </Grid>
                                            </Grid>

                                            <Grid item container direction='row' style={{ paddingBottom: 7.5, paddingTop: 7.5, borderBottom: "1px solid #e6e6e670" }}>
                                                <Grid item style={{ width: "25%", height: "100%", textAlign: "left" }}>
                                                    <DivData>
                                                        <label style={{ fontWeight: "bold" }}>Emissions/kg</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label>{normaliseValue(totalsOld.total / totalsFuelOld.total, 2)}</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label>{normaliseValue((totalsOld.biogenic + totalsOld.removals) / totalsFuelOld.total, 2)}</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label>{normaliseValue(totalsOld.reportable / totalsFuelOld.total, 2)}</label>
                                                    </DivData>
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </>}
                        </Grid>

                        <Grid item xs={6} style={{ marginBottom: 20, position: "relative" }}>
                            {totalsOld != null && totalsNew != null && <>
                                <div style={{ position: "absolute", transform: "rotate(90deg)", right: -50, top: 115, textAlign: "right" }}>
                                    <p style={{ marginBottom: 0, fontWeight: 900, fontSize: 12, color: "#65687f" }}>Simulated</p>
                                    <p style={{ marginBottom: 0, fontWeight: 900, fontSize: 12, color: "#65687f" }}>vs {reportYear}</p>
                                </div>
                                <Grid item container direction='row' style={{ background: "#fff", borderRadius: "0 0 6px 6px", marginTop: 10 }}>
                                    <Grid item container className="grid-header" direction='row' style={{ backgroundColor: "#FFE292" }}>
                                        <span style={{ color: "#102a63" }}>Simulated emissions - Scope 3</span>
                                    </Grid>

                                    {["", "Total", "Biogenic", "Reportable"].map((label, index) => (
                                        <Grid item style={{ width: "25%" }} key={index}>
                                            <HeaderTable>
                                                <label>{label}</label>
                                            </HeaderTable>
                                        </Grid>
                                    ))}

                                    {loading &&
                                        <Grid item style={{ width: "100%", height: "100%" }}>
                                            <TableLoading small={true} />
                                        </Grid>
                                    }

                                    {!loading &&
                                        <>
                                            <Grid item container direction='row' style={{ paddingBottom: 7.5, paddingTop: 7.5, borderBottom: "1px solid #e6e6e670" }}>
                                                <Grid item style={{ width: "25%", height: "100%", textAlign: "left" }}>
                                                    <DivData>
                                                        <label style={{ fontWeight: "bold" }}>Ton CO2e</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label>{calculatePercentageDifference(totalsOld.total, totalsNew.total)}</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label>{calculatePercentageDifference(totalsOld.biogenic + totalsOld.removals, totalsNew.biogenic + totalsNew.removals)}</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label>{calculatePercentageDifference(totalsOld.reportable, totalsNew.reportable)}</label>
                                                    </DivData>
                                                </Grid>
                                            </Grid>

                                            <Grid item container direction='row' style={{ paddingBottom: 7.5, paddingTop: 7.5, borderBottom: "1px solid #e6e6e670" }}>
                                                <Grid item style={{ width: "25%", height: "100%", textAlign: "left" }}>
                                                    <DivData>
                                                        <label style={{ fontWeight: "bold" }}>Emissions/kg (%)</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label><strong>{normaliseValue(totalsNew.total / totalsFuelNew.total, 2)}</strong> ( {calculatePercentageDifference(totalsOld.total / totalsFuelOld.total, totalsNew.total / totalsFuelNew.total)} )</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label><strong>{normaliseValue((totalsNew.biogenic + totalsNew.removals) / totalsFuelNew.total, 2)}</strong> ( {calculatePercentageDifference((totalsOld.biogenic + totalsOld.removals) / totalsFuelOld.total, (totalsNew.biogenic + totalsNew.removals) / totalsFuelNew.total)} )</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label><strong>{normaliseValue(totalsNew.reportable / totalsFuelNew.total, 2)}</strong> ( {calculatePercentageDifference(totalsOld.reportable / totalsFuelOld.total, totalsNew.reportable / totalsFuelNew.total)} )</label>
                                                    </DivData>
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </>}
                        </Grid>

                        <Grid item xs={6} style={{ marginBottom: 20, position: "relative" }}>
                            {totalsOld != null && totalsNew != null && <>
                                <div style={{ position: "absolute", transform: "rotate(-90deg)", left: -45, top: 30 }}>
                                    <p style={{ marginBottom: 0, fontWeight: 900, fontSize: 12, color: "#65687f" }}>Baseline</p>
                                    <p style={{ marginBottom: 0, fontWeight: 900, fontSize: 12, color: "#65687f" }}>2020</p>
                                </div>
                                <Grid item container direction='row' style={{ background: "#fff", borderRadius: "0 0 6px 6px", marginTop: 10 }}>
                                    {loading &&
                                        <Grid item style={{ width: "100%", height: "100%" }}>
                                            <TableLoading small={true} />
                                        </Grid>
                                    }

                                    {!loading &&
                                        <>
                                            <Grid item container direction='row' style={{ paddingBottom: 7.5, paddingTop: 7.5, borderBottom: "1px solid #e6e6e670" }}>
                                                <Grid item style={{ width: "25%", height: "100%", textAlign: "left" }}>
                                                    <DivData>
                                                        <label style={{ fontWeight: "bold" }}>Ton CO2e</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label>{normaliseValue(totalsOldBaseline.total, 0).toLocaleString()}</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label>{normaliseValue(totalsOldBaseline.biogenic + totalsOldBaseline.removals, 0).toLocaleString()}</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label>{normaliseValue(totalsOldBaseline.reportable, 0).toLocaleString()}</label>
                                                    </DivData>
                                                </Grid>
                                            </Grid>

                                            <Grid item container direction='row' style={{ paddingBottom: 7.5, paddingTop: 7.5, borderBottom: "1px solid #e6e6e670" }}>
                                                <Grid item style={{ width: "25%", height: "100%", textAlign: "left" }}>
                                                    <DivData>
                                                        <label style={{ fontWeight: "bold" }}>Emissions/kg</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label>{normaliseValue(totalsOldBaseline.total / totalsFuelOldBaseline.total, 2)}</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label>{normaliseValue((totalsOldBaseline.biogenic + totalsOldBaseline.removals) / totalsFuelOldBaseline.total, 2)}</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label>{normaliseValue(totalsOldBaseline.reportable / totalsFuelOldBaseline.total, 2)}</label>
                                                    </DivData>
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </>}
                        </Grid>

                        <Grid item xs={6} style={{ marginBottom: 20, position: "relative" }}>
                            {totalsOld != null && totalsNew != null && <>
                                <div style={{ position: "absolute", transform: "rotate(90deg)", right: -50, top: 25, textAlign: "right" }}>
                                    <p style={{ marginBottom: 0, fontWeight: 900, fontSize: 12, color: "#65687f" }}>Simulated</p>
                                    <p style={{ marginBottom: 0, fontWeight: 900, fontSize: 12, color: "#65687f" }}>vs 2020</p>
                                </div>
                                <Grid item container direction='row' style={{ background: "#fff", borderRadius: "0 0 6px 6px", marginTop: 10 }}>
                                    {loading &&
                                        <Grid item style={{ width: "100%", height: "100%" }}>
                                            <TableLoading small={true} />
                                        </Grid>
                                    }

                                    {!loading &&
                                        <>
                                            <Grid item container direction='row' style={{ paddingBottom: 7.5, paddingTop: 7.5, borderBottom: "1px solid #e6e6e670" }}>
                                                <Grid item style={{ width: "25%", height: "100%", textAlign: "left" }}>
                                                    <DivData>
                                                        <label style={{ fontWeight: "bold" }}>Ton CO2e</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label>{calculatePercentageDifference(totalsOldBaseline.total, totalsNew.total)}</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label>{calculatePercentageDifference(totalsOldBaseline.biogenic + totalsOldBaseline.removals, totalsNew.biogenic + totalsNew.removals)}</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label>{calculatePercentageDifference(totalsOldBaseline.reportable, totalsNew.reportable)}</label>
                                                    </DivData>
                                                </Grid>
                                            </Grid>

                                            <Grid item container direction='row' style={{ paddingBottom: 7.5, paddingTop: 7.5, borderBottom: "1px solid #e6e6e670" }}>
                                                <Grid item style={{ width: "25%", height: "100%", textAlign: "left" }}>
                                                    <DivData>
                                                        <label style={{ fontWeight: "bold" }}>Emissions/kg (%)</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label>{calculatePercentageDifference(totalsOldBaseline.total / totalsFuelOldBaseline.total, totalsNew.total / totalsFuelNew.total)}</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label>{calculatePercentageDifference((totalsOldBaseline.biogenic + totalsOldBaseline.removals) / totalsFuelOldBaseline.total, (totalsNew.biogenic + totalsNew.removals) / totalsFuelNew.total)}</label>
                                                    </DivData>
                                                </Grid>
                                                <Grid item style={{ width: "25%", height: "100%" }}>
                                                    <DivData>
                                                        <label>{calculatePercentageDifference(totalsOldBaseline.reportable / totalsFuelOldBaseline.total, totalsNew.reportable / totalsFuelNew.total)}</label>
                                                    </DivData>
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </>}
                        </Grid>

                        {dataActualScope1?.length > 0 && <Grid item xs={6} style={{ marginBottom: 20 }}>
                            <Grid item container direction='row' style={{ background: "#fff", borderRadius: "0 0 6px 6px", marginTop: 10 }}>
                                <Grid item container className="grid-header" direction='row' style={{ backgroundColor: "#D9F3D7" }}>
                                    <span style={{ color: "#102a63" }}>Actual In-House curing - Scope 1</span>
                                </Grid>

                                {["Category", "Reportable", "Biogenic", "Removals", "Total"].map((label, index) => (
                                    <Grid item style={{ width: index == 0 ? "24%" : "19%" }} key={index}>
                                        <HeaderTable>
                                            <label>{label}</label>
                                        </HeaderTable>
                                    </Grid>
                                ))}

                                {loading &&
                                    <Grid item style={{ width: "100%", height: "100%" }}>
                                        <TableLoading />
                                    </Grid>
                                }

                                <Grid item container direction='row' style={{ paddingBottom: 5, paddingTop: 5 }}>
                                    {
                                        dataActualScope1?.length > 0 &&
                                        dataActualScope1?.map((e, i) => (
                                            <Grid key={i} item container direction='row' style={{ paddingBottom: 7.5, paddingTop: 7.5, borderBottom: "1px solid #e6e6e670" }}>
                                                {["category", "reportable", "biogenic", "removals", "total"].map((label, index) => (
                                                    <Grid item style={{ width: index == 0 ? "24%" : "19%", height: "100%" }} key={label}>
                                                        <DivData>
                                                            <label style={{ fontWeight: index == 0 || index == 4 ? "bold" : "500" }}>{e[label].toLocaleString()}</label>
                                                        </DivData>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </Grid>}

                        {dataSimulatedScope1?.length > 0 && <Grid item xs={6} style={{ marginBottom: 20 }}>
                            <Grid item container direction='row' style={{ background: "#fff", borderRadius: "0 0 6px 6px", marginTop: 10 }}>
                                <Grid item container className="grid-header" direction='row' style={{ backgroundColor: "#FFE292" }}>
                                    <span style={{ color: "#102a63" }}>Simulated In-House curing - Scope 1</span>
                                </Grid>

                                {["Category", "Reportable", "Biogenic", "Removals", "Total"].map((label, index) => (
                                    <Grid item style={{ width: index == 0 ? "24%" : "19%" }} key={index}>
                                        <HeaderTable>
                                            <label>{label}</label>
                                        </HeaderTable>
                                    </Grid>
                                ))}

                                {loading &&
                                    <Grid item style={{ width: "100%", height: "100%" }}>
                                        <TableLoading />
                                    </Grid>
                                }

                                <Grid item container direction='row' style={{ paddingBottom: 5, paddingTop: 5 }}>
                                    {
                                        dataSimulatedScope1?.length > 0 &&
                                        dataSimulatedScope1?.map((e, i) => (
                                            <Grid key={i} item container direction='row' style={{ paddingBottom: 7.5, paddingTop: 7.5, borderBottom: "1px solid #e6e6e670" }}>
                                                {["category", "reportable", "biogenic", "removals", "total"].map((label, index) => (
                                                    <Grid item style={{ width: index == 0 ? "24%" : "19%", height: "100%" }} key={label}>
                                                        <DivData>
                                                            <label style={{ fontWeight: index == 0 || index == 4 ? "bold" : "500" }}>{e[label].toLocaleString()}</label>
                                                        </DivData>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </Grid>}

                        <Grid item xs={6} style={{ marginBottom: 20 }}>
                            <Grid item container direction='row' style={{ background: "#fff", borderRadius: "0 0 6px 6px", marginTop: 10 }}>
                                <Grid item container className="grid-header" direction='row' style={{ backgroundColor: "#D9F3D7" }}>
                                    <span style={{ color: "#102a63" }}>{reportYear} volume (ton)</span>
                                </Grid>

                                {["", "FCV+DFC", "Others", "Total"].map((label, index) => (
                                    <Grid item style={{ width: 100 / 4 + "%" }} key={index}>
                                        <HeaderTable>
                                            <label>{label}</label>
                                        </HeaderTable>
                                    </Grid>
                                ))}

                                {loading &&
                                    <Grid item style={{ width: "100%", height: "100%" }}>
                                        <TableLoading />
                                    </Grid>
                                }

                                <Grid item container direction='row' style={{ paddingBottom: 5, paddingTop: 5 }}>
                                    {
                                        dataFuelActual?.length > 0 &&
                                        dataFuelActual?.map((e, i) => (
                                            <Grid key={i} item container direction='row' style={{ paddingBottom: 7.5, paddingTop: 7.5, borderBottom: "1px solid #e6e6e670" }}>
                                                {["name", "fcvdfc", "others", "total"].map((label, index) => (
                                                    <Grid item style={{ width: 100 / 4 + "%" }} key={index}>
                                                        <DivData>
                                                            <label style={{ fontWeight: index == 0 || index == 3 ? "bold" : "500" }}>{e[label].toLocaleString()}</label>
                                                        </DivData>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        ))
                                    }
                                </Grid>

                                {dataFuelActual?.length > 0 && <>
                                    <div className="border-div" style={{ width: "97%", marginBottom: "0.5rem", marginLeft: "2%", marginRight: "1%" }} />
                                    <Grid item container direction='row' style={{ paddingBottom: 15, paddingTop: 5 }}>
                                        {["name", "fcvdfc", "others", "total"].map((label, index) => (
                                            <Grid item style={{ width: 100 / 4 + "%" }} key={index}>
                                                <DivData>
                                                    <label style={{ fontWeight: index == 0 || index == 3 ? "bold" : "500" }}>{totalsFuelOld[label].toLocaleString()}</label>
                                                </DivData>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </>}
                            </Grid>
                        </Grid>

                        <Grid item xs={6} style={{ marginBottom: 20 }}>
                            <Grid item container direction='row' style={{ background: "#fff", borderRadius: "0 0 6px 6px", marginTop: 10 }}>
                                <Grid item container className="grid-header" direction='row' style={{ backgroundColor: "#FFE292" }}>
                                    <span style={{ color: "#102a63" }}>Simulated volume (ton)</span>
                                </Grid>

                                {["", "FCV+DFC", "Others", "Total"].map((label, index) => (
                                    <Grid item style={{ width: 100 / 4 + "%" }} key={index}>
                                        <HeaderTable>
                                            <label>{label}</label>
                                        </HeaderTable>
                                    </Grid>
                                ))}

                                {loading &&
                                    <Grid item style={{ width: "100%", height: "100%" }}>
                                        <TableLoading />
                                    </Grid>
                                }

                                <Grid item container direction='row' style={{ paddingBottom: 5, paddingTop: 5 }}>
                                    {
                                        dataFuelSimulated?.length > 0 &&
                                        dataFuelSimulated?.map((e, i) => (
                                            <Grid key={i} item container direction='row' style={{ paddingBottom: 7.5, paddingTop: 7.5, borderBottom: "1px solid #e6e6e670" }}>
                                                {["name", "fcvdfc", "others", "total"].map((label, index) => (
                                                    <Grid item style={{ width: 100 / 4 + "%" }} key={index}>
                                                        <DivData>
                                                            <label style={{ fontWeight: index == 0 || index == 3 ? "bold" : "500" }}>{e[label].toLocaleString()}</label>
                                                        </DivData>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        ))
                                    }
                                </Grid>

                                {dataFuelSimulated?.length > 0 && <>
                                    <div className="border-div" style={{ width: "97%", marginBottom: "0.5rem", marginLeft: "2%", marginRight: "1%" }} />
                                    <Grid item container direction='row' style={{ paddingBottom: 15, paddingTop: 5 }}>
                                        {["name", "fcvdfc", "others", "total"].map((label, index) => (
                                            <Grid item style={{ width: 100 / 4 + "%" }} key={index}>
                                                <DivData>
                                                    <label style={{ fontWeight: index == 0 || index == 3 ? "bold" : "500" }}>{totalsFuelNew[label].toLocaleString()}</label>
                                                </DivData>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </>}
                            </Grid>
                            {/* {totalsFuelOld != null && totalsFuelNew != null && <>
                                <Grid item container direction='row' style={{ background: "#fff", borderRadius: "0 0 6px 6px", marginTop: 10 }}>
                                    {["FCV+DFC", "Others", "Total"].map((label, index) => (
                                        <Grid item style={{ width: "33.3%" }} key={index}>
                                            <HeaderTable style={{ textAlign: "center", justifyContent: "center" }}>
                                                <label>{label}</label>
                                            </HeaderTable>
                                        </Grid>
                                    ))}

                                    {loading &&
                                        <Grid item style={{ width: "100%", height: "100%" }}>
                                            <TableLoading small={true} />
                                        </Grid>
                                    }

                                    {!loading &&
                                        <Grid item container direction='row' style={{ paddingBottom: 7.5, paddingTop: 7.5, borderBottom: "1px solid #e6e6e670" }}>
                                            <Grid item style={{ width: "33.3%", height: "100%", textAlign: "center" }}>
                                                {calculatePercentageDifference(totalsFuelOld.fcvdfc, totalsFuelNew.fcvdfc, "#102a63")}
                                            </Grid>
                                            <Grid item style={{ width: "33.3%", height: "100%", textAlign: "center" }}>
                                                {calculatePercentageDifference(totalsFuelOld.others, totalsFuelNew.others, "#102a63")}
                                            </Grid>
                                            <Grid item style={{ width: "33.3%", height: "100%", textAlign: "center" }}>
                                                {calculatePercentageDifference(totalsFuelOld.total, totalsFuelNew.total, "#102a63")}
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </>} */}
                        </Grid>

                        <Grid item xs={12}>
                            <h5><strong>Summary</strong></h5>
                            {loading &&
                                <Card>
                                    <Grid item style={{ width: "100%", height: "100%" }}>
                                        <TableLoading />
                                    </Grid>
                                </Card>
                            }
                            <div style={{ marginBottom: 15 }}>
                                {resultData2?.length > 0 &&
                                    resultData2.map((e, i) => (
                                        <div key={e.company + 10}>
                                            <Grid item container className="grid-header" direction='row' style={{ backgroundColor: "#ececf3" }}>
                                                <span style={{ color: "#102a63" }}>{e.company}</span>
                                            </Grid>
                                            <Card>
                                                <Grid container spacing={1} style={{ alignItems: "center" }} direction='row'>
                                                    <Grid item xs={6}>
                                                        <label style={{ fontWeight: "bold", padding: "5px 10px" }}>Actual Volume(ton)</label>
                                                    </Grid>
                                                    <Grid item xs={6} style={{ justifyContent: "end", display: "flex" }}>
                                                        <label style={{ fontWeight: "bold", padding: "5px 10px" }}>Simulated Volume(ton)</label>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={0} style={{ justifyContent: "flex-end", alignItems: "center" }} direction='row'>
                                                    <Grid item xs={5}>
                                                        <label style={{ padding: "5px 10px", borderRadius: 10, backgroundColor: e.volumeOld != e.volumeActually ? "#D9F3D7" : "#fff", color: "#102961" }}>{normaliseValue(e.volumeOld, 0).toLocaleString()}</label>
                                                    </Grid>
                                                    <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
                                                        <FaLongArrowAltRight size={25} color='#102a63' />
                                                    </Grid>
                                                    <Grid item xs={5} style={{ justifyContent: "end", display: "flex" }}>
                                                        <label style={{ padding: "5px 10px", borderRadius: 10, backgroundColor: e.volumeOld != e.volumeActually ? "#FFE292" : "#fff", color: "#102961" }}>{normaliseValue(e.volumeActually, 0).toLocaleString()}</label>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                            {e.newFuels?.length > 0 && e.oldFuels?.length > 0 && (
                                                <Card>
                                                    <React.Fragment>
                                                        <Grid container spacing={1} style={{ alignItems: "center" }} direction='row'>
                                                            <Grid item xs={6}>
                                                                <label style={{ fontWeight: "bold", padding: "5px 10px" }}>Actual Fuels</label>
                                                            </Grid>
                                                            <Grid item xs={6} style={{ justifyContent: "end", display: "flex" }}>
                                                                <label style={{ fontWeight: "bold", padding: "5px 10px" }}>Simulated Fuels</label>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={1} style={{ alignItems: "center" }} direction='row'>
                                                            <Grid item xs={5}>
                                                                {e.oldFuels.sort((a, b) => a.description < b.description ? -1 : 1).map((of, i) => {
                                                                    var dif = e.newFuels.find(x => x.description == of.description && x.value == of.value) == null;
                                                                    return <Grid container spacing={1} style={{ alignItems: "center" }} direction='row' key={i + of.value}>
                                                                        <Grid item xs={12}>
                                                                            <label style={{ padding: "5px 10px", borderRadius: 10, backgroundColor: dif ? "#D9F3D7" : "#fff", color: "#102961" }}>{of.description} <strong>({of.value}%)</strong></label>
                                                                        </Grid>
                                                                    </Grid>
                                                                })}
                                                            </Grid>
                                                            <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
                                                                <FaLongArrowAltRight size={25} color='#102a63' />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                {e.newFuels.sort((a, b) => a.description < b.description ? -1 : 1).map((of, i) => {
                                                                    var dif = e.oldFuels.find(x => x.description == of.description && x.value == of.value) == null;
                                                                    return <Grid container spacing={1} style={{ alignItems: "center" }} direction='row' key={i + of.value}>
                                                                        <Grid item xs={12} style={{ justifyContent: "end", display: "flex" }}>
                                                                            <label style={{ padding: "5px 10px", borderRadius: 10, backgroundColor: dif ? "#FFE292" : "#fff", color: "#102961" }}>{of.description} <strong>({of.value}%)</strong></label>
                                                                        </Grid>
                                                                    </Grid>
                                                                })}
                                                            </Grid>
                                                        </Grid>
                                                    </React.Fragment>
                                                </Card>
                                            )}
                                        </div>
                                    ))}
                            </div>
                        </Grid>
                    </Grid>
                </Section>
            </Container >
        </>
    )
}

export default SimulationResult;