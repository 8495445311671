import React, { useEffect, useState } from 'react';

import Api, { exceptionNotificationAPI } from '../../services/api';
import FormCrud from '../../components/FormCrud';
import { options } from 'toastr';

const SectionForm = () => {
    const [modules, setModules] = useState([]);
    const [modulesOptions, setModulesOptions] = useState([]);
    const [reportOptions, setReportOptions] = useState([]);
    const [selectedReportYearID, setSelectedReportYearID] = useState();
    const [companysOptions, setCompanysOptions] = useState([]);    
    const [isLoading, setIsLoading] = useState(true);

    const activeOptions = [
        { value: true, text: "Yes" },
        { value: false, text: "No" }
    ];

    const filterModules = async () => {
        if (modules != null) {
            var filteredModules = modules.filter(x => x.reportYearID == selectedReportYearID);
            const options = filteredModules.map(item => ({ value: item.moduleID, text: `${item.operation.description} -> ${item.description}` }));

            setModulesOptions(options);
        }
    }

    const selectReportYear = async (e) => {
        setSelectedReportYearID(e.getFieldRef("reportYearID").current.value);
    }

    const fields = [
        {
            label: "Report Year",
            name: "reportYearID",
            type: "select",
            options: reportOptions,
            onChanged: selectReportYear
        },
        {
            label: "Module",
            name: "moduleID",
            type: "select",
            options: modulesOptions
        },
        {
            label: "Section Description",
            name: "description",
            type: "input"
        },
        {
            label: "Guidance",
            name: "guidance",
            type: "textarea",
            required: false
        },
        {
            label: "Order",
            name: "order",
            type: "number"
        },
        {
            label: "Companies to exclude",
            name: "sectionCompany",
            type: "select",
            required: false,
            options: companysOptions,
            multiple: true
        },
        {
            label: "Is Active",
            name: "isActive",
            type: "select",
            options: activeOptions
        },
    ]

    const loadModules = async () => {
        await Api.get('Module/getAll').then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.moduleID, text: `${item.operation.description} -> ${item.description}`, reportYearID: item.reportYearID }));

                setModules(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadCompanies = async () => {
        await Api.get('Company/getAll').then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.companyID, text: `${item.name}` }));

                setCompanysOptions(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadReportYear = async () => {
       await Api.get('ReportYear/getAll').then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.reportYearID, text: `${item.year}` }));
    
                setReportOptions(options);
            }
    
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    useEffect(() => {
        if (modules != null) {
            var filteredModules = modules.filter(x => x.reportYearID == selectedReportYearID);
            const options = filteredModules;

            setModulesOptions(options);
        }
    }, [selectedReportYearID]);

    useEffect(() => {
        loadModules();
        loadCompanies();
        loadReportYear();
        filterModules();        
        setIsLoading(false)
    }, []);

    // return (
    //     modulesOptions.length > 0 ? <FormCrud formName={"section"} title={"Section"} fields={fields} /> : <></>
    // );

    return (
        modules.length > 0 && reportOptions.length > 0 ? <FormCrud formName={"section"} title={"Section"} fields={fields} isLoading={isLoading} setSelectedReportYearID={setSelectedReportYearID} />: <></>
    );
}

export default SectionForm;
