import React, { useEffect, useState } from 'react';
import { Backdrop, ModalContainer, ModalForm, ModalButton, Field } from './styles';
import { ContainerBody } from './styles';
import Api from '../../../services/api';
import { error_message, success_message } from '../../Toast';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import { FaPlus } from "react-icons/fa";

const SimpleRuleModalComponent = ({ isOpen, onClose, reportYears, reportYearID, modules, moduleID, ssks, typeOptions, typeValues, rules, logicOperators, editData, loadSimpleRules }) => {
  const [selectedReportYear, setSelectedReportYear] = useState(6);
  const [selectedModule, setSelectedModule] = useState(90);
  const [simpleRuleID, setSimpleRuleID] = useState(null);
  const [simpleRuleGroupIdentifier, setSimpleRuleGroupIdentifier] = useState(1);
  const [selectedSSK, setSelectedSSK] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedTypeValue, setSelectedTypeValue] = useState('');
  const [selectedRule, setSelectedRule] = useState('');
  const [lowerLimit, setLowerLimit] = useState('');
  const [upperLimit, setUpperLimit] = useState('');
  const [selectedOperator, setSelectedOperator] = useState('');
  const [value, setValue] = useState('');
  const [linkedKpi, setLinkedKpi] = useState('');
  const [discussion, setDiscussion] = useState('');

  // Behaviour hooks
  const [simpleType, setSimpleType] = useState(false);
  const [ruleObject, setRuleObject] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  // Hooks for options
  const [reportYearList, setReportYearList] = useState(reportYears);
  const [moduleList, setModuleList] = useState(modules);
  const [subSectionKpiList, setSubSectionKpiList] = useState(ssks);
  const [typeOptionsList, setTypeOptionsList] = useState(typeOptions);
  const [typeValueList, setTypeValueList] = useState(typeValues);
  const [ruleList, setRuleList] = useState(rules);
  const [logicOperatorsList, setLogicOperatorsList] = useState(logicOperators);

  /* Selects Data */
  useEffect(() => {
    setModuleList(modules);
  }, [modules]);

  useEffect(() => {
    setReportYearList(reportYears);
  }, [reportYears]);

  useEffect(() => {
    let sskData = ssks.map(ssk => ({
      value: ssk.subSectionKPIID,
      label: ssk.description
    }));
    setSubSectionKpiList(sskData);
  }, [ssks]);

  useEffect(() => {
    let ruleData = rules.map(rule => ({
      value: rule.ruleID,
      label: rule.description
    }));
    setRuleList(ruleData);
  }, [rules]);

  useEffect(() => {
    let typeData = typeOptions.filter(typeOption => simpleType ? (typeOption.value === 6 || typeOption.value === 1) : true).map(type => ({
      value: type.value,
      label: type.description
    }));
    setTypeOptionsList(typeData);
  }, [typeOptions, simpleType]);

  useEffect(() => {
    let typeValueData = typeValues.filter(x => x.typeID == selectedType).map(typeValue => ({
      value: typeValue.typeValueID,
      label: typeValue.description
    }));
    setTypeValueList(typeValueData);
    setSimpleType((selectedType == 2 || selectedType == 3 || selectedType == 4) ? false : true);
  }, [selectedType]);

  useEffect(() => {
    if (ruleObject?.operatorsGroup == 2) {
      let operatorData = logicOperators?.filter(op => op.value == 3 || op.value == 4).map(operator => ({
        value: operator.value,
        label: operator.description
      }));
      setLogicOperatorsList(operatorData);
    } else {
      let operatorData = logicOperators?.map(operator => ({
        value: operator.value,
        label: operator.description
      }));
      setLogicOperatorsList(operatorData);
    }

  }, [ruleObject]);

  useEffect(() => {
    setSelectedModule(moduleID);
  }, [moduleID]);

  useEffect(() => {
    setSelectedOperator(selectedOperator);
  }, [logicOperatorsList]);



  /* Select OnChange Events*/
  const handleReportYearChange = (selectedOption) => {
    setSelectedReportYear(selectedOption.reportYearID);
  };

  const handleModuleChange = (selectedOption) => {
    setSelectedModule(selectedOption.value);
  };

  const handleSSKChange = (selectedOption) => {
    setSelectedSSK(selectedOption.value);
    setSelectedTypeValue('');
  };

  const handleTypeChange = (selectedOption) => {
    setSelectedType(selectedOption.value);
  };

  const handleTypeValueChange = (selectedOption) => {
    setSelectedTypeValue(selectedOption.value);
  };

  const handleRuleChange = (selectedOption) => {
    setSelectedRule(selectedOption.value);
    setLowerLimit('');
    setUpperLimit('');
    setSelectedOperator('');
    setValue('');
    setLinkedKpi('');
  };

  const handleOperatorChange = (selectedOption) => {
    setSelectedOperator(selectedOption.value);
  };

  /*Additional methods*/
  useEffect(() => {
    setSelectedReportYear(reportYearID);
  }, [reportYearID]);

  useEffect(() => {
    setSelectedType(ssks?.find(x => x.subSectionKPIID == selectedSSK)?.columnType);
  }, [selectedSSK]);

  useEffect(() => {
    setRuleObject(rules.find(x => x.ruleID == selectedRule));
  }, [selectedRule]);

  const clearInputFields = () => {
    setSimpleRuleGroupIdentifier(1);
    setSimpleRuleID(null);
    setSelectedSSK('');
    setSelectedType('');
    setSelectedTypeValue('');
    setSelectedRule('');
    setLowerLimit('');
    setUpperLimit('');
    setSelectedOperator('');
    setValue('');
    setLinkedKpi('');
    setDiscussion('');
  }

  const addRule = async (event) => {
    event.preventDefault();
    var dataObj = {
      "groupIdentifier": simpleRuleGroupIdentifier,
      "ruleRegisterID": simpleRuleID,
      "reportYearID": selectedReportYear,
      "moduleID": selectedModule,
      "subSectionKPIID": selectedSSK,
      "typeID": selectedType,
      "typeValueID": selectedTypeValue,
      "ruleID": selectedRule,
      "lowerLimit": lowerLimit,
      "upperLimit": upperLimit,
      "comparisonOperator": selectedOperator,
      "targetValue": value,
      "linkedKPI": linkedKpi,
      "discussion": discussion
    };
    await Api.post("Rule/createOrUpdateRule", dataObj)
      .then(response => {
        if (response) {
          clearInputFields();
          success_message("New rule created!");
          loadSimpleRules(reportYearID, moduleID);
          handleClose();
        } else {
          error_message("Error when trying to create the new rule!");
        }
      })
  }

  const handleClose = () => {
    clearInputFields();
    onClose();
  }

  useEffect(() => {
    if (editData) {
      setSimpleRuleGroupIdentifier(editData.groupIdentifier);
      setSelectedReportYear(editData.reportYearID);
      setSelectedModule(editData.moduleID);
      setSelectedSSK(editData.subSectionKPIID);
      setSelectedType(editData.typeID);
      setSelectedTypeValue(editData.typeValueID);
      setSelectedRule(editData.ruleID);
      setSimpleRuleID(editData.ruleRegisterID);
      setLowerLimit(editData.lowerLimit);
      setUpperLimit(editData.upperLimit);
      setSelectedOperator(editData.comparisonOperator);
      setValue(editData.targetValue);
      setLinkedKpi(editData.linkedKPI);
      setDiscussion(editData.discussion);
      setIsEdit(true);
    }
    else {
      clearInputFields();
      setIsEdit(false);
    }
  }, [editData]);

  if (!isOpen) return null;
  return (
    <Backdrop>
      <ModalContainer>
        <header className="modal-header">
          <h2>{editData ? "Edit" : "Create new rule"}</h2>
          <div className="remove-icon" onClick={(e) => { handleClose() }} >
            <FaPlus size={20} color="#112A61" />
          </div>
        </header>
        <ModalForm>
          <ContainerBody>
            <div className='row'>
              <label>
                Group Identifier
                <textarea className='input-text-field-cs' type="text" value={simpleRuleGroupIdentifier} onChange={(e) => setSimpleRuleGroupIdentifier(e.target.value)} />
              </label>
            </div>
            <div className='row' style={{ marginTop: '1rem' }}>
              <label>
                Report Year
                <Select
                  name="reportYear"
                  options={reportYearList}
                  className="basic-select"
                  classNamePrefix="select"
                  value={reportYearList.find(x => x.value == selectedReportYear)}
                  onChange={handleReportYearChange}
                  isDisabled={true}
                />
              </label>
            </div>

            <div className='row select-field'>
              <label>
                Module
                <Select
                  name="module"
                  options={moduleList}
                  className="basic-select"
                  classNamePrefix="select"
                  value={moduleList.find(x => x.value == selectedModule)}
                  onChange={handleModuleChange}
                  isDisabled={true}
                />
              </label>
            </div>
            <div className='row select-field'>
              <label>
                KPI
                <Select
                  name="kpi"
                  options={subSectionKpiList}
                  className="basic-select"
                  classNamePrefix="select"
                  value={subSectionKpiList.find(x => x.value == selectedSSK)}
                  onChange={handleSSKChange}
                />
              </label>
            </div>
            {
              <>
                <div className='row select-field'>
                  <label>
                    Type
                    <Select
                      name="type"
                      options={typeOptionsList}
                      className="basic-select"
                      classNamePrefix="select"
                      value={typeOptionsList.find(x => x.value == selectedType)}
                      onChange={handleTypeChange}
                    />
                  </label>
                </div>
                {
                  selectedType != 1 ? //If the type selected is SIMPLE, it should not display the following field
                    <div className='row select-field'>
                      <label>
                        Type Value
                        <Select
                          name="typeValue"
                          options={typeValueList}
                          className="basic-select"
                          classNamePrefix="select"
                          value={typeValueList.find(x => x.value == selectedTypeValue)}
                          onChange={handleTypeValueChange}
                        />
                      </label>
                    </div>
                    :
                    <></>
                }
              </>
            }
            <div className='row select-field'>
              <label>
                Rule
                <Select
                  name="rule"
                  options={ruleList}
                  className="basic-select"
                  classNamePrefix="select"
                  value={ruleList.find(x => x.value == selectedRule)}
                  onChange={handleRuleChange}
                />
              </label>
            </div>
            {
              selectedRule ? <>
                {ruleObject?.allowLowerLimit && (
                  <div className='row select-field'>
                    <label>
                      Lower Limit
                      <input className='input-field' type="text" value={lowerLimit} onChange={(e) => setLowerLimit(e.target.value)} />
                    </label>
                  </div>)}
                {ruleObject?.allowUpperLimit && (
                  <div className='row select-field'>
                    <label>
                      Upper Limit
                      <input className='input-field' type="text" value={upperLimit} onChange={(e) => setUpperLimit(e.target.value)} />
                    </label>
                  </div>)}
                {ruleObject?.allowComparisonOperator && (
                  <div className='row select-field'>
                    <label>
                      Operator
                      <Select
                        name="operator"
                        options={logicOperatorsList}
                        className="basic-select"
                        classNamePrefix="select"
                        value={logicOperatorsList.find(x => x.value == selectedOperator)}
                        onChange={handleOperatorChange}
                      />
                    </label>
                  </div>)}
                {ruleObject?.allowTargetValue && (
                  <div className='row select-field'>
                    <label>
                      Value
                      <input className='input-field' type="text" value={value} onChange={(e) => setValue(e.target.value)} />
                    </label>
                  </div>)}
                {ruleObject?.allowKpiLink && (
                  <div className='row select-field'>
                    <label>
                      Linked KPI
                      <input className='input-field' type="text" value={linkedKpi} onChange={(e) => setLinkedKpi(e.target.value)} />
                    </label>
                  </div>)}
                <div className='row select-field'>
                  <label>
                    Discussion
                    <textarea className='input-text-field' type="text" value={discussion} onChange={(e) => setDiscussion(e.target.value)} />
                  </label>
                </div>
              </> : <></>

            }
            <div className='row footer-buttons'>
              <ModalButton type="button" onClick={handleClose}>CANCEL</ModalButton>
              <ModalButton type="submit" onClick={addRule}>{isEdit ? "UPDATE" : "SAVE"}</ModalButton>
            </div>
          </ContainerBody>
        </ModalForm>
      </ModalContainer>
    </Backdrop>
  );
};

export default SimpleRuleModalComponent;
