import React, { useState, useEffect } from 'react';
import { Container, Table } from './styles';
import { useAuth } from '../../hooks/auth';
import { useParams } from 'react-router-dom';
import Api, { exceptionNotificationAPI } from '../../services/api';
import TableLoading from '../../components/TableLoading/index';
import { error_message } from '../../components/Toast/index.jsx';
import { FaArrowRight, FaAngleDown, FaAngleUp } from 'react-icons/fa';
import GetTypeInput from '../../components/FormInput/set';
import NumberFormat from 'react-number-format';
import ModalLoading from '../../components/ModalLoading/index';
import ChatWindow from '../../components/Chat/index';
import ChatButton from '../../components/ChatButton/index';
import moment from 'moment';
import Swal from 'sweetalert2';

const DiscussionDashboard = () => {
    const { user, userRoles } = useAuth();
    const { companyID, reportYearID, operationID, moduleID } = useParams();

    const [userObject, setUserObject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [discussionStatus, setDiscussionStatus] = useState("");

    //Company Select Variables
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(companyID);

    //Operation Select Variables
    const [operations, setOperations] = useState([]);
    const [selectedOperation, setSelectedOperation] = useState(operationID);

    //Module Select Variables
    const [modules, setModules] = useState([]);
    const [modulesFiltered, setModulesFiltered] = useState([]);
    const [selectedModule, setSelectedModule] = useState(moduleID);

    //Crop Year Select Variables
    const [cropYears, setCropYears] = useState([]);
    const [selectedReportYear, setSelectedReportYear] = useState(reportYearID);

    //Ref Number Select Variables
    const [refNumberSelect, setRefNumberSelect] = useState([]);
    const [selectedRefNumber, setSelectedRefNumber] = useState(null);

    //Load Table Versions
    const [loadingView, setLoadingView] = useState(false);
    const [viewData, setViewData] = useState(null);
    const [viewSpecificData, setViewSpecificData] = useState(null);
    const [selectedCompanyIsSourcingGroup, setSelectedCompanyIsSourcingGroup] = useState(false);

    //Tobacco Types Select Variables
    const [tobaccoTypes, setTobaccoTypes] = useState([]);
    const [selectedTobaccoType, setSelectedTobaccoType] = useState(null);

    const [isFirstLoading, setIsFirstLoading] = useState(!(companyID && reportYearID && operationID && moduleID));

    //Chat
    const defaultChatRequest = {
        "reportYearID": null,
        "companyId": null,
        "moduleId": null,
        "subSectionKPIID": null,
        "kpiId": null,
        "tobaccoTypeID": null,
        "fuelTypeId": null,
        "curingBarnID": null,
        "countryId": null,
        "row": null,
        "column": null
    };
    const [showChat, setShowChat] = useState(false);
    const [chatRequest, setChatRequest] = useState({ ...defaultChatRequest });
    const [kpiDiscussions, setKpiDiscussions] = useState([]);

    useEffect(() => {
        var userAux = typeof (user) == "string" ? JSON.parse(user) : user;
        setUserObject(userAux);

        if (!userAux.companyID || userAux.groupID) {
            loadCompanies(userAux.groupID);
        } else {
            setSelectedCompany(userAux.companyID);
        }
        loadCropYears(userAux);
        setLoading(false);
    }, []);


    useEffect(() => {
        setChatRequest({
            ...defaultChatRequest,
            "reportYearID": selectedReportYear,
            "companyId": selectedCompany,
            "moduleId": selectedModule
        });
    }, [selectedCompany, selectedReportYear, selectedModule])

    useEffect(() => {
        if (selectedCompany && selectedReportYear) {
            Api.get(`TobaccoType/GetSelectedTobaccoTypes?companyID=${selectedCompany}&reportYearID=${selectedReportYear}`)
                .then((result) => {
                    if (result && result.data) {
                        setTobaccoTypes([...result.data.response]);
                    }
                }).catch((error) => {
                    exceptionNotificationAPI(error);
                });
        }
    }, [selectedCompany, selectedReportYear])

    useEffect(() => {
        setSelectedModule('');
        loadModules(selectedCompanyIsSourcingGroup, selectedReportYear);
    }, [selectedOperation]);

    const loadCompanies = (groupID) => {
        if (groupID != null) {
            Api.get(`Dashboard/getAllCompaniesFilteredByGroup`).then((result) => {
                if (result && result.data) {
                    setCompanies(result.data.response);
                    // setSelectedCompanyIndex([0]);
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
        else {
            Api.get(`company/getAll`).then((result) => {
                if (result && result.data) {
                    setCompanies(result.data.response);

                    if (companyID)
                        setSelectedCompany(companyID);
                    else if (userObject?.companyID)
                        setSelectedCompany(userObject.companyID);
                    else
                        setSelectedCompany(result.data.response[0].companyID);
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
    }

    const loadCropYears = (userAux) => {
        Api.get(`thriveValue/getAllCropsYears`).then((result) => {
            setLoading(true);
            if (result && result.data) {
                setCropYears(result.data.response);

                if (reportYearID) {
                    setSelectedReportYear(reportYearID);
                    loadOperations(reportYearID);
                    loadModules(selectedCompanyIsSourcingGroup, reportYearID);
                }
                else {
                    if (userAux != null) {
                        setSelectedReportYear(userAux.selectedReportYearID);
                        loadOperations(userAux.selectedReportYearID);
                        loadModules(selectedCompanyIsSourcingGroup, userAux.selectedReportYearID);
                    }
                }
            }
            setLoading(false);
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadOperations = (reportYearID) => {
        Api.get(`thriveValue/getAllOperationsByReportYear?reportYearID=${reportYearID}`).then((result) => {

            if (result && result.data) {
                let response = result.data.response;
                if (response.length > 0) {
                    setOperations(response);
                    //setSelectedOperation(response[0].operationID);
                } else {
                    response.push({
                        operationID: "",
                        description: `No items found, please select another Report Year`,
                    })
                    setOperations(response);
                }
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadModules = (IsSourcingGroup, reportYearID) => {
        if (reportYearID) {
            Api.get(`Dashboard/GetAllByReportYear?reportYearID=${reportYearID}`).then((result) => {
                if (result && result.data) {
                    setModules(result.data.response);
                    setModulesFiltered(result.data.response.filter(x => x.operationID == selectedOperation));
                    if (selectedOperation) {

                        if (!IsSourcingGroup) {
                            setModulesFiltered(result.data.response.filter(x => x.operationID == selectedOperation && x.description != "GLT"));
                        }
                        else {
                            setModulesFiltered(result.data.response.filter(x => x.operationID == selectedOperation));
                        }
                    }
                    else {
                        if (!IsSourcingGroup) {
                            setModulesFiltered(result.data.response.filter(x => x.description != "GLT"));
                        }
                        else {
                            setModulesFiltered(result.data.response);
                        }
                    }
                }
            }).catch((error) => {
                setLoading(false);
                exceptionNotificationAPI(error);
            });
        }

    }

    const onChangeCompany = (value) => {
        setSelectedCompany(value);
    }

    const onChangeReportYearID = (value) => {
        setSelectedReportYear(value);
        setSelectedOperation(null);
        setSelectedModule(null);
        loadOperations(value);
        loadModules(selectedCompanyIsSourcingGroup, value);

        /*var operationID = operations && operations.length > 0 ? operations[0].operationID : "";
        setSelectedOperation(operationID);*/

        var modulesAux = modules.filter(x => x.operationID == operationID);

        setModulesFiltered(modulesAux);
        setSelectedOperation("");
    }

    const onChangeOperation = (value) => {
        var modulesAux = modules.filter(x => x.operationID == value);

        setSelectedOperation(value);
        setModulesFiltered(modulesAux);
    }

    const loadDiscussionsDashboard = async () => {
        setLoadingView(true);

        var selectedModuleDiscussions = await Api.get(`Discussion/GetKPIDiscussionsByModule?reportYearID=${selectedReportYear}&companyId=${selectedCompany}${selectedOperation && selectedOperation != "undefined" ? `&operationId=${selectedOperation}` : ``}${selectedModule && selectedModule != "undefined" ? `&moduleID=${selectedModule}` : ``}&filter=${discussionStatus}`).then((result) => {
            if (result && result.data) {
                console.log('retorno', result.data.response)
                setKpiDiscussions(result.data.response);
                return result.data.response;
            }
        });

        setChatRequest({
            ...chatRequest,
            "reportYearID": selectedReportYear,
            "companyId": selectedCompany
        });

        if (selectedCompany && selectedReportYear) {
            Api.get(`Discussion/GetDiscussionDashboard?companyId=${selectedCompany}${selectedOperation && selectedOperation != "undefined" ? `&operationId=${selectedOperation}` : ``}${selectedModule && selectedModule != "undefined" ? `&moduleID=${selectedModule}` : ``}&reportYearID=${selectedReportYear}${selectedTobaccoType != null ? '&tobaccoTypeID=' + selectedTobaccoType : ""}`).then((result) => {
                if (result && result.data) {
                    var viewDataAux = result.data.response;
                    var filteredDiscussions = selectedModuleDiscussions;

                    if (discussionStatus) {
                        if (discussionStatus == "NotAnswered") {
                            filteredDiscussions = filteredDiscussions.filter(x => x.lastDiscussionStatus == "Open");
                        }
                        else {
                            filteredDiscussions = filteredDiscussions.filter(x => x.lastDiscussionStatus == discussionStatus);
                        }
                    }
                    var discussionIDs = filteredDiscussions.map(function (discussion) {
                        return discussion.discussionID;
                    });
                    viewDataAux = viewDataAux.filter(x => discussionIDs.includes(x.discussionID));
                    setViewData(viewDataAux);
                }

                setSelectedRefNumber(null);
                setLoadingView(false);
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        } else {
            error_message("May you select all filters");
            setLoadingView(false);
        }
    }

    const handleSendDiscussionNotification = async () => {
        await Api.post("MailNotification/sendDiscussionNotificationToCompany", { companyId: selectedCompany, reportYearId: selectedReportYear, operationId: selectedOperation, moduleId: selectedModule, filter: discussionStatus }).then(async (result) => {
            if (result && result.data) {
                if (result.data.response == true) {
                    Swal.fire({
                        title: "",
                        text: "Email sent successfully.",
                        icon: 'success',
                        showDenyButton: false,
                        confirmButtonColor: '#112A61',
                        confirmButtonText: 'Ok',
                        showCloseButton: true
                    });
                }
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    useEffect(() => {
        if (selectedCompany != null && selectedReportYear != null && selectedOperation != null && selectedModule != null) {
            if (!isFirstLoading) {
                loadDiscussionsDashboard();
                setIsFirstLoading(true);
            }
        }
    }, [selectedCompany, selectedReportYear, selectedOperation, selectedModule]);

    return (
        !loading && userObject ?
            <Container className="container">
                <div className="row mt-2">
                    {
                        userObject && (!userObject.companyID || userObject.groupID) &&
                        <select className="col-md-12 mt-2" onChange={(x) => onChangeCompany(x.target.value)} value={selectedCompany ? selectedCompany : ""}>
                            <option key={0} value={""}>Select a company</option>
                            {

                                companies ?
                                    companies.map(company => {
                                        return <option key={company.companyID} value={company.companyID}>{company.name}</option>
                                    })
                                    :
                                    <></>
                            }
                        </select>
                    }
                    <select className="col-md-12 mt-2" onChange={(x) => onChangeReportYearID(x.target.value)} value={selectedReportYear ? selectedReportYear : ""}>
                        {
                            cropYears ?
                                cropYears.map(cropYear => {
                                    return <option key={cropYear.reportYearID} value={cropYear.reportYearID}>{cropYear.year}</option>
                                })
                                :
                                <></>
                        }
                    </select>
                    <select className="col-md-12 mt-2" onChange={(x) => onChangeOperation(x.target.value)} value={selectedOperation ? selectedOperation : ""}>
                        <option value="" >All Forms</option>
                        {
                            operations ?
                                operations.map(operation => {
                                    return <option key={operation.operationID} value={operation.operationID}>{operation.description}</option>
                                })
                                :
                                <></>
                        }
                    </select>
                    <select className="col-md-12 mt-2" onChange={(x) => setSelectedModule(x.target.value)} value={selectedModule ? selectedModule : ""}>
                        <option value="" >All Modules</option>
                        {
                            modulesFiltered ?
                                modulesFiltered.map(module => {
                                    return <option key={module.moduleID} value={module.moduleID}>{module.description}</option>
                                })
                                :
                                <></>
                        }
                    </select>
                    {
                        selectedModule ? modulesFiltered.find(x => x.moduleID == selectedModule)?.moduleExternalID == 15000 &&
                            <select className="col-md-12 mt-2" onChange={(x) => setSelectedTobaccoType(x.target.value)} value={selectedTobaccoType ? selectedTobaccoType : ""}>
                                <option value="" >Tobacco Type</option>
                                {
                                    modulesFiltered ?
                                        tobaccoTypes.map(tobaccoType => {
                                            return <option key={tobaccoType.tobaccoTypeID} value={tobaccoType.tobaccoTypeID}>{tobaccoType.name}</option>
                                        })
                                        :
                                        <></>
                                }
                            </select>
                            :
                            <>
                            </>
                    }
                    <select className="col-md-12 mt-2" onChange={(x) => setDiscussionStatus(x.target.value)} value={discussionStatus ? discussionStatus : ""}>
                        <option value={""}>Any discussion</option>
                        <option value={"Open"}>Ongoing discussions</option>
                        <option value={"Closed"}>Closed discussions</option>
                        <option value={"NotAnswered"}>Ongoing discussions not answered</option>
                    </select>
                    <button className="col-md-12 apply-btn mt-2" onClick={() => loadDiscussionsDashboard()}>Apply</button>
                </div>
                <div className="row">
                    {
                        loadingView ?
                            <div className="mt-2" style={{ padding: 0 }}>
                                <TableLoading />
                            </div>
                            :
                            viewData != null &&
                            <>
                                <Table id="versionTable" className="mt-5">
                                    <thead className="sticky-top">
                                        <tr>
                                            <th width="40%">KPI</th>
                                            <th width="5%">Value</th>
                                            <th width="15%">Section Name</th>
                                            <th width="10%">SubSection Name</th>
                                            <th width="15%">Title of Column</th>
                                            <th width="10%">Last Message Date</th>
                                            <th width="5%"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            viewData && viewData.length > 0 ?
                                                viewData.map((item, index) => {

                                                    var unitMeasure = GetTypeInput(item.unitMeasureID, item.unitMeasureType);

                                                    return <>
                                                        <tr key={`data_subSectionKPI_${item.kpiRefNumber}_${index}`}>
                                                            <td>
                                                                {
                                                                    item.tobaccoTypeName ?
                                                                        <span style={{ marginRight: "2px" }} className="badge">{item.tobaccoTypeName}</span>
                                                                        :
                                                                        <></>
                                                                }
                                                                {
                                                                    item.countryName ?
                                                                        <span style={{ marginRight: "2px" }} className="badge">{item.countryName}</span>
                                                                        :
                                                                        <></>
                                                                }
                                                                {
                                                                    item.fuelTypeName ?
                                                                        <span style={{ marginRight: "2px" }} className="badge">{item.fuelTypeName}</span>
                                                                        : <></>
                                                                }
                                                                {
                                                                    item.curingBarnName ?
                                                                        <span style={{ marginRight: "2px" }} className="badge">{item.curingBarnName}</span>
                                                                        : <></>
                                                                }
                                                                {
                                                                    item.fertilizerName ?
                                                                        <span style={{ marginRight: "2px" }} className="badge">{item.fertilizerName}</span>
                                                                        : <></>
                                                                }
                                                                <span>({item.kpiRefNumber}) {item.kpiDescription}</span>
                                                            </td>

                                                            <td className="text-left">
                                                                {
                                                                    unitMeasure.inputType == "Text" || item.unitMeasureType == "selectfuel" || item.unitMeasureType == "selectCuringBarn" || item.unitMeasureType == "selectcountry" || item.unitMeasureType == "selecttobacco" || unitMeasure.inputType == "selectcustom" ?
                                                                        <span>
                                                                            {
                                                                                item.value
                                                                            }
                                                                        </span>
                                                                        :
                                                                        <NumberFormat
                                                                            thousandsGroupStyle="thousand"
                                                                            value={item.value}
                                                                            decimalSeparator={unitMeasure.decimalSeparator != "" ? unitMeasure.decimalSeparator : "."}
                                                                            decimalScale={unitMeasure.decimalPrecision}
                                                                            suffix={item.value != "" ? unitMeasure.suffix : ""}
                                                                            displayType="input"
                                                                            type="text"
                                                                            disabled
                                                                            className={`read-only`}
                                                                            thousandSeparator={true}
                                                                            allowNegative={true}
                                                                            allowLeadingZeros={false}
                                                                            allowEmptyFormatting={true}
                                                                        />
                                                                }
                                                            </td>
                                                            <td className="text-left">
                                                                <span>
                                                                    {item.sectionName}
                                                                </span>
                                                            </td>
                                                            <td className="text-left">
                                                                <span>
                                                                    {item.subSectionName}
                                                                </span>
                                                            </td>
                                                            <td className="text-left">
                                                                <span>
                                                                    {item.titleOfColumn}
                                                                </span>
                                                            </td>
                                                            <td className="text-left">
                                                                <span>
                                                                    {item.lastMessageDate != null || item.lastMessageDate != "" ? item.lastMessageDate : ""}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <ChatButton
                                                                    kpiDiscussions={kpiDiscussions}
                                                                    setChatRequest={setChatRequest}
                                                                    setShowChat={setShowChat}
                                                                    request={
                                                                        {
                                                                            ...chatRequest,
                                                                            "subSectionKPIID": item.subSectionKPIID,
                                                                            "moduleId": item.moduleID,
                                                                            "kpiId": item.kpiid,
                                                                            "tobaccoTypeID": item.tobaccoTypeID,
                                                                            "fuelTypeId": item.fuelTypeID,
                                                                            "curingBarnID": item.curingBarnID,
                                                                            "countryId": item.countryID,
                                                                            "row": item.row,
                                                                            "column": item.column,
                                                                            "status": item.status
                                                                        }
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                    </>
                                                })
                                                :
                                                <tr><td colSpan="100%">There are no discussions for this filters.</td></tr>
                                        }
                                    </tbody>
                                </Table>
                                {
                                    (userRoles.includes("RoleReviewer") || userRoles.includes("RoleApprover")) && userObject.companyID === null ?
                                        <button className="col-md-2 apply-btn mt-2" onClick={() => handleSendDiscussionNotification()}>Send Notifications</button>
                                        :
                                        <></>
                                }
                            </>
                    }
                </div>
                <ChatWindow
                    request={chatRequest}
                    show={showChat}
                    setShowChat={setShowChat}
                />
            </Container>
            :
            <Container className="container mt-4">
                <TableLoading />
            </Container>
    );
}

export default DiscussionDashboard;
