import styled from 'styled-components';

export const Container = styled.div`
  .main-div{
    margin: 0 auto; 
    padding-top: 3rem;
    font-size: 14px;
    font-weight: bold;
  }

  .card {
    padding: 2rem;
    background-color: #FFFFFF;
    background-clip: border-box;
    box-shadow: 0px 3px 20px #0000000D;
    border: 1px solid #ECECF3;
    border-radius: 8px;
    width: 30rem;
    padding-bottom: 10px;
    padding-top: 10px;
    margin: 3rem auto 1rem;
    vertical-align: middle;    

    .card-body{
      label{
        margin-top: 10px;
      }

      button{
        background-color: #102A63;
        letter-spacing: 0.65px;
        text-align: center;
        width: 100%;
        color: #FFFFFF;
        font-weight: bold;
        margin-top: 20px;
      }    
      
      .return-button {
        background: #B0B0BB;
        margin-bottom: 10px;
        
        &:hover {
          background: #96969c;
        }

        &:focus{
          background: #96969c;
        }

        &:active{
          background: #96969c;
        }
      }
    }


    .terms {
      padding: 1rem 0 0;
    }

    a { 
      text-decoration: underline;
      color: #00b1eb;
      font-weight: 800;
    }

    p{
      font-size: .9rem;
    }

    .hidden {
      display: none;
    }
  }

  .main-div .card-terms {
    background-color: #f9fafb;
    margin: 1rem auto;
  }
`;

export const PasswordInput = styled.input`
    background: #F6F8FA 0% 0% no-repeat padding-box;
    border: 1px solid #D3D2E4;
    border-radius: 4px;
    resize: vertical;
    width: 100%;
    margin: 0.5rem 0rem;
    position: relative;
    padding: 0.2rem;
`;
