import React from 'react';
import TableCrud from '../../components/TableCrud';

const UserIndex = () => {
    const columns = [
        {
            label: "Username",
            name: "userName"
        },
        {
            label: "Fullname",
            name: "fullName"
        },
        {
            label: "Email",
            name: "email"
        },
        {
            label: "Supplier",
            name: "company.name"
        },
        {
            label: "Group",
            name: "groups.name"
        },
        {
            label: "Role",
            name: "role.name"
        },
        {
            label: "Active",
            name: "isActive"
        }
    ]

    return (
        <TableCrud title={"User"} table={"user"} columns={columns}/>
    );
}

export default UserIndex;
