import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import Api, { exceptionNotificationAPI } from '../../services/api';
import Form from 'react-bootstrap/Form';
import { handleAddZeroes } from '../../services/functions';
import ModalRejectPartial from '../ModalRejectPartial';
//Components
import { success_message } from '../Toast/index';

//Style
import { Container } from './styles';
import { FormCheck } from 'react-bootstrap';

const ModuleButtons = ({ userRoles, showButtons, isEditable, moduleID, answers,
    selectedCompanyID, selectedReportYearID, loadData, save, moduleObject, setModuleObject,
    setLoading, setLoadingSection = null, tobaccoTypeID = null, status = null, setShowFixedHeader = null, setReadConsent = null, readConsent = null, versionID = null, needReadConsent = null, subSectionKPIs, selectedTobaccoTypes, selectedCountries, selectedFuelTypes, fixedKpisList }) => {

    const [showAgreeConsent, setShowAgreeConsent] = useState(false);
    const [showModalRejectPartial, setShowModalRejectPartial] = useState(false);
    const [subSectionKPIsToReject, setSubSectionKPIsToReject] = useState([]);
    const [userObject, setUserObject] = useState(JSON.parse(localStorage.getItem('@Thrive:user')));

    const [ssksToReject, setSsksToReject] = useState([]);
    const COLUMN_ROW_TYPES = {
        'Simple': 1,
        'Country': 2,
        'FuelType': 3,
        'TobaccoType': 4,
        'Year': 5,
    }

    useEffect(() => {
        getReadAndAgreeChecked(moduleID, selectedCompanyID, selectedReportYearID, versionID);
    }, [])
    const getReadAndAgreeChecked = async (module, company, crop, version = null) => {
        var returnConsent = false;
        if (userRoles.includes("RoleApprover")) {
            if (status == 6 || status == 7) {
                await Api.get(`ReadConsent/FindReadConsentCurrentCropYear?moduleID=${module}&companyID=${company}&reportYearID=${crop}&versionID=${version}`).then((result) => {
                    if (result && result.data.response) {
                        if (result.data.response == true) {
                            returnConsent = result.data.response;
                            document.querySelector('#chkAgree').checked = true;
                            document.querySelector('#chkAgree').disabled = true;
                        }
                        else if (result.data.response == false) {
                            returnConsent = result.data.response;
                            document.querySelector('#chkAgree').checked = false;
                            document.querySelector('#chkAgree').disabled = false;
                        }
                    }
                }).catch((error) => {
                    exceptionNotificationAPI(error);
                });
            }
            else {
                returnConsent = true;
            }
        }
        setShowAgreeConsent(returnConsent);
        return returnConsent;

    }


    const handleSend = async (event) => {
        event.preventDefault();
        Swal.fire({
            title: 'Final version',
            text: "The system will validate all mandatory fields and the module will be closed after final approval.",
            icon: 'warning',
            confirmButtonColor: '#112A61',
            denyButtonColor: '#B0B0BB',
            confirmButtonText: 'Send',
            showCloseButton: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                await save(answers, moduleID, true, true, tobaccoTypeID);
            }
        })

    }

    const handleApprove = async (event) => {
        event.preventDefault();

        setLoading(true);
        if (setLoadingSection != null) {
            setLoadingSection(true);
        }

        await Api.post(`ThriveValue/approve?moduleID=${moduleID}&selectedCompanyID=${selectedCompanyID}&reportYearID=${selectedReportYearID}${tobaccoTypeID != null ? `&tobaccoTypeID=${tobaccoTypeID}` : ""}`).then((result) => {
            success_message("The data was approved successfully!");
            loadData();
            if (setShowFixedHeader != null)
                setShowFixedHeader(false);
        }).catch((error) => {
            exceptionNotificationAPI(error);
            setLoading(false);
            if (setLoadingSection != null) {
                setLoadingSection(false);
            }
            if (setShowFixedHeader != null)
                setShowFixedHeader(false);
        });
    }

    function validateSelectAnswers(colType, indexVal) {
        if (colType.toString() == COLUMN_ROW_TYPES['Country'].toString()) {
            return selectedCountries.find(c => c.value.toString() == indexVal.toString())?.text;
        } else if (colType.toString() == COLUMN_ROW_TYPES['FuelType'].toString()) {
            return selectedFuelTypes.find(c => c.value.toString() == indexVal.toString())?.text;
        } else if (colType.toString() == COLUMN_ROW_TYPES['TobaccoType'].toString()) {
            return selectedTobaccoTypes.find(c => c.value.toString() == indexVal.toString())?.text;
        }
    }

    function validateSelectedIndex(colType, index) {
        if (colType.toString() == COLUMN_ROW_TYPES['Country'].toString()) {
            return selectedCountries[index]?.text;
        } else if (colType.toString() == COLUMN_ROW_TYPES['FuelType'].toString()) {
            return selectedFuelTypes[index]?.text;
        } else if (colType.toString() == COLUMN_ROW_TYPES['TobaccoType'].toString()) {
            return selectedTobaccoTypes[index]?.text;
        }
    }

    const handlePartialReject = async (event) => {
        event.preventDefault();

        //Kpis
        var kpis = [];
        moduleObject.data.sections.map((section) => {
            section.subSections.map((ss) => {
                ss.kpis.map((kpi) => {
                    kpis.push(kpi);
                });
            });
        });

        //Sections
        var sections = [];
        moduleObject.data.sections.map((section) => {
            sections.push(section);
        });

        //Answers
        var answers = moduleObject.answers;
        //Columns
        var cols = moduleObject.columnRows;
        //ParentColumns
        var parentCols = cols.filter(c => c.isParentColumn);

        //subSections
        var subSections = []
        moduleObject.data.sections.map((section) => {
            section.subSections.map((ss) => {
                subSections.push(ss);
            });
        });

        setShowModalRejectPartial(true);
        setSsksToReject([]);

        var sskRejectList = [];
        var bold = false; //Controla bold
        var isHighlight = false; // Controla Highlight
        var lastRowSSkpi = null; //Controla primeiro objeto de rows
        var userObj = JSON.parse(localStorage.getItem('@Thrive:user'));
        var isApproving = false;
        //Validate if user is local approver or global
        if ((moduleObject?.version?.status == 2 && userObj?.companyID != null && userRoles.includes("RoleApprover")) || (moduleObject?.version?.status == 4 && userObj?.companyID == null && userRoles.includes("RoleApprover"))) {
            isApproving = true;
        }
        subSectionKPIs.forEach((ssk) => {
            bold = false;
            isHighlight = false;

            if (ssk.inputCalc != "Calculation") {
                let fromSubSection = subSections.find(ss => ss.subSectionID == ssk.subSectionID);
                let fromSection = sections.find(s => s.sectionID == fromSubSection?.sectionID);
                //If subsection has a number of Rows
                if (fromSubSection.numberOfRows != null && fromSubSection.numberOfRows > 0) {
                    //Add first entry with row values
                    if (lastRowSSkpi == null) {
                        lastRowSSkpi = ssk.refNumber;
                    }

                    var targetKpi = kpis.find(x => x.kpiid == ssk.kpiid);
                    var targetColumn = cols.find(c => c.columnRowID == ssk.columnRowID);
                    var targetParentColumn = targetColumn?.parentColumnRowID != null ? parentCols.find(pc => pc.columnRowOrder == targetColumn?.parentColumnRowID && pc.subSectionID == targetColumn?.subSectionID) : null
                    var targetAnswer = answers.find(a => a.subSectionKPIID == ssk.subSectionKPIID && a.row == 0);
                    var answer = targetAnswer?.numberValue != null ? targetAnswer?.numberValue : targetAnswer?.textValue;

                    var columnDescription = targetColumn?.name;

                    if (targetColumn?.type != 1 && targetColumn?.type != 5 && targetAnswer != null) {
                        //Validate if answer needs to be altered by selector description - isSelectAnswer
                        if (targetColumn?.kpiReference == targetAnswer.refNumber) {
                            answer = validateSelectAnswers(targetColumn?.type, answer);
                        }
                    }

                    if (targetParentColumn != null) {
                        columnDescription = `${targetParentColumn.name} - ${targetColumn?.name}`
                    }

                    if (lastRowSSkpi == ssk.refNumber) {
                        bold = true;
                    }



                    //Validate if user is local approver or global
                    if ((moduleObject?.version?.status == 2 && userObj?.companyID != null && userRoles.includes("RoleApprover")) || (moduleObject?.version?.status == 4 && userObj?.companyID == null && userRoles.includes("RoleApprover"))) {
                        isApproving = true;
                    }

                    if (fixedKpisList?.some(x => x.subSectionKPIID == ssk.subSectionKPIID && x.rowIndex == 0) && !moduleObject.isEditable && isApproving) {
                        isHighlight = true;
                    }


                    if (blockLocalApproverRejection()) {
                        for (let ind = 0; ind < moduleObject.version?.globalRejectThriveValuesList?.length; ind++) {
                            let element = moduleObject.version?.globalRejectThriveValuesList[ind];
                            if (element.subSectionKPIID == ssk.subSectionKPIID && element.rowIndex == 0 && element.columnIndex == null) {
                                sskRejectList.push(
                                    {
                                        rejectedInVersion: moduleObject.version.thriveValueVersionID,
                                        subSectionKpiID: ssk.subSectionKPIID,
                                        refNumber: ssk.refNumber,
                                        rowIndex: 0,
                                        columnIndex: null,
                                        kpiDescription: targetKpi?.description,
                                        columnDesc: columnDescription,
                                        answer: answer,
                                        unitMeasure: ssk.unitMeasure,
                                        isBold: bold,
                                        subSectionID: ssk.subSectionID,
                                        sectionID: fromSection?.sectionID,
                                        isHighlight: isHighlight,
                                        tobaccoTypeID: ssk.tobaccoTypeID
                                    }
                                );
                            }
                        }
                    } else {
                        sskRejectList.push(
                            {
                                rejectedInVersion: moduleObject.version.thriveValueVersionID,
                                subSectionKpiID: ssk.subSectionKPIID,
                                refNumber: ssk.refNumber,
                                rowIndex: 0,
                                columnIndex: null,
                                kpiDescription: targetKpi?.description,
                                columnDesc: columnDescription,
                                answer: answer,
                                unitMeasure: ssk.unitMeasure,
                                isBold: bold,
                                subSectionID: ssk.subSectionID,
                                sectionID: fromSection?.sectionID,
                                isHighlight: isHighlight,
                                tobaccoTypeID: ssk.tobaccoTypeID
                            }
                        );
                    }



                    //add extra SSK for each of the other rows
                    for (let i = 1; i < fromSubSection.numberOfRows; i++) {
                        isHighlight = false;
                        targetAnswer = answers.find(a => a.subSectionKPIID == ssk.subSectionKPIID && a.row == i);
                        answer = targetAnswer?.numberValue != null ? targetAnswer?.numberValue : targetAnswer?.textValue;
                        if (targetColumn?.type != 1 && targetColumn?.type != 5 && answer != null) {
                            //Validate if answer needs to be altered by selector description - isSelectAnswer
                            if (targetColumn?.kpiReference == targetAnswer.refNumber) {
                                answer = validateSelectAnswers(targetColumn?.type, answer);
                            }
                        }

                        if (lastRowSSkpi == ssk.refNumber) {
                            bold = true;
                        }

                        if (fixedKpisList?.some(x => x.subSectionKPIID == ssk.subSectionKPIID && x.rowIndex == i) && !moduleObject.isEditable && isApproving) {
                            isHighlight = true;
                        }

                        if (blockLocalApproverRejection()) {
                            for (let ind = 0; ind < moduleObject.version?.globalRejectThriveValuesList?.length; ind++) {
                                let element = moduleObject.version?.globalRejectThriveValuesList[ind];
                                if (element.subSectionKPIID == ssk.subSectionKPIID && element.rowIndex == i && element.columnIndex == null) {
                                    sskRejectList.push(
                                        {
                                            rejectedInVersion: moduleObject.version.thriveValueVersionID,
                                            subSectionKpiID: ssk.subSectionKPIID,
                                            refNumber: ssk.refNumber,
                                            rowIndex: i,
                                            columnIndex: null,
                                            kpiDescription: targetKpi?.description,
                                            columnDesc: columnDescription,
                                            answer: answer,
                                            unitMeasure: ssk.unitMeasure,
                                            isBold: bold,
                                            subSectionID: ssk.subSectionID,
                                            sectionID: fromSection?.sectionID,
                                            isHighlight: isHighlight,
                                            tobaccoTypeID: ssk.tobaccoTypeID
                                        });
                                }
                            };
                        } else {
                            sskRejectList.push(
                                {
                                    rejectedInVersion: moduleObject.version.thriveValueVersionID,
                                    subSectionKpiID: ssk.subSectionKPIID,
                                    refNumber: ssk.refNumber,
                                    rowIndex: i,
                                    columnIndex: null,
                                    kpiDescription: targetKpi?.description,
                                    columnDesc: columnDescription,
                                    answer: answer,
                                    unitMeasure: ssk.unitMeasure,
                                    isBold: bold,
                                    subSectionID: ssk.subSectionID,
                                    sectionID: fromSection?.sectionID,
                                    isHighlight: isHighlight,
                                    tobaccoTypeID: ssk.tobaccoTypeID
                                });
                        }
                    }

                }
                else {
                    lastRowSSkpi = null;
                    bold = false;
                    var targetKpi = kpis.find(x => x.kpiid == ssk.kpiid);
                    var targetColumn = cols.find(c => c.columnRowID == ssk.columnRowID);

                    if (targetColumn != null) {
                        //validate if targetColumn is tobacco type
                        if (targetColumn?.type?.toString() == COLUMN_ROW_TYPES['TobaccoType'].toString() && (targetColumn?.minimumQuantity != null && targetColumn?.minimumQuantity > 0)) {

                            var targetParentColumn = targetColumn?.parentColumnRowID != null ? parentCols.find(pc => pc.columnRowOrder == targetColumn?.parentColumnRowID && pc.subSectionID == targetColumn?.subSectionID) : null
                            var targetAnswer = answers.find(a => a.subSectionKPIID == ssk.subSectionKPIID && (a.column == 0));
                            var answer = targetAnswer?.numberValue != null ? targetAnswer?.numberValue : targetAnswer?.textValue;

                            var columnDescription = answer;



                            if (targetColumn?.type != 1 && targetColumn?.type != 5) {
                                //Adjust column description to match value selected
                                columnDescription = validateSelectedIndex(targetColumn?.type, 0);

                                if (answer != null) {
                                    //Validate if answer needs to be altered by selector description - isSelectAnswer
                                    if (targetColumn?.kpiReference == targetAnswer?.refNumber) {
                                        answer = validateSelectAnswers(targetColumn?.type, answer);
                                    }
                                }
                            }


                            if (targetParentColumn != null) {
                                columnDescription = `${targetParentColumn.name} - ${columnDescription}`
                            }

                            if (fixedKpisList?.some(x => x.subSectionKPIID == ssk.subSectionKPIID && x.columnIndex == 0) && !moduleObject.isEditable && isApproving) {
                                isHighlight = true;
                            }

                            if (columnDescription != "" && columnDescription != null) {
                                if (blockLocalApproverRejection()) {
                                    for (let ind = 0; ind < moduleObject.version?.globalRejectThriveValuesList?.length; ind++) {
                                        let element = moduleObject.version?.globalRejectThriveValuesList[ind];
                                        if (element.subSectionKPIID == ssk.subSectionKPIID && element.rowIndex == null && element.columnIndex == 0) {
                                            sskRejectList.push(
                                                {
                                                    rejectedInVersion: moduleObject.version.thriveValueVersionID,
                                                    subSectionKpiID: ssk.subSectionKPIID,
                                                    refNumber: ssk.refNumber,
                                                    rowIndex: null,
                                                    columnIndex: 0,
                                                    kpiDescription: targetKpi?.description,
                                                    columnDesc: columnDescription,
                                                    answer: answer,
                                                    unitMeasure: ssk.unitMeasure,
                                                    isBold: bold,
                                                    subSectionID: ssk.subSectionID,
                                                    sectionID: fromSection?.sectionID,
                                                    isHighlight: isHighlight,
                                                    tobaccoTypeID: ssk.tobaccoTypeID
                                                }
                                            );
                                        }
                                    }
                                } else {
                                    sskRejectList.push(
                                        {
                                            rejectedInVersion: moduleObject.version.thriveValueVersionID,
                                            subSectionKpiID: ssk.subSectionKPIID,
                                            refNumber: ssk.refNumber,
                                            rowIndex: null,
                                            columnIndex: 0,
                                            kpiDescription: targetKpi?.description,
                                            columnDesc: columnDescription,
                                            answer: answer,
                                            unitMeasure: ssk.unitMeasure,
                                            isBold: bold,
                                            subSectionID: ssk.subSectionID,
                                            sectionID: fromSection?.sectionID,
                                            isHighlight: isHighlight,
                                            tobaccoTypeID: ssk.tobaccoTypeID
                                        }
                                    );
                                }
                            }


                            //add extra SSK for each of the other columns
                            for (let i = 1; i < targetColumn?.minimumQuantity; i++) {

                                targetAnswer = answers.find(a => a.subSectionKPIID == ssk.subSectionKPIID && a.column == i);
                                answer = targetAnswer?.numberValue != null ? targetAnswer?.numberValue : targetAnswer?.textValue;
                                columnDescription = targetColumn?.name;
                                isHighlight = false;
                                //Validate if columnsType is from a selectedValue (TobaccoType for example)
                                if (targetColumn?.type != 1 && targetColumn?.type != 5) {
                                    //Adjust column description to match value selected
                                    columnDescription = validateSelectedIndex(targetColumn?.type, i);
                                    if (answer != null) {
                                        //Validate if answer needs to be altered by selector description - isSelectAnswer
                                        if (targetColumn?.kpiReference == targetAnswer?.refNumber) {
                                            answer = validateSelectAnswers(targetColumn?.type, answer);
                                            columnDescription = answer;
                                        }
                                    }
                                }

                                if (fixedKpisList?.some(x => x.subSectionKPIID == ssk.subSectionKPIID && x.columnIndex == i) && !moduleObject.isEditable && isApproving) {
                                    isHighlight = true;
                                }

                                if (columnDescription != "" && columnDescription != null) {
                                    if (blockLocalApproverRejection()) {
                                        for (let ind = 0; ind < moduleObject.version?.globalRejectThriveValuesList?.length; ind++) {
                                            let element = moduleObject.version?.globalRejectThriveValuesList[ind];
                                            if (element.subSectionKPIID == ssk.subSectionKPIID && element.rowIndex == null && element.columnIndex == i) {
                                                sskRejectList.push(
                                                    {
                                                        rejectedInVersion: moduleObject.version.thriveValueVersionID,
                                                        subSectionKpiID: ssk.subSectionKPIID,
                                                        refNumber: ssk.refNumber,
                                                        rowIndex: null,
                                                        columnIndex: i,
                                                        kpiDescription: targetKpi?.description,
                                                        columnDesc: columnDescription,
                                                        answer: answer,
                                                        unitMeasure: ssk.unitMeasure,
                                                        isBold: bold,
                                                        subSectionID: ssk.subSectionID,
                                                        sectionID: fromSection?.sectionID,
                                                        isHighlight: isHighlight,
                                                        tobaccoTypeID: ssk.tobaccoTypeID
                                                    }
                                                );
                                            }
                                        }
                                    } else {
                                        sskRejectList.push(
                                            {
                                                rejectedInVersion: moduleObject.version.thriveValueVersionID,
                                                subSectionKpiID: ssk.subSectionKPIID,
                                                refNumber: ssk.refNumber,
                                                rowIndex: null,
                                                columnIndex: i,
                                                kpiDescription: targetKpi?.description,
                                                columnDesc: columnDescription,
                                                answer: answer,
                                                unitMeasure: ssk.unitMeasure,
                                                isBold: bold,
                                                subSectionID: ssk.subSectionID,
                                                sectionID: fromSection?.sectionID,
                                                isHighlight: isHighlight,
                                                tobaccoTypeID: ssk.tobaccoTypeID
                                            }
                                        );
                                    }
                                }
                            }
                        } else {

                            var targetParentColumn = targetColumn?.parentColumnRowID != null ? parentCols.find(pc => pc.columnRowOrder == targetColumn?.parentColumnRowID && pc.subSectionID == targetColumn?.subSectionID) : null
                            var targetAnswer = answers.find(a => a.subSectionKPIID == ssk.subSectionKPIID);
                            var answer = targetAnswer?.numberValue != null ? targetAnswer?.numberValue : targetAnswer?.textValue;
                            var columnDescription = targetColumn?.name;

                            if (targetParentColumn != null) {
                                columnDescription = `${targetParentColumn.name} - ${targetColumn?.name}`
                            }

                            if (fixedKpisList?.some(x => x.subSectionKPIID == ssk.subSectionKPIID) && !moduleObject.isEditable && isApproving) {
                                isHighlight = true;
                            }

                            if (blockLocalApproverRejection()) {
                                for (let ind = 0; ind < moduleObject.version?.globalRejectThriveValuesList?.length; ind++) {
                                    let element = moduleObject.version?.globalRejectThriveValuesList[ind];
                                    if (element.subSectionKPIID == ssk.subSectionKPIID && element.rowIndex == null && element.columnIndex == null) {
                                        sskRejectList.push(
                                            {
                                                rejectedInVersion: moduleObject.version.thriveValueVersionID,
                                                subSectionKpiID: ssk.subSectionKPIID,
                                                refNumber: ssk.refNumber,
                                                rowIndex: null,
                                                columnIndex: null,
                                                kpiDescription: targetKpi?.description,
                                                columnDesc: columnDescription,
                                                answer: answer,
                                                unitMeasure: ssk.unitMeasure,
                                                isBold: bold,
                                                subSectionID: ssk.subSectionID,
                                                sectionID: fromSection?.sectionID,
                                                isHighlight: isHighlight,
                                                tobaccoTypeID: ssk.tobaccoTypeID
                                            }
                                        );
                                    }

                                }
                            } else {
                                sskRejectList.push(
                                    {
                                        rejectedInVersion: moduleObject.version.thriveValueVersionID,
                                        subSectionKpiID: ssk.subSectionKPIID,
                                        refNumber: ssk.refNumber,
                                        rowIndex: null,
                                        columnIndex: null,
                                        kpiDescription: targetKpi?.description,
                                        columnDesc: columnDescription,
                                        answer: answer,
                                        unitMeasure: ssk.unitMeasure,
                                        isBold: bold,
                                        subSectionID: ssk.subSectionID,
                                        sectionID: fromSection?.sectionID,
                                        isHighlight: isHighlight,
                                        tobaccoTypeID: ssk.tobaccoTypeID
                                    }
                                );
                            }
                        }

                    }



                }

                //Ordena subSectionKPIList
                sskRejectList.sort((a, b) => {
                    if (a.sectionID === b.sectionID) {
                        if (a.subSectionID === b.subSectionID) {
                            if (a.rowIndex === b.rowIndex) {
                                return a.subSectionKPIID < b.subSectionKPIID ? -1 : 1;
                            }
                            return a.rowIndex - b.rowIndex;
                        }
                        return a.subSectionID - b.subSectionID;
                    }
                    return a.sectionID - b.sectionID;
                });

            }
        });

        setSsksToReject(sskRejectList);

    }


    const handleReject = async (event) => {
        event.preventDefault();

        Swal.fire({
            title: 'Please specify the reason to reject all',
            input: 'textarea',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Comment',
            confirmButtonColor: '#112A61',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                if (setLoadingSection != null) {
                    setLoadingSection(true);
                }
                const objToRequest = {
                    "moduleID": moduleID,
                    "selectedCompanyID": selectedCompanyID,
                    "commentApprover": result.value,
                    "reportYearID": selectedReportYearID,
                    "tobaccoTypeID": tobaccoTypeID != null ? tobaccoTypeID : null
                }

                await Api.post(`ThriveValue/reject`, objToRequest).then((result) => {
                    success_message("The data was rejected successfully!");
                    loadData();
                    if (setShowFixedHeader != null)
                        setShowFixedHeader(false);
                }).catch((error) => {
                    exceptionNotificationAPI(error);
                    setLoading(false);
                    if (setLoadingSection != null) {
                        setLoadingSection(false);
                    }
                    if (setShowFixedHeader != null)
                        setShowFixedHeader(false);
                });
            }
        })

    }

    const handleReactive = async (event) => {
        event.preventDefault();

        Swal.fire({
            title: 'Please specify the reason and KPI rejected',
            input: 'textarea',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Comment',
            confirmButtonColor: '#112A61',
            showLoaderOnConfirm: true,
            allowOutsideClick: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                //setLoading(true);

                const objToRequest = {
                    "moduleID": moduleID,
                    "selectedCompanyID": selectedCompanyID,
                    "commentApprover": result.value,
                    "reportYearID": selectedReportYearID,
                    "tobaccoTypeID": tobaccoTypeID != null ? tobaccoTypeID : null
                }

                await Api.post(`ThriveValue/reactive`, objToRequest).then((result) => {
                    success_message("The module was reactive successfully!");
                    loadData();
                    if (setShowFixedHeader != null)
                        setShowFixedHeader(false);
                }).catch((error) => {
                    exceptionNotificationAPI(error);
                    setLoading(false);
                    if (setLoadingSection != null) {
                        setLoadingSection(false);
                    }
                    if (setShowFixedHeader != null)
                        setShowFixedHeader(false);
                });
            }
        })
    }

    const blockLocalApproverRejection = () => {
        return (userObject?.companyID != null && moduleObject?.version?.globalRejectThriveValuesList != null && moduleObject?.version?.globalRejectThriveValuesList?.length > 0);
    }

    const hasAddZeroesButton = () => {
        return [14, 15, 16, 17, 18].includes(moduleID);
    }

    const handleChecked = (value) => {
        if (value) {
            Swal.fire({
                title: 'Are you sure you have finished reading all the data in this module?',
                showCancelButton: true,
                cancelButtonColor: '#B0B0BB',
                confirmButtonText: 'Confirm',
                confirmButtonColor: '#112A61',
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !Swal.isLoading()
            }).then(async (result) => {
                if (result.isConfirmed) {
                    setLoading(true);
                    if (setLoadingSection != null) {
                        setLoadingSection(true);
                    }

                    const objToRequest = {
                        "moduleID": moduleID,
                        "companyID": selectedCompanyID,
                        "thriveValueVersionID": versionID,
                        "reportYearID": selectedReportYearID,
                        "tobaccoTypeID": tobaccoTypeID != null ? tobaccoTypeID : null
                    }

                    await Api.post(`ReadConsent/new`, objToRequest).then((result) => {
                        success_message("Your consent was successfully saved!");
                        loadData();
                        setShowAgreeConsent(true);
                        if (setShowFixedHeader != null)
                            setShowFixedHeader(false);
                    }).catch((error) => {
                        exceptionNotificationAPI(error);
                        setLoading(false);
                        if (setLoadingSection != null) {
                            setLoadingSection(false);
                        }
                        if (setShowFixedHeader != null)
                            setShowFixedHeader(false);
                    });
                }
                else {
                    document.querySelector('#chkAgree').checked = false;
                    setShowAgreeConsent(false);
                }
            })
        }
        else {
            document.querySelector('#chkAgree').checked = false;
            setShowAgreeConsent(false);
        }

    }

    return (
        <Container className="row">
            <div className="col-md-12" style={{ textAlign: 'left' }}>
                {userRoles.includes("RoleInfoHolder") && showButtons && isEditable ? //Info Holder
                    <>
                        {/*In Glidepath form infoholder can save as draft the coluns LE and YTD after status equals Approved (7)*/}
                        <button className="save-btn" type="submit">SAVE DRAFT</button>
                        {status != 7 && <button className="save-btn" onClick={(e) => handleSend(e)} type="button">SEND</button>}
                        {moduleObject.data.isHandleAddZeros == true ? <button className="save-btn float-right" onClick={(e) => handleAddZeroes(e, moduleObject, setModuleObject)} type="button">ADD ZEROS</button> : ""}
                    </>
                    : <></>
                }
                {userRoles.includes("RoleApprover") && showButtons && !isEditable && !blockLocalApproverRejection() && status != 7 && //Approver
                    <>
                        <button className="approve-btn" type="button" onClick={(e) => handleApprove(e)}>APPROVE ALL</button>
                        <button className="reject-btn" type="button" onClick={(e) => handleReject(e)}>REJECT ALL</button>
                        <button className="reject-partial-btn" type="button" onClick={(e) => handlePartialReject(e)}>PARTIAL APPROVAL/REJECTION</button>
                    </>
                }
                {userRoles.includes("RoleApprover") && showButtons && !isEditable && blockLocalApproverRejection() && //Approver
                    <>
                        <button className="approve-btn" type="button" onClick={(e) => handleApprove(e)}>APPROVE ALL</button>
                        <button className="reject-partial-btn" type="button" onClick={(e) => handlePartialReject(e)}>PARTIAL APPROVAL/REJECTION</button>
                    </>
                }
                {(userRoles.includes("RoleAdmin") || userRoles.includes("RoleApprover")) && showButtons && !isEditable && //Admin
                    <>
                        <button className="approve-btn" type="button" onClick={(e) => handleReactive(e)}>REACTIVATE</button>
                        <button className="reject-partial-btn" type="button" onClick={(e) => handlePartialReject(e)}>PARTIAL REACTIVATION</button>
                    </>
                }
                {userRoles.includes("RoleReviewer") && showButtons && !isEditable && //Reviewer
                    <>
                        <button className="reject-btn" type="button" onClick={(e) => handleReject(e)}>REJECT ALL</button>
                        <button className="reject-partial-btn" type="button" onClick={(e) => handlePartialReject(e)}>PARTIAL REJECTION</button>
                    </>
                }
            </div>
            <div className="col-md-6">
                {userRoles.includes("RoleApprover") && !readConsent && needReadConsent && //Approver
                    <>
                        {status == 6 || status == 7 ?
                            <>
                                <Form.Check.Input id='chkAgree' label={"I Agree"} onChange={(e) => handleChecked(e.target.checked)} />
                                <Form.Check.Label className="mx-3">{`I Read and Agree! `}</Form.Check.Label>
                            </>
                            :
                            <></>
                        }
                    </>
                }
            </div>
            <ModalRejectPartial
                showModalRejectPartial={showModalRejectPartial}
                setShowModalRejectPartial={setShowModalRejectPartial}
                ssksToReject={ssksToReject}
                moduleID={moduleID}
                selectedCompanyID={selectedCompanyID}
                selectedReportYearID={selectedReportYearID}
                tobaccoTypeID={tobaccoTypeID}
                loadData={loadData}
                setLoading={setLoading}
                setShowFixedHeader={setShowFixedHeader}
                setLoadingSection={setLoadingSection}
                userRoles={userRoles}
            />
        </Container>
    );
}

export default ModuleButtons;
