import React from 'react';
import Button from '../Button/index';
import { FaFileAlt } from 'react-icons/fa';
import { RoundFixedNumber } from '../../services/functions';
import { Container } from './styles';
import { ICON_COLORS } from './colors.jsx';
import { useState } from 'react';
import Swal from 'sweetalert2';

const EvidenceButton = ({ evidenceRequest, setEvidenceRequest, setShowUploadEvidence, isMandatory = false, kpiEvidences = null, disabled = false, buttonText = "Evidence", moduleObject}) => {
    const [userObject, setUserObject] = useState(JSON.parse(localStorage.getItem('@Thrive:user')));
   

    var iconColor = ICON_COLORS.default;

    var buttonIconId = "evidence-button-icon-" + evidenceRequest.subSectionKPIID;
        if(evidenceRequest.column != null) {
            buttonIconId += "-c" + evidenceRequest.column;
        }
        if(evidenceRequest.row != null) {
            buttonIconId += "-r" + evidenceRequest.row;
        }
        


    if(kpiEvidences != null){
        var kpiEvidence = kpiEvidences.filter((d) => {
            return d.subSectionKPIID == evidenceRequest.subSectionKPIID
                && d.tobaccoTypeID == (evidenceRequest.tobaccoTypeID !== null ? evidenceRequest.tobaccoTypeID : null)
                && d.fuelTypeID == (evidenceRequest.fuelTypeId !== null ? evidenceRequest.fuelTypeId : null)
                && d.curingBarnID == (evidenceRequest.curingBarnID !== null ? evidenceRequest.curingBarnID : null)
                && d.countryID == (evidenceRequest.countryId !== null ? evidenceRequest.countryId : null)
                && d.row == (evidenceRequest.row !== null ? evidenceRequest.row : null)
                && d.column == (evidenceRequest.column !== null ? evidenceRequest.column : null)
        });
        var validEvidence = null;
        if(kpiEvidence.length > 0){
            validEvidence = kpiEvidence.find(x => x.value == RoundFixedNumber(evidenceRequest.subSectionKPIValue, 2) && x.subSectionKPIValue == RoundFixedNumber(evidenceRequest.subSectionKPIValue, 2))
            if(validEvidence) {
                if(validEvidence.isValidated){
                    iconColor = ICON_COLORS.closed;
                }
                else{
                    iconColor = ICON_COLORS.open;
                }
            }
            else{
                iconColor = ICON_COLORS.open;
            }
            
        }
        
        
    }

    function openModal () {
        if(kpiEvidences != null){
            var kpiEvidence = kpiEvidences.filter((d) => {
                return d.subSectionKPIID == evidenceRequest.subSectionKPIID
                    && d.tobaccoTypeID == (evidenceRequest.tobaccoTypeID !== null ? evidenceRequest.tobaccoTypeID : null)
                    && d.fuelTypeID == (evidenceRequest.fuelTypeId !== null ? evidenceRequest.fuelTypeId : null)
                    && d.curingBarnID == (evidenceRequest.curingBarnID !== null ? evidenceRequest.curingBarnID : null)
                    && d.countryID == (evidenceRequest.countryId !== null ? evidenceRequest.countryId : null)
                    && d.row == (evidenceRequest.row !== null ? evidenceRequest.row : null)
                    && d.column == (evidenceRequest.column !== null ? evidenceRequest.column : null)
            });
            var validEvidence = null;
            if(kpiEvidence.length > 0){
                validEvidence = kpiEvidence.find(x => x.value == RoundFixedNumber(evidenceRequest.subSectionKPIValue, 2) && x.subSectionKPIValue == RoundFixedNumber(evidenceRequest.subSectionKPIValue, 2))
                if(validEvidence) {
                    if(validEvidence.isValidated){
                        iconColor = ICON_COLORS.closed;
                    }
                    else{
                        iconColor = ICON_COLORS.open;
                    }
                }
                else{
                    iconColor = ICON_COLORS.open;
                }
                
            }
        }
        if((moduleObject.isEditable) || (!moduleObject.isEditable && validEvidence)){
            setEvidenceRequest(validEvidence == null ? evidenceRequest : validEvidence);
            setShowUploadEvidence(true);
        }
        else if(!moduleObject.isEditable && validEvidence == null) {
            Swal.fire({
                title: 'Evidence not found!',
                text: "There is no evidence for this KPI.",
                icon: 'warning',
                showDenyButton: false,
                confirmButtonColor: '#112A61',
                confirmButtonText: 'Ok',
                showCloseButton: true
            });
        }

            
        
    }

    return <Container
                onClick={() => openModal()}
                disabled={disabled}>
                    
        <Button disabled={disabled} style={{position: 'relative'}}>
            {buttonText}
            <FaFileAlt
                color={iconColor}
                id={buttonIconId}
                className="icon-chat"
                size={30}
            />
            {isMandatory && <div className="isMandatory"></div>}
        </Button>
    </Container>
}

export default EvidenceButton;
