import React, { useEffect, useState } from 'react';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { ContainerHeaderDetail, TableDetail } from './styles';
import { useParams } from 'react-router-dom';
import TableLoading from '../../components/TableLoading';
import moment from 'moment';
import CancelButton from '../../components/CancelButton';

const FSMIntegrationLogsIndex = () => {
    const columns = [
        {
            label: "ID",
            name: "fsmIntegrationLogID",
        },
        {
            label: "Description",
            name: "description",
        },
        {
            label: "Company",
            name: "company.name",
        },
        {
            label: "Crop",
            name: "crop.cropYear",
        },
        {
            label: "Module",
            name: "module.description",
        },
        {
            label: "Filename",
            name: "fileName",
        },
        {
            label: "Created Date",
            name: "created",
        }
    ]

    const [data, setData] = useState([]);
    const [loadingFile, setLoadingFile] = useState(false);

    useEffect(() => {
        loadData();
    },[]);

    const loadData = async (id) => {
        await Api.get(`FSMIntegrationLog/getAll`).then((result) => {
            if (result && result.data) {
                console.log(result.data.response)
                var dataReturn = result.data.response;  

                setData(dataReturn);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }  

    const getFile = async (fileName) => {
        setLoadingFile(true);

        await Api.get(`FSMIntegrationData/getFile/${fileName}`, { responseType: 'blob' })
            .then(res => {
                let url = window.URL.createObjectURL(res.data);
                window.open(url);
                setLoadingFile(false);
            });
    }


    return (
            <div className="container">
                <ContainerHeaderDetail>
                    <h3>FSM Integration Data - Importation Logs</h3>                    
                </ContainerHeaderDetail>
                {
                    data ? <TableDetail>
                        <thead className="sticky-top">
                            <tr>
                                {
                                    columns.map((c, i) => {
                                        return <th key={i + "th"}>{c.label}</th>
                                    })
                                }
                                <th width="1%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.length > 0 ?
                                    data.map((item, i) => {
                                        var offset = moment().utcOffset();
                                        var localText = moment.utc(item.created).utcOffset(offset).format("DD/MM/YYYY HH:mm:ss");

                                        return <tr key={i + "tr"}>                                
                                                <td key={i}>{item.fsmIntegrationLogID}</td>
                                                <td key={i}>{item.description}</td>
                                                <td key={i}>{item.company?.name}</td>
                                                <td key={i}>{item.reportYear?.year}</td>
                                                <td key={i}>{item.module?.description}</td>
                                                <td key={i} style={{ textDecoration: 'underline', color: "#5c8fff", fontWeight: '700', cursor: 'pointer'}} onClick={() => getFile(item.fileName)}>{item.fileName}</td>
                                                <td key={i}>{item.created != null ? localText: ""}</td>
                                                <td key={i}></td>
                                        </tr>
                                    })
                                    :
                                    <tr><td className="no-records" colSpan={columns.length + 1}>No records found</td></tr>
                            }
                        </tbody>
                    </TableDetail>
                        :
                    <TableLoading />
                }                
            </div>
        ); 
    
}

export default FSMIntegrationLogsIndex;
