import React, { useEffect, useState } from 'react';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { ContainerHeaderDetail, TableDetail } from './styles';
import { useParams } from 'react-router-dom';
import TableLoading from '../../components/TableLoading';
import moment from 'moment';
import CancelButton from '../../components/CancelButton';

const ReadConsentDetailIndex = () => {
  const [data, setData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    loadData(id);
  }, []);

  const loadData = async id => {
    await Api.get(`ReadConsent/get?id=${id}`)
      .then(result => {
        if (result && result.data) {
          var dataReturn = result.data.response;

          setData(dataReturn);
        }
      })
      .catch(error => {
        exceptionNotificationAPI(error);
      });
  };

  const columns = [
    {
      label: 'User',
      name: 'userName',
    },
    {
      label: 'Company',
      name: 'companyName',
    },
    {
      label: 'Module',
      name: 'moduleName',
    },
    {
      label: 'Report Year',
      name: 'reportYear',
    },
    {
      label: 'Created',
      name: 'created',
    },
  ];

  return (
    <div className="container">
      <ContainerHeaderDetail>
        <h3>Read Consent - Detail</h3>
      </ContainerHeaderDetail>
      {data ? (
        <TableDetail>
          <thead className="sticky-top">
            <tr>
              {columns.map((c, i) => {
                return <th key={i + 'th'}>{c.label}</th>;
              })}
              <th width="1%"></th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((item, i) => {
                return (
                  <tr key={i + 'tr'}>
                    <td key={i}>{item.userName}</td>
                    <td key={i}>{item.companyName}</td>
                    <td key={i}>{item.moduleName}</td>
                    <td key={i}>{item.reportYear}</td>
                    <td key={i}>
                      {item.created != null
                        ? moment(item.created).format('DD-MMM-yyyy')
                        : ''}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="no-records" colSpan={columns.length + 1}>
                  No records found
                </td>
              </tr>
            )}
          </tbody>
        </TableDetail>
      ) : (
        <TableLoading />
      )}
      <div style={{ float: 'right', margin: '1rem 0' }}>
        <CancelButton desc={'Back'} />
      </div>
    </div>
  );
};

export default ReadConsentDetailIndex;
