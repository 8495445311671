import React from 'react';
import moment from 'moment';

// import { Discussion } from './styles';
import ChatMessage from '../ChatMessages/index';

const ChatDiscussion = ({ discussion, currentUser, userObject, editMessage }) => {
    return <>
        {discussion.messages.map((message, i) => {
            return <ChatMessage message={message} currentUser={currentUser} userObject={userObject} editMessage={editMessage} lastMessage={i + 1 == discussion.messages.length} />;
        })}
        <li className="end_date">{discussion.dateEndMessage != null ? moment(discussion.dateEndMessage, "DD/MM/YYYY HH:mm:ss").add(moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss") : ""}</li>
        <li className="end_message">{discussion.endMessage}</li>
    </>

}

export default ChatDiscussion;


