import styled from 'styled-components';

export const Container = styled.div`
    &.scroll-div{
        overflow-x: auto;
        overflow-y: hidden;
    }

    .div-header{
        background-color: #112A61;
        border-radius: 8px 8px 0px 0px;
        padding: 14px;
        font-weight: bold;
        margin-top: 0.5rem;
        font-size: 13px;
    }

    .sub-section-description{
            font-weight: bold;
            color: #FFFFFFFF;
            padding: 0.5rem;
            height: 2rem;
            border-radius: 8px 8px 0px 0px;
    }

    .sub-section-guidance{
            color: #FFFFFFFF;
            padding: 0 0.5rem 0.5rem 0.5rem;
            font-size: 12px;
            font-weight: 400;
            margin-top: 0.5rem;
    }

    .tableFixHead {
        overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
        overflow-x:auto;
    }

    .fixed-col{
            background-color: #FFFFFFFF;
            position: sticky;
            left: 0;
            z-index: 9;
    }
`;


export const Field = styled.div`
    position: relative;

    .required-icon {
    display: inline-block;
    vertical-align: middle;
    margin: -0.25em 0.25em 0em;
    background-color: transparent;
    padding: 0.5em 0.8em;
    color: rgba(0, 0, 0, 0);
    text-transform: uppercase;
    font-weight: normal;
    border-radius: 0.325em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: background 0.1s linear;
    -moz-transition: background 0.1s linear;
    transition: background 0.1s linear;
    font-size: 75%;
    }

    .required-icon {
        background-color: transparent;
        position: absolute;
        bottom: 2.85em;
        right: 0.2em;
        z-index: 10;
        margin: 0em;
        width: 8px;
        height: 8px;
        padding: 0em;
        text-align: center;
        -webkit-transition: color 0.2s ease;
        -moz-transition: color 0.2s ease;
        transition: color 0.2s ease;
    }

    .required-icon .text {
        color: #B80000;
        font-size: 18px;
    }

    select{
        appearance: none
    }
`;

export const Table = styled.table`
    font-size: 13px;
    width: 100%;
    background-color: #FFFFFF;


    .action-icon{
        display: flex;
        width: 2rem;
        padding: 0.5rem;
        border-radius: 1.5rem;
        &:hover {
        background: rgba(0,0,0,0.1);
        }
    }

    .input-text-field{
        border: 1px solid #ececf3;
        border-radius: 5px;
        display: flex;
        height: 32px;
        width: -webkit-fill-available;
        padding: 6px;
        height: 4rem;
        resize: none;
    max-width: 10rem;
        &:focus-visible {
        outline: 2px solid rgba(17, 42, 97, 0.06);
        }
    }

    thead{
        background-color: #112A61;
        height: 40px;

        th.sub-section-description{
            font-weight: bold;
            color: #FFFFFFFF;
            padding: 14px;
            border-radius: 8px 8px 0px 0px;
        }

        th.sub-section-guidance{
            color: #FFFFFFFF;
            padding: 0 14px 14px 14px;
            font-size: 12px;
            font-weight: 400;
        }

        th.column-head{
            text-align: center;
        }
    }

    tbody{
        td.column-cell-center{
            text-align: center;
        }
    }

    .second-header{
        background-color: rgb(252, 252, 252);
        border: 1px solid #ECECF3;

        td{
            font-weight: bold;
            padding: 14px;
            border-radius: 0!important;
        }
    }

    .country-table-header{
        background-color: #F6F8FA;
        border: 1px solid #ECECF3;

        span{
            color: black;
            font-weight: bold;
        }

        &:first-child{
            border-right: 0;
        }

        &:last-child{
            border-left: 0;
        }
    }

    tbody{
        background-color: #FFFFFFFF;

        tr.add-another{
            background-color: #F6F8FA;

            td{
                text-align: left!important;

                span{
                    font-weight: bold;
                    color: #387722;
                    opacity: 0.8;
                    cursor: pointer;

                    &:hover{
                        opacity: 1;
                    }
                }
            }
        }

        tr{
            border: 1px solid #ECECF3;
            border-left: #FFFFFF;
            border-right: #FFFFFF;

            .upload-btn-wrapper {
                position: relative;
                overflow: hidden;
                display: inline-block;
            }

            .btn-custom {
                background-color: transparent;
                border: 1px solid #B0B0BB;
                color: #B0B0BB;
                padding: 14px 40px 14px 40px;
                border-radius: 5px;
            }

            .upload-btn-wrapper input[type=file] {
                font-size: 100px;
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
            }

            td{
                padding: 14px;

                /* &:first-child{
                    text-align: center;
                } */

                &:last-child{
                    /* text-align: center; */

                    .btn{
                        background-color: transparent;
                        border: 0;
                        padding: 0;
                        margin: 0;
                        cursor: unset;

                        &:focus{
                            background-color: transparent;
                            border: 0;
                            padding: 0;
                            margin: 0;
                            box-shadow: 0 0 0 0 transparent;
                        }

                        &:active{
                            background-color: transparent;
                            border: 0;
                            padding: 0;
                            margin: 0;
                            box-shadow: 0 0 0 0 transparent;
                        }
                    }

                    .dropdown-menu{
                        box-shadow: 0px 3px 20px #00000026;
                        border: 1px solid #ECECF3;
                        border-radius: 4px;
                        font-size: 13px;

                        .dropdown-item{
                            padding-left: 19px;
                            padding-right: 19px;

                            &:active{
                                background-color: #112A61;
                            }
                        }
                    }
                }

                input {
                    background: transparent;
                    color: #495057;
                    background-color: #FFFFFF;
                    flex: 1;
                    padding: 0.375rem 0.75rem;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    border: 1px solid #ced4da;
                    border-radius: 0.25rem;
                    width: 100%;
                    padding-left: 5px!important;
                    padding-right: 5px!important;

                    &::placeholder {
                    color: #e2e2e2;
                    }

                    &:focus{
                        box-shadow: none;
                    }

                    &:active{
                        box-shadow: none;
                    }

                    &:focus-visible{
                        box-shadow: none;
                        outline: none;
                    }

                    &:disabled{
                    background-color: #e9ecef;
                    }

                    option{
                    background-color: #FFFFFF;
                    }
                }

                select{
                    padding-left: 0;
                }

                select.read-only, input.read-only{
                    background-color: transparent;
                    border: 0;

                }

                input.read-only-total{
                    background-color: #DEF2D6;
                    color: black;
                    font-weight: bold;
                    border: 1px solid #DEF2D6;
                }

                input.no-total{
                    background-color: transparent;
                    border: 0;
                }

                .required-field{
                    border-color: #ff0000;
                }

                .required-comment svg{
                    color: #ff0000 !important;
                }

                .required-comment-textarea {
                    border-color: #ff0000;
                }

                .required-file svg{
                    color: #ff0000 !important;
                }

                .required-file-btn {
                    border-color: #ff0000 !important;
                    color: #ff0000!important;
                }

                .required-kpi{
                    color: #ff0000;
                }
            }

            .no-records{
                text-align: center;
            }

            &:last-child{
                td:first-child{
                    border-radius: 0px 0px 0px 8px;
                }

                td:last-child{
                    border-radius: 0px 0px 8px 0px;
                }

                border-bottom: #F6F8FA;
            }
        }
    }
`;
