import styled from 'styled-components';

export const CommentTextArea = styled.textarea`
    background: #F6F8FA 0% 0% no-repeat padding-box;
    border: 1px solid #D3D2E4;
    border-radius: 4px;
    resize: vertical;
    width: 95%;
    margin: 0.5rem 1rem;
    position: relative;

    &.block-comment{
        background-color: transparent !important;
        border: 0 !important;
        resize: none !important;
        font: italic normal normal 14px Montserrat;
        text-align: left;
    }
`;

export const Table = styled.div`
    .sticky-col {
        position: sticky;
        z-index: 1;
        background-color: rgb(252, 252, 252);
        padding: 0;
        box-shadow: 0px 3px 20px #0000000d;

        &:first-child{
            left: 0;
            min-width: 273px!important;
        }

        .sticky-header-tooltipbox{
            z-index: 5;
            left: 19rem;
        }
    }
`;


export const Field = styled.div`
    position: relative;

    .required-icon {
    display: inline-block;
    vertical-align: middle;
    margin: -0.25em 0.25em 0em;
    background-color: transparent;
    padding: 0.5em 0.8em;
    color: rgba(0, 0, 0, 0);
    text-transform: uppercase;
    font-weight: normal;
    border-radius: 0.325em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: background 0.1s linear;
    -moz-transition: background 0.1s linear;
    transition: background 0.1s linear;
    font-size: 75%;
}
    .required-icon {
        background-color: transparent;
        position: absolute;
        bottom: 2.85em;
        right: 0.2em;
        z-index: 2;
        margin: 0em;
        width: 8px;
        height: 8px;
        padding: 0em;
        text-align: center;
        -webkit-transition: color 0.2s ease;
        -moz-transition: color 0.2s ease;
        transition: color 0.2s ease;
    }
    .required-icon .text {
        color: #B80000;
        font-size: 18px;
    }

    select{
        appearance: none
    }
`;

export const IconNewDiscussion = styled.div`
/* Define your styles for the 'icon-newdiscussion' class here */
        line-height: 30px;
        height: 6px;
        width: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 3px;
        right: 14px;
        font-size: 10px;
        border-radius: 50%;
        background-color: red;
        font-weight: 400;
        color: white;
/* Other styles */
`;
