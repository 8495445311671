import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../components/Button';
import TableLoading from '../../components/TableLoading/index';
import { error_message } from '../../components/Toast';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { Card, Container } from './styles';

const ExportESGTemplateForm = () => {
    const history = useHistory();
    const { id } = useParams();

    const [operation, setOperation] = useState("");
    const [module, setModule] = useState("");

    const [name, setName] = useState("");

    //KPI Select Variables
    const [kpi, setKpi] = useState([]);
    const [selectedKpi, setSelectedKpi] = useState([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        console.log(id);
    }, [id])

    const loadData = () => {
        Api.get('exportESG/getTemplateById/' + id).then((result) => {
            result && result.data && setSelectedKpi(result.data.response.kpis.split(";"));
            result && result.data && setName(result.data.response.name);
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });

        Api.get('exportESG/getAllKPIDistinct').then((result) => {
            result && result.data && setKpi(result.data.response);
            setLoading(false);
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const sendTemplate = () => {
        if (name == "") {
            error_message("Please provide a description for the template.");
            return;
        }
        if (selectedKpi.length == 0) {
            error_message("Please select at least one KPI.");
            return;
        }

        setLoading(true);

        Api.post('exportESG/newTemplate', {
            exportESGTemplateID: id,
            name: name,
            kpis: selectedKpi.map(x => x),
        }).then((r) => {
            if (r.data.response) {
                history.push(`/exportESGTemplate`)
            }
            setLoading(false);
        }).catch((error) => {
            exceptionNotificationAPI(error);
            setLoading(false);
        });
    }

    const handleCheckboxChange = (value) => {
        if (selectedKpi.includes(value)) {
            setSelectedKpi(selectedKpi.filter((selectedItem) => selectedItem != value));
        } else {
            setSelectedKpi([...selectedKpi, value]);
        }
    };

    return (
        <Container className="container">
            <div className="row mt-2">
                {!loading && <>
                    <Card className="show">
                        <div className='card'>
                            <div className="card-row">
                                <div className='card-body'>
                                    <div className="row my-2" style={{ width: "100%" }}>
                                        <h4 style={{ fontWeight: 900 }}>ESG Template</h4>

                                        <hr />

                                        <div className="col-12 pl-0 pr-0 mt-2">
                                            <p>Description</p>
                                            <input
                                                autoComplete={"off"}
                                                onChange={(x) => setName(x.target.value)}
                                                type={"text"}
                                                defaultValue={name}
                                                placeholder={"Description"}
                                            />
                                        </div>

                                        <div className="col-12 pl-0 pr-0 mt-4">
                                            <p style={{ marginBottom: 10 }}>{selectedKpi.length > 0 && <>
                                                <span>{selectedKpi.length} selected KPIs</span> - <span className="text-primary" onClick={() => setSelectedKpi([])}>CLEAR</span>
                                            </>}</p>
                                            {kpi.map(x => {
                                                return <button key={x.operationName + Math.random()} className={`btn ${x.operationName == operation ? 'btn-primary' : 'btn-light'}`} onClick={() => { setOperation(x.operationName); setModule("") }} style={{ marginRight: 5 }}>{x.operationName}</button>
                                            })}
                                        </div>

                                        {operation != "" && <div className="col-12 pl-0 pr-0 mt-2">
                                            {kpi.filter(x => x.operationName == operation).map(x => {
                                                return x.modules.map(x2 => {
                                                    return <button key={x2.moduleName + Math.random()} className={`btn ${x2.moduleName == module ? 'btn-primary' : 'btn-light'}`} onClick={() => setModule(x2.moduleName)} style={{ marginRight: 5 }}>{x2.moduleName}</button>
                                                })
                                            })}
                                        </div>}

                                        {module != "" && <div className="col-12 pl-0 pr-0 mt-2">
                                            <hr class="my-4" />
                                            {kpi.filter(x => x.operationName == operation).map(x => {
                                                return x.modules.filter(x2 => x2.moduleName == module).map(x2 => {
                                                    return x2.sections.map(x3 => {
                                                        return <div className="col-12 my-2" key={x3.sectionName + Math.random()}>
                                                            <h5>{x3.sectionName}</h5>
                                                            {x3.subSections.map(x4 => {
                                                                return <div className="col-12 my-2" key={x4.subSectionName + Math.random()}>
                                                                    <span>{x4.subSectionName}</span>
                                                                    <div className="row">
                                                                        {x4.subSectionKpis.map(x5 => {
                                                                            return <div className="col-3 my-3" key={x5.refNumber + Math.random()} onClick={() => handleCheckboxChange(x5.refNumber)}>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input" type="checkbox" style={{ width: "5px", padding: "6px" }} id={x5.refNumber} onChange={() => handleCheckboxChange(x5.refNumber)} checked={selectedKpi.includes(x5.refNumber)} />
                                                                                    <label className="form-check-label" htmlFor={x5.refNumber}>{x5.refNumberName}</label>
                                                                                </div>
                                                                            </div>
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            })}
                                                            <hr />
                                                        </div>
                                                    })
                                                })
                                            })}
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                    <div className="col-12 pl-0 pr-0 mt-2">
                        <Button className="btn btn-primary" style={{ float: "right", marginLeft: 10, marginTop: 10, marginBottom: 10 }} onClick={() => sendTemplate()}>Create</Button>
                        <Button className="btn btn-primary" style={{ float: "right", backgroundColor: "#B0B0BB", marginLeft: 0, marginTop: 10, marginBottom: 10 }} onClick={() => history.push("/exportESGTemplate")}>Back</Button>
                    </div>
                </>}

                {loading && <div className="mt-2"><TableLoading /></div>}
            </div>
        </Container >
    );
}

export default ExportESGTemplateForm;
