import { Container, Grid } from '@material-ui/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import FormCheck from 'react-bootstrap/FormCheckInput';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FiArrowLeft } from 'react-icons/fi';
import { MdBackspace, MdDone, MdHistory } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import Swal from 'sweetalert2';
import TableLoading from '../../../../components/TableLoading';
import Api, { exceptionNotificationAPI } from '../../../../services/api';
import FormInput from '../../../FormInput';
import GetTypeInput from '../../../FormInput/set';
import PleaseRead from '../../../PleaseRead';
import { DivLeftButtons, DivRightButtons, HeaderData, HeaderTable, HeaderTable2, Section } from './styles';

const SimulationFuels = ({ jumpTo3, allValues, setAllValues, stageData, stageColumns, stage2Block, setStage2Block, typeSimulation, simpleGroup, reportYearID, stage1Values, values, setValues, rows, setRows, saveAndSee, goTo, fuelTypesList, emissionsEfficiencyList, emissionsFactorsList, emissionsListCompanies }) => {
    const formRef = useRef(null);

    const [changed, setChanged] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingJump, setLoadingJump] = useState(jumpTo3);
    const [active, setActive] = useState(null);
    const [editSim, setEdit] = useState(false);
    const [editCidGid, setEditCidGid] = useState([]);
    const [fuelTypes, setFuelTypes] = useState(fuelTypesList);
    const [emissionsEfficiency, setEmissionsEficiency] = useState(emissionsEfficiencyList);
    const [emissionsFactors, setEmissionsFactors] = useState(emissionsFactorsList);
    const [cidsState, setCidsState] = useState(null);
    const [allOldValues, setAllOldValues] = useState([]);
    const [updatedAllValues, setUpdatedAllValues] = useState(false);
    const [jumpAlreadyVisited, setJumpAlreadyVisited] = useState([]);

    // ACTUAL - FUELS
    const [rowsFuelsActual, setRowsFuelsActual] = useState(0);

    // SIMULATED - FUELS
    const [rowsFuelsSimulated, setRowsFuelsSimulated] = useState(0);
    const [optionSimulated, setOptionSimulated] = useState(0);
    const [efficiencySimulated, setEfficiencySimulated] = useState(false);

    // ACTUAL - ADD DATA EMISSIONS
    const [rowsEmissionsActual, setRowsEmissionsActual] = useState(0);

    // SIMULATED - EMISSIONS FACTORS
    const [rowsEmissionsSimulated, setRowsEmissionsSimulated] = useState(0);

    useEffect(() => {
        var first = [1, 2].includes(typeSimulation) && simpleGroup.find((x) => x.groupID != null || x.companyID != null);
        if (first.companyID != null) {
            setActive(first.companyID);
        }
        if (first.groupID != null) {
            setActive(first.groupID);
        }

        if (allValues != null && allValues.find(x => x.refNumber == "1101") == null) {
            setAllOldValues(allValues);
            setAllValues([]);
        }

        setFuelTypes(fuelTypesList.filter(x => {
            return emissionsEfficiencyList.some(e => e.fuelTypeID == x.fuelTypeID && (e.companyID == 0 || e.companyID == null) && (e.value != null && x.value != "0"));
        }));

        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Add smooth scrolling animation
        });
    }, []);

    useEffect(() => {
        getData();
    }, [active, stageColumns])

    useEffect(() => {
        if (updatedAllValues) {
            getData();
            setUpdatedAllValues(false);
        }
    }, [allValues])

    function createData(companyID, groupID, row, stageColumn, textValue, numberValue) {
        var newValue = {
            companyID: companyID,
            groupID: groupID,
            row: row,
            refNumber: stageColumn.refNumber,
            stageColumn: stageColumn,
            stageColumnID: stageColumn.stageColumnID,
            textValue: textValue,
            numberValue: numberValue,
        };
        return newValue;
    }

    function getColumn(id) {
        return stageColumns.find(x => x.refNumber == id);
    }

    function normaliseValue(value, decimals = 2) {
        if (!value) return 0;
        const parsed = Number(value).toFixed(decimals);
        if (isNaN(parsed)) return 0;
        return parseFloat(parsed);
    }

    async function edit() {
        setLoading(true);
        setAllOldValues(allValues);
        setAllValues([]);
        setStage2Block(false);
        setEdit(true);
        getData(true);
    }

    async function resetState() {
        setLoading(true);
        var cid = typeSimulation == 1 ? active : null, gid = typeSimulation == 2 ? active : null;
        const removeActualCidOrGid = allValues != null
            ? allValues.filter(x => (cid != null ? x.companyID != cid : true) && (gid != null ? x.groupID != gid : true))
            : [];

        setAllValues(removeActualCidOrGid);
        setUpdatedAllValues(true);
    }

    async function saveState(newActive = null, finish = false, obj = null) {
        if (stage2Block) {
            setActive(newActive);
            return;
        }
        setLoading(true);
        var cid = typeSimulation == 1 ? active : null, gid = typeSimulation == 2 ? active : null;
        var saveActualValues = obj != null ? obj : {
            companyID: cid,
            groupID: gid,
            cidsState: cidsState,
            optionSimulated: optionSimulated,
            efficiencySimulated: efficiencySimulated,
            rowsFuelsActual: rowsFuelsActual,
            rowsFuelsSimulated: rowsFuelsSimulated,
            rowsEmissionsActual: rowsEmissionsActual,
            rowsEmissionsSimulated: rowsEmissionsSimulated,
            values: values
        }
        const removeActualCidOrGid = allValues != null
            ? allValues.filter(x => (cid != null ? x.companyID != cid : true) && (gid != null ? x.groupID != gid : true))
            : [];

        var newArrayAllValues = [...removeActualCidOrGid, saveActualValues];
        setAllValues(newArrayAllValues);

        if (newActive != null)
            setActive(newActive);
        else {
            if (finish) {
                var data = newArrayAllValues.map(x => x.values);

                if (editSim && newArrayAllValues.length !== simpleGroup.length) {
                    simpleGroup.forEach(group => {
                        const add = newArrayAllValues.find(x2 => typeSimulation == 1 ? x2.companyID == group.companyID : x2.groupID == group.groupID) == null;
                        if (add) {
                            var getValues = allOldValues.filter(x2 => typeSimulation == 1 ? x2.companyID == group.companyID : x2.groupID == group.groupID);
                            data = [...data, getValues];
                        }
                    });
                }

                saveAndSee(data);
                setLoading(false);
            }
            else
                setLoading(false);
        }
    }

    async function getState(checkArrayAllValues) {
        setCidsState(checkArrayAllValues.cidsState);
        setOptionSimulated(checkArrayAllValues.optionSimulated);
        setEfficiencySimulated(checkArrayAllValues.efficiencySimulated);
        setRowsFuelsActual(checkArrayAllValues.rowsFuelsActual);
        setRowsFuelsSimulated(checkArrayAllValues.rowsFuelsSimulated);
        setRowsEmissionsActual(checkArrayAllValues.rowsEmissionsActual);
        setRowsEmissionsSimulated(checkArrayAllValues.rowsEmissionsSimulated);
        setValues(checkArrayAllValues.values);
        setLoading(false);
    }

    async function getData(edit = false) {
        if (active == null || stageColumns.length == 0)
            return;

        setLoading(true);
        var cid = typeSimulation == 1 ? active : null, gid = typeSimulation == 2 ? active : null;

        var cids = [];
        if (gid != null)
            cids = simpleGroup.find(x => x.groupID == gid)?.cids;
        else
            cids.push(cid);
        setCidsState(cids);

        // VALIDA SE JA TENHO INFORMAÇÔES DO GRUPO OU COMPANIA ARMAZENADAS EM MEMORIA, SE HOVUER CARREGA, SE NAO SEGUE O FLUXO PARA CAPTURA DOS DADOS NA BASE
        if (active != null && allValues != null) {
            if (((editSim || edit) && !editCidGid.includes(active)) || stage2Block) {
                // SE OS DADOS ESTÂO SALVOS EM BASE, EXIBE ELES, SE NÃO PEGA DA MEMORIA EDITAVEL ATUAL
                var valuesToFilter = editSim ? allOldValues : allValues;
                const dbvalues = valuesToFilter.filter(x => (cid != null ? x.companyID == cid : true) && (gid != null ? x.groupID == gid : true));
                const rowsActual = dbvalues.filter(x => x.refNumber == "1103")?.length || 0;
                const rowsActualEm = dbvalues.filter(x => x.refNumber == "1113")?.length || 0;
                const rowsSim = dbvalues.filter(x => x.refNumber == "1108")?.length || 0;
                const rowsSimEm = dbvalues.filter(x => x.refNumber == "1118")?.length || 0;

                setValues(dbvalues);
                setRowsFuelsActual(rowsActual);
                setRowsFuelsSimulated(rowsSim);
                setRowsEmissionsActual(rowsActualEm);
                setRowsEmissionsSimulated(rowsSimEm);
                if (editSim || edit) {
                    setEfficiencySimulated(true);
                    setEditCidGid(prevEditCidGid => {
                        const updatedCidGid = prevEditCidGid.filter(x => x !== active);
                        updatedCidGid.push(active);
                        return updatedCidGid;
                    });
                }
                setLoading(false);
                return;
            }

            // PEGA DA MEMORIA EDITAVEL ATUAL
            const checkArrayAllValues = allValues.find(x => (cid != null ? x.companyID == cid : true) && (gid != null ? x.groupID == gid : true));
            if (checkArrayAllValues) {
                getState(checkArrayAllValues);
                return;
            }
        }

        var c1101 = getColumn(1101), c1102 = getColumn(1102), c1103 = getColumn(1103), c1104 = getColumn(1104), c1105 = getColumn(1105), c1106 = getColumn(1106), c1107 = getColumn(1107), c1113 = getColumn(1113), c1114 = getColumn(1114), c1115 = getColumn(1115), c1116 = getColumn(1116), c1117 = getColumn(1117);

        var arrayValues = [];
        var companyActiveValues = stage1Values.filter(x => cids.includes(x.companyID));

        // ACTUAL - VOLUME
        const actual1002 = cids.map(c => {
            const companyValue = companyActiveValues.find(x => x.refNumber == 1002 && x.companyID == c)?.numberValue || 0;
            return { cid: c, actual1002: normaliseValue(companyValue, 0) };
        });

        const sumActual1002 = actual1002.reduce((sum, entry) => sum + entry.actual1002, 0);
        const FCV_DFC_Actual = createData(cid, gid, 0, c1101, null, normaliseValue(sumActual1002, 0));
        arrayValues.push(FCV_DFC_Actual);

        // SIMULATED - VOLUME
        const simulated1005 = cids.reduce((sum, c) => {
            const s1005 = companyActiveValues.find(x => x.refNumber == 1005 && x.companyID == c);
            if (s1005 != null && s1005.numberValue != null)
                return sum + s1005.numberValue;
            const a1005 = companyActiveValues.find(x => x.refNumber == 1002 && x.companyID == c);
            return sum + (a1005 ? a1005.numberValue : 0);
        }, 0);

        const FCV_DFC_Simulated = createData(cid, gid, 0, c1102, null, simulated1005 > 0 ? normaliseValue(simulated1005, 0) : normaliseValue(sumActual1002, 0));
        arrayValues.push(FCV_DFC_Simulated);

        Api.post(`Simulation/getCompaniesGroupFuel`, { reportYearID: reportYearID, companyID: typeSimulation == 1 ? active : null, groupID: typeSimulation == 2 ? active : null })
            .then(result => {
                if (result && result.data) {
                    let object = result.data.response;

                    setEfficiencySimulated(false);
                    if (result.data.response == null) {
                        setValues(arrayValues);
                        setRowsFuelsActual(0);
                        setRowsFuelsSimulated(0);
                        setRowsEmissionsActual(0);
                        setRowsEmissionsSimulated(0);
                        setLoading(false);
                        return;
                    }

                    // ACTUAL - FUELS
                    var actualFuels = object.values;

                    // SOMA O TOTAL DE TABACO CURADO INFORMADOS NA CARBON FOOTPRINT
                    var sumTobaccoCuredArray = [], fuelList = [], actualFuelsArray = [], actualEmissionsArray = [], fuelListSimulated = [], simulatedFuelsArray = [], simulatedEmissionsArray = [];

                    // LOGICA NECESSARIA PARA TER O TOTAL DE TABACO CURADO POR COMPANIA
                    cids.forEach(c => {
                        var actual1002cid = actual1002.find(x => x.cid == c)?.actual1002;
                        var sumTobaccoCuredByCid = actualFuels.filter(x2 => x2.subSectionKPI.column.columnRowOrder == 4 && x2.companyID == c)?.map(x => x.numberValue).reduce((a, b) => a + b, 0);
                        var sumTobaccoCuredCalc = 0;

                        actualFuels.filter(x => x.subSectionKPI.column.columnRowOrder == 1 && x.companyID == c).forEach(x => {
                            var tobaccoCured = actualFuels.find(x2 => x2.subSectionKPI.column.columnRowOrder == 4 && x.row == x2.row && x2.companyID == c)?.numberValue;

                            var tobaccoCuredCalc = 0;
                            // FORMULA DO EXCEL
                            try { tobaccoCuredCalc = (actual1002cid / sumTobaccoCuredByCid) * tobaccoCured; } catch { tobaccoCuredCalc = 0; }

                            sumTobaccoCuredCalc += normaliseValue(tobaccoCuredCalc, 2);
                        });
                        sumTobaccoCuredArray.push({ cid: c, sumTobaccoCuredCalc: normaliseValue(sumTobaccoCuredCalc, 2) });
                    });

                    // FOREACH RESPONSAVEL POR CALCULAR COM BASE NO COMBUSTIVEL E TABACO CURADO NA CARBON FOOTPRINT, O RESTANTE DAS INFORMACOES, COMO % EM RELACAO AO VOLUME TOTAL ATUAL (EMISSIONS DATA VOLUME), APOS ISSO SE TEM A EFICIENCIA DO COMBUSTIVEL_
                    var sumTobaccoCured = sumTobaccoCuredArray?.map(x => x.sumTobaccoCuredCalc).reduce((a, b) => a + b, 0);
                    cids.forEach((c) => {
                        var actual1002cid = actual1002.find(x => x.cid == c)?.actual1002;
                        var sumTobaccoCuredByCid = actualFuels.filter(x2 => x2.subSectionKPI.column.columnRowOrder == 4 && x2.companyID == c)?.map(x => x.numberValue).reduce((a, b) => a + b, 0);
                        actualFuels.filter(x => x.subSectionKPI.column.columnRowOrder == 1 && x.companyID == c).map((x, i) => {
                            // CAPTURA ID DO COMBUSTIVEL DA CARBON FOOTPRINT
                            var fuel = actualFuels.find(x2 => x2.subSectionKPI.column.columnRowOrder == 1 && x.row == x2.row && x2.companyID == c)?.textValue;
                            // CAPTURA O TOTAL DE COMBUSTIVEL E TABACO CURADO INFORMADO NA CARBON FOOTPRINT
                            var totalFuelUsed = actualFuels.find(x2 => x2.subSectionKPI.column.columnRowOrder == 3 && x.row == x2.row && x2.companyID == c)?.numberValue;
                            var tobaccoCured = actualFuels.find(x2 => x2.subSectionKPI.column.columnRowOrder == 4 && x.row == x2.row && x2.companyID == c)?.numberValue;

                            // FORMULA DO EXCEL
                            var tobaccoCuredCalc = 0, totalFuelUsedCalc = 0, percCuredCalc = 0, efficiencyCalc = 0;
                            try { tobaccoCuredCalc = normaliseValue((actual1002cid / sumTobaccoCuredByCid) * tobaccoCured, 2); } catch { tobaccoCuredCalc = 0; }
                            try { totalFuelUsedCalc = normaliseValue((totalFuelUsed * tobaccoCuredCalc) / tobaccoCured, 2); } catch { totalFuelUsedCalc = 0; }
                            try { percCuredCalc = normaliseValue((tobaccoCuredCalc / sumTobaccoCured) * 100, 2) } catch { percCuredCalc = 0; }
                            try { efficiencyCalc = normaliseValue(totalFuelUsedCalc / tobaccoCuredCalc, 3); } catch { efficiencyCalc = 0; }

                            // CAPTURA A EFICIENCIA DO COMBUSTIVEL PARA X SUPPLIER
                            var c_efi = emissionsEfficiency.find(ef => ef.companyID == c && ef.fuelTypeID == fuel)?.value;
                            fuelList.push({
                                "fuel": x.textValue,
                                "totalFuel": normaliseValue(totalFuelUsedCalc, 2),
                                "tobaccoCured": normaliseValue(tobaccoCuredCalc, 2),
                                "percCuredCalc": normaliseValue(percCuredCalc, 2),
                                "efficiencyCalc": normaliseValue(efficiencyCalc, 3),
                                "cid": c,
                                "c_efi": c_efi ? normaliseValue(c_efi, 3) : null
                            });
                        });
                    });

                    const updatedFuelTypes = fuelList.map((fuelType) => {
                        const fuelTypeName = fuelTypes.find(x => x.fuelTypeID == fuelType.fuel)?.name;
                        return { ...fuelType, fuelTypeName: fuelTypeName };
                    });

                    // Ordenar o array com base em fuelTypeName em ordem alfabética
                    updatedFuelTypes.sort((a, b) => {
                        if (a.fuelTypeName < b.fuelTypeName) return -1;
                        if (a.fuelTypeName > b.fuelTypeName) return 1;
                        return 0;
                    });
                    fuelList = updatedFuelTypes;

                    // IDENTIFICA OS COMBUSTIVEIS UNICOS
                    var uniqueFuels = [...new Set(fuelList.map(f => f.fuel))];

                    var sumTobaccoCuredArrayFuels = uniqueFuels.reduce((sum, f) => {
                        var tobaccoCuredc = fuelList.filter(x => x.fuel == f).map(x => x.tobaccoCured).reduce((a, b) => a + b, 0);
                        return sum + tobaccoCuredc;
                    }, 0);

                    var actualFuelList = [];
                    // FOREACH RESPONSAVEL POR POPULAR A SECTION DOS COMBUSTIVEIS ATUAIS
                    uniqueFuels.forEach((f, i) => {
                        var fuelListById = fuelList.filter(x => x.fuel == f);
                        var totalFuelc = fuelListById.reduce((total, x) => total + x.totalFuel, 0);
                        var tobaccoCuredc = fuelListById.reduce((total, x) => total + x.tobaccoCured, 0);
                        var percCuredCalc = normaliseValue((tobaccoCuredc / sumTobaccoCuredArrayFuels) * 100, 2) || 0;
                        var efficiencyCalc = normaliseValue(totalFuelc / tobaccoCuredc, 3) || 0;

                        actualFuelList.push({ fuel: f, perc: percCuredCalc, efficiency: efficiencyCalc });
                        actualFuelsArray.push(
                            createData(cid, gid, i, c1103, f, null),
                            createData(cid, gid, i, c1104, null, normaliseValue(totalFuelc, 2)),
                            createData(cid, gid, i, c1105, null, normaliseValue(tobaccoCuredc, 2)),
                            createData(cid, gid, i, c1106, null, percCuredCalc),
                            createData(cid, gid, i, c1107, null, efficiencyCalc)
                        );
                    });

                    // FOREACH RESPONSAVEL POR POPULAR A SECTION DAS EMISSIONS ATUAIS
                    // ACTUAL - ADD DATA EMISSIONS
                    var addDataEmissions = object.additionalDataEmissions.filter(x => x.fuelForCuring != "");
                    addDataEmissions.forEach((ade, i) => {
                        var total = 0;
                        try { total = parseFloat(ade.reportableEmissionsCO2e.replace(",", "")) + parseFloat(ade.biogenicEmissionsCO2e.replace(",", "")) + parseFloat(ade.ghgRemovalsEmissionsKgCO2e.replace(",", "")); } catch { total = 0; }

                        actualEmissionsArray.push(
                            createData(cid, gid, i, c1113, ade.fuelForCuring, null),
                            createData(cid, gid, i, c1114, null, normaliseValue(ade.reportableEmissionsCO2e.replace(",", ""), 0)),
                            createData(cid, gid, i, c1115, null, normaliseValue(ade.biogenicEmissionsCO2e.replace(",", ""), 0)),
                            createData(cid, gid, i, c1116, null, normaliseValue(ade.ghgRemovalsEmissionsKgCO2e.replace(",", ""), 0)),
                            createData(cid, gid, i, c1117, null, normaliseValue(total, 0))
                        );
                    });

                    // SIMULATED
                    var checkOldValues = [];
                    // NOS CASOS ONDE EU VOLTEI PARA O STAGE 1, E ALTEREI ALGUMA INFORMAÇÂO, SEJA DE VOLUME OU SUPPLIERS/GROUP, O SISTEMA TENTA INSERIR OS VALORES ANTERIORMENTE TESTADOS
                    // EDIT SIM, NO CASO DE EDICAO COM CLIQUE NO BOTAO RESET, É PORQUE QUER PEGAR VALORES DA BASE E NÂO DA MEMORIA
                    if (allOldValues != null && !editSim) checkOldValues = allOldValues.filter(x => (cid != null ? x.companyID == cid : true) && (gid != null ? x.groupID == gid : true));
                    var rowsSimulated = 0;
                    if (checkOldValues.length > 0) {
                        // SE EXISTE DADOS ANTIGOS, ELE PROCURA OS TIPOS DE COMBUSTIVEIS SELECIONADOS NAS SIMULAÇÕES PASSADAS E MOSTRA EM TELA
                        var checkOldValuesFuels = checkOldValues.filter(x => x.refNumber == "1108");
                        checkOldValuesFuels.forEach((f, i) => {
                            var efficiencyCalc = null;
                            var fuelTypeID = f.textValue;
                            cids.forEach((c) => {
                                var ef = null;
                                //SE FOR GROUP, PROCURA A EFFICIENCY DO COMBUSTIVEL NO ACTUAL, SE NAO FOR, PEGA DO SUPPLIER, E POR ULTIMO DO GLOBAL, SE FOR A ULTIMA OPCAO
                                if (gid != null) {
                                    var fuelListById = actualFuelList.find(x => x.fuel == fuelTypeID);
                                    if (fuelListById && fuelListById.efficiency) { ef = fuelListById.efficiency; }
                                }
                                if (ef == null) {
                                    ef = emissionsEfficiency.find(ef => ef.companyID == c && ef.fuelTypeID == fuelTypeID)?.value;
                                    if (ef == null) {
                                        const findCountry = emissionsListCompanies.find(x => x.companyID == c);
                                        const findCompaniesCountry = emissionsListCompanies.filter(x => x.countryID == findCountry?.countryID && x.companyID != c).map(x => x.companyID);
                                        if (findCompaniesCountry.length > 0) {
                                            const companiesInEmissionsEfficiency = emissionsEfficiency.filter(x => findCompaniesCountry.includes(x.companyID));

                                            let sumEfficiency = 0;

                                            findCompaniesCountry.forEach(x => {
                                                let findEfficiencyInCompany = companiesInEmissionsEfficiency.find(x2 => x2.companyID == x && x2.fuelTypeID == fuelTypeID)?.value;
                                                if (!findEfficiencyInCompany)
                                                    findEfficiencyInCompany = emissionsEfficiency.find(ef => (ef.companyID == 0 || ef.companyID == null) && ef.fuelTypeID == fuelTypeID)?.value;

                                                sumEfficiency += parseFloat(findEfficiencyInCompany);
                                            });

                                            if (sumEfficiency && sumEfficiency != 0)
                                                ef = sumEfficiency / findCompaniesCountry.length;
                                        }
                                    }
                                    ef = ef ?? emissionsEfficiency.find(ef => (ef.companyID == 0 || ef.companyID == null) && ef.fuelTypeID == fuelTypeID)?.value;
                                }
                                efficiencyCalc += normaliseValue(ef, 3);
                            });

                            if (cids.length > 1) efficiencyCalc = normaliseValue(efficiencyCalc / cids.length, 3);

                            var oldEfficiencyCalc = checkOldValues.find(x => x.refNumber == "1112" && x.row == f.row)?.numberValue || null;
                            if (oldEfficiencyCalc != null && oldEfficiencyCalc != efficiencyCalc) {
                                efficiencyCalc = oldEfficiencyCalc;
                                setEfficiencySimulated(true);
                            }

                            var oldPerc = checkOldValues.find(x => x.refNumber == "1111" && x.row == f.row)?.numberValue || 0;
                            var percCuredCalc = normaliseValue(oldPerc, 2);
                            var tobaccoCuredc = normaliseValue(FCV_DFC_Simulated.numberValue * (percCuredCalc / 100), 2);
                            var totalFuelc = normaliseValue(tobaccoCuredc * efficiencyCalc, 2);

                            fuelListSimulated.push({
                                "fuel": fuelTypeID,
                                "totalFuel": normaliseValue(totalFuelc, 2),
                                "tobaccoCured": normaliseValue(tobaccoCuredc, 2),
                                "percCuredCalc": normaliseValue(percCuredCalc, 2),
                                "efficiencyCalc": normaliseValue(efficiencyCalc, 3),
                                "cid": cid,
                                "gid": gid,
                                "row": i
                            });
                        });

                        // FOREACH RESPONSAVEL POR POPULAR A SECTION DOS COMBUSTIVEIS E DAS EMISSIONS SIMULADAS
                        // CALCULOS DE DADOS ANTIGOS
                        checkOldValuesFuels.forEach((f, i) => {
                            var retornoSimulatedFuels = calculateSimulatedFuels(simulatedFuelsArray, fuelListSimulated, f.textValue, cid, gid, i);
                            simulatedFuelsArray = retornoSimulatedFuels.simulatedFuelsArray;
                            simulatedEmissionsArray = calculateSimulatedEmissions(simulatedEmissionsArray, emissionsFactors.find(x => x.fuelTypeID == f.textValue), retornoSimulatedFuels.totalFuelc, f.textValue, cid, gid, i);
                        });

                        //QUANTIDADE DE LINHAS POR SECTION
                        setRowsFuelsActual(uniqueFuels.length);
                        setRowsFuelsSimulated(checkOldValuesFuels.length);
                        setRowsEmissionsActual(addDataEmissions.length);
                        setRowsEmissionsSimulated(checkOldValuesFuels.length);

                        rowsSimulated = checkOldValuesFuels.length;
                    } else {
                        // SE NAO EXISTE DADOS ANTIGOS OU É UMA SIMULACAO NOVA, ELE SEGUE O FLUXO PADRÂO
                        // FOREACH RESPONSAVEL POR CALCULAR A PORCENTAGEM DOS COMBUSTIVEIS SIMULADOS BASEADO NO SIMULATED VOLUME E ACTUAL, APOS O TOBACCO CURED, E COM BASE NA EFICIENCIA DO COMBUSTIVEL, SABER O QUANTO FOI GASTO
                        uniqueFuels.forEach((f, i) => {
                            var fuelListById = actualFuelList.find(x => x.fuel == f);
                            var efficiencyCalc = null;
                            var fuelTypeID = f;
                            cids.forEach((c) => {
                                var ef = null;
                                //SE FOR GROUP, PROCURA A EFFICIENCY DO COMBUSTIVEL NO ACTUAL, SE NAO FOR, PEGA DO SUPPLIER, E POR ULTIMO DO GLOBAL, SE FOR A ULTIMA OPCAO
                                if (gid != null) {
                                    var fuelListById = actualFuelList.find(x => x.fuel == fuelTypeID);
                                    if (fuelListById && fuelListById.efficiency) { ef = fuelListById.efficiency; }
                                }
                                if (ef == null) {
                                    ef = emissionsEfficiency.find(ef => ef.companyID == c && ef.fuelTypeID == fuelTypeID)?.value;
                                    if (ef == null) {
                                        const findCountry = emissionsListCompanies.find(x => x.companyID == c);
                                        const findCompaniesCountry = emissionsListCompanies.filter(x => x.countryID == findCountry?.countryID && x.companyID != c).map(x => x.companyID);
                                        if (findCompaniesCountry.length > 0) {
                                            const companiesInEmissionsEfficiency = emissionsEfficiency.filter(x => findCompaniesCountry.includes(x.companyID));

                                            let sumEfficiency = 0;

                                            findCompaniesCountry.forEach(x => {
                                                let findEfficiencyInCompany = companiesInEmissionsEfficiency.find(x2 => x2.companyID == x && x2.fuelTypeID == fuelTypeID)?.value;
                                                if (!findEfficiencyInCompany)
                                                    findEfficiencyInCompany = emissionsEfficiency.find(ef => (ef.companyID == 0 || ef.companyID == null) && ef.fuelTypeID == fuelTypeID)?.value;

                                                sumEfficiency += parseFloat(findEfficiencyInCompany);
                                            });

                                            if (sumEfficiency && sumEfficiency != 0)
                                                ef = sumEfficiency / findCompaniesCountry.length;
                                        }
                                    }
                                    ef = ef ?? emissionsEfficiency.find(ef => (ef.companyID == 0 || ef.companyID == null) && ef.fuelTypeID == fuelTypeID)?.value;
                                }
                                efficiencyCalc += normaliseValue(ef, 3);
                            });

                            if (cids.length > 1) efficiencyCalc = normaliseValue(efficiencyCalc / cids.length, 3);
                            var percCuredCalc = normaliseValue(fuelListById.perc, 2);
                            var tobaccoCuredc = normaliseValue(FCV_DFC_Simulated.numberValue * (percCuredCalc / 100), 2);
                            var totalFuelc = normaliseValue(tobaccoCuredc * efficiencyCalc, 2);

                            fuelListSimulated.push({
                                "fuel": f,
                                "totalFuel": normaliseValue(totalFuelc, 2),
                                "tobaccoCured": normaliseValue(tobaccoCuredc, 2),
                                "percCuredCalc": normaliseValue(percCuredCalc, 2),
                                "efficiencyCalc": normaliseValue(efficiencyCalc, 3),
                                "cid": cid,
                                "gid": gid,
                                "row": i
                            });
                        });

                        // CAPTURA SE O TOTAL DAS PERC É 100, SE FOR DIFERENTE DE 100, CAPTURA O ITEM DE MAIOR PERCENTUAL, CALCULA A DIFERENCA PRA CHEGAR A 100, E ADICIONA A DIFERENCA
                        const checkArrayPerc = fuelListSimulated.reduce((sum, item) => sum + item.percCuredCalc, 0);
                        if (checkArrayPerc != 100) {
                            const updatedIndex = fuelListSimulated.findIndex(item => item.percCuredCalc == Math.max(...fuelListSimulated.map(x => x.percCuredCalc)));
                            try {
                                if (updatedIndex) {
                                    fuelListSimulated[updatedIndex].percCuredCalc = normaliseValue(fuelListSimulated[updatedIndex].percCuredCalc + (100 - checkArrayPerc == 100 ? 100 : 100 - checkArrayPerc), 2);
                                    fuelListSimulated[updatedIndex].tobaccoCured = normaliseValue(FCV_DFC_Simulated.numberValue * (fuelListSimulated[updatedIndex].percCuredCalc / 100), 2);
                                    fuelListSimulated[updatedIndex].totalFuel = normaliseValue(fuelListSimulated[updatedIndex].tobaccoCured * fuelListSimulated[updatedIndex].efficiencyCalc, 2);
                                }
                            } catch { }
                        }

                        // FOREACH RESPONSAVEL POR POPULAR A SECTION DOS COMBUSTIVEIS E DAS EMISSIONS SIMULADAS
                        // CALCULOS DA EMISSAO SIMULADA / ACTUAL
                        uniqueFuels.forEach((f, i) => {
                            var retornoSimulatedFuels = calculateSimulatedFuels(simulatedFuelsArray, fuelListSimulated, f, cid, gid, i);
                            simulatedFuelsArray = retornoSimulatedFuels.simulatedFuelsArray;
                            simulatedEmissionsArray = calculateSimulatedEmissions(simulatedEmissionsArray, emissionsFactors.find(x => x.fuelTypeID == f), retornoSimulatedFuels.totalFuelc, f, cid, gid, i);
                        });

                        //QUANTIDADE DE LINHAS POR SECTION
                        setRowsFuelsActual(uniqueFuels.length);
                        setRowsFuelsSimulated(uniqueFuels.length);
                        setRowsEmissionsActual(addDataEmissions.length);
                        setRowsEmissionsSimulated(uniqueFuels.length);

                        rowsSimulated = uniqueFuels.length;
                    }

                    arrayValues = [...arrayValues, ...actualFuelsArray, ...actualEmissionsArray, ...simulatedFuelsArray, ...simulatedEmissionsArray];

                    if (jumpTo3) {
                        const newJump = [...jumpAlreadyVisited, active];
                        setJumpAlreadyVisited(newJump);

                        const itemToJump = simpleGroup.find(item => !newJump.includes(typeSimulation == 1 ? item.companyID : item.groupID));

                        setTimeout(() => {
                            saveState(itemToJump ? typeSimulation == 1 ? itemToJump.companyID : itemToJump.groupID : null, newJump.length == simpleGroup.length, {
                                companyID: cid,
                                groupID: gid,
                                cidsState: cids,
                                optionSimulated: optionSimulated,
                                efficiencySimulated: efficiencySimulated,
                                rowsFuelsActual: uniqueFuels.length,
                                rowsFuelsSimulated: rowsSimulated,
                                rowsEmissionsActual: addDataEmissions.length,
                                rowsEmissionsSimulated: rowsSimulated,
                                values: arrayValues
                            });
                        }, 500);
                    }
                }

                setValues(arrayValues);
                setLoading(false);
            })
            .catch(error => {
                exceptionNotificationAPI(error);
                setLoading(false);
            });
    }

    async function deleteRow(rowToDelete) {
        var arr = values.filter(x => ["1108", "1109", "1110", "1111", "1112", "1118", "1119", "1120", "1121", "1122"].includes(x.refNumber));
        var otherValues = values.filter(x => !["1108", "1109", "1110", "1111", "1112", "1118", "1119", "1120", "1121", "1122"].includes(x.refNumber));

        var newArr = reorganizeArrayAndRemoveRow(arr.sort((a, b) => a.row - b.row), rowToDelete);

        setRowsFuelsSimulated(rowsFuelsSimulated - 1);
        setRowsEmissionsSimulated(rowsEmissionsSimulated - 1);
        setValues([...newArr, ...otherValues]);
    }

    function reorganizeArrayAndRemoveRow(arr, rowToDelete) {
        const rowsToKeep = new Map();
        return arr.filter(obj => {
            if (obj.row !== rowToDelete) {
                if (!rowsToKeep.has(obj.row)) rowsToKeep.set(obj.row, rowsToKeep.size);
                obj.row = rowsToKeep.get(obj.row);
                return true;
            }
            return false;
        });
    }

    async function calcSim(updatedValues, row, type = 0, fuelEfficiencyBase = false) {
        !changed && setChanged(true);
        var cid = typeSimulation == 1 ? active : null, gid = typeSimulation == 2 ? active : null;
        // TIPO 0 É REFERENTE A ALTERAÇÂO DE % CURED NA SECTION DE SIMULATED
        // TIPO 1 É REFERENTE A ALTERAÇÂO DE TOBACCO CURED NA SECTION DE SIMULATED
        // COM BASE NISSO, E COM O ACEITA DA EDUARDA EM 06/07/23, É POSSIVEL PEGAR O VALOR INFORMADO, DIVIDIR PELO TOTAL DE VOLUME SIMULADO E ASSIM OBTER UMA PORCENTAGEM VÁLIDA DISPARADO A MESMA FUNCAO, SO QUE COM TYPE 0

        if (type == 1) {
            const my1110 = updatedValues.find(x => x.refNumber == "1110" && x.row == row)?.numberValue;
            const volumeSimulated = updatedValues.find(x => x.refNumber == "1102")?.numberValue;
            const findValuePercIndex = updatedValues.findIndex(x => x.refNumber == "1111" && x.row == row);
            var calculatedValue = normaliseValue((my1110 / volumeSimulated) * 100, 2);

            if (findValuePercIndex == -1) {
                const c1111 = getColumn(1111);
                updatedValues.push(createData(cid, gid, row, c1111, null, calculatedValue));
            } else {
                updatedValues[findValuePercIndex].numberValue = calculatedValue;
            }
        }

        var valuesSim = updatedValues.filter(x => ["1108", "1109", "1110", "1111", "1112", "1118", "1119", "1120", "1121", "1122"].includes(x.refNumber) && x.row == row);
        var otherValues = updatedValues.filter(x => !["1108", "1109", "1110", "1111", "1112", "1118", "1119", "1120", "1121", "1122"].includes(x.refNumber) || x.row != row);

        const uniqueFuels = valuesSim.filter(x => x.refNumber == "1108").map(f => ({ fuel: f.textValue, row: f.row })).sort((a, b) => a.row - b.row);
        const volumeSimulated = updatedValues.find(x => x.refNumber == "1102")?.numberValue;

        var fuelsActualEfficiencyList = updatedValues.filter(x => x.refNumber == "1103" || x.refNumber == "1107");
        var uniqueRows = [...new Set(fuelsActualEfficiencyList.map(item => item.row))];
        var fuelsActualEfficiency = [];
        uniqueRows.map((x) => {
            var dataFromRow = fuelsActualEfficiencyList.filter(f => f.row == x);
            var fuel = dataFromRow.find(d => d.refNumber == "1103")?.textValue;
            var efficiency = dataFromRow.find(d => d.refNumber == "1107")?.numberValue;
            fuelsActualEfficiency.push({ fuel: fuel, efficiency: efficiency });
        });

        var fuelListSimulated = [], simulatedFuelsArray = [], simulatedEmissionsArray = [];
        uniqueFuels.forEach((f, i) => {
            // capturar a porcentagem simulated do tipo de combustivel alterado
            var percCurecTotal = valuesSim.find(x => x.refNumber == 1111 && x.row == f.row)?.numberValue;
            // capturar a effiency simulated do tipo de combustivel alterado
            var effiencyChanged = valuesSim.find(x => x.refNumber == 1112 && x.row == f.row)?.numberValue;

            var efficiencyCalc = null;
            cidsState.forEach((c) => {
                var ef = null;
                var fuelTypeID = f.fuel;
                //SE FOR GROUP, PROCURA A EFFICIENCY DO COMBUSTIVEL NO ACTUAL, SE NAO FOR, PEGA DO SUPPLIER, E POR ULTIMO DO GLOBAL, SE FOR A ULTIMA OPCAO
                if (gid != null) {
                    var fuelListById = fuelsActualEfficiency.find(x => x.fuel == fuelTypeID);
                    if (fuelListById && fuelListById.efficiency) { ef = fuelListById.efficiency; }
                }
                if (efficiencySimulated && !fuelEfficiencyBase) {
                    ef = effiencyChanged;
                } else {
                    if (ef == null) {
                        ef = emissionsEfficiency.find(ef => ef.companyID == c && ef.fuelTypeID == fuelTypeID)?.value;
                        if (ef == null) {
                            const findCountry = emissionsListCompanies.find(x => x.companyID == c);
                            const findCompaniesCountry = emissionsListCompanies.filter(x => x.countryID == findCountry?.countryID && x.companyID != c)?.map(x => x.companyID);
                            if (findCompaniesCountry.length > 0) {
                                const companiesInEmissionsEfficiency = emissionsEfficiency.filter(x => findCompaniesCountry.includes(x.companyID));

                                let sumEfficiency = 0;

                                findCompaniesCountry.forEach(x => {
                                    let findEfficiencyInCompany = companiesInEmissionsEfficiency.find(x2 => x2.companyID == x && x2.fuelTypeID == fuelTypeID)?.value;
                                    if (!findEfficiencyInCompany)
                                        findEfficiencyInCompany = emissionsEfficiency.find(ef => (ef.companyID == 0 || ef.companyID == null) && ef.fuelTypeID == fuelTypeID)?.value;

                                    sumEfficiency += parseFloat(findEfficiencyInCompany);
                                });

                                if (sumEfficiency && sumEfficiency != 0)
                                    ef = sumEfficiency / findCompaniesCountry.length;
                            }
                        }
                        ef = ef ?? emissionsEfficiency.find(ef => (ef.companyID == 0 || ef.companyID == null) && ef.fuelTypeID == fuelTypeID)?.value;
                    }
                }
                efficiencyCalc += normaliseValue(ef, 3);
            });

            if (cidsState.length > 1)
                efficiencyCalc = normaliseValue(efficiencyCalc / cidsState.length, 3);

            // FORMULA DO EXCEL
            var percCuredCalc = normaliseValue(percCurecTotal, 2);
            var tobaccoCuredc = normaliseValue(volumeSimulated * (percCuredCalc / 100), 2);
            var totalFuelc = normaliseValue(tobaccoCuredc * efficiencyCalc, 2);

            fuelListSimulated.push({
                "fuel": f.fuel,
                "totalFuel": normaliseValue(totalFuelc, 2),
                "tobaccoCured": normaliseValue(tobaccoCuredc, 2),
                "percCuredCalc": normaliseValue(percCuredCalc, 2),
                "efficiencyCalc": normaliseValue(efficiencyCalc, 3),
                "cid": cid,
                "gid": gid,
                "row": f.row
            });
        });

        uniqueFuels.forEach((f, i) => {
            var retornoSimulatedFuels = calculateSimulatedFuels(simulatedFuelsArray, fuelListSimulated, f.fuel, cid, gid, f.row);
            simulatedFuelsArray = retornoSimulatedFuels.simulatedFuelsArray;
            simulatedEmissionsArray = calculateSimulatedEmissions(simulatedEmissionsArray, emissionsFactors.find(x => x.fuelTypeID == f.fuel), retornoSimulatedFuels.totalFuelc, f.fuel, cid, gid, f.row);
        });

        setValues([...otherValues, ...simulatedFuelsArray, ...simulatedEmissionsArray]);
    }

    function calculateSimulatedFuels(simulatedFuelsArray, fuelListSimulated, fuel, cid, gid, i) {
        var c1108 = getColumn(1108), c1109 = getColumn(1109), c1110 = getColumn(1110), c1111 = getColumn(1111), c1112 = getColumn(1112);

        // FORMULA DO EXCEL
        var fuelListById = fuelListSimulated.filter(x => x.fuel == fuel && x.row == i);
        var totalFuelc = fuelListById.map(x => x.totalFuel).reduce((a, b) => a + b, 0);
        var tobaccoCuredc = fuelListById.map(x => x.tobaccoCured).reduce((a, b) => a + b, 0);
        var percCuredCalc = fuelListById.map(x => x.percCuredCalc).reduce((a, b) => a + b, 0);
        var efficiencyCalc = fuelListById.map(x => x.efficiencyCalc).reduce((a, b) => a + b, 0);

        simulatedFuelsArray.push(
            createData(cid, gid, i, c1108, fuel, null),
            createData(cid, gid, i, c1109, null, normaliseValue(totalFuelc, 2)),
            createData(cid, gid, i, c1110, null, normaliseValue(tobaccoCuredc, 2)),
            createData(cid, gid, i, c1111, null, normaliseValue(percCuredCalc, 2)),
            createData(cid, gid, i, c1112, null, normaliseValue(efficiencyCalc, 3))
        );

        return { simulatedFuelsArray, totalFuelc: normaliseValue(totalFuelc, 2) };
    }

    function calculateSimulatedEmissions(simulatedEmissionsArray, findEmission, totalFuelc, fuel, cid, gid, i) {
        var c1118 = getColumn(1118), c1119 = getColumn(1119), c1120 = getColumn(1120), c1121 = getColumn(1121), c1122 = getColumn(1122);

        var reportable = 0, biogenic = 0, removals = 0;
        // FORMULA DO EXCEL
        // VERIFICA NO EMISSIONS FACTORS, SE PRECISA DIVIDIR POR 1000 (LOGICA SOLICITADA PELA EDUARDA EM 11/07/2023)
        try { reportable = (totalFuelc / (findEmission.s3Divider ? 1 : 1000)) * normaliseValue(findEmission.reportableCO2e.replace(",", ""), 2); } catch { reportable = 0; }
        try { biogenic = (totalFuelc / (findEmission.s3Divider ? 1 : 1000)) * normaliseValue(findEmission.biogenicCO2e.replace(",", ""), 2); } catch { biogenic = 0; }
        try { removals = biogenic * -0.031; } catch { removals = 0; }

        simulatedEmissionsArray.push(
            createData(cid, gid, i, c1118, fuel, null),
            createData(cid, gid, i, c1119, null, normaliseValue(reportable, 0)),
            createData(cid, gid, i, c1120, null, normaliseValue(biogenic, 0)),
            createData(cid, gid, i, c1121, null, normaliseValue(removals, 0)),
            createData(cid, gid, i, c1122, null, normaliseValue(reportable + biogenic + removals, 0))
        );

        return simulatedEmissionsArray;
    }

    function addNewRowSim() {
        var cid = typeSimulation == 1 ? active : null, gid = typeSimulation == 2 ? active : null;

        var c1108 = getColumn(1108), c1109 = getColumn(1109), c1110 = getColumn(1110), c1111 = getColumn(1111), c1112 = getColumn(1112), c1118 = getColumn(1118), c1119 = getColumn(1119), c1120 = getColumn(1120), c1121 = getColumn(1121), c1122 = getColumn(1122);

        var newRowFuels = rowsFuelsSimulated + 1, newRowSimulated = rowsEmissionsSimulated + 1;
        const array = [
            createData(cid, gid, rowsFuelsSimulated, c1108, null, null), createData(cid, gid, rowsFuelsSimulated, c1109, null, 0),
            createData(cid, gid, rowsFuelsSimulated, c1110, null, 0), createData(cid, gid, rowsFuelsSimulated, c1111, null, 0),
            createData(cid, gid, rowsFuelsSimulated, c1112, null, 0), createData(cid, gid, rowsEmissionsSimulated, c1118, null, null),
            createData(cid, gid, rowsEmissionsSimulated, c1119, null, 0), createData(cid, gid, rowsEmissionsSimulated, c1120, null, 0),
            createData(cid, gid, rowsEmissionsSimulated, c1121, null, 0), createData(cid, gid, rowsEmissionsSimulated, c1122, null, 0)
        ];

        setRowsFuelsSimulated(newRowFuels);
        setRowsEmissionsSimulated(newRowSimulated);
        setValues([...values, ...array]);
    }

    return (
        <>
            <div style={{ backgroundColor: "#fff", minHeight: 100, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                <Container className="container">
                    <Section>
                        <div className="row">
                            <div className="col-1">
                                <h2 style={{ marginTop: 7.5 }}>
                                    <FiArrowLeft color={"#FFFFFF"} className="icon-left" size={10} onClick={() => {
                                        if (changed)
                                            Swal.fire({
                                                title: 'Are you sure?',
                                                text: "To make sure you save your curing simulation, you should proceed to the 3rd step before returning to volume. Otherwise your information will not be saved and you will need to go through the 2nd step again.",
                                                icon: 'warning',
                                                showDenyButton: true,
                                                confirmButtonColor: '#112A61',
                                                denyButtonColor: '#B0B0BB',
                                                denyButtonText: 'Cancel',
                                                confirmButtonText: 'Back to Volume',
                                                showCloseButton: true
                                            }).then(async (result) => {
                                                if (result.isConfirmed) {
                                                    goTo(1);
                                                }
                                            });
                                        else
                                            goTo(1);
                                    }} />
                                </h2>
                            </div>
                            {!jumpTo3 && [1, 2].includes(typeSimulation) && simpleGroup && simpleGroup.map((s, i) => {
                                var id = (s.companyID != null && s.companyID) || (s.groupID != null && s.groupID);
                                return <div className={`col-${simpleGroup.length > 5 ? "1" : "2"} tab ${active == (s.groupID || s.companyID) && 'tab-active'}`} onClick={() => { !loading && saveState(id) }} key={i}>
                                    <div className="tab-header">
                                        <span>
                                            {s.companyName != null && s.companyName}
                                            {s.groupName != null && s.groupName}
                                        </span>
                                    </div>
                                    <div className="tab-bar"></div>
                                </div>
                            })}
                        </div>
                    </Section>
                </Container>
            </div>
            <Container>
                {!loadingJump &&
                    <div style={{ marginLeft: -10, marginRight: -10 }}>
                        <PleaseRead guidance={'You are now in the second stage! \n \n ' +
                            'To proceed, please follow the steps below:\n ' +
                            '- In the left side you have actual information available for the year selected and in the right side you can simulate\n ' +
                            '- Click on Add+ to add a new curing fuel and click on the gray arrow to delete an existing curing fuel\n ' +
                            '- You can either simulate based on "% cured" or "Tobacco cured" by using the flag available in the header to switch options\n ' +
                            '- If you are a global user, You must go through all the selected companies or groups before continuing to the third stage'} style={{ margin: -10 }} />
                    </div>}
                <Section>
                    {loadingJump && <div className="row bs-wizard" style={{ justifyContent: "center" }}>
                        <div className="progress" style={{ marginBottom: 10 }}>
                            <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ backgroundColor: "#102a63", width: ((jumpAlreadyVisited.length / simpleGroup.length) * 100) + "%" }} role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        {simpleGroup.map((x, i) => {
                            return <div key={i} className="col-2" style={{ marginBottom: 10, marginTop: 10 }}>
                                <div className="text-center justify-content-center">{jumpAlreadyVisited.includes(typeSimulation == 1 ? x.companyID : x.groupID) ? <MdDone /> : <MdHistory />} {typeSimulation == 1 ? x.companyName : x.groupName}</div>
                            </div>
                        })}
                    </div>}
                    {loading && !loadingJump && <TableLoading />}
                    {!loading && !loadingJump && <>
                        <Form onSubmit={() => { }} ref={formRef}>
                            <Grid container spacing={1} direction='row'>
                                {stageData.stageSections && stageData.stageSections.map((ss, is) => {
                                    var fixRows = [4, 5].includes(ss.stageSectionID) ? 1 : rows;
                                    switch (ss.stageSectionID) {
                                        case 6:
                                            fixRows = rowsFuelsActual;
                                            break;
                                        case 7:
                                            fixRows = rowsFuelsSimulated;
                                            break;
                                        case 8:
                                            fixRows = rowsEmissionsActual;
                                            break;
                                        case 9:
                                            fixRows = rowsEmissionsSimulated;
                                            break;
                                    }

                                    return <Grid item xs={6} key={ss.stageSectionID}>
                                        <Grid item container className="grid-header" direction='row'>
                                            <span>{ss.description}</span>
                                        </Grid>
                                        <Grid item container direction='row'>
                                            {ss.stageColumns && ss.stageColumns.map((sc, ih) => {
                                                return <>
                                                    <Grid item xs={[4, 5].includes(ss.stageSectionID) ? 12 : ih < 2 ? 3 : 2} key={sc.stageColumnID}>
                                                        <HeaderTable style={{ justifyContent: "center" }} key={ss.stageColumnID}>
                                                            <label>{sc.name}</label>
                                                            {!stage2Block && <>
                                                                {["1110", "1111"].includes(sc.refNumber) &&
                                                                    <FormCheck
                                                                        onChange={(e) => { if (optionSimulated == 0) setOptionSimulated(1); else setOptionSimulated(0); }}
                                                                        style={{ height: 20, marginLeft: 5, marginRight: 5 }}
                                                                        checked={(sc.refNumber == "1110" && optionSimulated == 1) || (sc.refNumber == "1111" && optionSimulated == 0)}
                                                                    />
                                                                }
                                                                {["1112"].includes(sc.refNumber) &&
                                                                    <FormCheck
                                                                        onChange={(e) => setEfficiencySimulated(!efficiencySimulated)}
                                                                        style={{ height: 20, marginLeft: 5, marginRight: 5 }}
                                                                        checked={efficiencySimulated}
                                                                    />
                                                                }
                                                            </>}
                                                        </HeaderTable>
                                                    </Grid>
                                                </>
                                            })}
                                        </Grid>
                                        <Grid item container direction='row' style={{ background: "#fff", borderRadius: "0 0 6px 6px", paddingBottom: 5, paddingTop: 5 }}>
                                            {[...Array(fixRows)].map((r, row) => {
                                                return <Grid item container spacing={1} direction='row' key={row} style={{ position: "relative", margin: "1px 0px" }}>
                                                    {!stage2Block && ss.stageSectionID == 7 && <div style={{ position: "absolute", right: -25, color: "#ececf3", top: 8, fontSize: 18, cursor: "pointer" }} onClick={() => deleteRow(row)}>
                                                        <MdBackspace />
                                                    </div>}
                                                    {
                                                        ss.stageColumns && ss.stageColumns.map((sc, ih) => {
                                                            var unitMeasure = GetTypeInput(sc.unitMeasureID, sc.unitMeasure.type);
                                                            var isNumber = (unitMeasure.inputType == "number" || unitMeasure.inputType == "decimal") ? true : false;
                                                            var calculation = sc.inputCalc == "Calculation" ? sc.simpleCalculation : null;
                                                            var isCalculation = false;
                                                            var valueItem = values?.find(x => x.refNumber == sc.refNumber && x.row == row);

                                                            if (calculation != null && sc.inputCalc == "Calculation") {
                                                                isCalculation = true;
                                                            }

                                                            var d = ((sc.refNumber == "1110" && optionSimulated == 0) || (sc.refNumber == "1111" && optionSimulated == 1)) || (sc.refNumber == "1112" && !efficiencySimulated);

                                                            var findValue = null;
                                                            var floatValue = 0;
                                                            var tooltipValue = null;
                                                            if (valueItem != null) {
                                                                if (sc.unitMeasureID == 6) {
                                                                    findValue = valueItem?.textValue;
                                                                    tooltipValue = findValue;
                                                                } else if (sc.unitMeasureID == 68) {
                                                                    findValue = fuelTypes?.find(x => x.fuelTypeID == valueItem?.textValue)?.name;
                                                                    tooltipValue = findValue;
                                                                } else {
                                                                    findValue = valueItem?.numberValue;
                                                                    floatValue = valueItem?.numberValue;
                                                                    tooltipValue = normaliseValue(floatValue, unitMeasure.decimalPrecision)
                                                                }
                                                            }

                                                            return (
                                                                <OverlayTrigger
                                                                    delay={{ hide: 0, show: 100 }}
                                                                    overlay={(props) => <Tooltip {...props}>{tooltipValue}</Tooltip>}
                                                                    placement={"bottom"}
                                                                    key={sc.stageColumnID}
                                                                >
                                                                    <Grid item xs={ih < 2 ? 3 : 2}>
                                                                        {isCalculation && isNumber ? (
                                                                            <HeaderData style={{ justifyContent: "center", backgroundColor: "#D9F3D7" }}>
                                                                                <NumberFormat
                                                                                    name={sc.refNumber + row + "" + is + ih}
                                                                                    id={sc.refNumber + row + "" + is + ih}
                                                                                    autoComplete="off"
                                                                                    className="read-only-total"
                                                                                    disabled
                                                                                    title={""}
                                                                                    thousandsGroupStyle="thousand"
                                                                                    value={findValue != null ? findValue : ""}
                                                                                    decimalSeparator={unitMeasure.decimalSeparator || "."}
                                                                                    decimalScale={unitMeasure.decimalPrecision}
                                                                                    suffix={findValue != null ? unitMeasure.suffix : ""}
                                                                                    displayType="text"
                                                                                    type="text"
                                                                                    thousandSeparator={true}
                                                                                    allowNegative={true}
                                                                                    allowLeadingZeros={false}
                                                                                    allowEmptyFormatting={true}
                                                                                />
                                                                            </HeaderData>
                                                                        ) : (
                                                                            <>
                                                                                {isNumber && !isCalculation ? (
                                                                                    <NumberFormat
                                                                                        name={sc.refNumber + row + "" + is + ih}
                                                                                        id={sc.refNumber + row + "" + is + ih}
                                                                                        autoComplete="off"
                                                                                        thousandsGroupStyle="thousand"
                                                                                        value={findValue != null ? findValue : ""}
                                                                                        decimalSeparator={unitMeasure.decimalSeparator || "."}
                                                                                        decimalScale={unitMeasure.decimalPrecision}
                                                                                        suffix={unitMeasure.suffix}
                                                                                        displayType="input"
                                                                                        type="text"
                                                                                        thousandSeparator={true}
                                                                                        allowNegative={false}
                                                                                        allowLeadingZeros={false}
                                                                                        allowEmptyFormatting={false}
                                                                                        style={{ borderRadius: 6, backgroundColor: stage2Block || d ? "#D9F3D7" : "#fff" }}
                                                                                        disabled={stage2Block || d}
                                                                                        onValueChange={values => (floatValue = values.floatValue)}
                                                                                        onFocus={(event) => {
                                                                                            event.preventDefault();
                                                                                            event.target.select();
                                                                                        }}
                                                                                        onKeyDown={(event) => {
                                                                                            if (event.key == 'Enter') {
                                                                                                event.preventDefault();
                                                                                                event.target.blur(); // Forces the input field to lose focus

                                                                                                const inputElement = document.getElementById(sc.refNumber + (row + 1) + "" + is + ih);
                                                                                                if (inputElement) inputElement.focus();
                                                                                            }
                                                                                        }}
                                                                                        onBlur={(e) => {
                                                                                            if (!d) {
                                                                                                const updatedValues = values.map((x) => {
                                                                                                    if (x.refNumber == sc.refNumber && x.row == row)
                                                                                                        return { ...x, numberValue: floatValue };
                                                                                                    return x;
                                                                                                });
                                                                                                calcSim(updatedValues, row, ["1110", "1111"].includes(sc.refNumber) ? optionSimulated : 0);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <>
                                                                                        {sc.unitMeasure.type == "selectfuel" && sc.inputCalc == "Input" ? (
                                                                                            <FormInput
                                                                                                name={sc.refNumber + row + "" + is + ih}
                                                                                                id={sc.refNumber + row + "" + is + ih}
                                                                                                autoComplete="off"
                                                                                                value={valueItem?.textValue ?? ""}
                                                                                                inputType={sc.unitMeasure.type} 
                                                                                                options={fuelTypes?.map(x => ({ value: x.fuelTypeID, text: x.name }))}
                                                                                                style={{ borderRadius: 6, backgroundColor: stage2Block || d ? "#D9F3D7" : "#fff" }}
                                                                                                disabled={stage2Block || d}
                                                                                                onChange={(f) => {
                                                                                                    const updatedValues = values.map((x) => {
                                                                                                        if (x.refNumber == sc.refNumber && x.row == row)
                                                                                                            return { ...x, textValue: f.target.value };
                                                                                                        return x;
                                                                                                    });
                                                                                                    calcSim(updatedValues, row, 0, true);
                                                                                                }}
                                                                                            />
                                                                                        ) : (
                                                                                            <FormInput
                                                                                                name={sc.refNumber + row + "" + is + ih}
                                                                                                id={sc.refNumber + row + "" + is + ih}
                                                                                                autoComplete="off"
                                                                                                value={findValue != null ? findValue : ""}
                                                                                                type={"text"}
                                                                                                isSimulation={true} 
                                                                                                style={{ backgroundColor: '#D9F3D7', borderRadius: 6 }}
                                                                                                disabled={true}                                                                                              
                                                                                                className={"read-only-total"}
                                                                                            />
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </Grid>
                                                                </OverlayTrigger>
                                                            );
                                                        })
                                                    }
                                                </Grid>
                                            })}
                                            {ss.stageColumns?.map((sc, ih) => {
                                                let decimal = sc.refNumber == "1111" ? 2 : 0;
                                                let total = 0;

                                                if (["1106", "1110", "1111", "1117", "1122"].includes(sc.refNumber) && values && values.length > 0)
                                                    total = normaliseValue(values.filter(x => x.refNumber == sc.refNumber).map(x => x.numberValue).reduce((a, b) => a + b, 0), decimal);

                                                return (
                                                    <Grid item xs={ih < 2 ? 3 : 2} key={sc.stageColumnID}>
                                                        {sc.refNumber == "1108" && !stage2Block && (
                                                            <button type="button" style={{ padding: 10, margin: "0rem 1rem 0rem 1rem" }} onClick={() => addNewRowSim()} className="btn btn-custom">Add +</button>
                                                        )}
                                                        {["1106", "1111"].includes(sc.refNumber) && (
                                                            <HeaderTable2 style={{ justifyContent: "center" }} key={ss.stageColumnID}>
                                                                <label style={{ color: total != 100 ? "red" : "" }}>{total} %</label>
                                                            </HeaderTable2>
                                                        )}
                                                        {["1117", "1122"].includes(sc.refNumber) && (
                                                            <HeaderTable2 style={{ justifyContent: "center" }} key={ss.stageColumnID}>
                                                                <label>{total.toLocaleString()}</label>
                                                            </HeaderTable2>
                                                        )}
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                })}
                            </Grid>
                        </Form>
                    </>}
                </Section>
                <div style={{ display: "flex", marginTop: 20 }}>
                    {!loading && !loadingJump && <>
                        <DivLeftButtons>
                            {stage2Block && <>
                                <button type="button" style={{ padding: 10, margin: "0rem 1rem 0rem 0rem" }} onClick={() => Swal.fire({
                                    title: 'Unlock changes',
                                    text: "Some values may be altered, impacting the previous result. Do you wish to proceed?",
                                    icon: 'warning',
                                    showDenyButton: true,
                                    confirmButtonColor: '#112A61',
                                    denyButtonColor: '#B0B0BB',
                                    denyButtonText: 'Cancel',
                                    confirmButtonText: 'Yes',
                                    showCloseButton: true
                                }).then(async (result) => {
                                    if (result.isConfirmed) {
                                        edit();
                                    }
                                })
                                } className="btn btn-custom">Edit</button>
                            </>}
                            {!stage2Block && <>
                                <button type="button" style={{ padding: 10, margin: "0rem 1rem 0rem 0rem" }} onClick={() => resetState()} className="btn btn-custom">Reset</button>
                            </>}
                        </DivLeftButtons>
                        <DivRightButtons>
                            {stage2Block && <>
                                <button type="button" style={{ padding: 10, margin: "0rem 0rem 0rem 1rem" }} onClick={() => goTo(3)} className="btn btn-custom">See results</button>
                            </>}
                            {!stage2Block && <>
                                <button type="button" style={{ padding: 10, margin: "0rem 0rem 0rem 1rem" }} onClick={() => saveState(null, true)} className="btn btn-custom">Save and continue</button>
                            </>}
                        </DivRightButtons>
                    </>}
                </div>
            </Container >
        </>
    )
}

export default SimulationFuels;