import moment from "moment";
import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { FaArrowRight } from "react-icons/fa";
import { FiUpload } from "react-icons/fi";
import NumberFormat from "react-number-format";
import Api, { exceptionNotificationAPI } from "../../services/api";
import Button from "../Button";
import GetTypeInput from "../FormInput/set";
import { success_message } from "../Toast";
// import ModalBody from 'react-bootstrap/ModalBody';
import { Header, ModalBody } from './styles';
import { transform } from "typescript";
import { margin } from "polished";
import Select, { ActionMeta, OnChangeValue } from 'react-select';

export default function ModalCustomInput({referenceObject, referenceColIndex, referenceIndex, selectedValues, options, isMultipleSelect, showModalCustomInput, setShowModalCustomInput, requestName, requestDescription, onChangeEvent}) {

    const [values, setValues] = useState(selectedValues);
    const [selectAll, setSelectAll] = useState(false);
    // Function to handle the "Select All" button click
    const handleSelectAll = () => {
        if (!selectAll) {
        // Select all options
        setValues(options);
        } else {
        // Deselect all options
        setValues([]);
        }
        setSelectAll(!selectAll);
    };

    return (

        <Modal
            show={showModalCustomInput}
            onHide={() => {
                setShowModalCustomInput(false);
            }}
            onShow={() => {

                    var dataCompany = selectedValues?.map((ce) => {
                        var data = {
                            value: ce.companyID,
                            label: options.find(x => x.value == ce.companyID)?.label
                        };
                        return data;
                    });
                    if(selectedValues)
                        setValues(dataCompany);
                    else
                        setValues([]);
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalHeader closeButton>
                <ModalTitle id="contained-modal-title-vcenter">
                    <Header>{requestName}</Header>
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Select
                    options={options}
                    value={values}
                    isMulti={isMultipleSelect}
                    closeMenuOnSelect={false}
                    onChange={(selectedOptions) => {
                        setValues(selectedOptions);
                        setSelectAll(false);
                    }}
                />
                <div style={{ margin: "0.5rem 0" }}>
                    <Button
                        style={{ margin: "0 1rem", float: "right", textTransform: "none" }}
                        onClick={() => {
                            onChangeEvent(referenceObject, referenceColIndex, referenceIndex, values);
                            setValues([]);
                            setSelectAll(false);
                        }}
                    >
                        {"Update companies"}
                    </Button>
                    </div>
                    <div style={{ margin: "0.5rem 0" }}>
                    <Button
                        style={{ margin: "0 1rem", float: "right", textTransform: "none" }}
                        onClick={handleSelectAll} // Call the handleSelectAll function
                    >
                        {selectAll ? "Deselect All" : "Select All"}
                    </Button>
                    </div>
            </ModalBody>

        </Modal>
    );
}

