import styled from 'styled-components';

export const Message = styled.li`
    clear: both;
    overflow: hidden;
    padding-bottom: 20px;
    transition: all 0.5s linear;

    .message_information {
        color: gray;
        font-size: 12px;
        margin-bottom: 5px;
    }

    .left .message_information{
        margin-left: 20px;
        float: left;
    }

    .right .message_information{
        margin-right: 20px;
        float: right;
    }

    .left .text_wrapper {
        background-color: white;
        box-shadow: 0px 3px 20px #0000000d;
        margin-left: 20px;
    }

    .left .text {
        color: #234285;
    }

    .right .text_wrapper {
        background-color: #234285;
        margin-right: 20px;
        float: right;
        box-shadow: 0px 3px 10px rgb(0 0 0 / 50%);
    }

    .right .text {
        color: white;
    }

    .appeared {
        opacity: 1;
    }

    .text_wrapper {
        display: flex;
        flex-direction: row;
        padding: 15px;
        border-radius: 6px;
        width: calc(100% - 85px);
        min-width: 100px;
        position: relative;
    }

    .text_wrapper::after, .text_wrapper:before {
        top: 18px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .text_wrapper::after {
        border-width: 13px;
        margin-top: 0px;
    }

    .text_wrapper::before {
        border-width: 15px;
        margin-top: -2px;
    }

    .text_wrapper .text {
        font-size: 14px;
        font-weight: 500;
    }

    .edit_button {
        margin-left: auto;
        background-color: #234285;
        border: none;
    }

    .message_input {
        border: none;
        height: 100%;
        box-sizing: border-box;
        width: calc(100% - 40px);
        outline-width: 0;
        color: gray;
        font-size: 15px;
        resize: none;
        border-radius: 8px;
      }


`;