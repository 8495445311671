import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import Button from 'react-bootstrap/Button';
import { ModalHeaderStyled, ModalTitleStyled, ModalFooterStyled, Text } from './style';

const formatTime = (countdown) => {
  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  return `${minutes}:${formattedSeconds}`;
};

const SessionTimeoutDialog = ({ show, countdown, onContinue }) => {
  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onContinue}
    >
      <ModalHeaderStyled closeButton>
        <ModalTitleStyled>Session Timeout</ModalTitleStyled>
      </ModalHeaderStyled>
      <ModalBody>
        <Text>
          The session will be ended in{" "}
          <span>{formatTime(countdown)} </span>
          minutes due to inactivity.
        </Text>
      </ModalBody>
      <ModalFooterStyled>
        <Button
          style={{
            backgroundColor: '#102A63',
            borderColor: '#102A63',
            fontWeight: 'bold',
          }}
          onClick={onContinue}
        >
          Continue Session
        </Button>
      </ModalFooterStyled>
    </Modal>
  );
};

export default SessionTimeoutDialog;
