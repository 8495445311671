import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TableLoading from '../../components/TableLoading/index';

import { MdDelete, MdEdit, MdSearch } from 'react-icons/md';
import Button from '../../components/Button';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { Card, Container } from './styles';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment';

const ExportESGTemplate = () => {
    const history = useHistory();

    const [active, setActive] = useState(null);
    const [exports, setExports] = useState([]);
    const [nameFilter, setNameFilter] = useState(null);
    const [dateFilter, setDateFilter] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadData();
    }, []);

    function filter(a, b) {
        loadData();
    }

    const loadData = () => {
        setLoading(true);
        Api.post(`exportESG/getTemplates`, { date: dateFilter, name: nameFilter }).then((result) => {
            result && result.data && setExports(result.data.response);
            setLoading(false);
        }).catch((error) => {
            exceptionNotificationAPI(error);
            setLoading(false);
        });
    }

    const deleteTemplate = (id) => {
        setLoading(true);
        Api.post(`exportESG/deleteTemplate/` + id, null).then((result) => {
            loadData();
        }).catch((error) => {
            exceptionNotificationAPI(error);
            setLoading(false);
        });
    }

    return (
        <Container className="container">
            <div className="row mb-5 mt-5">
                <div className="col-12">
                    <h3 style={{ marginBottom: 0 }}>ESG Template - Filter</h3>
                </div>
                <div className="col-4">
                    <input
                        autoComplete={"off"}
                        onChange={(x) => setNameFilter(x.target.value)}
                        type={"text"}
                        defaultValue={nameFilter}
                        placeholder={"Name"}
                    />
                </div>
                <div className="col-4">
                    <DatePicker
                        isClearable={true}
                        onChange={(x) => setDateFilter(x)}
                        onSelect={(x) => setDateFilter(x)}
                        selected={dateFilter}
                        dateFormat="dd-MMMM-yyyy"
                        placeholderText={"Date"}
                    />
                </div>
                <div className="col-2">
                    <Button type="button" onClick={() => filter(dateFilter, nameFilter)} className="btn btn-primary" style={{ marginTop: 2, minWidth: 10 }}><MdSearch size={20} /></Button>
                    {((dateFilter != null && dateFilter != "") || (nameFilter != null && nameFilter != "")) && <Button type="button" onClick={() => { setDateFilter(null); setNameFilter(""); filter(null, ""); }} className="btn btn-primary" style={{ marginTop: 2, minWidth: 10 }}>Clear</Button>}
                </div>
                <div className="col-2">
                    <Button type="button" onClick={() => history.push(`exportESGTemplate/form`)} className="btn btn-primary" style={{ float: "right", marginTop: 2, backgroundColor: "#102A63" }}>New ESG Template</Button>
                </div>
            </div>

            {exports != null && <>
                <div className="mt-5">
                    {!loading && (!exports || (exports != null && exports.length == 0)) && <div>There is no data to list here</div>}
                    {!loading && exports.map((s, i) => {
                        return <Card className="show" key={i} onClick={() => active == i ? setActive(null) : setActive(i)}>
                            <div className='card'>
                                <div className="card-row">
                                    <div className='card-body'>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-6">
                                                    {s.name}
                                                </div>
                                                <div className="col-6 text-right">
                                                    {moment(s.created).format("DD - MMMM - YYYY")}
                                                </div>
                                                <hr style={{ marginBottom: 5, marginTop: 7.5 }} />
                                                {active != i && <div className="col-12">
                                                    <span style={{ fontWeight: 500 }}>See more details</span>
                                                </div>}
                                                {active == i && <div className="show">
                                                    <div className="col-12" style={{ marginBottom: 5, marginTop: 5 }}>
                                                        <p>KPI's:</p> {s.kpis.split(";").map(x => <span className="badge rounded-pill" key={x} style={{ backgroundColor: "rgb(16 42 99)", color: "#fff", marginRight: 5 }}>{x}</span>)}
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                    {s.name != "Default" && <div className="card-row-buttons-body">
                                        <button title="Edit" className="card-row-button" style={{ height: "100%" }} onClick={() => history.push("/exportESGTemplate/form/" + s.exportESGTemplateID)}>
                                            <MdEdit size={20} />
                                        </button>
                                        {<button title="Delete" className="card-row-button-last" style={{ height: "100%" }} onClick={() => deleteTemplate(s.exportESGTemplateID)}>
                                            <MdDelete size={20} />
                                        </button>}
                                    </div>}
                                </div>
                            </div>
                        </Card>
                    })}
                </div>
            </>}

            {loading && <div className="mt-2"><TableLoading /></div>}
        </Container>
    );
}

export default ExportESGTemplate;
