import styled from 'styled-components';

export const Container = styled.div`
    width: 150px;
    line-height: 30px;

    .icon-chat{
        cursor: pointer;
        float: right;
    }

    button{
        margin: 0;
    }

    .icon-newdiscussion {
        height: 13px;
        width: 13px;
        max-width: 13px;
        min-height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 1px;
        right: 8px;
        font-size: 10px;
        border-radius: 50%;
        background-color: red;
        font-weight: 400;
        color: white;
    }
`;