import React, { useState } from 'react';
import { Switch } from 'react-router-dom';

import Route from './route';
import RoutesList from './routesList';

var mainArray = [];

const userObject = JSON.parse(localStorage.getItem('@Thrive:user'));
const userRoles = JSON.parse(localStorage.getItem('@Thrive:userRoles'));


RoutesList.forEach(item => {
    var array = [];
    if(((item?.isMenu == false || item.isMenu == undefined) && !item?.onlyGlobal) || (item?.onlyGlobal && userObject?.companyID == null) || (item?.onlyBATGroup && userObject?.isBATGroup)) {
        array.push(item);
    }

    item.childrens && item.childrens.forEach(child => {
        if(((child?.isMenu == false || child.isMenu == undefined) && !child?.onlyGlobal) || (child?.onlyGlobal && userObject?.companyID == null) || child.justInfoHolder) {
            array.push(child);
        }
        // !child.isMenu && array.push(child);
    });
    mainArray = mainArray.concat(array);
});

const Routes = () => (
    <Switch>
        {mainArray.map(route => (
            <Route
                key={route.path}
                path={route.path}
                exact
                component={route.component}
                isPrivate={route.isPrivate}
                allowedRoles={route.allowedRoles} />
        ))}
    </Switch>
);

export default Routes;
