import React, { useState } from 'react';

import { useEffect } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { Spreadsheet } from 'react-spreadsheet';
import Swal from 'sweetalert2';
import Button from '../../components/Button';
import TableLoading from '../../components/TableLoading';
import { error_message, info_message, success_message } from '../../components/Toast/index';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { Container, ContainerHeader } from './styles';

import optionsLogo from '../../assets/icons/options.svg';

const AdditionalDataEmissionsFormIndex = () => {
    const columns = ["Supplier", "Emission", "Fuel for Curing", "Converted Volume (tonnes)", "Fossil Fuel / Biomass", "Reportable Emissions (kg CO2e)", "Biogenic CO2 (Other)  - Emissions (kg CO2)", "GHG removals - emissions (kgCO2e)", "Scope Category (1 or 3)", " "]

    //Crop Year Select Variables
    const [cropYears, setCropYears] = useState([]);
    const [selectedCropID, setSelectedCropID] = useState(null);
    const [countries, setCountries] = useState([]);
    const [rowLabels, setRowLabels] = useState([]);
    const [selectedCountryID, setSelectedCountryID] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [dataSheet, setDataSheet] = useState([
        [],
        [],
    ]);

    const removeRow = (id) => {
        Swal.fire({
            title: 'Are you sure, you want delete the register?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonColor: '#102A63',
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                Api.post(`AdditionalDataEmissions/delete?id=${id}`).then((result) => {
                    if (selectedCropID && selectedCountryID) {
                        loadDataByCropYear(selectedCropID, selectedCountryID)
                    }
                })
            }
        })
    }

    const deleteAll = () => {
        Swal.fire({
            title: 'Are you sure, you want delete all?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonColor: '#102A63',
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                Api.post(`AdditionalDataEmissions/deleteAll?reportYearID=${selectedCropID}&countryID=${selectedCountryID}`).then((result) => {
                    if (result.data.response == true) {
                        if (selectedCropID && selectedCountryID) {
                            success_message("All values was deleted successfully!")
                            setTimeout(() => {
                                loadDataByCropYear(selectedCropID, selectedCountryID)
                            }, 2000)
                        }
                    }
                    else {
                        error_message("Some error was happened. Please try again or contact the administrator.")
                    }
                })
            }
        })
    }

    const InputNumberEdit = ({ cell, onChange }) => {
        var rowID = cell.className.split("-");

        return (
            <div style={{ padding: '0.5rem 1rem', textAlign: "center" }} onClick={() => removeRow(rowID[1])}>
                <FaTrashAlt />
            </div>
        );
    };

    const InputOptions = ({ cell, onChange }) => {
        return (
            <div style={{ padding: '0.5rem 1rem' }}>
                <img src={optionsLogo} alt="" />
            </div>
        );
    };

    useEffect(() => {
        setIsLoading(true)
        loadCropYears()
        loadCountries()
    }, [])

    useEffect(() => {
        if (selectedCropID && selectedCountryID) {
            setIsLoading(true);
            loadDataByCropYear(selectedCropID, selectedCountryID)
        }
    }, [selectedCropID, selectedCountryID])

    const loadCropYears = (userAux) => {
        Api.get(`thriveValue/getAllCropsYears`).then((result) => {
            if (result && result.data) {
                setCropYears(result.data.response);
                setSelectedCropID(result.data.response[0].cropID);
                setIsLoading(false)
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const onChangeCropID = (value) => {
        setSelectedCropID(value);
    }

    const loadCountries = (userAux) => {
        Api.get(`Company/getAllCountries`).then((result) => {
            if (result && result.data) {
                setCountries(result.data.response);

                setSelectedCountryID(result.data.response[0].countryID);
                setIsLoading(false)
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const onChangeCountryID = (value) => {
        setSelectedCountryID(value);
    }

    const loadDataByCropYear = (selectedReportYearID) => {
        if (selectedReportYearID && selectedCountryID) {
            Api.get(`AdditionalDataEmissions/getAllByReportYearAndCountry?reportYearID=${selectedReportYearID}&countryID=${selectedCountryID}`).then((result) => {
                if (result && result.data) {
                    var resultList = result.data.response;
                    var newLine = [
                        { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }
                    ]
                    resultList.push(newLine)
                    setIsLoading(false)

                    try {
                        resultList.map((row) => {
                            var newRow = row.map((col) => {
                                var newCol = { ...col };
                                return newCol;
                            })
                            return newRow;
                        })

                        try {
                            var rowLabel = [];
                            resultList.map((row) => {
                                var id = row[0].value;

                                row[0] = { value: row[1].value };
                                row[1] = { value: row[2].value };
                                row[2] = { value: row[3].value };

                                row[3] = { value: row[4].value != "" || row[4].value.includes("-") ? eval(parseFloat(row[4].value.replaceAll(',', ''))).toLocaleString('en') + "" : "" };
                                row[4] = { value: row[5].value };
                                row[5] = { value: row[6].value != "" || row[6].value.includes("-") ? eval(parseFloat(row[6].value.replaceAll(',', ''))).toLocaleString('en') + "" : "" };
                                row[6] = { value: row[7].value != "" || row[7].value.includes("-") ? eval(parseFloat(row[7].value.replaceAll(',', ''))).toLocaleString('en') + "" : "" };
                                row[7] = { value: row[8].value != "" || row[8].value.includes("-") ? eval(parseFloat(row[8].value.replaceAll(',', ''))).toLocaleString('en') + "" : "" };
                                row[8] = { value: row[9] && (row[9].value != "" || row[9].value.includes("-")) ? eval(parseFloat(row[9].value.replaceAll(',', ''))).toLocaleString('en') + "" : "" };
                                row[9] = { readOnly: false, className: 'remove-' + id, onClick: () => removeRow(id) };
                                row[9].DataViewer = InputOptions;
                                row[9].DataEditor = InputNumberEdit;

                                rowLabel.push(id);
                            })
                            setRowLabels(rowLabel);
                        } catch (e) { console.log(e); }

                        setDataSheet(resultList);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
    }

    const save = () => {
        var objAdditionalData = {
            reportYearID: selectedCropID,
            countryID: selectedCountryID,
            IDs: rowLabels,
            data: dataSheet
        }
        setIsLoading(true);

        Api.post(`AdditionalDataEmissions/New`, objAdditionalData).then((result) => {
            if (result && result.data) {
                setIsLoading(false);
                if (result.data.response == true) {
                    success_message("The data was saved!");
                }
                else {
                    info_message("No data to saved!");
                }
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const onChangeTable = (e) => {
        try {
            setDataSheet(e);
        } catch { }
    }

    const onBlurTable = () => {
        try {
            var nesw = dataSheet.map((row) => {
                var newRow = row.map((col) => {
                    var newCol = { ...col };
                    return newCol;
                })
                return newRow;
            })

            try {
                nesw.map((row, i) => {
                    var value1 = row[4].value != "" ? row[4].value : "";
                    var value2 = row[6].value != "" ? row[6].value : "";
                    var value3 = row[7].value != "" ? row[7].value : "";
                    var value4 = row[8].value != "" ? row[8].value : "";

                    row[0] = { value: row[0].value };
                    row[4] = { value: value1.includes(" - ") || value1 == " " || value1 == "-" || value1 == null || value1 == undefined ? "" : value1 };
                    row[6] = { value: value2.includes(" - ") || value2 == " " || value2 == "-" || value2 == null || value2 == undefined ? "" : value2 };
                    row[7] = { value: value3.includes(" - ") || value3 == " " || value3 == "-" || value3 == null || value3 == undefined ? "" : value3 };
                    row[8] = { value: value4 == "" || value4 == " " || value4 == null || value4 == undefined ? "3" : value4 };
                })
            } catch { }
            setDataSheet(nesw);
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Container className="container">
            <ContainerHeader style={{ margin: '2rem 0' }}>
                <h3>Additional Data - Carbon Emissions Form</h3>
                <div>
                    <Button style={{ marginRight: '15px' }} onClick={() => save()}>Save</Button>
                    <Button style={{ marginRight: '15px' }} onClick={() => deleteAll()}>Delete All</Button>
                </div>
            </ContainerHeader>
            <div className="row mt-2">
                <div className="col-md-6 mt-2">
                    <label className='label'>Select Reporting Year:</label>
                    <select className="col-md-6 mx-4 mt-2" onChange={(x) => onChangeCropID(x.target.value)} value={selectedCropID ? selectedCropID : ""}>
                        {
                            cropYears ?
                                cropYears.map(cropYear => {
                                    return <option key={cropYear.year} value={cropYear.reportYearID}>{cropYear.year}</option>
                                })
                                :
                                <></>
                        }
                    </select>
                </div>
                <div className="col-md-6 mt-2">
                    <label className='label'>Select Country:</label>

                    <select className="col-md-6 mx-4 mt-2" onChange={(x) => onChangeCountryID(x.target.value)} value={selectedCountryID ? selectedCountryID : ""}>
                        {
                            countries ?
                                countries.map(country => {
                                    return <option key={country.countryID} value={country.countryID}>{country.name}</option>
                                })
                                :
                                <></>
                        }
                    </select>

                </div>
            </div>
            {!isLoading ?
                <Spreadsheet data={dataSheet} rowLabels={rowLabels} columnLabels={columns} onBlur={() => onBlurTable()} onChange={(e) => onChangeTable(e)} />
                :
                <TableLoading />
            }
        </Container>
    )
}

export default AdditionalDataEmissionsFormIndex;
