import styled from 'styled-components';

export const HeaderModal = styled.div`
    
    .btn_download {
        color: #0EAC27;
        text-decoration: underline;
        width: 80%;
        padding: 0.7rem 0.5rem;
        border-radius: 4px;   
        cursor: pointer;
        border: 1px solid;

        &svg {
            margin: 0 0.2rem;
        }
    }

    h6 {
        margin: 0 0.2rem;
    }
`;

export const Header = styled.div`
    font-size: 14px;
    margin-bottom:0;
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;


`;  

export const ContentTitle = styled.div`
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #112A61;
    padding: 1rem;
    font-weight: 500;
    font-weight: bold;
`;

export const ModalBody = styled.div`
    height: 29rem;
    padding: 2rem 1rem;


    .upload-btn-wrapper {
        position: relative;
    }

    .btn-custom {
        padding: 14px 40px 14px 40px;
        width: 100%;
        height: 11rem;
        background: #F2F5F8;
        border: 2px dashed #ACB4BA;
        border-radius: 8px;
    }

    .upload-btn-wrapper input[type=file] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }

    .displayValue {
        border: 1px solid #cccccc;
        border-radius: 5px;
        padding: 0.7rem;
        margin: 1rem 0px;
        text-align: center;
    }

    .btn {
        padding: 1rem 2rem;
    }

    
`;

export const CommentTextArea = styled.textarea`
    background: #F6F8FA 0% 0% no-repeat padding-box;
    border: 1px solid #D3D2E4;
    border-radius: 4px;
    resize: vertical;
    width: 95%;
    margin: 0.5rem 1rem;
    position: relative;

    &.block-comment{
        background-color: transparent !important;
        border: 0 !important;
        resize: none !important;
        font: italic normal normal 14px Montserrat;
        text-align: left;
    }

    &.all-versions {
        height: 5rem;
    }
`;

