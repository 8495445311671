import Package from "../../../../package.json";
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiMenu, FiBell, FiChevronDown } from 'react-icons/fi';
import { BiLogOut, BiUser } from 'react-icons/bi';
import { useAuth } from '../../../hooks/auth';
import ModalProfile from '../../../components/ModalProfile';
import { useHistory } from 'react-router-dom'

import Sidebar from '../../../components/Sidebar';
import logo from '../../../assets/images/logo.jpg';
import logoThrive from '../../../assets/images/logoThrive.png';
import logoFooter from '../../../assets/images/detalhe_top.jpg';
import { Container } from './styles';
import Dropdown from 'react-bootstrap/Dropdown';
import SessionTimeOut from '../../../components/SessionTimeout';
import NotificationMenu from "../../../components/NotificationMenu";
import Api, { exceptionNotificationAPI } from "../../../services/api";
import { MdDateRange } from "react-icons/md";

const Authorized = ({ children }) => {
    const [openMenu, setOpenMenu] = useState(null);
    const [active, setActive] = useState(false);
    const { signOut, user, changePassword } = useAuth();
    const [userObject, setUserObject] = useState(null);
    const [crops, setCrops] = useState([]);
    const [selectedCrop, setSelectedCrop] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const history = useHistory()

    const URLsToHide = ["/glidepath/", "/signin/MultiFactorAuthentication", "/additionalData", "additionalDataEmissions", "/s3EmissionsSimulator/new", "/s3EmissionsSimulator/edit", "/emissionEfficiency", "/emissionFactor"]

    useEffect(() => {
        setUserObject(typeof (user) == "string" ? JSON.parse(user) : user);
        const openedMenu = localStorage.getItem('@Thrive:openMenu');
        setOpenMenu(openedMenu ?? null);
    }, []);

    useEffect(() => {
        if (document.location.pathname.lastIndexOf("/glidepath/") == -1) {
            const userStoraged = JSON.parse(localStorage.getItem('@Thrive:user'));

            document.querySelector("#selectedCropYear").innerHTML = userStoraged ? userStoraged.selectedReportYear : "";

            loadCrops();
        }
    }, [history]);

    const loadCrops = () => {
        var flag = true;
        Api.get(`ReportYear/getAllYears`).then((result) => {
            if (result && result.data) {
                setCrops(result.data.response);
                // if(userStoraged){
                //     setSelectedCrop(userStoraged.selectedReportYearID)
                // }
            }
        }).catch((error) => {
            console.log('Erro Crop: ', error);
            exceptionNotificationAPI(error);
        });
    }

    useEffect(() => {
        if (selectedCrop != null && selectedCrop != '' && selectedCrop != 'undefined') {

            const userStoraged = JSON.parse(localStorage.getItem('@Thrive:user'));
            userStoraged.selectedReportYear = selectedCrop.year;
            userStoraged.selectedReportYearID = selectedCrop.reportYearID;
            localStorage.setItem('@Thrive:user', JSON.stringify(userStoraged));

            document.querySelector("#selectedCropYear").innerHTML = userStoraged ? userStoraged.selectedReportYear : "";

            window.location.reload(false);
            console.log('Erro useEffect Dashboard - Valor selectedCrop: ', selectedCrop);
        }
    }, [selectedCrop]);

    return (
        <Container>
            {
                (!(document.location.pathname === '/') && !document.location.pathname.includes("report")) ? <SessionTimeOut /> : <></>
            }
            <header className="main-header">
                <ul>
                    <li className="li-menu">
                        <FiMenu onClick={() => { setActive(!active) }} size={23} />
                    </li>

                    <li className="li-logo">
                        <Link to="/" title="Home">
                            <img src={logoThrive} alt="Thrive Logo" width="100" height="36" />
                        </Link>
                    </li>

                    {document.location.pathname.includes("/s3EmissionsSimulator") && <li className="li-s3">
                        <Link to="/s3EmissionsSimulator" title="S3 Emissions Simulator" style={{ color: "#fff", textDecoration: "none" }}>
                            <p>S3 Emissions Simulator</p>
                        </Link>
                    </li>}

                    {/* <li className="nav-item margin-itens ml-auto">
                        <input type="button"
                            style="width: 117px;background: #00b1eb;color: #fff;padding-left: 3px; border-radius: 8px; text-align: center;border: none;margin-top:-4px; font-size: 22px; cursor:pointer"
                            id="yearparam"
                            className="datepicker-here"
                            data-language='en'
                            data-min-view="years"
                            data-view="years"
                            data-date-format="yyyy" />
                    </li> */}

                    <li className="li-logo logo-bat">
                        <img src={logo} alt="BAT Logo" width="86" height="36" />
                    </li>

                    {
                        !URLsToHide.some(url => document.location.pathname.includes(url) || document.location.pathname.startsWith(url)) ?
                            <li className="li-crop-year">
                                <div className="col-md-12">
                                    <Dropdown className={`col-md-12 dropdown-crop`}>
                                        <Dropdown.Toggle>
                                            <span>{userObject ? userObject.companyID ? "Crop Year" : "Report Year" : "Report Year"} <br />
                                                <strong id="selectedCropYear">...</strong>
                                            </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dpn-crop">
                                            <div className="label-name">
                                                <div>
                                                    {
                                                        crops ?
                                                            crops.map(year => {
                                                                return <Dropdown.Item key={year.reportYearID} onClick={() => setSelectedCrop(year)} ><MdDateRange /> <span>{year.year}</span></Dropdown.Item>
                                                            })
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </li>
                            :
                            <li className="li-crop-year">
                                <p>
                                    {userObject ? userObject.companyID ? "Crop Year" : "Report Year" : "Report Year"}
                                    <br />
                                    <strong id="selectedCropYear">...</strong>
                                </p>
                            </li>
                    }

                    <li className="li-notification">
                        <NotificationMenu />
                    </li>

                    <li className="li-profile">
                        <div className="col-md-12">
                            <Dropdown className={`col-md-12 user-name ${userObject && userObject.companyID ? "has-company" : ""}`}>
                                <Dropdown.Toggle>
                                    <span>Hello, {userObject ? userObject.firstName : ""}</span>
                                    <FiChevronDown size={20} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <div className="label-line">
                                        <div className="hrdivider">
                                            <hr />
                                            <span className="profile-label">Profile</span>
                                        </div>
                                    </div>
                                    <div className="label-name">
                                        <div className="profile-info">
                                            <p>Name: <span>{userObject ? userObject.firstName : ""}</span></p>
                                            <p>Email: <span>{userObject ? userObject.email : ""}</span></p>
                                            {userObject && userObject.companyID ? <p>Company: <span>{userObject && userObject.companyID ? userObject.company.name : "Global"}</span></p> : ""}
                                            {userObject && userObject.companyID ? <p>Currency: <span>{userObject && userObject.companyID ? userObject.company.currencyType.description : "Global"}</span></p> : ""}
                                        </div>
                                    </div>
                                    <div className="label-line">
                                        <div className="hrdivider options">
                                            <hr />
                                            <span className="profile-label">Options</span>
                                        </div>
                                    </div>
                                    <div className="label-name">
                                        <div className="profile-info">
                                            <Dropdown.Item onClick={() => setModalShow(true)} ><BiUser /> Change password</Dropdown.Item>
                                            <Dropdown.Item onClick={() => signOut()} ><BiLogOut /> Logout</Dropdown.Item>
                                        </div>
                                    </div>
                                    <div className="label-line">
                                        <div className="hrdivider options">
                                            <hr />
                                            <span className="profile-label">Version</span>
                                        </div>
                                    </div>
                                    <div className="label-name">
                                        <div className="profile-info">
                                            <p><small style={{ color: "#929292" }}>thrive {Package.version}</small></p>
                                        </div>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div className="user-infos">
                                {userObject && userObject.companyID ? <span className="col-md-12">{`${userObject.company.name} / ${userObject.company.currencyType.description}`}</span> : <></>}
                            </div>
                        </div>
                    </li>
                </ul >
            </header >

            <ModalProfile
                show={modalShow}
                onHide={() => setModalShow(false)}
                setModalShow={setModalShow}
            />

            <Sidebar active={active} setActive={setActive} openMenu={openMenu} setOpenMenu={setOpenMenu} />

            <div className="main-body">
                <main role="main">
                    {children}
                </main>
            </div>

            <footer>
                <p>Britsh American Tobacco&copy; All Rights Reserved</p>
                <div className="col-md-12 d-flex">

                    <div className="blue-dark"></div>
                    <div className="blue"></div>
                    <div className="blue-twitter"></div>
                    <div className="orange"></div>
                    <div className="blue-dark-sec"></div>
                    <div className="green"></div>
                    <div className="yellow"></div>
                    <div className="purple-dark"></div>
                    <div className="pink"></div>
                    {/*
                        <div class="blue-dark"></div>
                        <div class="blue"></div>
                        <div class="blue-twitter"></div>
                        <div class="orange"></div>
                        <div class="blue-dark-sec"></div>
                        <div class="green"></div>
                        <div class="yellow"></div>
                        <div class="purple-dark"></div>
                        <div class="pink"></div> */}

                </div>
            </footer>
        </Container >
    );
}

export default Authorized;
