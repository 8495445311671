import { Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SimulationFuels from '../../../components/Simulations/SimulationS3E/SimulationFuels';
import SimulationResult from '../../../components/Simulations/SimulationS3E/SimulationResult';
import SimulationVolume from '../../../components/Simulations/SimulationS3E/SimulationVolume';

import * as Yup from 'yup';
import TableLoading from '../../../components/TableLoading';
import Api, { exceptionNotificationAPI } from '../../../services/api';
import { CalculateTargetSubsectionKPI } from '../../../services/simulation_functions';
import getValidationErrors from '../../../utils/getValidationErrors';

import { error_message } from '../../../components/Toast';

const SimulationS3EForm = () => {
  const history = useHistory();
  const { id, summary, jump } = useParams();

  const [screen, setScreen] = useState(1);
  const [loading, setLoading] = useState(true);

  //stage 1
  const [typeSimulation, setTypeSimulation] = useState(null);
  const [name, setName] = useState("Simulation");
  const [stageData, setStageData] = useState(null);
  const [stageColumns, setStageColumns] = useState([]);
  const [stage1Block, setStage1Block] = useState(false);
  const [values, setValues] = useState(null);
  const [rows, setRows] = useState(0);
  const [companies, setCompanies] = useState(null);
  const [actualCompanies, setActualCompanies] = useState(null);
  const [simpleGroup, setSimpleGroup] = useState(null);
  const [reportYearID, setReportYearID] = useState(null);
  const [jumpTo3, setJumpTo3] = useState(jump ?? false);

  //stage 2
  const [fuelTypes, setFuelTypes] = useState(null);
  const [emissionsFactors, setEmissionsFactors] = useState(null);
  const [emissionsEfficiency, setEmissionsEfficiency] = useState(null);
  const [allValues2, setAllValues2] = useState(null);
  const [stageData2, setStageData2] = useState(null);
  const [stageColumns2, setStageColumns2] = useState([]);
  const [stage2Block, setStage2Block] = useState(false);
  const [values2, setValues2] = useState(null);
  const [rows2, setRows2] = useState(0);

  const [reportYear, setReportYear] = useState(null);

  useEffect(() => {
    var userObject = JSON.parse(localStorage.getItem('@Thrive:user'));
    if (userObject?.company?.isESGYear ? parseInt(userObject.selectedReportYear) < 2021 : parseInt(userObject.selectedReportYear) < 2022) {
      error_message(`You can only simulate for years equal to or above ${userObject?.company?.isESGYear ? '2021' : '2022'}.`);
      history.replace('/s3EmissionsSimulator');
    }

    getStage(1);
  }, []);

  async function getStage(stage) {
    setLoading(true);
    //capturar dados Stage - Volume Purchased
    if ((stage == 1 && stageData != null) || (stage == 2 && stageData2 != null)) {
      setLoading(false);
      return;
    }

    Api.get(`Stage/getStageWithSectionsAndColumnByStageId/${stage}`)
      .then(result => {
        if (result && result.data) {
          let object = result.data.response;
          var columnsList = [];

          object.stageSections.map((section) => {
            columnsList = columnsList.concat(section.stageColumns);
          });

          if (stage == 1) {
            setStageData(object);
            setStageColumns(columnsList);
            getCompaniesVolume(columnsList);
          } else if (stage == 2) {
            setStageData2(object);
            setStageColumns2(columnsList);
            setLoading(false);
          }
        }
      })
      .catch(error => {
        exceptionNotificationAPI(error);
      });
  }

  async function getCompaniesVolume(columnsList) {
    var userObject = JSON.parse(localStorage.getItem('@Thrive:user'));

    if (id != null) {
      setStage1Block(true);
      Api.get(`Simulation/GetS3SimulationById/${id}`)
        .then(result => {
          if (result && result.data) {
            setName(result.data.response.name);
            setCompanies(result.data.response.volume.companies);
            setActualCompanies(result.data.response.volume.actualCompanies);
            setSimpleGroup(result.data.response.volume.simpleGroup);
            setTypeSimulation(result.data.response.typeSimpleGroup);
            setReportYearID(result.data.response.reportYearID);
            setReportYear(result.data.response.reportYear.year)
            setFuelTypes(result.data.response.volume.fuelType.filter(x => x.kpiRefNumber == "8103"));
            setEmissionsFactors(result.data.response.volume.emissionFactors);
            setEmissionsEfficiency(result.data.response.volume.emissionEfficiency);

            var answers = result.data.response.volume.values;
            var columnsCalculated = columnsList.filter(x => x.inputCalc == 'Calculation' && (x.simpleCalculation != "" || x.groupedCalculation != ""))
            columnsCalculated.map((c) => {
              for (let rowIndex = 0; rowIndex < result.data.response.volume.rows; rowIndex++) {
                var valueToAnswer = answers.find(x => x.stageColumnID == c.stageColumnID && x.row == rowIndex);
                if (valueToAnswer && valueToAnswer.numberValue == null) {
                  answers = CalculateTargetSubsectionKPI(c, null, null, null, answers, columnsCalculated, rowIndex, answers);
                }
              }
            });
            setValues(answers);

            if (summary != null && summary == 2)
              goTo(2);

            var answers2 = result.data.response.volume.values2;
            if (answers2 != null && answers2.length > 0) {
              setAllValues2(answers2);
              if (answers2.find(x => x.refNumber == "1101") != null)
                setStage2Block(true);
            }

            if (summary != null && summary == 3)
              goTo(3);

            setRows(result.data.response.volume.rows);

            setLoading(false);
          }
        })
        .catch(error => {
          exceptionNotificationAPI(error);
        });
    } else {
      Api.get(`Simulation/getCompaniesVolume/${userObject.selectedReportYearID}`)
        .then(result => {
          if (result && result.data) {
            setCompanies(result.data.response.companies);
            setActualCompanies(result.data.response.actualCompanies);

            var answers = result.data.response.values;
            var columnsCalculated = columnsList.filter(x => x.inputCalc == 'Calculation' && (x.simpleCalculation != "" || x.groupedCalculation != ""))
            columnsCalculated.map((c) => {
              for (let rowIndex = 0; rowIndex < result.data.response.rows; rowIndex++) {
                var valueToAnswer = answers.find(x => x.stageColumnID == c.stageColumnID && x.row == rowIndex);
                if (valueToAnswer && valueToAnswer.numberValue == null) {
                  answers = CalculateTargetSubsectionKPI(c, null, null, null, answers, columnsCalculated, rowIndex, answers);
                }
              }
            });
            setValues(answers);
            setRows(result.data.response.rows);

            setLoading(false);
          }
        })
        .catch(error => {
          exceptionNotificationAPI(error);
        });
    }
  }

  const continueCuringSimulations = async (type, groups, suppliers, j) => {
    try {
      var toValidate = {}
      toValidate.type = Yup.string().required("Simulation type is required");
      if (type == 1) {
        toValidate.suppliers = Yup.array().required("Suppliers is required");
      } else if (type == 2) {
        toValidate.groups = Yup.array().required("Groups is required");
      }

      const schema = Yup.object().shape(toValidate);

      await schema.validate({
        type,
        groups,
        suppliers
      }, {
        abortEarly: false,
      });

      setJumpTo3(j);

      setTypeSimulation(type.value);

      if (type.value == 1) {
        setSimpleGroup(suppliers.map(x => { return { companyID: x.value, companyName: x.label } }));
      } else if (type.value == 2) {
        setSimpleGroup(groups.map(x => { return { groupID: x.value, groupName: x.label } }));
      }

      sendToAPI(false, type.value, type.value == 1 ? suppliers : groups, j);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        for (var key in errors) {
          error_message(errors[key])
        }
        return;
      }
      exceptionNotificationAPI(error);
    }
  }

  const goTo = async (s) => {
    setLoading(true);
    setScreen(s);
    if (s == 1) {
      history.replace(`/s3EmissionsSimulator/edit/${id}/1`);
      getStage(1);
    }
    if (s == 2) {
      history.replace(`/s3EmissionsSimulator/edit/${id}/2`);
      getStage(2);
    }
    if (s == 3) {
      history.replace(`/s3EmissionsSimulator/edit/${id}/3`);
      setLoading(false);
    }
  }

  const sendToAPI = async (isFinal = false, type, simpleGroup, j) => {
    setLoading(true);
    var userObject = JSON.parse(localStorage.getItem('@Thrive:user'));

    var data = {
      simulationVersionID: id != null ? id : null,
      simulationValuesRequest: values,
      simulationID: 1,
      reportYearID: userObject.selectedReportYearID,
      stageID: 1,
      typeSimpleGroup: type,
      simpleGroup: type == 1 ? simpleGroup.map(x => { return { companyID: x.value } }) : type == 2 ? simpleGroup.map(x => { return { groupID: x.value } }) : [],
      isFinalVersion: isFinal,
      name: name
    };

    Api.post(`Simulation/SimulationVersion`, data)
      .then(result => {
        if (id == null) history.replace(`/s3EmissionsSimulator/edit/${result.data.response.simulationVersionID}/2` + (j ? "/true" : ""));
        else {
          setSimpleGroup(result.data.response.simpleGroup);
          setAllValues2(result.data.response.oldValues);
          setStage1Block(true);

          if (!isFinal)
            goTo(2);
          else
            goTo(3);

          setLoading(false);
        }
      })
      .catch(error => {
        exceptionNotificationAPI(error);
        setLoading(false);
      });
  }

  const saveStage2AndSee = async (values, jumpTo3Direct = false) => {
    setLoading(true);

    if (!jumpTo3Direct) {
      if (values.filter(x => x.refNumber == "1108").some(x => x.textValue == null || x.textValue == "")) {
        error_message("The selected fuels are not valid. Please review the simulation or remove the unnecessary rows and try again after the correction.");
        setLoading(false);
        return;
      }

      if (values.length != simpleGroup.length) {
        error_message("To save the simulation at this stage, you need to simulate all companies and groups. Please review the forms and submit again.");
        setLoading(false);
        return;
      }

      const totalPercentage = parseFloat(Number(values.flatMap(x => x.filter(x2 => x2.refNumber == "1111")).reduce((total, x2) => total + x2.numberValue, 0)).toFixed(2));
      if (totalPercentage != (100 * values.length) && !jumpTo3) {
        error_message("The simulation percentage needs to be 100%. Please review the forms and submit again.");
        setLoading(false);
        return;
      }
    }

    var data = {
      simulationVersionID: id != null ? id : null,
      simulationValuesRequest: values.flatMap((x) => x),
      stageID: 2,
      isFinalVersion: false,
    };

    Api.post(`Simulation/SimulationVersion2`, data)
      .then(result => {
        setStage1Block(true);
        setStage2Block(true);
        setAllValues2(values.flatMap((x) => x));
        goTo(3);
        setJumpTo3(false);
        setLoading(false);
      })
      .catch(error => {
        exceptionNotificationAPI(error);
        setLoading(false);
      });
  }

  return (
    <>
      {loading && <Container style={{ marginTop: 10 }}><TableLoading /></Container>}
      {!loading && screen == 1 ? <SimulationVolume
        //stage section/columns
        stageData={stageData}
        stageColumns={stageColumns}
        stage1Block={stage1Block}
        setStage1Block={setStage1Block}
        setStage2Block={setStage2Block}
        setAllValues2={setAllValues2}
        typeSimulation={typeSimulation}
        simpleGroup={simpleGroup}
        //simulation data
        values={values}
        setValues={setValues}
        name={name}
        setName={setName}
        rows={rows}
        setRows={setRows}
        companies={companies}
        actualCompanies={actualCompanies}
        //bottom bar
        continueCuringSimulations={continueCuringSimulations}
        goTo={goTo}
      /> : <></>}

      {!loading && screen == 2 ? <SimulationFuels
        jumpTo3={jumpTo3}
        //stage section/columns
        stageData={stageData2}
        stageColumns={stageColumns2}
        stage2Block={stage2Block}
        setStage2Block={setStage2Block}
        typeSimulation={typeSimulation}
        simpleGroup={simpleGroup}
        reportYearID={reportYearID}
        //simulation data
        stage1Values={values}
        values={values2}
        setValues={setValues2}
        rows={rows2}
        setRows={setRows2}
        allValues={allValues2}
        setAllValues={setAllValues2}
        fuelTypesList={fuelTypes}
        emissionsEfficiencyList={emissionsEfficiency}
        emissionsFactorsList={emissionsFactors}
        emissionsListCompanies={[...companies, ...actualCompanies]}
        //bottom bar
        saveAndSee={saveStage2AndSee}
        goTo={goTo}
      /> : <></>}

      {!loading && screen == 3 ? <SimulationResult
        //stage section/columns
        typeSimulation={typeSimulation}
        simpleGroup={simpleGroup}
        //simulation data
        fuelTypesList={fuelTypes}
        stage1Values={values}
        stage2Values={values2}
        //bar
        goTo={goTo}
        reportYear={reportYear}
      /> : <></>}
    </>
  );
};

export default SimulationS3EForm;
