import React, { useEffect, useState } from 'react';
import { GoLinkExternal, GoChevronDown } from 'react-icons/go';
import { Container, Card } from './styles';
import { useHistory } from 'react-router-dom';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useAuth } from '../../hooks/auth';
import { MdChat } from 'react-icons/md';
import SessionTimeOut from '../../components/SessionTimeout';

import ModalReadConsent from '../../components/ReadConsentModal';

import Api, { exceptionNotificationAPI } from '../../services/api';

import TableLoading from '../../components/TableLoading';

const DashboardLocal = () => {
    const history = useHistory();
    const { user, userRoles } = useAuth();
    const [userObject, setUserObject] = useState(null);
    const [modalReadShow, setModalReadShow] = useState(false);
    const [data, setData] = useState([]);
    const [selectedCrop, setSelectedCrop] = useState("");
    const [selectedAccordionOperation, setSelectedAccordionOperation] = useState("");
    const [crops, setCrops] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        var userObjectAux = JSON.parse(localStorage.getItem('@Thrive:user'));

        // var userLogged = JSON.parse(userObjectAux);

        if (userObjectAux) {
            if (userRoles.includes('RoleApprover') && userObjectAux.needReadConsent && userObjectAux.isFirstLogin) {
                setModalReadShow(true);
            }
        }

        setUserObject(userObjectAux);
        setData([]);
        loadCrops(userObjectAux);
    }, [])

    useEffect(() => {
        if (selectedCrop != "") {
            setLoading(true);
            Api.get(`Dashboard/GetByFilter?selectedReportYear=${selectedCrop}`).then((result) => {
                if (result && result.data) {
                    setData(result.data.response);
                    if (result.data.response && result.data.response.length > 0) {
                        setSelectedAccordionOperation(result.data.response[0].operationID);
                    }
                    setLoading(false);
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });

            if (userObject.selectedReportYearID && userObject.selectedReportYearID != selectedCrop) {
                var targetCrop = crops.find(x => x.reportYearID == selectedCrop);
                userObject.selectedReportYearID = parseInt(selectedCrop);
                userObject.selectedReportYear = targetCrop ? targetCrop.year : "";
                localStorage.setItem('@Thrive:user', JSON.stringify(userObject));
                document.querySelector("#selectedCropYear").innerHTML = targetCrop ? targetCrop.year : "";
            }
        }
    }, [selectedCrop]);

    const onClickModuleRedirect = (operationName, module) => {
        var operationNameWords = operationName.split(" ");
        var moduleNameWords = module.description.split(" ");
        var url;

        if (module.tobaccoTypeID != null) {
            url = `/report/${operationName.replaceAll(' ', '').replaceAll('&', '').toLowerCase()}/${module.description.replaceAll(' ', '').replaceAll('&', '').toLowerCase()}/${module.tobaccoTypeID}`
        } else {
            if (module.moduleID == 24 || module.moduleID == 25 || module.moduleID == 26 || module.moduleID == 27) {
                url = `/${operationName.replaceAll(' ', '').replaceAll('&', '').toLowerCase()}/${module.description.replaceAll(' ', '').replaceAll('&', '').toLowerCase()}`
            }
            else {
                url = `/report/${operationName.replaceAll(' ', '').replaceAll('&', '').toLowerCase()}/${module.description.replaceAll(' ', '').replaceAll('&', '').toLowerCase()}`
            }
        }
        history.push(url);
    }

    const redirectBILink = () => {
        window.open("https://app.powerbi.com/groups/me/apps/257047f9-5a20-4cc4-8fc8-46f7ba84d10c/reports/d5f328ee-17cb-44ba-805f-91ff77e4349b/ReportSection650d75efb75b006da952?ctid=ff9c7474-421d-4957-8d47-c4b64dec87b5&experience=power-bi", "_blank");
    }

    const loadCrops = (userObjectAux) => {
        setLoading(true);
        Api.get(`Dashboard/getAllYears`).then((result) => {
            if (result && result.data) {
                setCrops(result.data.response);
            }
            setLoading(false);
            if (userObjectAux != null && userObjectAux.selectedReportYearID)
                setSelectedCrop(userObjectAux.selectedReportYearID);
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const onClickButtonCompareVersion = (companyID, reportYearID, operationID, moduleID) => {
        history.push(`/compareVersion/companyID=${companyID}&reportYearID=${reportYearID}&operationID=${operationID}&moduleID=${moduleID}`);
    };

    const onClickButtonDiscussionDashboard = (companyID, reportYearID, operationID, moduleID) => {
        history.push(`/discussionDashboard/companyID=${companyID}&reportYearID=${reportYearID}&operationID=${operationID}&moduleID=${moduleID}`);
    };

    return (
        <Container>
            {
                <SessionTimeOut />
            }
            <div className="dashboard-header">
                <div className="container">
                    <div className="row">
                        <div className="dashboard-title col-md-8">
                            {userObject && <h1>{userObject.company.name}</h1>}
                        </div>
                        <div className="dashboard-filter col-md-4">
                            {
                                !loading &&
                                <select className="col-md-12" onChange={(x) => setSelectedCrop(x.target.value)} value={selectedCrop}>
                                    {
                                        crops ?
                                            crops.map(crop => {
                                                return <option key={crop.reportYearID} value={crop.reportYearID}>{crop.year}</option>
                                            })
                                            :
                                            <></>
                                    }
                                </select>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="container dashboard-body">
                {data && data.length > 0 ?
                    <>
                        {
                            loading ?
                                <TableLoading />
                                :
                                <>
                                    <div className="cards-header">
                                        <div className="col-md-3">Form</div>
                                        <div className="col-md-2 last-change-column" style={{ paddingRight: '55px' }}>Last change</div>
                                        <div className="col-md-2" style={{ paddingLeft: 32 }}>Sent by</div>
                                        <div className="col-md-1 year-version-column">Crop Year</div>
                                    </div>
                                    {
                                        data.map((operation) => {
                                            return <>
                                                <Card className="show" key={operation.operationID} item={operation.operationID}>
                                                    <div className={`card-body ${selectedAccordionOperation === operation.operationID ? 'collapsed' : ''}`} onClick={() => selectedAccordionOperation == operation.operationID ? setSelectedAccordionOperation(null) : setSelectedAccordionOperation(operation.operationID)}>
                                                        <div className="col-md-3">
                                                            <GoChevronDown />{operation.description}
                                                        </div>
                                                        <div className="col-md-2 last-change-column"></div>
                                                        <div className="col-md-2"></div>
                                                        <div className="col-md-1 year-version-column">
                                                            {operation.operationID != 4 ? operation.year : ""}
                                                        </div>
                                                        <div className="col-md-2">
                                                        </div>
                                                        <div className="col-md-1"></div>
                                                        <div className="col-md-1">
                                                            {
                                                                <div className={operation.percentage == 0 ?
                                                                    "status pending"
                                                                    :
                                                                    "status complete"}>
                                                                    {`${operation.percentage}%`}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={`card collapse ${selectedAccordionOperation === operation.operationID ? 'show' : ''}`}>
                                                        {operation.modules ?
                                                            operation.modules.length > 0 ?
                                                                operation.modules.map((module) =>
                                                                    <div className="card-row">
                                                                        <div className="card-body" item={module.moduleID} onClick={() => { onClickModuleRedirect(operation.description, module) }}>
                                                                            <div className="col-md-3">
                                                                                {module.tobaccoTypeID != null ? module.tobaccoTypeName : module.description}
                                                                            </div>
                                                                            <div className="col-md-2 last-change-column">
                                                                                {module.dateLastChange != null ? module.dateLastChange : ""}
                                                                            </div>
                                                                            <div className="col-md-2 year-version-column">
                                                                                {module.sentBy}
                                                                            </div>
                                                                            <div className="col-md-1 year-version-column" style={{ paddingLeft: '35px' }}>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <div className={module.statusNumber == 0 ?
                                                                                    "status notStarted"
                                                                                    :
                                                                                    ((module.statusNumber == 3 || module.statusNumber == 5 || module.statusNumber == 8 || module.statusNumber == 9) ?
                                                                                        "status rejected"
                                                                                        :
                                                                                        "status complete")}>
                                                                                    {module.status}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-1">
                                                                                {module.isFinalVersion != null ? <div className={!module.isFinalVersion ?
                                                                                    "status pending"
                                                                                    :
                                                                                    "status complete"}>{module.isFinalVersion ? "Final" : "Partial"}</div> : null}
                                                                            </div>
                                                                            <div className="col-md-1">
                                                                                {
                                                                                    <div className={module.percentage == 0 ?
                                                                                        "status pending"
                                                                                        :
                                                                                        "status complete"}>
                                                                                        {`${module.percentage}%`}
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-row-buttons-body">
                                                                            <button title="Compare Versions" className="card-row-button version-button" onClick={() => onClickButtonCompareVersion(userObject.companyID, operation.reportYearID, operation.operationID, module.moduleID)}>
                                                                                v{module.version}
                                                                            </button>
                                                                            <button title="Discussion Dashboard" className="card-row-button-last" onClick={() => onClickButtonDiscussionDashboard(userObject.companyID, operation.reportYearID, operation.operationID, module.moduleID)}>
                                                                                <MdChat size={20} />
                                                                            </button>
                                                                        </div>
                                                                    </div>)
                                                                :
                                                                <div className="no-record-found">
                                                                    <div className="col-md-12">No records found</div>
                                                                </div>
                                                            :
                                                            <TableLoading />
                                                        }
                                                    </div>
                                                </Card>
                                            </>
                                        })
                                    }
                                </>
                        }
                    </>
                    :
                    loading ?
                        <TableLoading />
                        :
                        <div>There is no data to list here</div>
                }
            </div>
            {
                userObject && userObject.isSourcingGroup == false ?
                    <div className="container dashboard-buttons">
                        <button type="button" onClick={() => redirectBILink()} className="btn btn-custom">see dashboard <GoLinkExternal size={17} /></button>
                    </div>
                    : <></>
            }

            <ModalReadConsent
                show={modalReadShow}
                onHide={() => setModalReadShow(false)}
                setModalReadShow={setModalReadShow}
                userObject={userObject}
            />

        </Container>
    );
}

export default DashboardLocal;
