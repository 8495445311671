import React, { useEffect, useState } from 'react';
import { Backdrop, ModalContainer, ModalForm, ModalButton } from './styles';
import { ContainerBody } from './styles';
import Api from '../../../services/api';
import { error_message, success_message } from '../../Toast';
import Select from 'react-select';
import { FaPlus } from 'react-icons/fa';

const CompoundRuleModalComponent = ({ isOpen, onClose, reportYears, reportYearID, modules, moduleID, logicOptions, rules, compoundModalType, editData, loadCompoundRules }) => {
  const [selectedReportYear, setSelectedReportYear] = useState(reportYearID);
  const [selectedModule, setSelectedModule] = useState(moduleID);
  const [compoundRuleID, setCompoundRuleID] = useState(null);
  const [selectedRules, setSelectedRules] = useState([]);
  const [selectedTargetRuleDiscussion, setSelectedTargetRuleDiscussion] = useState(null);
  const [selectedLogicOption, setSelectedLogicOption] = useState(null);
  const [discussion, setDiscussion] = useState('');
  const [compoundType, setCompoundType] = useState(compoundModalType);
  const [compoundRuleGroupIdentifier, setCompoundRuleGroupIdentifier] = useState(1);
  const [isEdit, setIsEdit] = useState(false);

  // Hooks for options
  const [reportYearList, setReportYearList] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const [logicOptionsList, setLogicOptionsList] = useState([]);
  const [ruleList, setRuleList] = useState([]);

  useEffect(() => {
    if (editData) {

      clearInputFields();
      setCompoundRuleGroupIdentifier(editData.groupIdentifier);
      setSelectedReportYear(editData.reportYearID);
      setSelectedModule(editData.moduleID);
      setCompoundRuleID(editData.compoundRuleRegisterID);
      setDiscussion(editData.discussion);
      setSelectedTargetRuleDiscussion(editData.targetRuleDiscussionID);
      setSelectedLogicOption(logicOptionsList.find(x => x.value == editData.logicOperator));
      setCompoundType(editData.compoundType);
      let usedRules = [];
      editData.rules.forEach(x => {
        var usedRule = ruleList?.find(y => y.value == x);
        usedRules.push(usedRule);
      });
      if (usedRules.length > 0) {
        setSelectedRules(usedRules);
      }
      setIsEdit(true);
    }
    else {
      clearInputFields();
      setIsEdit(false);
    }
  }, [editData]);

  useEffect(() => {
    setSelectedReportYear(reportYearID);
    setSelectedModule(moduleID);
  }, [reportYearID, moduleID]);

  useEffect(() => {
    setCompoundType(compoundModalType);
  }, [compoundModalType]);

  /* Selects Data */
  useEffect(() => {
    setModuleList(modules);
  }, [modules]);

  useEffect(() => {
    if (compoundType == 1) {
      const ruleData = rules?.map((rule, count) => ({
        value: rule.ruleRegisterID,
        label: `R${rule.ruleRegisterID}`
      }));
      setRuleList(ruleData);
    } else {
      const ruleData = rules?.map((rule, count) => ({
        value: rule.compoundRuleRegisterID,
        label: `CR${rule.compoundRuleRegisterID}`
      }));
      setRuleList(ruleData);
    }
  }, [rules]);

  useEffect(() => {
    setLogicOptionsList(logicOptions);
  }, [logicOptions]);

  useEffect(() => {
    setReportYearList(reportYears);
  }, [reportYears]);

  /* Select OnChange Events*/
  const handleReportYearChange = (selectedOption) => {
    setSelectedReportYear(selectedOption.value);
  };

  const handleLogicOptionChange = (selectedOption) => {
    setSelectedLogicOption(selectedOption);
  };

  const handleModuleChange = (selectedOption) => {
    setSelectedModule(selectedOption.value);
  };

  const handleRuleChange = (selectedOptions) => {
    setSelectedRules(selectedOptions);
  };

  const handleTargetRuleDiscussionChange = (selectedOptions) => {
    setSelectedTargetRuleDiscussion(selectedOptions);
  };

  /* Form Control */
  const clearInputFields = () => {
    setSelectedRules([]);
    setSelectedTargetRuleDiscussion('')
    setSelectedLogicOption(null);
    setDiscussion('');
  }

  /* Submit */
  const addRule = async (event) => {
    event.preventDefault();
    const rulesIDs = [];
    selectedRules.forEach((rule) => rulesIDs.push(rule.value));
    var dataObj = {
      "groupIdentifier": compoundRuleGroupIdentifier,
      "compoundRuleRegisterID": compoundRuleID,
      "reportYearID": selectedReportYear,
      "moduleID": selectedModule,
      "rules": rulesIDs,
      "targetRuleDiscussionID": selectedTargetRuleDiscussion?.value,
      "logicOperator": selectedLogicOption.value,
      "discussion": discussion,
      "compoundType": compoundType,

    };
    await Api.post("Rule/createOrUpdateCompoundRule", dataObj)
      .then(response => {
        if (response) {
          clearInputFields();
          success_message("New compound rule created!");
          loadCompoundRules(reportYearID, moduleID);
          onClose(true);
        } else {
          error_message("Error when trying to create the new rule!");
        }
      })
  }

  const handleClose = () => {
    clearInputFields();
    onClose();
  }

  if (!isOpen) return null;
  return (
    <Backdrop>
      <ModalContainer>
        <header className="modal-header">
          <h2>{editData ? (compoundModalType == 1 ? "Edit compound rule" : "Edit expression rule") : (compoundModalType == 1 ? "Create compound rule" : "Create expression rule")}</h2>
          <div className="remove-icon" onClick={(e) => { handleClose() }}>
            <FaPlus size={20} color="#112A61" />
          </div>
        </header>
        <ModalForm>
          <ContainerBody>
            <div className='row'>
              <label>
                Group Identifier
                <textarea className='input-text-field-cs' type="text" value={compoundRuleGroupIdentifier} onChange={(e) => setCompoundRuleGroupIdentifier(e.target.value)} />
              </label>
            </div>
            <div className='row' style={{ marginTop: '1rem' }}>
              <label>
                Report Year
                <Select
                  name="reportYear"
                  options={reportYearList}
                  className="basic-select"
                  classNamePrefix="select"
                  value={reportYearList?.find(x => x.value == selectedReportYear) || null}
                  onChange={handleReportYearChange}
                  isDisabled={true}
                />
              </label>
            </div>

            <div className='row select-field'>
              <label>
                Module
                <Select
                  name="module"
                  options={moduleList}
                  className="basic-select"
                  classNamePrefix="select"
                  value={moduleList?.find(x => x.value == selectedModule) || null}
                  onChange={handleModuleChange}
                  isDisabled={true}
                />
              </label>
            </div>
            <div className='row select-field'>
              <label>
                Rules
                <Select
                  isMulti
                  name="rules"
                  options={ruleList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={selectedRules}
                  onChange={handleRuleChange}
                />
              </label>
            </div>
            <div className='row select-field'>
              <label>
                Logic Operation
                <Select
                  name="logicOperation"
                  options={logicOptionsList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={selectedLogicOption}
                  onChange={handleLogicOptionChange}
                />
              </label>
            </div>
            {
              selectedRules &&
              <div className='row select-field'>
                <label>
                  Target Rule Discussion
                  <Select
                    name="targetRulesDiscussion"
                    options={selectedRules}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={selectedRules?.find(x => x?.value == selectedTargetRuleDiscussion)}
                    onChange={handleTargetRuleDiscussionChange}
                  />
                </label>
              </div>
            }
            <div className='row select-field'>
              <label>
                Discussion
                <textarea className='input-text-field' type="text" value={discussion} onChange={(e) => setDiscussion(e.target.value)} />
              </label>
            </div>

            <div className='row footer-buttons'>
              <ModalButton type="button" onClick={() => { handleClose(); }}>CANCEL</ModalButton>
              <ModalButton type="submit" onClick={addRule}>{isEdit ? "UPDATE" : "SAVE"}</ModalButton>
            </div>
          </ContainerBody>
        </ModalForm>
      </ModalContainer>
    </Backdrop>
  );
};

export default CompoundRuleModalComponent;
