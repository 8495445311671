import React from 'react';
import { useHistory } from "react-router-dom";

import { Container } from './styles';

const CancelLink = (props) => {
    const history = useHistory();

    return (
        <Container type="button" onClick={() => history.goBack()}>
           { props.desc != null ? props.desc : "Cancel"}
        </Container>
    );
}

export default CancelLink;
