//CRUDS
import CompanyForm from '../pages/Company/form';
import CompanyIndex from '../pages/Company/index';
import CurrencyTypeForm from '../pages/CurrencyType/form';
import CurrencyTypeIndex from '../pages/CurrencyType/index';
import Dashboard from '../pages/Dashboard';
import GroupForm from '../pages/Group/form';
import GroupIndex from '../pages/Group/index';
import KPIForm from '../pages/KPI/form';
import KPIIndex from '../pages/KPI/index';
import MailNotificationDeadlineFormIndex from '../pages/MailNotificationDeadlineForm';
import ModuleForm from '../pages/Module/form';
import ModuleIndex from '../pages/Module/index';
import OperationForm from '../pages/Operation/form';
import OperationIndex from '../pages/Operation/index';
import ReportYearForm from '../pages/ReportYear/form';
import ReportYearIndex from '../pages/ReportYear/index';
import SectionForm from '../pages/Section/form';
import SectionIndex from '../pages/Section/index';
import SubSectionForm from '../pages/SubSection/form';
import SubSectionIndex from '../pages/SubSection/index';
import SubSectionKPIEdit from '../pages/SubSectionKPI/edit';
import SubSectionKPIForm from '../pages/SubSectionKPI/form';
import SubSectionKPIIndex from '../pages/SubSectionKPI/index';
import TobaccoTypeForm from '../pages/TobaccoType/form';
import TobaccoTypeIndex from '../pages/TobaccoType/index';
import UnitMeasureForm from '../pages/UnitMeasure/form';
import UnitMeasureIndex from '../pages/UnitMeasure/index';
import UserForm from '../pages/User/form';
import UserIndex from '../pages/User/index';
import VolumeTypeForm from '../pages/VolumeType/form';
import VolumeTypeIndex from '../pages/VolumeType/index';
import YearForm from '../pages/Year/form';
import YearIndex from '../pages/Year/index';

import MigrateDashboard from '../pages/MigrateDashboard/index';

//Other Pages
import AdditionalDataDefaultValuesForm from '../pages/AdditionalDataDefaultValuesForm';
import AdditionalDataEmissionsFormIndex from '../pages/AdditionalDataEmissionsForm';
import AdditionalDataFormIndex from '../pages/AdditionalDataForm';
import DiscussionDashboard from '../pages/DiscussionsDashboard/index';
import IndexFolder from '../pages/DocumentUpload/IndexFolder';
import Export from '../pages/Export/index';
import ExportESGForm from '../pages/ExportESG/form';
import ExportESG from '../pages/ExportESG/index';
import ExportESGTemplateForm from '../pages/ExportESGTemplate/form';
import ExportESGTemplate from '../pages/ExportESGTemplate/index';
import FSMIntegrationLogsIndex from '../pages/FSMIntegrationLogs';
import ReadConsentIndex from '../pages/ReadConsent';
import ReadConsentDetailIndex from '../pages/ReadConsent/detail';
import Report from '../pages/Report/index';
import SignIn from '../pages/SignIn';
import ForgotPassword from '../pages/SignIn/forgotPassword';
import MFASignIn from '../pages/SignIn/mfaSignIn';
import VersionComparison from '../pages/VersionComparison/index';

// Simulation CRUDs
import EmissionEfficiencyFormIndex from '../pages/EmissionEfficiency';
import EmissionFactorFormIndex from '../pages/EmissionFactor';
import SimulationCrudForm from '../pages/SimulationCruds/Simulation/form';

//Simulation
import AutomatedReviewDashboard from '../pages/AutomatedReview';
import AutomatedReviewForm from '../pages/AutomatedReview/form';
import ExportAuditLog from '../pages/ExportAuditLog';
import RoleYearConfig from '../pages/RoleYearConfig';
import SimulationS3EIndex from '../pages/Simulation/SimulationS3E';
import SimulationS3EForm from '../pages/Simulation/SimulationS3E/form';
import RejectDashboard from '../pages/RejectDashboard';
import ExportLogging from '../pages/ExportLogging';

const ROLE_ADMIN = 'RoleAdmin';
const ROLE_INFO_HOLDER = 'RoleInfoHolder';
const ROLE_APPROVER = 'RoleApprover';
const ROLE_READ_ONLY = 'RoleReadOnly';
const ROLE_REVIEWER = 'RoleReviewer';
const ROLE_ADDITIONALDATA_INFOHOLDER = 'RoleVolumeDataInfoHolder';
const ROLE_ADDITIONALDATA_APPROVER = 'RoleVolumeDataApprover';
const ROLE_S3 = 'RoleS3';

const RoutesList = [
  {
    path: '/signin',
    label: 'SignIn',
    component: SignIn,
    onlyGlobal: false,
  },
  {
    path: '/Report/:operation/:moduleName/:tobaccoTypeID?',
    label: 'Report',
    component: Report,
    isPrivate: true,
    allowedRoles: [
      ROLE_ADMIN,
      ROLE_APPROVER,
      ROLE_INFO_HOLDER,
      ROLE_READ_ONLY,
      ROLE_REVIEWER,
    ],
    showInSidebar: false,
    onlyGlobal: false,
  },
  {
    path: '/signin/MultiFactorAuthentication',
    label: 'MFASignIn',
    component: MFASignIn,
    onlyGlobal: false,
  },
  {
    path: '/ForgotPassword/Token=:token&Id=:id',
    label: 'Forgot Password',
    component: ForgotPassword,
    onlyGlobal: false,
  },
  {
    path: '/populate',
    label: 'SignIn',
    component: SignIn,
    onlyGlobal: false,
  },
  {
    path: '/',
    label: 'Home',
    component: Dashboard,
    isPrivate: true,
    showInSidebar: true,
    onlyGlobal: false,
  },
  {
    path: '/s3EmissionsSimulator',
    label: 'S3 Emissions Simulator',
    component: SimulationS3EIndex,
    isPrivate: true,
    allowedRoles: [
      ROLE_ADMIN,
      ROLE_READ_ONLY,
      ROLE_S3,
      ROLE_INFO_HOLDER,
      ROLE_APPROVER,
    ],
    showInSidebar: true,
    onlyGlobal: true,
    onlyBATGroup: true,
  },
  {
    path: '/simulation/edit/:id',
    label: 'Edit Simulation',
    component: SimulationCrudForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN, ROLE_S3],
    onlyGlobal: true,
  },
  {
    path: '/s3EmissionsSimulator/new',
    label: 'New s3 Simulation',
    component: SimulationS3EForm,
    isPrivate: true,
    allowedRoles: [
      ROLE_ADMIN,
      ROLE_READ_ONLY,
      ROLE_S3,
      ROLE_INFO_HOLDER,
      ROLE_APPROVER,
    ],
    onlyGlobal: true,
    onlyBATGroup: true,
  },
  {
    path: '/s3EmissionsSimulator/edit/:id/:summary?/:jump?',
    label: 'Edit S3 Simulation',
    component: SimulationS3EForm,
    isPrivate: true,
    allowedRoles: [
      ROLE_ADMIN,
      ROLE_READ_ONLY,
      ROLE_S3,
      ROLE_INFO_HOLDER,
      ROLE_APPROVER,
    ],
    onlyGlobal: true,
    onlyBATGroup: true,
  },
  {
    path: '/Documents',
    label: 'Documents Folder',
    component: IndexFolder,
    isPrivate: true,
    allowedRoles: [
      ROLE_ADMIN,
      ROLE_APPROVER,
      ROLE_INFO_HOLDER,
      ROLE_READ_ONLY,
      ROLE_REVIEWER,
    ],
    showInSidebar: true,
    onlyGlobal: false,
    childrens: [
      {
        path: '/Documents/id=:documentFolderID',
        label: 'Documents Folder',
        component: IndexFolder,
        isPrivate: true,
        allowedRoles: [
          ROLE_ADMIN,
          ROLE_APPROVER,
          ROLE_INFO_HOLDER,
          ROLE_READ_ONLY,
          ROLE_REVIEWER,
        ],
        showInSidebar: false,
        onlyGlobal: false,
      },
    ],
  },
  {
    path: '/discussionDashboard',
    label: 'Discussion Dashboard',
    component: DiscussionDashboard,
    isPrivate: true,
    allowedRoles: [
      ROLE_ADMIN,
      ROLE_APPROVER,
      ROLE_INFO_HOLDER,
      ROLE_READ_ONLY,
      ROLE_REVIEWER,
    ],
    showInSidebar: true,
    onlyGlobal: false,
    childrens: [
      {
        path: '/discussionDashboard/companyID=:companyID&reportYearID=:reportYearID&operationID=:operationID&moduleID=:moduleID',
        label: 'Discussion Dashboard',
        component: DiscussionDashboard,
        isPrivate: true,
        allowedRoles: [
          ROLE_ADMIN,
          ROLE_APPROVER,
          ROLE_INFO_HOLDER,
          ROLE_READ_ONLY,
          ROLE_REVIEWER,
        ],
        showInSidebar: false,
        onlyGlobal: false,
      },
    ],
  },
  {
    path: '/compareVersion',
    label: 'Version Comparison',
    component: VersionComparison,
    isPrivate: true,
    allowedRoles: [
      ROLE_ADMIN,
      ROLE_APPROVER,
      ROLE_INFO_HOLDER,
      ROLE_READ_ONLY,
      ROLE_REVIEWER,
    ],
    showInSidebar: true,
    onlyGlobal: false,
    childrens: [
      {
        path: '/compareVersion/companyID=:companyID&reportYearID=:reportYearID&operationID=:operationID&moduleID=:moduleID',
        label: 'Version Comparison',
        component: VersionComparison,
        isPrivate: true,
        allowedRoles: [
          ROLE_ADMIN,
          ROLE_APPROVER,
          ROLE_INFO_HOLDER,
          ROLE_READ_ONLY,
          ROLE_REVIEWER,
        ],
        showInSidebar: false,
        onlyGlobal: false,
      },
    ],
  },
  {
    label: 'Exports',
    isMenu: true,
    isPrivate: true,
    allowedRoles: [
      ROLE_ADMIN,
      ROLE_APPROVER,
      ROLE_INFO_HOLDER,
      ROLE_READ_ONLY,
      ROLE_REVIEWER,
    ],
    showInSidebar: true,
    onlyGlobal: false,
    childrens: [
      {
        path: '/export',
        label: 'Masterfile',
        component: Export,
        isPrivate: true,
        allowedRoles: [
          ROLE_ADMIN,
          ROLE_APPROVER,
          ROLE_INFO_HOLDER,
          ROLE_READ_ONLY,
          ROLE_REVIEWER,
        ],
        showInSidebar: true,
      },
      {
        path: '/exportAuditLog',
        label: 'Audit Log',
        component: ExportAuditLog,
        isPrivate: true,
        allowedRoles: [
          ROLE_ADMIN,
          ROLE_APPROVER,
          ROLE_INFO_HOLDER,
          ROLE_READ_ONLY,
          ROLE_REVIEWER,
        ],
        showInSidebar: true,
      },
      {
        path: '/exportLogging',
        label: 'Administrative Logging',
        component: ExportLogging,
        isPrivate: true,
        allowedRoles: [
          ROLE_ADMIN,
        ],
        showInSidebar: true,
      },
      {
        path: '/exportESG',
        label: 'Master ESG',
        component: ExportESG,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN, ROLE_APPROVER],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/exportESG/form',
        label: 'Master ESG',
        component: ExportESGForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN, ROLE_APPROVER],
        onlyGlobal: true,
      },
    ],
  },
  // {
  //     path: "/reopenModules",
  //     label: "Reopen Modules",
  //     component: ReopenModule,
  //     isPrivate: true,
  //     allowedRoles: [ROLE_ADMIN],
  //     showInSidebar: true,
  //     onlyGlobal: false
  // },
  {
    label: 'Automated Review',
    isMenu: true,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN, ROLE_APPROVER, ROLE_REVIEWER],
    showInSidebar: true,
    onlyGlobal: false,
    childrens: [
      {
        path: '/automatedReview',
        label: 'Dashboard',
        component: AutomatedReviewDashboard,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN, ROLE_APPROVER, ROLE_REVIEWER],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/automatedReview/form',
        label: 'Register',
        component: AutomatedReviewForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN, ROLE_APPROVER, ROLE_REVIEWER],
        showInSidebar: true,
        onlyGlobal: true,
      },
    ],
  },
  {
    label: 'Thrive Registers',
    isMenu: true,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN, ROLE_APPROVER, ROLE_REVIEWER],
    showInSidebar: true,
    onlyGlobal: false,
    childrens: [
      {
        path: '/operation',
        label: 'Form',
        component: OperationIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/operation/edit/:id',
        label: 'Edit Form',
        component: OperationForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/operation/new',
        label: 'New Form',
        component: OperationForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/module',
        label: 'Module',
        component: ModuleIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/module/edit/:id',
        label: 'Edit Module',
        component: ModuleForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/module/new',
        label: 'New Module',
        component: ModuleForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/section',
        label: 'Section',
        component: SectionIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/section/edit/:id',
        label: 'Edit Section',
        component: SectionForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/section/new',
        label: 'New Section',
        component: SectionForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/subSection',
        label: 'Sub Section',
        component: SubSectionIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/subSection/edit/:id',
        label: 'Edit Subsection',
        component: SubSectionForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/subSection/new',
        label: 'New Subsection',
        component: SubSectionForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/kpi',
        label: 'KPI',
        component: KPIIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/kpi/edit/:id',
        label: 'KPI',
        component: KPIForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/kpi/new',
        label: 'KPI',
        component: KPIForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/subSectionKPI',
        label: 'Sub Section KPI',
        component: SubSectionKPIIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/subSectionKPI/edit/:id',
        label: 'Edit Sub Section KPI',
        component: SubSectionKPIEdit,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/subSectionKPI/new',
        label: 'New Subsection KPI',
        component: SubSectionKPIForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/answerTypeKPI',
        label: 'Answer Type KPI',
        component: UnitMeasureIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/answerTypeKPI/edit/:id',
        label: 'Edit Answer Type KPI',
        component: UnitMeasureForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/answerTypeKPI/new',
        label: 'New Answer Type KPI',
        component: UnitMeasureForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/tobaccoType',
        label: 'Tobacco Type',
        component: TobaccoTypeIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/tobaccoType/edit/:id',
        label: 'Edit Tobacco Type',
        component: TobaccoTypeForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/tobaccoType/new',
        label: 'New Tobacco Type',
        component: TobaccoTypeForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/reportYear',
        label: 'Report Year',
        component: ReportYearIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/reportYear/edit/:id',
        label: 'Edit Report Year',
        component: ReportYearForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/reportYear/new',
        label: 'New Report Year',
        component: ReportYearForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/year',
        label: 'Glidepath years',
        component: YearIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/migrate',
        label: 'Migrate Structure',
        component: MigrateDashboard,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/rejectDashboard',
        label: 'Reject Dashboard',
        component: RejectDashboard,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN, ROLE_APPROVER, ROLE_REVIEWER],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/year/edit/:id',
        label: 'Edit Year',
        component: YearForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/year/new',
        label: 'New Year',
        component: YearForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/notificationDeadlines',
        label: 'Email Notification Deadlines',
        component: MailNotificationDeadlineFormIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/exportESGTemplate',
        label: 'ESG Template',
        component: ExportESGTemplate,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN, ROLE_APPROVER],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/exportESGTemplate/Form',
        label: 'ESG Template',
        component: ExportESGTemplateForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: false,
      },
      {
        path: '/exportESGTemplate/Form/:id',
        label: 'ESG Template',
        component: ExportESGTemplateForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: false,
      },
      {
        path: '/roleYearConfig',
        label: 'Role Default Year',
        component: RoleYearConfig,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true,
        onlyGlobal: true,
      },
    ],
  },
  {
    label: 'Suppliers - Registers',
    isMenu: true,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
    showInSidebar: true,
    childrens: [
      {
        path: '/company',
        label: 'Supplier',
        component: CompanyIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/company/edit/:id',
        label: 'Edit Supplier',
        component: CompanyForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/company/new',
        label: 'New Supplier',
        component: CompanyForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/currencyType',
        label: 'Currency Type',
        component: CurrencyTypeIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/currencyType/edit/:id',
        label: 'Edit Currency Type',
        component: CurrencyTypeForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/currencyType/new',
        label: 'New Currency Type',
        component: CurrencyTypeForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/group',
        label: 'Group',
        component: GroupIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/group/edit/:id',
        label: 'Edit Group',
        component: GroupForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/group/new',
        label: 'New Group',
        component: GroupForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
    ],
  },
  {
    label: 'Users - Registers and Logs',
    isMenu: true,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN, ROLE_APPROVER],
    showInSidebar: true,
    childrens: [
      {
        path: '/user',
        label: 'User',
        component: UserIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN, ROLE_APPROVER],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/user/new',
        label: 'New User',
        component: UserForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/user/edit/:id',
        label: 'Edit User',
        component: UserForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/readConsent',
        label: 'Read Consent',
        component: ReadConsentIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true,
      },
      {
        path: '/readConsent/edit/:id',
        label: 'View Read Consent',
        component: ReadConsentDetailIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
      },
    ],
  },
  {
    label: 'Additional Data',
    isMenu: true,
    isPrivate: true,
    allowedRoles: [
      ROLE_ADMIN,
      ROLE_APPROVER,
      ROLE_ADDITIONALDATA_INFOHOLDER,
      ROLE_ADDITIONALDATA_APPROVER,
    ],
    showInSidebar: true,
    childrens: [
      {
        path: '/emissionFactor',
        label: 'Emission Factor',
        component: EmissionFactorFormIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN, ROLE_APPROVER],
        showInSidebar: true,
      },
      {
        path: '/emissionEfficiency',
        label: 'Emission Efficiency',
        component: EmissionEfficiencyFormIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN, ROLE_APPROVER],
        showInSidebar: true,
      },
      {
        path: '/additionalData',
        label: 'Additional Data - Volumes',
        component: AdditionalDataFormIndex,
        isPrivate: true,
        allowedRoles: [
          ROLE_ADMIN,
          ROLE_APPROVER,
          ROLE_ADDITIONALDATA_INFOHOLDER,
          ROLE_ADDITIONALDATA_APPROVER,
        ],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/additionalDataEmissions',
        label: 'Additional Data - Emissions',
        component: AdditionalDataEmissionsFormIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN, ROLE_APPROVER],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/volumeType',
        label: 'Volume Type',
        component: VolumeTypeIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN, ROLE_APPROVER],
        showInSidebar: true,
        onlyGlobal: true,
      },
      {
        path: '/volumeType/edit/:id',
        label: 'Edit Volume Type',
        component: VolumeTypeForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/volumeType/new',
        label: 'New Volume Type',
        component: VolumeTypeForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        onlyGlobal: true,
      },
      {
        path: '/subSectionKPI/defaultValues',
        label: 'Default Values',
        component: AdditionalDataDefaultValuesForm,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true,
        onlyGlobal: true,
      },
    ],
  },
  {
    path: '/fsmIntegrationLogs',
    label: 'FSMIntegration - Logs',
    component: FSMIntegrationLogsIndex,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
    showInSidebar: true,
    onlyGlobal: true,
  },
];

const RoutesListOld = [
  {
    path: '/',
    label: 'Home',
    component: Dashboard,
    isPrivate: true,
    showInSidebar: true,
  },
  {
    path: '/answerTypeKPI',
    label: 'Answer Type KPI',
    component: UnitMeasureIndex,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
    showInSidebar: true,
  },
  {
    path: '/answerTypeKPI/edit/:id',
    label: 'Edit Answer Type KPI',
    component: UnitMeasureForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/answerTypeKPI/new',
    label: 'New Answer Type KPI',
    component: UnitMeasureForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/currencyType',
    label: 'Currency Type',
    component: CurrencyTypeIndex,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
    showInSidebar: true,
  },
  {
    path: '/currencyType/edit/:id',
    label: 'Edit Currency Type',
    component: CurrencyTypeForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/currencyType/new',
    label: 'New Currency Type',
    component: CurrencyTypeForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/reportYear',
    label: 'Report Year',
    component: ReportYearIndex,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
    showInSidebar: true,
  },
  {
    path: '/reportYear/edit/:id',
    label: 'Edit Report Year',
    component: ReportYearForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/reportYear/new',
    label: 'New Report Year',
    component: ReportYearForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/kpi',
    label: 'KPI',
    component: KPIIndex,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
    showInSidebar: true,
  },
  {
    path: '/kpi/edit/:id',
    label: 'KPI',
    component: KPIForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/kpi/new',
    label: 'KPI',
    component: KPIForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/module',
    label: 'Module',
    component: ModuleIndex,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
    showInSidebar: true,
  },
  {
    path: '/module/edit/:id',
    label: 'Edit Module',
    component: ModuleForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/module/new',
    label: 'New Module',
    component: ModuleForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/operation',
    label: 'Form',
    component: OperationIndex,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
    showInSidebar: true,
  },
  {
    path: '/operation/edit/:id',
    label: 'Edit Form',
    component: OperationForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/operation/new',
    label: 'New Form',
    component: OperationForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/populate',
    label: 'SignIn',
    component: SignIn,
  },
  {
    path: '/section',
    label: 'Section',
    component: SectionIndex,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
    showInSidebar: true,
  },
  {
    path: '/section/edit/:id',
    label: 'Edit Section',
    component: SectionForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/section/new',
    label: 'New Section',
    component: SectionForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/signin',
    label: 'SignIn',
    component: SignIn,
  },
  {
    path: '/signin/MultiFactorAuthentication',
    label: 'MFASignIn',
    component: MFASignIn,
  },
  {
    path: '/subSection',
    label: 'Sub Section',
    component: SubSectionIndex,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
    showInSidebar: true,
  },
  {
    path: '/subSection/edit/:id',
    label: 'Edit Subsection',
    component: SubSectionForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/subSection/new',
    label: 'New Subsection',
    component: SubSectionForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/subSectionKPI',
    label: 'Sub Section KPI',
    component: SubSectionKPIIndex,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
    showInSidebar: true,
  },
  {
    path: '/subSectionKPI/edit/:id',
    label: 'Edit Sub Section KPI',
    component: SubSectionKPIEdit,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/subSectionKPI/new',
    label: 'New Subsection KPI',
    component: SubSectionKPIForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/company',
    label: 'Supplier',
    component: CompanyIndex,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
    showInSidebar: true,
  },
  {
    path: '/Report/:operation/:moduleName/:tobaccoTypeID?',
    label: 'Report',
    component: Report,
    isPrivate: true,
    allowedRoles: [
      ROLE_ADMIN,
      ROLE_APPROVER,
      ROLE_INFO_HOLDER,
      ROLE_READ_ONLY,
      ROLE_REVIEWER,
    ],
    showInSidebar: false,
  },
  {
    path: '/company/edit/:id',
    label: 'Edit Supplier',
    component: CompanyForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/company/new',
    label: 'New Supplier',
    component: CompanyForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/tobaccoType',
    label: 'Tobacco Type',
    component: TobaccoTypeIndex,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
    showInSidebar: true,
  },
  {
    path: '/tobaccoType/edit/:id',
    label: 'Edit Tobacco Type',
    component: TobaccoTypeForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/tobaccoType/new',
    label: 'New Tobacco Type',
    component: TobaccoTypeForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/volumeType',
    label: 'Volume Type',
    component: VolumeTypeIndex,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
    showInSidebar: true,
  },
  {
    path: '/volumeType/edit/:id',
    label: 'Edit Volume Type',
    component: VolumeTypeForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/volumeType/new',
    label: 'New Volume Type',
    component: VolumeTypeForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/user',
    label: 'User',
    component: UserIndex,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
    showInSidebar: true,
  },
  {
    path: '/user/new',
    label: 'New User',
    component: UserForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/user/edit/:id',
    label: 'Edit User',
    component: UserForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/export',
    label: 'Export',
    component: Export,
    isPrivate: true,
    allowedRoles: [
      ROLE_ADMIN,
      ROLE_APPROVER,
      ROLE_INFO_HOLDER,
      ROLE_READ_ONLY,
      ROLE_REVIEWER,
    ],
    showInSidebar: true,
  },
  {
    path: '/compareVersion',
    label: 'Version Comparison',
    component: VersionComparison,
    isPrivate: true,
    allowedRoles: [
      ROLE_ADMIN,
      ROLE_APPROVER,
      ROLE_INFO_HOLDER,
      ROLE_READ_ONLY,
      ROLE_REVIEWER,
    ],
    showInSidebar: true,
  },
  {
    path: '/compareVersion/companyID=:companyID&reportYearID=:reportYearID&operationID=:operationID&moduleID=:moduleID',
    label: 'Version Comparison',
    component: VersionComparison,
    isPrivate: true,
    allowedRoles: [
      ROLE_ADMIN,
      ROLE_APPROVER,
      ROLE_INFO_HOLDER,
      ROLE_READ_ONLY,
      ROLE_REVIEWER,
    ],
    showInSidebar: false,
  },
  {
    path: '/discussionDashboard',
    label: 'Discussion Dashboard',
    component: DiscussionDashboard,
    isPrivate: true,
    allowedRoles: [
      ROLE_ADMIN,
      ROLE_APPROVER,
      ROLE_INFO_HOLDER,
      ROLE_READ_ONLY,
      ROLE_REVIEWER,
    ],
    showInSidebar: true,
  },
  {
    path: '/discussionDashboard/companyID=:companyID&reportYearID=:reportYearID&operationID=:operationID&moduleID=:moduleID',
    label: 'Discussion Dashboard',
    component: DiscussionDashboard,
    isPrivate: true,
    allowedRoles: [
      ROLE_ADMIN,
      ROLE_APPROVER,
      ROLE_INFO_HOLDER,
      ROLE_READ_ONLY,
      ROLE_REVIEWER,
    ],
    showInSidebar: false,
  },
  {
    path: '/year',
    label: 'Glidepath years',
    component: YearIndex,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
    showInSidebar: true,
  },
  {
    path: '/year/edit/:id',
    label: 'Edit Year',
    component: YearForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/year/new',
    label: 'New Year',
    component: YearForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/group',
    label: 'Group',
    component: GroupIndex,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
    showInSidebar: true,
  },
  {
    path: '/group/edit/:id',
    label: 'Edit Group',
    component: GroupForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/group/new',
    label: 'New Group',
    component: GroupForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/readConsent',
    label: 'Read Consent',
    component: ReadConsentIndex,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
    showInSidebar: true,
  },
  {
    path: '/readConsent/edit/:id',
    label: 'View Read Consent',
    component: ReadConsentDetailIndex,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  } /*
    {
        path: "/emissionFactor",
        label: "Emission Factor",
        component: EmissionFactorFormIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true
    },
    {
        path: "/emissionEfficiency",
        label: "Emission Efficiency",
        component: EmissionEfficiencyFormIndex,
        isPrivate: true,
        allowedRoles: [ROLE_ADMIN],
        showInSidebar: true
    },
    */,
  {
    path: '/additionalData',
    label: 'Additional Data - Volumes',
    component: AdditionalDataFormIndex,
    isPrivate: true,
    allowedRoles: [
      ROLE_ADMIN,
      ROLE_ADDITIONALDATA_INFOHOLDER,
      ROLE_ADDITIONALDATA_APPROVER,
    ],
    showInSidebar: true,
  },
  {
    path: '/additionalDataEmissions',
    label: 'Additional Data - Emissions',
    component: AdditionalDataEmissionsFormIndex,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
    showInSidebar: true,
  },
  {
    path: '/notificationDeadlines',
    label: 'Email Notification Deadlines',
    component: MailNotificationDeadlineFormIndex,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
    showInSidebar: true,
  },
  {
    path: '/fsmIntegrationLogs',
    label: 'FSMIntegration - Logs',
    component: FSMIntegrationLogsIndex,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
    showInSidebar: true,
  },
  {
    path: '/ForgotPassword/Token=:token&Id=:id',
    label: 'Forgot Password',
    component: ForgotPassword,
  },
  {
    path: '/Documents',
    label: 'Documents Folder',
    component: IndexFolder,
    isPrivate: true,
    allowedRoles: [
      ROLE_ADMIN,
      ROLE_APPROVER,
      ROLE_INFO_HOLDER,
      ROLE_READ_ONLY,
      ROLE_REVIEWER,
    ],
    showInSidebar: true,
  },
  {
    path: '/Documents/id=:documentFolderID',
    label: 'Documents Folder',
    component: IndexFolder,
    isPrivate: true,
    allowedRoles: [
      ROLE_ADMIN,
      ROLE_APPROVER,
      ROLE_INFO_HOLDER,
      ROLE_READ_ONLY,
      ROLE_REVIEWER,
    ],
    showInSidebar: false,
  },
  {
    path: '/s3EmissionsSimulator',
    label: 'S3 Emissions Simulator',
    component: SimulationS3EIndex,
    isPrivate: true,
    allowedRoles: [
      ROLE_ADMIN,
      ROLE_APPROVER,
      ROLE_INFO_HOLDER,
      ROLE_READ_ONLY,
      ROLE_REVIEWER,
    ],
    showInSidebar: true,
  },
  {
    path: '/simulation/edit/:id',
    label: 'Edit Simulation',
    component: SimulationCrudForm,
    isPrivate: true,
    allowedRoles: [ROLE_ADMIN],
  },
  {
    path: '/s3EmissionsSimulator/new',
    label: 'New s3 Simulation',
    component: SimulationS3EForm,
    isPrivate: true,
    allowedRoles: [
      ROLE_ADMIN,
      ROLE_APPROVER,
      ROLE_INFO_HOLDER,
      ROLE_READ_ONLY,
      ROLE_REVIEWER,
    ],
  },
  {
    path: '/s3EmissionsSimulator/edit/:id/:summary?/:jump?',
    label: 'Edit S3 Simulation',
    component: SimulationS3EForm,
    isPrivate: true,
    allowedRoles: [
      ROLE_ADMIN,
      ROLE_APPROVER,
      ROLE_INFO_HOLDER,
      ROLE_READ_ONLY,
      ROLE_REVIEWER,
    ],
  },
];

export default RoutesList;
