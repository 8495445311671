import React, { useState } from 'react';

import { useEffect } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { Spreadsheet } from 'react-spreadsheet';
import Swal from 'sweetalert2';
import Button from '../../components/Button';
import TableLoading from '../../components/TableLoading';
import { error_message, info_message, success_message } from '../../components/Toast/index';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { Container, ContainerHeader } from './styles';

import optionsLogo from '../../assets/icons/options.svg';

const EmissionFactorFormIndex = () => {
    const columns = ["Fuel for Curing", "Reportable CO2e - Emissions Factor", "Biogenic CO2 (Other) - Emissions Factor", "S3 Simulator Divisor by 1000 disabled (True/False)", " "]

    //Crop Year Select Variables
    const [reportYears, setReportYears] = useState([]);
    const [selectedReportYearID, setSelectedReportYearID] = useState(null);
    const [rowLabels, setRowLabels] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [dataSheet, setDataSheet] = useState([[], [],]);

    const removeRow = (id) => {
        Swal.fire({
            title: 'Are you sure, you want delete the register?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonColor: '#102A63',
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                Api.post(`EmissionFactor/deleteEmissionFactor?id=${id}`).then((result) => {
                    if (selectedReportYearID) {
                        loadDataByCropYear(selectedReportYearID)
                    }
                })
            }
        })
    }

    const InputNumberEdit = ({ cell, onChange }) => {
        var rowID = cell.className.split("-");

        return (
            <div style={{ padding: '0.5rem 1rem', textAlign: "center" }} onClick={() => removeRow(rowID[1])}>
                <FaTrashAlt />
            </div>
        );
    };

    const InputOptions = ({ cell, onChange }) => {
        return (
            <div style={{ padding: '0.5rem 1rem' }}>
                <img src={optionsLogo} alt="" />
            </div>
        );
    };

    useEffect(() => {
        setIsLoading(true)
        loadCropYears()
    }, [])

    useEffect(() => {
        if (selectedReportYearID) {
            setIsLoading(true);
            loadDataByCropYear(selectedReportYearID)
        }
    }, [selectedReportYearID])

    const loadCropYears = (userAux) => {
        Api.get(`thriveValue/getAllCropsYears`).then((result) => {
            if (result && result.data) {
                setReportYears(result.data.response);
                setSelectedReportYearID(result.data.response[0].reportYearID);
                setIsLoading(false);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const onChangeReportYearID = (value) => {
        setSelectedReportYearID(value);
    }

    const loadDataByCropYear = (selectedReportYearID) => {
        setIsLoading(true);
        if (selectedReportYearID) {
            Api.get(`EmissionFactor/getAllEmissionFactorByReportYear?reportYearID=${selectedReportYearID}`).then((result) => {
                if (result && result.data) {
                    var resultList = result.data.response;
                    var newLine = [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }];
                    resultList.push(newLine);
                    setIsLoading(false);

                    try {
                        resultList.map((row) => {
                            var newRow = row.map((col) => {
                                var newCol = { ...col };
                                return newCol;
                            })
                            return newRow;
                        })

                        try {
                            var rowLabel = [];
                            resultList.map((row) => {
                                var id = row[0].value;
                                row[0] = { value: row[1].value }
                                row[1] = { value: row[2].value != "" || row[2].value.includes("-") ? eval(parseFloat(row[2].value.replaceAll(',', ''))).toLocaleString('en') + "" : "" };
                                row[2] = { value: row[3].value != "" || row[3].value.includes("-") ? eval(parseFloat(row[3].value.replaceAll(',', ''))).toLocaleString('en') + "" : "" };
                                row[3] = { value: row[4].value != "" ? row[4].value : "False" };
                                row[4] = { value: " ", readOnly: false, className: 'remove-' + id, onClick: () => removeRow(id) };
                                row[4].DataViewer = InputOptions;
                                row[4].DataEditor = InputNumberEdit;
                                rowLabel.push(id);
                            })
                            setRowLabels(rowLabel);
                        } catch { }
                        setDataSheet(resultList);
                    } catch (e) {
                        console.log(e)
                    }
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
    }

    const save = () => {
        var objAdditionalData = {
            reportYearID: selectedReportYearID,
            data: dataSheet,
            IDs: rowLabels
        }
        setIsLoading(true);

        Api.post(`EmissionFactor/newEmissionFactor`, objAdditionalData).then((result) => {
            if (result && result.data) {
                if (result.data.response == true) {
                    loadDataByCropYear(selectedReportYearID);
                    success_message("The data was saved!");
                }
                else {
                    setIsLoading(false);
                    info_message("No data to saved!");
                }
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const onChangeTable = (e) => {
        try {
            setDataSheet(e);
        } catch { }
    }

    const onBlurTable = () => {
        try {
            var nesw = dataSheet.map((row) => {
                var newRow = row.map((col) => {
                    var newCol = { ...col };
                    return newCol;
                })
                return newRow;
            })

            try {
                nesw.map((row) => {
                    var value1 = row[1].value != "" ? row[1].value : "";
                    var value2 = row[2].value != "" ? row[2].value : "";
                    var value3 = row[3].value != "" ? row[3].value : "";
                    row[1] = { value: value1.includes(" - ") || value1 == " " || value1 == "-" || value1 == null || value1 == undefined ? "" : value1 };
                    row[2] = { value: value2.includes(" - ") || value2 == " " || value2 == "-" || value2 == null || value2 == undefined ? "" : value2 };
                    row[3] = { value: value3 == " " || value3 == null || value3 == undefined ? "False" : value3 };
                })
            } catch { }
            setDataSheet(nesw);
        } catch (e) {
            console.log(e)
        }
    }

    const deleteAll = () => {
        Swal.fire({
            title: 'Are you sure, you want delete all?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonColor: '#102A63',
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                Api.post(`EmissionFactor/deleteAll?reportYearID=${selectedReportYearID}`).then((result) => {
                    if (result.data.response == true) {
                        if (selectedReportYearID != null) {
                            success_message("All values was deleted successfully!")
                            setTimeout(() => {
                                loadDataByCropYear(selectedReportYearID);
                            }, 2000)
                        }
                    } else {
                        error_message("Some error was happened. Please try again or contact the administrator.")
                    }
                })
            }
        })
    }

    return (
        <Container className="container">
            <ContainerHeader style={{ margin: '2rem 0' }}>
                <h3>Simulation Data - Emission Factor Form</h3>
                <div>
                    <Button style={{ marginRight: '15px' }} onClick={() => save()}>Save</Button>
                    <Button style={{ marginRight: '15px' }} onClick={() => deleteAll()}>Delete All</Button>
                </div>
            </ContainerHeader>
            <div className="row mt-2">
                <div className="col-md-6 mt-2">
                    <label className='label'>Select Reporting Year:</label>
                    <select className="col-md-6 mx-4 mt-2" onChange={(x) => onChangeReportYearID(x.target.value)} value={selectedReportYearID ? selectedReportYearID : ""}>
                        {reportYears && reportYears.map(reportYear => {
                            return <option key={reportYear.year} value={reportYear.reportYearID}>{reportYear.year}</option>
                        })}
                    </select>
                </div>
            </div>
            {!isLoading ?
                <Spreadsheet data={dataSheet} rowLabels={rowLabels} columnLabels={columns} onBlur={() => onBlurTable()} onChange={(e) => onChangeTable(e)} />
                :
                <TableLoading />
            }
        </Container>
    )
}

export default EmissionFactorFormIndex;
