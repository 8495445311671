import styled from 'styled-components';

export const Container = styled.div`
    h3{
        margin-top: 12px;
        padding-bottom: 25px;
    }

    .btn-primary {
        background-color: #102A63;
        border-color: #102A63; 
    }

    .btn-light {
        border: 1px solid #e4e4e4 !important;
    }

    p {
        margin-bottom: 5px;
    }

    input, select, textarea {
        background: transparent;
        color: #495057;
        background-color: #FFFFFF;
        flex: 1;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        width: 100%;

        &::placeholder {
        color: #e2e2e2;
        }

        &:focus{
        box-shadow: 0;
        }

        &:active{
        box-shadow: 0;
        }

        &:focus-visible{
        box-shadow: 0;
        outline: none;
        }

        &:disabled{
        background-color: #e9ecef;
        }

        option{
        background-color: #FFFFFF;
        }
    }
`

export const ContainerHeader = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-bottom: 25px;
`;

export const Title = styled.h3`
    color: #27b5e5;
    text-align: left;
    font: normal normal bold 22px Segoe UI;
    letter-spacing: 1.1px;
`;

export const Actions = styled.div`
    display: flex;
`;


export const Card = styled.div`
    background-color: transparent;
    border: 0;

    .card-body{
        display: flex;
        padding: 0;
        background-color: #FFFFFF;
        filter: drop-shadow(2px 2px 5px rgb(0 0 0 / 0.1));
        color: #102A63;
        border-radius: 8px;
        font-weight: bold;
        padding: 10px 27px 10px 27px;
        margin-bottom: 17px;
        animation: fadeIn;
        animation-duration: 1s;

        .show{
            animation: fadeIn;
            animation-duration: 1s;
        }

        .form-check-label {
            font-weight: 500;
            font-size: 13px;
        }

        &.collapsed{
            color: #102A63;

            svg{
                color: #102A63;
                transform: rotate(180deg);
            }

            .status{
                color: black;
            }
        }

        .status{
            padding-top: 0;
            text-align: center;
            display: flex;
            justify-content: center;
            text-align: center;
            border-radius: 13px;
            padding: 4px 5px 4px 5px;
            font-size: 12px;
            margin-right: 5px;

            &.complete{
                background-color: #ABF0B0;
            }

            &.pending{
                background-color: #FFDA7E;
            }

            &.rejected{
                background-color: #FB9D9D;
            }

            &.notStarted{
                background-color: #e6e6e6;
            }
        }
    }

    .card{
        padding: 0px;
        background-color: transparent;
        border: 0;
        font-size: 14px;

        .card-row{
            display: flex;

            .card-body{
                z-index: 1;
                border-radius: 8px 0 0 8px;
                filter: drop-shadow(2px 2px 5px rgb(0 0 0 / 0.1));
                box-shadow: none;
            }
            
            .card-row-button, .card-row-button-last{
                height: 46px;
                width: 46px;
                background-color: #102A63;
                color: white;
                border: 0;
                padding: 8px 10px 10px 10px;
                opacity: 1;
                margin-right: 5px;
                font-weight: bold;

                &:hover{
                    opacity: 0.6;
                }
            }

            .card-row-button-last{
                border-radius: 0 8px 8px 0;
                margin: 0;
            }

            .card-row-buttons-body{
                background: white;
                margin-bottom: 17px;
            }
        }
    }
`;