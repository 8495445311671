import React from 'react';
import TableCrud from '../../components/TableCrud';

const OperationIndex = () => {
    const columns = [
        {
            label: "Form Description",
            name: "description"
        },
        {
            label: "Guidance",
            name: "guidance"
        },
        {
            label: "Order",
            name: "order"
        },
        {
            label: "Report Year",
            name: "reportYear.year"
        },
    ]

    return (
        <TableCrud title={"Form"} table={"operation"} columns={columns}/>
    );
}

export default OperationIndex;
