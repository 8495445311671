import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Button from '../../components/Button';
import TableLoading from '../../components/TableLoading/index';
import { error_message } from '../../components/Toast';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { Card, Container } from './styles';

const ExportESGForm = () => {
    const history = useHistory();

    const [operation, setOperation] = useState("");
    const [module, setModule] = useState("");

    const [name, setName] = useState("");
    const [templates, setTemplates] = useState([]);
    const [templateSelected, setTemplateSelected] = useState(null);
    const [cl, setCL] = useState(false);
    const [tg, setTG] = useState(false);
    const [fu, setFU] = useState(false);
    const [wg, setWG] = useState(false);

    //Company Select Variables
    const [groups, setGroups] = useState([]);
    const [relationships, setRelationships] = useState([]);

    //Company Select Variables
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState([]);

    //Crop Year Select Variables
    const [cropYears, setCropYears] = useState([]);
    const [selectedReportYears, setSelectedReportYears] = useState([]);

    //KPI Select Variables
    const [kpi, setKpi] = useState([]);
    const [selectedKpi, setSelectedKpi] = useState([]);

    const [loading, setLoading] = useState(true);
    const [send, setSend] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        Api.get(`ReportYear/getAllYears`).then((result) => {
            result && result.data && setCropYears([{
                year: "Select all",
                year: "*"
            }, ...result.data.response]);
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });

        Api.get(`exportESG/getGroupCompany`).then((result) => {
            const uniqueCompanies = new Map();
            const uniqueGroups = new Map();
            const groupToCompanyRelationships = new Map();

            // Iterar sobre os dados e adicionar empresas e grupos únicos aos mapas
            result.data.response.forEach(item => {
                const companyKey = `${item.companyID}`;
                const groupKey = `${item.groupID}`;

                // Verificar se a empresa já existe no mapa
                if (!uniqueCompanies.has(companyKey)) {
                    uniqueCompanies.set(companyKey, {
                        companyID: item.companyID,
                        name: item.company.name,
                    });
                }

                // Verificar se o grupo já existe no mapa
                if (!uniqueGroups.has(groupKey)) {
                    uniqueGroups.set(groupKey, {
                        groupID: item.groupID,
                        name: item.group.name,
                    });
                }

                // Adicionar o relacionamento entre grupo e empresa
                const relationshipKey = `${groupKey}-${companyKey}`;
                groupToCompanyRelationships.set(relationshipKey, {
                    groupID: item.groupID,
                    companyID: item.companyID,
                });
            });

            // Converter os mapas em arrays e ordená-los pelo nome
            const uniqueCompaniesArray = Array.from(uniqueCompanies.values()).sort((a, b) => a.name.localeCompare(b.name));
            const uniqueGroupsArray = Array.from(uniqueGroups.values()).sort((a, b) => a.name.localeCompare(b.name));
            const groupToCompanyRelationshipsArray = Array.from(groupToCompanyRelationships.values());

            setGroups(uniqueGroupsArray);
            setCompanies(uniqueCompaniesArray);
            setRelationships(groupToCompanyRelationshipsArray);
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });

        Api.post('exportESG/getTemplates', null).then((result) => {
            result && result.data && setTemplates(result.data.response);
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });

        Api.get('exportESG/getAllKPIDistinct').then((result) => {
            result && result.data && setKpi(result.data.response);
            setLoading(false);
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const sendExport = () => {
        if (name == "") {
            error_message("Please provide a description for the export.");
            return;
        }

        if (statusFiltered == null) {
            error_message("Please select a status.");
            return;
        }

        if (selectedReportYears.length == 0) {
            error_message("Please select at least one year.");
            return;
        }

        if (selectedKpi.length == 0) {
            error_message("Please select at least one KPI.");
            return;
        }

        setLoading(true);

        Api.post('exportESG/downloadExcelESG', {
            name: name,
            status: statusFiltered.value,
            reportYear: selectedReportYears.map(x => x.value),
            suppliers: selectedCompany.map(x => x.value),
            kpis: selectedKpi.map(x => x),
            cl: cl,
            tg,
            fu,
            wg
        }).then((r) => {
            if (r.data.response) {
                history.push(`/exportESG`)
            }
            setLoading(false);
        }).catch((error) => {
            exceptionNotificationAPI(error);
            setLoading(false);
        });
    }

    const handleCheckboxChange = (value) => {
        if (selectedKpi.includes(value)) {
            setSelectedKpi(selectedKpi.filter((selectedItem) => selectedItem != value));
        } else {
            setSelectedKpi([...selectedKpi, value]);
        }
    }

    const handleChangeTemplate = (id) => {
        var getKpis = templates.find(x => x.exportESGTemplateID == id);
        if (getKpis) {
            const kpiArray = getKpis.kpis.split(";");
            setSelectedKpi(kpiArray);
        }
    }

    const selectAllKpisInModule = () => {
        const kpisInModule = kpi.filter(x => x.operationName === operation).map(x => x.modules).flat().find(x2 => x2.moduleName === module)?.sections.map(x3 => x3.subSections).flat().map(x4 => x4.subSectionKpis).flat().map(x5 => x5.refNumber);
        const uniqueKpisInModule = [...new Set(kpisInModule)];
        const updatedSelectedKpi = [...new Set([...selectedKpi, ...uniqueKpisInModule]),];
        setSelectedKpi(updatedSelectedKpi);
    };

    const addUniqueCompaniesFromRelationships = (groupID) => {
        const filteredRelationships = relationships.filter(
            (relationship) => relationship.groupID == groupID
        );

        // Criar um conjunto a partir de selectedCompany para rastrear companyID
        const selectedCompanyIDs = new Set(selectedCompany.map((company) => company.value));

        // Mapear as empresas únicas e adicionar ao array
        const uniqueCompaniesArray = [];

        filteredRelationships.forEach((relationship) => {
            const companyID = relationship.companyID;

            // Verificar se o companyID já está em selectedCompany
            if (!selectedCompanyIDs.has(companyID)) {
                selectedCompanyIDs.add(companyID);
                const company = companies.find((company) => company.companyID == companyID);
                uniqueCompaniesArray.push({
                    value: company.companyID,
                    label: company.name,
                });
            }
        });

        // Combinar empresas únicas com as selecionadas
        const updatedSelectedCompany = [...selectedCompany, ...uniqueCompaniesArray];

        // Definir o estado selectedCompany com o array atualizado
        setSelectedCompany(updatedSelectedCompany);
    };

    const status = [
        { value: 0, label: "Select all" },
        { value: 1, label: "Saved as Draft" },
        { value: 2, label: "Sent to Local Approval" },
        { value: 3, label: "Reopened by Local Approver" },
        { value: 4, label: "Sent to Global Approval" },
        { value: 5, label: "Reopened by Global Approver" },
        { value: 6, label: "Version Approved" },
        { value: 7, label: "Approved" },
        { value: 8, label: "Reactivated by Admin" },
    ];
    const [statusFiltered, setStatusFiltered] = useState(10);

    return (
        <Container className="container">
            <div className="row mt-2">
                {!loading && <>
                    <Card className="show">
                        <div className='card'>
                            <div className="card-row">
                                <div className='card-body'>
                                    <div className="row my-2" style={{ width: "100%" }}>
                                        <h4 style={{ fontWeight: 900 }}>Export ESG</h4>

                                        <hr />

                                        <div className="col-12 pl-0 pr-0 mt-2">
                                            <p>Description</p>
                                            <input
                                                autoComplete={"off"}
                                                onChange={(x) => setName(x.target.value)}
                                                type={"text"}
                                                defaultValue={name}
                                                placeholder={"Description"}
                                            />
                                        </div>

                                        <div className="col-12 pl-0 pr-0 mt-4">
                                            <p>Select an group</p>
                                            {groups.map(x => {
                                                return <button key={x.name + Math.random()} onClick={() => addUniqueCompaniesFromRelationships(x.groupID)} className={`btn btn-light`} style={{ marginRight: 5, marginBottom: 5 }}>{x.name}</button>
                                            })}
                                        </div>

                                        <div className="col-12 pl-0 pr-0 mt-4">
                                            <p>Supplier(s)</p>
                                            <Select
                                                options={companies.map((o) => {
                                                    var data = {
                                                        value: o.companyID,
                                                        label: o.name
                                                    }
                                                    return data;
                                                })}
                                                loading={loading}
                                                isDisabled={loading}
                                                placeholder={"Select supplier(s)"}
                                                value={selectedCompany}
                                                isMulti
                                                hideSelectedOptions={false}
                                                closeMenuOnSelect={false}
                                                onChange={(selected) => {
                                                    if (selected !== null && selected.length > 0 && selected[selected.length - 1].value === "*") {
                                                        var com = companies.map((o) => {
                                                            var data = {
                                                                value: o.companyID,
                                                                label: o.name
                                                            }
                                                            if (data != undefined && data.value != "*")
                                                                return data;
                                                        })
                                                        setSelectedCompany(com.filter(a => a != undefined));
                                                    } else {
                                                        setSelectedCompany(selected);
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div className="col-12 pl-0 pr-0 mt-4">
                                            <p>Report Year(s)</p>
                                            <Select
                                                options={cropYears.map((o) => {
                                                    var data = {
                                                        value: o.year,
                                                        label: o.year == "*" ? "Select all" : o.year
                                                    }
                                                    return data;
                                                })}
                                                loading={loading}
                                                isDisabled={loading}
                                                placeholder={"Select report year(s)"}
                                                value={selectedReportYears}
                                                isMulti
                                                hideSelectedOptions={false}
                                                closeMenuOnSelect={false}
                                                onChange={(selected) => {
                                                    if (selected !== null && selected.length > 0 && selected[selected.length - 1].value === "*") {
                                                        var crops = cropYears.map((o) => {
                                                            var data = {
                                                                value: o.year,
                                                                label: o.year == "*" ? "Select all" : o.year
                                                            }
                                                            if (data != undefined && data.value != "*")
                                                                return data;
                                                        });
                                                        setSelectedReportYears(crops.filter(a => a != undefined));
                                                    } else {
                                                        setSelectedReportYears(selected);
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div className="col-12 pl-0 pr-0 mt-4">
                                            <p>Status</p>
                                            <Select
                                                options={status}
                                                loading={loading}
                                                isDisabled={loading}
                                                //value={statusFiltered}
                                                defaultValue={{ value: 0, label: "Select all" }}
                                                closeMenuOnSelect={false}
                                                onChange={(selected) => setStatusFiltered(selected)}
                                            />
                                        </div>

                                        <div className="col-12 pl-0 pr-0 mt-4" onClick={() => setCL(!cl)}>
                                            <p>Include Child Labour Detailed</p>
                                            <input
                                                onChange={(x) => setCL(!cl)}
                                                style={{ width: 15 }}
                                                type={"checkbox"}
                                                checked={cl}
                                                placeholder={"Child Labour Detailed"}
                                            />
                                        </div>

                                        <div className="col-12 pl-0 pr-0 mt-4" onClick={() => setTG(!tg)}>
                                            <p>Include Tobacco Growing Regions</p>
                                            <input
                                                onChange={(x) => setTG(!tg)}
                                                style={{ width: 15 }}
                                                type={"checkbox"}
                                                checked={tg}
                                                placeholder={"Tobacco Growing Regions Detailed"}
                                            />
                                        </div>

                                        <div className="col-12 pl-0 pr-0 mt-4" onClick={() => setFU(!fu)}>
                                            <p>Include Fuels Used to Cure Tobacco </p>
                                            <input
                                                onChange={(x) => setFU(!fu)}
                                                style={{ width: 15 }}
                                                type={"checkbox"}
                                                checked={fu}
                                                placeholder={"Fuels Used to Cure Tobacco Detailed"}
                                            />
                                        </div>

                                        <div className="col-12 pl-0 pr-0 mt-4" onClick={() => setWG(!wg)}>
                                            <p>Include Wood growing States or Province</p>
                                            <input
                                                onChange={(x) => setWG(!wg)}
                                                style={{ width: 15 }}
                                                type={"checkbox"}
                                                checked={wg}
                                                placeholder={"Wood growing States or Province Detailed"}
                                            />
                                        </div>


                                        <div className="col-12 pl-0 pr-0 mt-4">
                                            <p>Select an template</p>
                                            {templates.map(x => {
                                                return <button key={x.name + Math.random()} className={`btn ${x.exportESGTemplateID == templateSelected ? 'btn-primary' : 'btn-light'}`} onClick={() => { setTemplateSelected(x.exportESGTemplateID); handleChangeTemplate(x.exportESGTemplateID); }} style={{ marginRight: 5, marginBottom: 5 }}>{x.name}</button>
                                            })}
                                        </div>

                                        <div className="col-12 pl-0 pr-0 mt-4">
                                            {selectedKpi.length > 0 && <p style={{ marginBottom: 10 }}>
                                                <span>{selectedKpi.length} selected KPIs</span> - <span className="text-primary" onClick={() => setSelectedKpi([])}>CLEAR</span><br />
                                                {selectedKpi.map(x => <span className="badge rounded-pill" key={x + Math.random()} style={{ backgroundColor: "rgb(16 42 99)", color: "#fff", marginRight: 5 }}>{x}</span>)}
                                            </p>}
                                            {kpi.map(x => {
                                                return <button key={x.operationName + Math.random()} className={`btn ${x.operationName == operation ? 'btn-primary' : 'btn-light'}`} onClick={() => { setOperation(x.operationName); setModule("") }} style={{ marginRight: 5 }}>{x.operationName}</button>
                                            })}
                                        </div>

                                        {operation != "" && <div className="col-12 pl-0 pr-0 mt-2">
                                            {kpi.filter(x => x.operationName == operation).map(x => {
                                                return x.modules.map(x2 => {
                                                    return <button key={x2.moduleName + Math.random()} className={`btn ${x2.moduleName == module ? 'btn-primary' : 'btn-light'}`} onClick={() => setModule(x2.moduleName)} style={{ marginRight: 5 }}>{x2.moduleName}</button>
                                                })
                                            })}
                                        </div>}

                                        {module != "" && <div className="col-12 pl-0 pr-0 mt-2">
                                            <hr className="my-4" />
                                            <div className="col-12 pl-0 pr-0 mt-2 mb-4">
                                                <button className="btn btn-light" onClick={selectAllKpisInModule}>Select all module KPI's</button>
                                            </div>

                                            {kpi.filter(x => x.operationName == operation).map(x => {
                                                return x.modules.filter(x2 => x2.moduleName == module).map(x2 => {
                                                    return x2.sections.map(x3 => {
                                                        return <div className="col-12 my-2" key={x3.sectionName + Math.random()}>
                                                            <h5>{x3.sectionName}</h5>
                                                            {x3.subSections.map(x4 => {
                                                                return <div className="col-12 my-2" key={x4.subSectionName + Math.random()}>
                                                                    <span>{x4.subSectionName}</span>
                                                                    <div className="row">
                                                                        {x4.subSectionKpis.map(x5 => {
                                                                            return <div className="col-3 my-3" key={x5.refNumber + Math.random()} onClick={() => handleCheckboxChange(x5.refNumber)}>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input" type="checkbox" style={{ width: "5px", padding: "6px" }} id={x5.refNumber} onChange={() => handleCheckboxChange(x5.refNumber)} checked={selectedKpi.includes(x5.refNumber)} />
                                                                                    <label className="form-check-label" htmlFor={x5.refNumber}>{x5.refNumberName}</label>
                                                                                </div>
                                                                            </div>
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            })}
                                                            <hr />
                                                        </div>
                                                    })
                                                })
                                            })}
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                    <div className="col-12 pl-0 pr-0 mt-2">
                        <Button className="btn btn-primary" style={{ float: "right", marginLeft: 10, marginTop: 10, marginBottom: 10 }} onClick={() => sendExport()}>Export</Button>
                        <Button className="btn btn-primary" style={{ float: "right", backgroundColor: "#B0B0BB", marginLeft: 0, marginTop: 10, marginBottom: 10 }} onClick={() => history.push("/exportESG")}>Back</Button>
                    </div>
                </>}

                {loading && <div className="mt-2"><TableLoading /></div>}
            </div>
        </Container >
    );
}

export default ExportESGForm;
