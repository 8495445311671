import React from 'react';
import TableCrud from '../../components/TableCrud';

const GroupIndex = () => {
    const columns = [
        {
            label: "Name",
            name: "name"
        }
    ]

    return (
        <TableCrud title={"Group"} table={"group"} columns={columns}/>
    );
}

export default GroupIndex;
