import React, { useEffect, useState } from 'react';

import Api, { exceptionNotificationAPI } from '../../services/api';
import { useParams } from 'react-router-dom';
import FormCrud from '../../components/FormCrud';

const ModuleForm = () => {
    const [operationsOptions, setOperationsOptions] = useState(null);
    const [operations, setOperations] = useState(null);
    const [reportOptions, setReportOptions] = useState();
    const [selectedReportYearID, setSelectedReportYearID] = useState();
    const { id } = useParams();
    const activeOptions = [
        { value: true, text: "Yes" },
        { value: false, text: "No" }
    ];

    const filterOperations = async () => {
        if (operations != null) {
            var filteredModules = operations.filter(x => x.reportYearID == selectedReportYearID);
            const options = filteredModules.map(item => ({ value: item.operationID, text: item.description, reportYearID: item.reportYearID }));
            setOperationsOptions(options);
        }
    }

    const selectReportYear = async (e) => {
        setSelectedReportYearID(e.getFieldRef("reportYearID").current.value);
    }

    const fields = [
        {
            label: "Report Year",
            name: "reportYearID",
            type: "select",
            options: reportOptions,
            onChanged: selectReportYear
        },
        {
            label: "Form",
            name: "operationID",
            type: "select",
            options: operations?.filter(x => x.reportYearID == selectedReportYearID)
        },
        {
            label: "Module Description",
            name: "description",
            type: "input",
            disabled: false
        },
        {
            label: "Guidance",
            name: "guidance",
            type: "textarea",
            required: false
        },
        {
            label: "Order",
            name: "order",
            type: "number"
        },
        {
            label: "Is Active",
            name: "isActive",
            type: "select",
            options: activeOptions
        },
        {
            label: "Is FSM Module?",
            name: "isFSMModule",
            type: "select",
            options: activeOptions
        },
        {
            label: "Show Add Zeros button?",
            name: "isHandleAddZeros",
            type: "select",
            options: activeOptions
        },
    ];


    const loadData = async () => {
        Api.get('Operation/getAll').then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.operationID, text: item.description, reportYearID: item.reportYearID }));
                setOperations(options)
                setOperationsOptions(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadReportYear = async () => {
        Api.get('ReportYear/getAll').then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.reportYearID, text: `${item.year}` }));

                if(!id) setSelectedReportYearID(options[0].value)
                setReportOptions(options);
            }

        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    useEffect(() => {
        if (operations != null && selectedReportYearID) {
            var filteredModules = operations.filter(x => x.reportYearID == selectedReportYearID);
            const options = filteredModules;
            setOperationsOptions(options);
        }
    }, [selectedReportYearID, operations]);

    useEffect(() => {
        loadData();
        loadReportYear();
        filterOperations();
    }, []);


    return (
        operations != null  ? <FormCrud formName={"module"} title={"Module"} fields={fields} setSelectedReportYearID={setSelectedReportYearID}/> : <></>
    );
}

export default ModuleForm;
