import React, { useState, useEffect } from 'react';

import { ContainerBody } from './styles';
import AutomatedReviewRuleTable from './AutomatedReviewTable/index';
import CompoundRuleModalComponent from './CompoundRuleModal';
import SimpleRuleModalComponent from './SimpleRuleModal/index';
import Api from '../../services/api';
import { error_message, success_message } from '../Toast';
import { FaPlus } from 'react-icons/fa';
import Swal from 'sweetalert2';

const simpleRuleTableHead = [
  { width: '5%', description: 'ID' },
  { width: '5%', description: 'Rule' },
  { width: '5%', description: 'KPI' },
  { width: '5%', description: 'Column' },
  { width: '15%', description: 'Rule' },
  { width: '10%', description: 'Lower Limit' },
  { width: '10%', description: 'Upper Limit' },
  { width: '5%', description: 'Operator' },
  { width: '10%', description: 'Value' },
  { width: '10%', description: 'LinkedKPI' },
  { width: '10%', description: 'Discussion' },
];

const compoundRuleTableHead = [
  { width: '10%', description: 'ID' },
  { width: '10%', description: 'C. Rule' },
  { width: '50%', description: 'Rules' },
  { width: '20%', description: 'Discussion' },
  { width: '20%', description: 'Logic relation' },
];

const expressionCompoundRuleTableHead = [
  { width: '10%', description: 'ID' },
  { width: '10%', description: 'C. Rule' },
  { width: '50%', description: 'Rules' },
  { width: '20%', description: 'Discussion' },
  { width: '20%', description: 'Logic relation' },
];

const AutomatedReviewRules = ({
  reportYearList,
  reportYearID,
  moduleList,
  moduleID,
  subSectionKpisList,
  typeOptions,
  typeValues,
  rules,
  logicOperators,
  simpleRuleList,
  loadSimpleRules,
  compoundRuleList,
  loadCompoundRules,
  logicOptions,
}) => {
  const [isRuleModalOpen, setIsRuleModalOpen] = useState(false);
  const [isCompoundRuleModalOpen, setIsCompoundRuleModalOpen] = useState(false);
  const [
    isExpressionCompoundRuleModalOpen,
    setIsExpressionCompoundRuleModalOpen,
  ] = useState(false);
  const [simpleRuleEdit, setSimpleRuleEdit] = useState(null);
  const [compoundRuleEdit, setCompoundRuleEdit] = useState(null);

  const handleSimpleEdit = ruleData => {
    setSimpleRuleEdit(ruleData);
    setIsRuleModalOpen(true);
  };

  const handleSimpleDelete = async ruleData => {
    Swal.fire({
      title: 'Delete rule',
      text: 'Are you sure you want do remove this rule?',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonColor: '#112A61',
      denyButtonColor: '#B0B0BB',
      denyButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
      showCloseButton: true,
    }).then(async result => {
      if (result.isConfirmed) {
        await Api.post(
          `Rule/deleteSimpleRule?ruleID=${ruleData.ruleRegisterID}`,
        ).then(response => {
          if (response) {
            success_message('Rule deleted!');
            loadSimpleRules(reportYearID, moduleID);
          } else {
            error_message('Error when trying to delete rule!');
          }
        });
      }
    });
  };

  const handleCompoundEdit = ruleData => {
    setCompoundRuleEdit(ruleData);
    if (ruleData.compoundType == 1) {
      setIsCompoundRuleModalOpen(true);
    } else {
      setIsExpressionCompoundRuleModalOpen(true);
    }
  };

  const handleCompoundDelete = async ruleData => {
    Swal.fire({
      title: 'Delete rule',
      text: 'Are you sure you want do remove this rule?',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonColor: '#112A61',
      denyButtonColor: '#B0B0BB',
      denyButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
      showCloseButton: true,
    }).then(async result => {
      if (result.isConfirmed) {
        await Api.post(
          `Rule/deleteCompoundRule?ruleID=${ruleData.compoundRuleRegisterID}`,
        ).then(response => {
          if (response) {
            success_message('Rule deleted!');
            loadCompoundRules(reportYearID, moduleID);
            loadSimpleRules(reportYearID, moduleID);
          } else {
            error_message('Error when trying to delete rule!');
          }
        });
      }
    });
  };

  return (
    <ContainerBody>
      <div className="container-body" style={{ marginTop: '-10px' }}>
        <div className="row rule-table">
          <div className="row col-md-12 sub-section-description">
            <div className="col-md-10">Simple Rules</div>
            <div className="col-md-2">
              <div
                className="add-icon"
                onClick={e => {
                  setSimpleRuleEdit(null);
                  setIsRuleModalOpen(true);
                }}
              >
                <FaPlus size={20} color="white" style={{ float: 'right' }} />
              </div>
            </div>
          </div>
          {simpleRuleList?.length > 0 ? (
            <>
              <AutomatedReviewRuleTable
                tableHead={simpleRuleTableHead}
                tableDataType={0}
                tableData={simpleRuleList}
                handleEdit={handleSimpleEdit}
                handleDelete={handleSimpleDelete}
              />
            </>
          ) : (
            <>
              <div className="no-data">No registers to display..</div>
            </>
          )}
          <SimpleRuleModalComponent
            isOpen={isRuleModalOpen}
            onClose={() => {
              setSimpleRuleEdit(null);
              setIsRuleModalOpen(false);
            }}
            reportYears={reportYearList}
            reportYearID={reportYearID}
            modules={moduleList}
            moduleID={moduleID}
            ssks={subSectionKpisList}
            typeOptions={typeOptions}
            typeValues={typeValues}
            rules={rules}
            logicOperators={logicOperators}
            editData={simpleRuleEdit}
            loadSimpleRules={loadSimpleRules}
          />
        </div>

        <div className="row rule-table">
          <div className="row col-md-12 sub-section-description">
            <div className="col-md-10">Compound Rules</div>
            <div className="col-md-2">
              <div
                className="add-icon"
                onClick={e => {
                  setCompoundRuleEdit(null);
                  setIsCompoundRuleModalOpen(true);
                }}
              >
                <FaPlus size={20} color="white" style={{ float: 'right' }} />
              </div>
            </div>
          </div>
          {compoundRuleList?.filter(x => x.compoundType == 1)?.length > 0 ? (
            <>
              <AutomatedReviewRuleTable
                tableHead={compoundRuleTableHead}
                tableDataType={1}
                tableData={compoundRuleList}
                handleEdit={handleCompoundEdit}
                handleDelete={handleCompoundDelete}
              />
            </>
          ) : (
            <>
              <div className="no-data">No registers to display..</div>
            </>
          )}
          <CompoundRuleModalComponent
            isOpen={isCompoundRuleModalOpen}
            onClose={() => {
              setCompoundRuleEdit(null);
              setIsCompoundRuleModalOpen(false);
            }}
            reportYears={reportYearList}
            reportYearID={reportYearID}
            modules={moduleList}
            moduleID={moduleID}
            rules={simpleRuleList}
            logicOptions={logicOptions}
            compoundModalType={1}
            editData={compoundRuleEdit}
            loadCompoundRules={loadCompoundRules}
          />
        </div>

        <div className="row rule-table">
          <div className="row col-md-12 sub-section-description">
            <div className="col-md-10">Expression Rules</div>
            <div className="col-md-2">
              <div
                className="add-icon"
                onClick={e => {
                  setCompoundRuleEdit(null);
                  setIsExpressionCompoundRuleModalOpen(true);
                }}
              >
                <FaPlus size={20} color="white" style={{ float: 'right' }} />
              </div>
            </div>
          </div>
          {compoundRuleList?.filter(x => x.compoundType == 2)?.length > 0 ? (
            <>
              <AutomatedReviewRuleTable
                tableHead={expressionCompoundRuleTableHead}
                tableDataType={2}
                tableData={compoundRuleList}
                handleEdit={handleCompoundEdit}
                handleDelete={handleCompoundDelete}
              />
            </>
          ) : (
            <>
              <div className="no-data">No registers to display..</div>
            </>
          )}
          <CompoundRuleModalComponent
            isOpen={isExpressionCompoundRuleModalOpen}
            onClose={() => {
              setCompoundRuleEdit(null);
              setIsExpressionCompoundRuleModalOpen(false);
            }}
            reportYears={reportYearList}
            reportYearID={reportYearID}
            modules={moduleList}
            moduleID={moduleID}
            rules={compoundRuleList?.filter(x => x.compoundType == 1)}
            logicOptions={logicOptions}
            compoundModalType={2}
            editData={compoundRuleEdit}
            loadCompoundRules={loadCompoundRules}
          />
        </div>
      </div>
    </ContainerBody>
  );
};

export default AutomatedReviewRules;
