import React from 'react';
import TableCrud from '../../components/TableCrud';

const SectionIndex = () => {
    const columns = [
        {
            label: "Form",
            name: "module.operation.description"
        },
        {
            label: "Module",
            name: "module.description"
        },
        {
            label: "Section Description",
            name: "description"
        },
        {
            label: "Guidance",
            name: "guidance"
        },
        {
            label: "Order",
            name: "order"
        },
        {
            label: "Report Year",
            name: "reportYear.year"
        }
    ]

    return (
        <TableCrud title={"Section"} table={"section"} columns={columns}/>
    );
}

export default SectionIndex;
