import styled from 'styled-components';



export const ContainerBody = styled.div`
  /* Add styles to the Container if needed */
  .container-body{
    margin: 16px;
  }
  .rule-table{
    display: flex;
    margin-top: 2rem;
  }
  th.column-head {
    /* Customize styles for the column head here */
    padding: 16px; /* Example padding */
    /* Add any other styles as needed */
  }

  td.column-cell {
    /* Customize styles for the column head here */
    padding-left: 16px; /* Example padding */
    vertical-align: middle;
    /* Add any other styles as needed */
  }

  .no-data{
    margin-top: 3rem;
    text-align: center;
  }

  .add-icon{
    display: flex;
    height: 2.5rem;
    width: 2.5rem;
    padding: 0.5rem;
    border-radius: 1.5rem;
    margin-top: -7px;
    float: right;
    margin-right: -5px;
    &:hover {
      background: rgba(255,255,255,0.2);
    }
  }

  .sub-section-description{
    background-color: #112A61;
    height: 48px;
    font-weight: bold;
    color: #FFFFFFFF;
    padding: 14px;
    border-radius: 8px 8px 0px 0px;
    margin-left: 11px;
    width: 98.4%;
    margin-bottom: -16px;
  }
`;
