import styled from 'styled-components';

export const Container = styled.div`

    hr {
        background-color: #c6c6c6;
    }

    .dashboard-header{
        font-size: 14px;
        font-weight: bold;
        background-color: #f6f8fa;
        
        .year-version-column{
            text-align: center;
        }

        .pointer {
            cursor: pointer;
        }

        .popover{
            min-width: 25%;
            max-width: 25%;
        }
        
        .container{
            .row{
                padding-top: 26px;
                padding-bottom: 26px;

                div{
                    .card{
                        background-color: #FFFFFF;
                        height: 103px;
                        margin-left: 8px;
                        margin-right: 8px;
                        font-size: 14px;
                        box-shadow: 0px 3px 20px #0000000D;
                        border-radius: 8px;
                        padding: 0;
                        animation: flipInX;
                        animation-duration: 1s;
        
                        .title-col{
                            padding: 5px 20px 5px 30px;
                            margin-top: auto;
                            margin-bottom: auto;
    
                            .card-title{
                                text-align: left;
                                font-size: 14px;
                                letter-spacing: 0px;
                                color: #00B1EB;
                            }
        
                            .card-number{
                                color: #112A61;
                                font-size: 23px;
                                margin-bottom: 0;
                            }
                        }
    
                        .chart-col{
                            padding: 5px;
                        }
                    }
                }
            }

        }
    }

    .dashboard-body{
        margin-top: 32px;
        animation: fadeIn;
        animation-duration: 1s;

        .show {
            animation: fadeIn;
            animation-duration: 1s;
        }

        .dashboard-body-title{
            p{
                font-size: 18px;
                font-weight: bold;
                text-align: left;
            }
        }

        .dashboard-body-filters{
            margin-bottom: 28px;

            div{
                select{
                    border-radius: 4px;
                    cursor: pointer;
                    padding: 0 6px ;
                    border: 1px solid #ECECF3;
                    color: #7F7F8A;
                    height: 40px;
                    width: 100%;
                    
                    option{
                        background-color: #F6F8FA;
                        color: black;
                        cursor: pointer;
                    }

                    &:focus-visible{
                        outline: none;
                    }
                }

                input{
                    border-radius: 4px;
                    padding: 0 6px ;
                    border: 1px solid #ECECF3;
                    color: #7F7F8A;
                    height: 40px;
                    width: 100%;
                    margin-bottom: 0.5rem;

                    &:focus-visible{
                        outline: none;
                    }
                }
            }
        }
    }
    .dashboard-buttons{
        button{
            text-transform: uppercase;
            text-align: center;
            font-size: 13px;
            margin-top: 15px;
            margin-left: 0;
            letter-spacing: 0.65px;
            color: #FFFFFF;
            background: #102A63 0% 0% no-repeat padding-box;
            border-radius: 5px;
            opacity: 1;

            svg{
                float: right
            }

            &:hover{
                background-color: #102A63;
            }
        }

        
    }
    
    #expandAll:disabled {
            background: #557ac9;
        }
`;

export const Accordion = styled.div`
    .accordion-item{
        animation: fadeIn;
        animation-duration: 1s;

        background-color: transparent;
        border: 0;
        margin-bottom: 34px;

        transition: width 2s ease;

        .year-version-column{
            text-align: center !important;
        }

        .last-change-column{
            text-align: center !important;
        }
        
        .accordion-header{
            button{
                background-color: transparent;
                font-size: 16px;
                color: #7F7F8A;
                font-weight: bold;
                border: 0;
                width: 100%;
                text-align: left;
                padding-left: 0;
                cursor: pointer;
                display: flex;

                .company-name{
                    padding-top: 2px;
                }

                span{
                    margin-left: 7px;
                    vertical-align: middle;
                        display: flex;
                        justify-content: center;

                    &.status{
                        padding-top: 0;
                        text-align: center;
                        border-radius: 13px;
                        padding: 5px 15px;
                        font-size: 12px;

                        &.complete{
                            background-color: #ABF0B0;
                        }

                        &.pending{
                            background-color: #FFDA7E;
                        }

                        &.rejected{
                            background-color: #FB9D9D;
                        }

                        &.notStarted{
                            background-color: #e6e6e6;
                        }
                    }
                }

                &::after{
                    display: none;
                }

                &.collapsed{
                    color: #102A63;
                    transition: 0.5s;

                    svg{
                        color: #102A63;
                        transform: rotate(180deg);
                    }
                }

                &:hover{
                    color: #102A63;
                    transition: 0.5s;
                }
            }
        }

        .accordion-body{
            padding: 0;
            padding-top: 20px;

            .no-record-found{
                margin-bottom: 10px;
            }

            .cards-header{
                margin-bottom: 14px;
                padding-left: 27px;
                padding-right: 27px;
                display: flex;

                div{
                    padding-bottom: 15px;
                    text-align: left;
                    font-size: 13px;
                    letter-spacing: 0px;
                    color: #7F7F8A;
                    text-shadow: 0px 3px 20px #0000000D;
                    padding: 0;
                }
            }
        }
    }
`

export const Card = styled.div`
    background-color: transparent;
    border: 0;

    .card-body{
        display: flex;
        padding: 0;
        background-color: #FFFFFF;
        filter: drop-shadow(2px 2px 5px rgb(0 0 0 / 0.1));
        border-radius: 8px;
        font-weight: bold;
        padding: 10px 27px 10px 27px;
        margin-bottom: 17px;
        cursor: pointer;
        animation: fadeIn;
        animation-duration: 1s;

        &.collapsed{
            color: #102A63;

            svg{
                color: #102A63;
                transform: rotate(180deg);
            }

            .status{
                color: black;
            }
        }

        div{
            position:relative;
            margin: auto;
        }

        .status{
            padding-top: 0;
            text-align: center;
            display: flex;
            justify-content: center;
            text-align: center;
            border-radius: 13px;
            padding: 4px 5px 4px 5px;
            font-size: 12px;
            margin-right: 5px;

            &.complete{
                background-color: #ABF0B0;
            }

            &.pending{
                background-color: #FFDA7E;
            }

            &.rejected{
                background-color: #FB9D9D;
            }

            &.notStarted{
                background-color: #e6e6e6;
            }
        }

        &:hover{
            background-color: #f6f8fa;
        }
    }

    .card{
        padding-left: 15px;
        background-color: transparent;
        border: 0;
        font-size: 14px;

        .card-row{
            display: flex;

            .card-body{
                padding-left: 11px;
                z-index: 1;
                border-radius: 8px 0 0 8px;
                filter: drop-shadow(2px 2px 5px rgb(0 0 0 / 0.1));
                box-shadow: none;
            }
            
            .card-row-button, .card-row-button-last{
                height: 46px;
                width: 48px;
                background-color: #102A63;
                color: white;
                border: 0;
                padding: 8px 10px 10px 10px;
                animation: fadeIn;
                animation-duration: 1s;
                opacity: 1;
                margin-right: 5px;
                font-weight: bold;

                &:hover{
                    opacity: 0.6;
                }
            }

            .card-row-button-last{
                border-radius: 0 8px 8px 0;
                margin: 0;
            }

            .card-row-buttons-body{
                background: white;
                margin-bottom: 17px;
            }
        }
    }
`