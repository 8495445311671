import React, { useEffect, useState } from 'react';
import FormCrud from '../../../components/FormCrud';

const SimulationForm = () => {
   
    const activeOptions = [
        { value: true, text: "Yes" },
        { value: false, text: "No" }
    ];

    const fields = [        
        {
            label: "Description",
            name: "description",
            type: "input"
        },  
        {
            label: "Order",
            name: "order",
            type: "number"
        },
        {
            label: "Is Active",
            name: "isActive",
            type: "select",
            options: activeOptions,
            required: false
        }        
    ]

    return (
        <FormCrud formName={"simulation"} title={"Simulation"} fields={fields}/>        
    );
}

export default SimulationForm;
