import styled from 'styled-components';

export const Container = styled.div`
    .tab{
        color: #102961;
        cursor: pointer;

        .tab-header {
            display: flex;
            min-height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            text-decoration: uppercase;
            font-weight: bold;

            span{
                opacity: 0.5
            }
        }
        
        .tab-bar{
            background: #FFFFFFFF 0% 0% no-repeat padding-box;
            border-radius: 8px 8px 0 0;
            height: 4px;
        }

        &:hover{
            span{
                color: #4DAE49;
                opacity: 1!important;
            }

            .tab-bar{
                background: #4DAE49 0% 0% no-repeat padding-box;
                border-radius: 8px 8px 0 0;
                height: 4px;

                animation: zoomIn;
                animation-duration: 1s;
            }
        }
    }

    .tab-active {
        span{
            color: #4DAE49;
            opacity: 1!important;
        }

        .tab-bar{
            background: #4DAE49 0% 0% no-repeat padding-box;
            border-radius: 8px 8px 0 0;
        }
    }
`;