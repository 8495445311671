import React, { useEffect, useState } from 'react';

import Api, { exceptionNotificationAPI } from '../../services/api';
import FormCrud from '../../components/FormCrud';
import { fas } from '@fortawesome/free-solid-svg-icons';

const UserForm = () => {
    const [companyOptions, setCompanyOptions] = useState(null); //Utilizado para controlar quais as options da dropdownlist de Supplier
    const [groupOptions, setGroupOptions] = useState(null); //Utilizado para controlar quais as options da dropdownlist de Supplier
    const [companyOptionsAux, setCompanyOptionsAux] = useState(null); //Utilizado para guardar a lista inicial de Suppliers
    const [rolesOptions, setRolesOptions] = useState(null);
  
    const handleUserRoleChange = (form) => {
        const roleSelect = form.getFieldRef('roleID').current.value;
        const companySelect = form.getFieldRef('companyID');
        const groupSelect = form.getFieldRef('groupID');
        const needReadConsent = form.getFieldRef('needReadConsent');

        if (roleSelect === "23D9D409-D7AA-4966-9047-48C04B41F0A1"){ //Admin
            companySelect.current.disabled = true;
            companySelect.current.value = "";
            groupSelect.current.disabled = true;
            groupSelect.current.value = "";
            needReadConsent.current.disabled = true;
        }
        else if(roleSelect === "198E176A-E8F5-437C-B6B9-AAB5972099F4"){ //Approver
            needReadConsent.current.disabled = false
            companySelect.current.disabled = false;
            groupSelect.current.disabled = false;
        }
        else {
            if(roleSelect === "BA6C43EB-7334-463B-B5CB-D712BD29339D"){ //Info Holder
                setCompanyOptions(companyOptions.filter(x => x.value !== ""));
                groupSelect.current.disabled = true;
                groupSelect.current.value = "";
                companySelect.current.value = companySelect.current[0].value;
                needReadConsent.current.disabled = true;
            }else{
                setCompanyOptions(companyOptionsAux);
                companySelect.current.value = "";
                groupSelect.current.disabled = true;
                groupSelect.current.value = "";
                needReadConsent.current.disabled = true;

            }

            companySelect.current.disabled = false;
            groupSelect.current.disabled = false;
            needReadConsent.current.disabled = true;
        }
    };

    const fields = [
        {
            label: "Username",
            name: "userName",
            type: "input"
        },
        {
            label: "First Name",
            name: "firstName",
            type: "input"
        },
        {
            label: "Last Name",
            name: "lastName",
            type: "input"
        },
        {
            label: "Email",
            name: "email",
            type: "input"
        },
        {
            label: "Password",
            name: "password",
            editable: false,
            type: "password"
        },
        {
            label: "Confirm Password",
            name: "confirmPassword",
            editable: false,
            type: "password"
        },
        {
            label: "Role",
            name: "roleID",
            type: "select",
            options: rolesOptions,
            onChanged: handleUserRoleChange,
        },
        {
            label: "Supplier",
            name: "companyID",
            type: "select",
            options: companyOptions,
            disabled: true,
            required: false,
        },
        {
            label: "Viewer Group",
            name: "groupID",
            type: "select",
            options: groupOptions,
            disabled: true,
            required: false,
        },
        {
            label: "Need Read and Agree Consent?",
            name: "needReadConsent",
            type: "check",
            disabled: true,
            required: false,
        },
        {
            label: "Is Active",
            name: "isActive",
            type: "check",
            disabled: false,
            required: false,
        }          
        
    ]

    const loadData = async () => {
        Api.get('Company/getAll').then((result) => {
            if (result && result.data) {
                var companyOptions = result.data.response.map(item => ({ value: item.companyID, text: item.name }));

                companyOptions = [{value: "", text: "Global"}, ...companyOptions];

                setCompanyOptions(companyOptions);
                setCompanyOptionsAux(companyOptions);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
        Api.get('User/getAllRoles').then((result) => {
            if (result && result.data) {
                const rolesOptions = result.data.response.map(item => ({ value: item.id, text: item.name }));

                setRolesOptions(rolesOptions);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
        Api.get('Group/getAllGroups').then((result) => {
            if (result && result.data) {
                var groupsOptions = result.data.response.map(item => ({ value: item.groupID, text: item.name }));

                groupsOptions = [{value: "", text: "All/NoGroup"}, ...groupsOptions];

                setGroupOptions(groupsOptions);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        companyOptions != null && rolesOptions != null && groupOptions != null ? <FormCrud formName={"user"} title={"User"} fields={fields} /> : <></>       
    );
}

export default UserForm;
