import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/auth';
import moment from 'moment';
import { FiEdit2, FiSave } from 'react-icons/fi';

import { Message } from './styles';

const ChatMessage = ({ message, currentUser, userObject, editMessage, lastMessage }) => {
    const { userRoles } = useAuth();
    const [isEdit, setIsEdit] = useState(false);
    const [editedText, setEditedText] = useState();


    useEffect(() => {
        if (message && message.text) {
            setEditedText(message.text)
        }
    }, [message]);

    const getMessageSide = () => {
        return currentUser == message.createdBy ? "right" : "left";
    }

    const edit = () => {
        setIsEdit(!isEdit)
        editMessage({...message, text: editedText })
    }


    return <Message >
        <div className={getMessageSide() + " appeared"}>
            <div className="message_information">{message.user} - {moment(message.created, "DD/MM/YYYY HH:mm:ss").add(moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss")}</div>
            <div className="text_wrapper">
                {
                    !isEdit
                        ?
                        <div className="text">{`${message.text}`}</div>
                        :
                        <textarea className='message_input' value={editedText} onChange={(e) => {
                            setEditedText(e.target.value)
                        }}></textarea>
                }
                {
                    ((userRoles.includes("RoleApprover") && userObject.companyID == null) || userRoles.includes("RoleAdmin")) && currentUser == message.createdBy && lastMessage ? (
                        !isEdit ?
                            <button className="edit_button" onClick={() => {
                                setIsEdit(!isEdit)
                            }}>
                                <FiEdit2
                                    color={"#FFFFFF"}
                                    className="icon-right"
                                    size={14}
                                />
                            </button>
                            :

                            <button className="edit_button" onClick={edit}>
                                <FiSave
                                    color={"#FFFFFF"}
                                    className="icon-right"
                                    size={14}
                                />
                            </button>
                    )
                        : <></>
                }
            </div>
        </div>
    </Message>
}

export default ChatMessage;
