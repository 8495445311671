import { Component, useEffect, useState } from "react";
import Api, { exceptionNotificationAPI } from "./api";
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useAuth } from '../hooks/auth';
import { success_message, info_message, error_message } from '../components/Toast/index';
import { func } from "prop-types";
import { toleranceValidations } from "./tolerance";
import GetTypeInput from "../components/FormInput/set";
import { regex } from "uuidv4";

var answersToBeValited = [];

export const setNumber = ({ ref, stageColumn, stageSection, value, row, fuelTypeID, companyID, groupID, values, setValues, stageColumns, totalRows, uniqueValue, originalFuel }) => {
    setFormAnswers(
        {
            'values': values,
            'stageColumn': stageColumn,
            'stageSection': stageSection,
            'ref': ref,
            'value': value != null ? value : "",
            'row': row,
            'totalRows': totalRows,
            'fuelTypeID': fuelTypeID,
            'companyID': companyID,
            'groupID': groupID,
            'setValues': setValues,
            'stageColumns': stageColumns,
            'uniqueValue': uniqueValue,
            'originalFuel': originalFuel
        });
};

export const setText = ({ ref, stageColumn, stageSection, value, row, fuelTypeID, companyID, groupID, values, setValues, stageColumns, totalRows, originalFuel }) => {
    setFormAnswers(
        {
            'values': values,
            'stageColumn': stageColumn,
            'stageSection': stageSection,
            'ref': ref,
            'totalRows': totalRows,
            'text': value != null ? value : "",
            'row': row,
            'fuelTypeID': fuelTypeID,
            'companyID': companyID,
            'groupID': groupID,
            'setValues': setValues,
            'stageColumns': stageColumns,
            'originalFuel': originalFuel
        });
};


const setFormAnswers = ({ values, setValues, stageColumn, ref, companyID = null, groupID = null, totalRows, value = null, text = null, row = null, fuelTypeID = null, stageSection = null, stageColumns = null, uniqueValue = false, originalFuel = null }) => {
    var newAnswers = values;

    var myAnswer = getAnswers(
        {
            'newAnswers': newAnswers,
            'ref': ref,
            'stageColumn': stageColumn,
            'row': row
        })[0];
    var answerExists = false;

    if (myAnswer != null) {
        if (value != null)
            myAnswer.numberValue = value;
        if (text != null)
            myAnswer.textValue = text;
        if (row != null)
            myAnswer.row = row;
        if (companyID != null)
            myAnswer.companyID = companyID;
        if (groupID != null)
            myAnswer.groupID = groupID;
        if (fuelTypeID != null)
            myAnswer.fuelTypeID = fuelTypeID;
        if (originalFuel != null)
            myAnswer.originalFuel = originalFuel;

        answerExists = true;
    } else if (value != null || text != null) {
        myAnswer = {
            "stageColumnID": stageColumn.stageColumnID,
            "refNumber": stageColumn.refNumber,
            "stageSectionID": stageSection.stageSectionID,
            "numberValue": value,
            "textValue": text,
            "fuelTypeID": fuelTypeID,
            "row": row,
            "companyID": companyID,
            "groupID": groupID,
            "originalFuel": originalFuel,
        }

        answerExists = true;
    }

    var notChangedAnswers = values.filter(x => (x.stageColumnID != stageColumn.stageColumnID) || (x.stageColumnID == stageColumn.stageColumnID && x.row != row));

    if (answerExists && myAnswer) {
        newAnswers = [...notChangedAnswers, myAnswer];
    }
    else {
        newAnswers = notChangedAnswers;
    }

    var newAnswersWithCalculatedValues = [...refNumberCalculationsDependencies(stageColumn, fuelTypeID, companyID, groupID, newAnswers, stageColumns, values, totalRows, uniqueValue, row, originalFuel)];

    setValues(newAnswersWithCalculatedValues);
};

export const refNumberCalculationsDependencies = (stageColumn, fuelTypeID, companyID, groupID, answers, stageColumns = null, values = null, totalRows = null, uniqueValue = false, row = null, originalFuel = null) => {
    var refNumberCalculations = null;
    var formattedRefNumber = "[" + stageColumn.refNumber + "]";
    if (stageColumns != null) {
        refNumberCalculations = stageColumns.filter(x => (x.simpleCalculation != "" && x.simpleCalculation.indexOf(formattedRefNumber) > -1) || (x.groupedCalculation != "" && x.groupedCalculation.indexOf(formattedRefNumber) > -1));
    }

    refNumberCalculations.map((columnTarget) => {
        if (!uniqueValue) {
            for (let rowIndex = 0; rowIndex < totalRows; rowIndex++) {
                answers = CalculateTargetSubsectionKPI(columnTarget, fuelTypeID, companyID, groupID, answers, stageColumns, rowIndex, values, originalFuel);
            }
        }
        else {
            answers = CalculateTargetSubsectionKPI(columnTarget, fuelTypeID, companyID, groupID, answers, stageColumns, row, values, originalFuel);
        }
    });

    return answers;
}

export const CalculateTargetSubsectionKPI = (columnTarget, fuelTypeID, companyID, groupID, answers, stageColumns, row, values, originalFuel) => {
    var valueCalculated = null;

    var answersWithoutTargetRefNumber = answers.filter(x => (x.stageColumnID != columnTarget.stageColumnID)
        || (x.stageColumnID == columnTarget.stageColumnID && x.row != row));

    if (columnTarget.inputCalc == "Calculation") {
        valueCalculated = calculation(columnTarget.refNumber, columnTarget.simpleCalculation, columnTarget.defaultValueReference, answers, row, values, stageColumns);
    }

    if (valueCalculated != null) {
        var targetAnswer = answers.find(x => (x.stageColumnID == columnTarget.stageColumnID && x.row == row));

        if (targetAnswer) {
            targetAnswer.numberValue = valueCalculated;
        }
        else {
            targetAnswer = {
                "stageColumnID": columnTarget.stageColumnID,
                "refNumber": columnTarget.refNumber,
                "stageSectionID": columnTarget.stageSectionID,
                "numberValue": valueCalculated,
                "fuelTypeID": fuelTypeID,
                "row": row,
                "companyID": companyID,
                "groupID": groupID,
                "originalFuel": originalFuel
            }
        }


        var answerToBeValited = answersToBeValited.find(x => x.stageColumnID == targetAnswer.stageColumnID && x.row == targetAnswer.row);

        if (answerToBeValited == null) {
            answersToBeValited.push({ 'stageColumn': columnTarget, ...targetAnswer });
        }

        answers = [...answersWithoutTargetRefNumber, targetAnswer];

        answers = [...refNumberCalculationsDependencies(columnTarget, fuelTypeID, companyID, groupID, answers, stageColumns, values)];
    } else {
        answers = [...answersWithoutTargetRefNumber];
        answers = [...refNumberCalculationsDependencies(columnTarget, fuelTypeID, companyID, groupID, answers, stageColumns, values)];
    }

    return answers;
}

export const getAnswers = ({ newAnswers, ref, stageColumn, row }) => {
    var myAnswer;
    var filter = {
        'stageColumnID': stageColumn?.stageColumnID,
        'refNumber': ref,
        'row': row,
    }

    myAnswer = newAnswers.filter(function (item) {
        for (var key in filter) {
            if (filter[key] == null)
                continue;
            else if (item[key] == null || item[key] != filter[key])
                return false;
        }
        return true;
    });

    return myAnswer;
};

export function RoundFixedNumber(num, digits, base = 10) {
    var pow = Math.pow(base || 10, digits);
    return Math.round(num * pow) / pow;
}

export function calculation(refNumber, advancedCalculation, defaultValueReference, answers, row = null, values, stageColumns) {
    var result = null;

    var formattedCalculation = advancedCalculation.replaceAll(" ", "");

    if (formattedCalculation.includes("FROMLASTCROPYEAR")) {
        var count = (formattedCalculation.match(/FROMLASTCROPYEAR/g) || []).length;
        for (let index = 0; index < count; index++) {
            var parsed = parseKeyWordsFromCalculation("FROMLASTCROPYEAR", formattedCalculation);

            if (parsed != null) {
                var splits = parsed.ExpressionValues.split(';')
                if (splits.length > 0) {
                    var values = [];
                    splits.map((item) => {
                        var ref = '[' + item.replace('[', '').replace(']', '') + '.5' + ']';
                        values.push(...getValueFromCalculationParse(ref, answers));
                    })


                    var sum = values.every(x => x == null) ? "null" : values.reduce((previous, current) => previous + current, 0) + "_";

                    formattedCalculation = formattedCalculation.replace(parsed.ExpressionToReplace, sum);
                }
            }
        }
    }

    //ALLTYPES(SUM;[$1] * [$2];[15065];[15049])
    if (formattedCalculation.includes("ALLTYPES")) {
        var count = (formattedCalculation.match(/ALLTYPES/g) || []).length;
        for (let index = 0; index < count; index++) {
            var parsed = parseKeyWordsFromCalculation("ALLTYPES", formattedCalculation);

            if (parsed != null) {
                var [type, expression, ...splits] = parsed.ExpressionValues.split(';')
                if (type != null && expression != null && splits != null) {
                    var resultFragment = "";
                    if (type === 'SUM' || type === 'MAX' || type === 'AVG') {
                        resultFragment = `${type}([args])`;
                    }

                    var regExp = new RegExp(`(\\[\\$\\d\\])+`, 'g');
                    var expressionMatches = expression.match(regExp);
                    if (getHighestPlaceholderValue(expressionMatches) != splits.length) {
                        resultFragment = 'null';
                    }
                    var answerValues = [];
                    splits.map((item) => {
                        if (item.includes('[')) {
                            var refNumber = item.replace('[', '').replace(']', '')
                            var refNumberAnswers = getAnswers(
                                {
                                    'newAnswers': answers,
                                    'ref': refNumber
                                }
                            );

                            answerValues.push(refNumberAnswers);
                        }
                    });
                    var resultList = [];
                    var expressionToEvaluate = expression;
                    var indexOfLongestArray = answerValues.reduce((p, c, i, a) => a[p].length > c.length ? p : i, 0);
                    answerValues[indexOfLongestArray].map((answer) => {
                        var placeholderIndex = indexOfLongestArray + 1;
                        expressionToEvaluate = expressionToEvaluate.replaceAll('[$' + placeholderIndex + ']', answer.numberValue + "_");

                        for (var i = 0; i < answerValues.length; i++) {
                            if (i != indexOfLongestArray) {
                                var ans = answerValues[i]?.find(x => answer.tobaccoTypeID != null ? x.tobaccoTypeID == answer.tobaccoTypeID : true
                                    && answer.fuelTypeID != null ? x.fuelTypeID == answer.fuelTypeID : true
                                        && answer.companyID != null ? x.companyID == answer.companyID : true
                                            && answer.groupID != null ? x.groupID == answer.groupID : true) ?? null;
                                if (ans != null) {
                                    placeholderIndex = i + 1;
                                    expressionToEvaluate = expressionToEvaluate.replaceAll('[$' + placeholderIndex + ']', ans.numberValue + "_");
                                }
                            }
                        }

                        resultList.push(evaluate(expressionToEvaluate, answers));
                        expressionToEvaluate = expression;
                    });

                    resultFragment = resultFragment.replace('[args]', resultList.join(';'));

                    formattedCalculation = formattedCalculation.replace(parsed.ExpressionToReplace, resultFragment);
                }
            }
        }
    }

    if (formattedCalculation.includes("MAX")) {
        var count = (formattedCalculation.match(/MAX/g) || []).length;
        for (let index = 0; index < count; index++) {
            var parsed = parseKeyWordsFromCalculation("MAX", formattedCalculation);

            if (parsed != null) {
                var splits = parsed.ExpressionValues.split(';')
                if (splits.length > 0) {
                    var values = [];
                    splits.map((item) => {
                        values.push(...getValueFromCalculationParse(item, answers));
                    })


                    var max = values.every(x => x == null) ? "null" : Math.max(...values) + "_";

                    formattedCalculation = formattedCalculation.replace(parsed.ExpressionToReplace, max);
                }
            }
        }
    }

    if (formattedCalculation.includes("SUMIF")) {
        var count = (formattedCalculation.match(/SUMIF/g) || []).length;
        for (let index = 0; index < count; index++) {
            var parsed = parseKeyWordsFromCalculation("SUMIF", formattedCalculation);

            if (parsed != null) {
                var [type, condition, matchType, ...splits] = parsed.ExpressionValues.split(';')
                if (type != null && condition != null && matchType != null && splits != null) {
                    var values = [];
                    //create objects to build a string to use as filter for answers;
                    var filteredAnswers = getFilteredAnswers(type, condition, matchType, answers, values); // criar
                    //tobacco, fcv, equal -> TobaccoTypeId == getTobaccoTypeId(fcv)
                    //tobacco, fcv, notequal -> TobaccoTypeId != getTobaccoTypeId(fcv)

                    splits.map((item) => {
                        values.push(...getValueFromCalculationParse(item, filteredAnswers));
                    })

                    var sum = values.every(x => x == null) ? "null" : values.reduce((previous, current) => previous + current, 0) + "_";

                    formattedCalculation = formattedCalculation.replace(parsed.ExpressionToReplace, sum);
                }
            }
        }
    }

    if (formattedCalculation.includes("SUM")) {
        var count = (formattedCalculation.match(/SUM/g) || []).length;
        for (let index = 0; index < count; index++) {
            var parsed = parseKeyWordsFromCalculation("SUM", formattedCalculation);

            if (parsed != null) {
                var splits = parsed.ExpressionValues.split(';')
                if (splits.length > 0) {
                    var values = [];
                    splits.map((item) => {
                        values.push(...getValueFromCalculationParse(item, answers));
                    })
                    var sum = values.every(x => x == null) ? "null" : values.reduce((previous, current) => previous + current, 0) + "_";

                    formattedCalculation = formattedCalculation.replace(parsed.ExpressionToReplace, sum);
                }
            }
        }
    }

    if (formattedCalculation.includes("AVG")) {
        var count = (formattedCalculation.match(/AVG/g) || []).length;
        for (let index = 0; index < count; index++) {
            var parsed = parseKeyWordsFromCalculation("AVG", formattedCalculation);

            if (parsed != null) {
                var splits = parsed.ExpressionValues.split(';')
                if (splits.length > 0) {
                    var values = [];
                    splits.map((item) => {
                        values.push(...getValueFromCalculationParse(item, answers));
                    })

                    var sum = values.every(x => x == null) ? "null" : values.reduce((previous, current) => previous + current, 0);
                    var avg = sum == "null" ? "null" : ((sum / values.length) || 0) + "_";

                    formattedCalculation = formattedCalculation.replace(parsed.ExpressionToReplace, avg);
                }
            }
        }
    }

    //IF([15717] < 0;[15033];0)
    if (formattedCalculation.includes("IF")) {
        var count = (formattedCalculation.match(/IF/g) || []).length;
        for (let index = 0; index < count; index++) {
            var parsed = parseKeyWordsFromCalculation("IF", formattedCalculation);

            if (parsed != null) {
                var splits = parsed.ExpressionValues.split(';')
                if (splits.length >= 2) {
                    var value = 0;
                    var condition = splits[0];
                    var valueTrue = splits[1];
                    var valueFalse = splits[2] || 0;

                    if (evaluate(condition, answers)) {
                        value = getValueFromCalculationParse(valueTrue, answers);
                    }
                    else {
                        value = getValueFromCalculationParse(valueFalse, answers);
                    }

                    value = value.every(x => x == null) ? "null" : value[0] + "_";

                    formattedCalculation = formattedCalculation.replace(parsed.ExpressionToReplace, value);
                }
            }
        }
    }

    result = evaluate(formattedCalculation, answers, row, defaultValueReference, stageColumns);

    return result;
}

function parseKeyWordsFromCalculation(keyWord, calculationFormula) {
    var regExp = new RegExp(keyWord + `\\(([^)]+)\\)`);
    var matches = regExp.exec(calculationFormula);

    if (matches && matches.length > 0)
        return {
            'ExpressionToReplace': matches[0],
            'ExpressionValues': matches[1]
        };
    else
        return null;
}

function getValueFromCalculationParse(item, answers, defaultRef = null, row = null, addUnderline = false) {
    var value = [];

    if (item.includes('[')) {
        var refNumber = item.replace('[', '').replace(']', '')
        var refNumberAnswers = getAnswers(
            {
                'newAnswers': answers,
                'ref': refNumber,
                'row': row,
            }
        );
        if (refNumberAnswers != null && refNumberAnswers.length > 0) {
            refNumberAnswers.map((answer) => {
                var answerValue = answer.numberValue;
                if (answerValue === "" || answerValue === null) {
                    var newValue = getAnswers(
                        {
                            'newAnswers': answers,
                            'ref': defaultRef?.replace('[', '').replace(']', ''),
                            'row': row,
                        })[0];

                    answerValue = newValue.numberValue;
                }
                if (addUnderline) {
                    answerValue += "_";
                }
                value.push(answerValue);
            });
        } else {
            refNumberAnswers = getAnswers(
                {
                    'newAnswers': answers,
                    'row': row,
                }
            );
            if (refNumberAnswers != null) {
                refNumberAnswers.map((answer) => {
                    var answerValue = answer.numberValue;
                    if (answerValue == "" || answerValue == null) {
                        var newValue = getAnswers(
                            {
                                'newAnswers': answers,
                                'ref': defaultRef?.replace('[', '').replace(']', ''),
                                'row': row,
                            })[0];

                        answerValue = newValue.numberValue;
                    }
                    if (addUnderline) {
                        answerValue += "_";
                    }
                    value.push(answerValue);
                });
            }
            else {
                value.push(null);
            }
        }
    } else {
        var itemHadUnderline = false;
        if (item.includes("_")) {
            item = item.replace("_", "");
            itemHadUnderline = true;
        }
        item = 1 * item
        if (addUnderline && itemHadUnderline) {
            item += "_";
        }
        value.push(item);

    }
    return value;
}

export function evaluate(formattedCalculation, answers, row = null, defaultValueReference = null, stageColumns) {
    if (formattedCalculation.includes("null")) {
        formattedCalculation = formattedCalculation.replaceAll("null", 0);
        // return null;
    }
    var result = null;
    var regexFilter = /[^-()\[\]\d/*+.<>=>_]/g;
    formattedCalculation = formattedCalculation.replace(regexFilter, '');
    var operations = /(?:==|<=|>=|>|<|\+|-|\*|\/)/g;
    var matches = formattedCalculation.match(operations);
    if (matches != null && matches.length > 0) {
        matches = matches.filter((value, index, array) => array.indexOf(value) === index);
        matches.forEach(match => {
            formattedCalculation = formattedCalculation.replaceAll(match, ` ${match} `)
        });
    }

    var advancedCalculationFragments = formattedCalculation.replaceAll("( ", "").replaceAll(" )", "").replaceAll("(", "").replaceAll(")", "").split(" ");
    var defaultValueReferenceFragments = defaultValueReference?.replaceAll("( ", "").replaceAll(" )", "").replaceAll("(", "").replaceAll(")", "").split(" ");

    var expressionToEvaluate = formattedCalculation;
    var needDefaultValue = false;

    advancedCalculationFragments.map((fragment, i) => {
        var answer = null;

        if (!isNaN(parseInt(fragment.replace("[", "").replace("]", "")))) {
            var stageColumnFrag = stageColumns && stageColumns.find(x => x.refNumber == fragment.replace("[", "").replace("]", ""))

            answer = getValueFromCalculationParse(fragment, answers, stageColumnFrag?.defaultValueReference ?? null, row, true)[0] ?? "null";

            expressionToEvaluate = expressionToEvaluate.replaceAll(fragment, answer);
        }
    })

    try {
        if (!expressionToEvaluate.includes("_")) {
            return null;
        }
        else {
            if (defaultValueReference != null) {
                if (expressionToEvaluate == "_ + null_" || expressionToEvaluate == "_ + _") {
                    needDefaultValue = true;
                    expressionToEvaluate = expressionToEvaluate.replaceAll("_", "");
                }
                else {
                    expressionToEvaluate = expressionToEvaluate.replaceAll("_", "").replaceAll("null", "0");
                }
            }
            else {
                expressionToEvaluate = expressionToEvaluate.replaceAll("_", "").replaceAll("null", "0");
            }
        }

        if (needDefaultValue) {
            expressionToEvaluate = defaultValueReference;
            defaultValueReferenceFragments.map((fragment, i) => {
                var answer = null;

                if (!isNaN(parseInt(fragment.replace("[", "").replace("]", "")))) {
                    answer = getValueFromCalculationParse(fragment, answers, defaultValueReference, row, true)[0] ?? "null";

                    expressionToEvaluate = expressionToEvaluate.replaceAll(fragment, answer);
                }
            })

            if (!expressionToEvaluate.includes("_")) {
                return null;
            }
            else {
                expressionToEvaluate = expressionToEvaluate.replaceAll("_", "").replaceAll("null", "0");
            }
        }

        if (expressionToEvaluate.startsWith(" ")) {
            expressionToEvaluate = expressionToEvaluate.substring(2);
        }
        else if (expressionToEvaluate.endsWith(" ")) {
            expressionToEvaluate = expressionToEvaluate.substring(0, expressionToEvaluate.length - 2);
        }

        result = Function("return " + expressionToEvaluate.replace(regexFilter, ''))();
        result = !isFinite(result) ? 0 : result;
    } catch (error) {
        console.log({ "ERROR - Expression": expressionToEvaluate });
    }

    return result;
}

export function GetCurrentRowCount(subSectionID, subSectionKPIList, answers) {
    var rowCount = 0;
    var rowAnswers = answers.filter(x => x.row != null);

    var sskList = subSectionKPIList.filter(x => x.subSectionID == subSectionID);

    sskList.map((ssk) => {
        if (ssk.calculation != null && (ssk.inputCalc == 'Calculation' || ssk.inputCalc == 'DisplayText')) {
            var splitRefNumbers

            var regexFilter = /[^-()\[\]\d/*+.<>=>_]/g;
            var formattedCalculation = ssk.calculation.replace(regexFilter, '');
            var operations = /(?:==|<=|>=|>|<|\+|-|\*|\/)/g;
            var matches = formattedCalculation.match(operations);
            if (matches != null && matches.length > 0) {
                matches = matches.filter((value, index, array) => array.indexOf(value) === index);
                matches.forEach(match => {
                    formattedCalculation = formattedCalculation.replaceAll(match, ` ${match} `)
                });
            }

            splitRefNumbers = formattedCalculation.replaceAll("( ", "").replaceAll(" )", "").replaceAll("(", "").replaceAll(")", "").split(" ");

            splitRefNumbers.map((fragment, i) => {
                if (!isNaN(parseInt(fragment.replace("[", "").replace("]", "")))) {
                    var answerList = getAnswers(
                        {
                            'newAnswers': rowAnswers,
                            'ref': fragment.replace("[", "").replace("]", "")
                        });

                    if (answerList.length > 0) {
                        answerList.map((answ) => {
                            if (answ.row > rowCount) {
                                rowCount = answ.row;
                            }
                        });
                    }
                }
            })
        }

        var answer = getAnswers(
            {
                'newAnswers': rowAnswers,
                'ref': ssk.refNumber
            });

        if (answer.length > 0) {
            answer.map((answ) => {
                if (answ.row > rowCount) {
                    rowCount = answ.row;
                }
            });
        }
    })

    return rowCount + 1;
}

const getFilteredAnswers = (type, condition, matchType, answers, values) => {
    var filteredAnswers = [];

    var types = {
        'Column': 'column',
        'Row': 'row',
        'Tobacco': 'tobaccoTypeID',
        'Fuel': 'fuelTypeID',
        'Country': 'companyID'
    };

    if (type == 'Tobacco' || type == 'Fuel' || type == 'Country') {
        condition = getTypeIdsFromName(type, condition, matchType, values);
    }

    filteredAnswers = answers.filter(x => condition.some(c => c.value == x[types[type]]));

    return filteredAnswers;
}

const getTypeIdsFromName = (type, condition, matchType, values) => {
    var conditionIds = [];

    var types = {
        'Tobacco': 'tobaccoTypesOptions',
        'Fuel': 'fuelTypesOptions',
        'Country': 'countriesOptions'
    };

    switch (matchType) {
        case 'Equal':
            conditionIds = values[types[type]]?.filter(x => x.text?.replaceAll(' ', '') == condition);
            break;

        case 'NotEqual':
            conditionIds = values[types[type]]?.filter(x => x.text?.replaceAll(' ', '') != condition);
            break;

        case 'StartWith':
            conditionIds = values[types[type]]?.filter(x => x.text?.replaceAll(' ', '')?.startsWith(condition));
            break;

        case 'EndWith':
            conditionIds = values[types[type]]?.filter(x => x.text?.replaceAll(' ', '')?.endsWith(condition));
            break;

        case 'Contain':
            conditionIds = values[types[type]]?.filter(x => x.text?.replaceAll(' ', '')?.includes(condition));
            break;
        default:
            break;
    }

    return conditionIds;
}

export const getFieldsToBlock = ({ subSectionKPI = null, subSectionKPIs, refNumbersToBlock = [] }) => {
    let subSectionKPIsToBlock = [];

    refNumbersToBlock.map(refNumber => {
        var ssk = subSectionKPIs.find(x => x.refNumber == refNumber);
        if (ssk && !subSectionKPIsToBlock.some(x => x.subSectionKPIID == ssk.subSectionKPIID) && (subSectionKPI != null ? ssk.subSectionID == subSectionKPI.subSectionID : true)) {
            subSectionKPIsToBlock.push(ssk)
        }
    });

    return subSectionKPIsToBlock;
}

const getHighestPlaceholderValue = (expressionMatches) => {
    var matches = expressionMatches.map((match) => {
        return match.replace(/\D/g, '');
    })

    return Math.max(...matches);
}