import moment from 'moment';
import React, { useState } from 'react';
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { FiUpload } from 'react-icons/fi';
import { MdFileDownload } from 'react-icons/md';
import { SiMicrosoftexcel } from 'react-icons/si';
import Api, { exceptionNotificationAPI } from '../../services/api';
import Button from '../Button';
import ModalCompareDataFSM from '../ModalCompareDataFSM';
import { error_message, info_message, success_message } from '../Toast';
// import ModalBody from 'react-bootstrap/ModalBody';
import { Header, ContentTitle, HeaderModal, ModalBody } from './styles';
import { Container } from 'react-bootstrap';

export default function ModalUploadEvidence({
  kpiEvidences,
  setKPIEvidences,
  showUploadEvidence,
  setShowUploadEvidence,
  setEvidenceRequest,
  evidenceRequest,
  MODULE_ID,
}) {
  const [isUploading, setIsUploading] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const [file, setFile] = useState(null);
  const [lastFSMImportation, setLastFSMImportation] = useState('');
  const [userObject, setUserObject] = useState(
    JSON.parse(localStorage.getItem('@Thrive:user')),
  );
  // var file = null;

  useEffect(() => {
    if (showUploadEvidence == false) {
      setFile(null);
    }
  }, [showUploadEvidence]);

  function setFileUpload(fileUploaded) {
    setFile(fileUploaded.target.files[0]);
  }

  const getTemplateFile = async () => {
    if (showUploadEvidence) {
      await Api.get(`EvidenceAudit/getFile/EvidenceTemplate.xlsx`, {
        responseType: 'blob',
      }).then(res => {
        let url = window.URL.createObjectURL(res.data);
        window.open(url);
      });
    }
  };

  const getFile = async fileName => {
    if (showUploadEvidence) {
      await Api.get(`EvidenceAudit/getFile/${fileName}`, {
        responseType: 'blob',
      }).then(res => {
        let url = window.URL.createObjectURL(res.data);
        window.open(url);
      });
    }
  };

  const closeModal = () => {
    setFile(null);
    setShowUploadEvidence(false);
  };

  async function uploadFile(files) {
    try {
      if (files.size / 1024 > 30720) {
        error_message('File is too big (max 30mb)!');
        return;
      }
      info_message('The upload was sent!');
      const data = new FormData();
      data.append('files', files);
      setIsUploading(true);
      setShowButtons(false);
      await Api.post(
        `EvidenceAudit/readEvidence?subSectionKPIID=${evidenceRequest.subSectionKPIID}&companyID=${userObject.companyID}&reportYearID=${userObject.selectedReportYearID}&moduleID=${MODULE_ID}&sskValueToMatch=${evidenceRequest.subSectionKPIValue}`,
        data,
      )
        .then(response => {
          if (response.data.response) {
            setEvidence({
              subSectionKPIID: evidenceRequest.subSectionKPIID,
              file: response.data.response.file,
              column: evidenceRequest.column,
              row: evidenceRequest.row,
              fuelTypeID: evidenceRequest.fuelTypeID,
              curingBarnID: evidenceRequest.curingBarnID,
              countryID: evidenceRequest.countryID,
              tobaccoTypeID: evidenceRequest.tobaccoTypeID,
              subSectionKPIValue: response.data.response.subSectionKPIValue,
              value: response.data.response.value,
              farmersCount: response.data.response.farmersCount,
              isValidated: response.data.response.isValidated,
            });
          } else {
            error_message(
              'The value inputed not match with the evidence value. Please, upload a valid evidence!',
            );
          }
        })
        .catch(error => {
          exceptionNotificationAPI(error);
        });
    } catch (error) {
      exceptionNotificationAPI(error);
    }
  }

  const handleTryAgain = () => {
    var newRqst = {
      subSectionKPIID: evidenceRequest.subSectionKPIID,
      file: null,
      column: evidenceRequest.column,
      row: evidenceRequest.row,
      fuelTypeID: evidenceRequest.fuelTypeID,
      curingBarnID: evidenceRequest.curingBarnID,
      countryID: evidenceRequest.countryID,
      tobaccoTypeID: evidenceRequest.tobaccoTypeID,
      subSectionKPIValue: evidenceRequest.subSectionKPIValue,
      value: null,
      farmersCount: null,
      isValidated: false,
    };

    setEvidenceRequest(newRqst);
  };

  const setEvidence = ({
    subSectionKPIID,
    file,
    column,
    row,
    fuelTypeID,
    curingBarnID,
    countryID,
    tobaccoTypeID,
    subSectionKPIValue,
    value,
    farmersCount,
    isValidated,
  }) => {
    var object = kpiEvidences;
    var request = {
      subSectionKPIID: subSectionKPIID,
      file: file,
      column: column,
      row: row,
      fuelTypeID: fuelTypeID,
      curingBarnID: curingBarnID,
      countryID: countryID,
      tobaccoTypeID: tobaccoTypeID,
      subSectionKPIValue: subSectionKPIValue,
      value: value,
      farmersCount: farmersCount,
      isValidated: isValidated,
      companyID: userObject.companyID,
      reportYearID: userObject.selectedReportYearID,
      moduleID: MODULE_ID,
    };
    Api.post(`EvidenceAudit/new`, request)
      .then(result => {
        if (result && result.data) {
          setEvidenceRequest(result.data.response);
          var evidencesList = [...object, result.data.response];
          setKPIEvidences(evidencesList);
        }
      })
      .catch(error => {
        exceptionNotificationAPI(error);
      });
  };

  return (
    <>
      <Modal
        show={showUploadEvidence}
        onHide={() => {
          setShowUploadEvidence(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader closeButton>
          <HeaderModal className="row w-100 align-items-center">
            <div className="col-lg-8">
              <ModalTitle id="contained-modal-title-vcenter">
                <Header>Upload Evidence</Header>
              </ModalTitle>
            </div>
            <div className="col-lg-4 ">
              <div className="btn_download d-flex align-items-center">
                <MdFileDownload size={20} color="#0EAC27"></MdFileDownload>
                {evidenceRequest.file == null ? (
                  <h6
                    className="evidence_download"
                    onClick={() => getTemplateFile()}
                  >
                    Evidence Template
                  </h6>
                ) : (
                  <h6
                    className="evidence_download"
                    onClick={() => getFile(evidenceRequest.file)}
                  >
                    Download Evidence
                  </h6>
                )}
              </div>
            </div>
          </HeaderModal>
        </ModalHeader>
        <ModalBody>
          {evidenceRequest?.file == null && evidenceRequest?.value == null ? (
            <div className="upload-btn-wrapper">
              <button className="btn-custom">
                <FiUpload
                  color={'#0E2B63'}
                  className="icon-comment"
                  style={{ padding: '0 0.5rem' }}
                  size={70}
                />
                <div>
                  Please download the evidence template, fill with the data and
                  upload here to validate. <br />
                  <strong>File extensions must be .XLSX or .XLS</strong>
                </div>
              </button>
              <input type="file" onChange={files => setFileUpload(files)} />

              {file != null && (
                <div className="filetodownload text-center">
                  <div
                    className="filename d-flex align-items-center"
                    style={{ margin: '1rem 0.5rem' }}
                  >
                    {file.name}
                    <SiMicrosoftexcel
                      size={15}
                      style={{ margin: '0 0.5rem' }}
                    />
                  </div>
                  <button
                    className="btn btn-success"
                    onClick={() => uploadFile(file)}
                  >
                    Validate
                  </button>
                </div>
              )}
            </div>
          ) : (
            <Container>
              <div className="row">
                <div className="col-lg-4">
                  <label>KPI Value</label>
                  <div className="displayValue">
                    {evidenceRequest.subSectionKPIValue}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label>Evidence Value</label>
                  <div className="displayValue">
                    {evidenceRequest.value ?? ''}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label>Result</label>
                  <div className="displayValue">
                    {evidenceRequest.isValidated == true
                      ? 'Match'
                      : 'Not Match'}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {evidenceRequest.isValidated == true ? (
                    <div className="text-center" style={{ margin: '3rem' }}>
                      {evidenceRequest.subSectionKPIValue ==
                      evidenceRequest.value ? (
                        <>
                          <h2>The values are matching!</h2>
                          <p>Close this window to continue your report.</p>
                        </>
                      ) : (
                        <>
                          <h2>Values do not match.</h2>
                          <p>
                            Please adjust the KPI value or the submitted
                            evidence.
                          </p>
                        </>
                      )}

                      <div
                        className="d-flex justify-content-evenly"
                        style={{ margin: '2rem auto' }}
                      >
                        <button
                          class="btn btn-success"
                          onClick={() => handleTryAgain()}
                        >
                          Upload another
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="text-center" style={{ margin: '3rem' }}>
                      <h2>The values are not matching!</h2>
                      <p>
                        Please review the evidence and the KPI reported <br />
                        Please check total number of farmers KPI, may have been
                        changed. <br />
                        Close this window to make the adjustments and try again.
                      </p>
                      <div
                        className="d-flex justify-content-evenly"
                        style={{ margin: '2rem auto' }}
                      >
                        <button
                          class="btn btn-success"
                          onClick={() => handleTryAgain()}
                        >
                          Try again
                        </button>
                        <button
                          class="btn btn-danger"
                          onClick={() => closeModal()}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Container>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}
