import styled from 'styled-components';

export const Container = styled.div`
    h3{
        margin-top: 42px;
        padding-bottom: 25px;
    }

    table{
        tbody{
            box-shadow: 0px 3px 20px #0000000d;

            tr{
                td{
                    display: table-cell;
                    padding: 10px;
                    vertical-align: middle;

                    .required{
                        color: red;
                    }
                }
    
                &:first-child{
                    td:first-child{
                        border-radius: 8px 0 0 0;
                    }
    
                    td:last-child{
                        border-radius: 0 8px 0 0;
                    }
                }
    
                &:last-child{
                    td:first-child{
                        border-radius: 0 0 0 8px;
                    }
                    
                    td:last-child{
                        border-radius: 0 0 8px 0;
                    }

                    border-bottom: transparent;
                }

                &.hide{
                    display: none;
                }
            }
        }
    }
`

export const Accordion = styled.div`
    .accordion-item{
        animation: fadeIn;
        animation-duration: 1s;

        background-color: transparent;
        border: 0;
        margin-bottom: 34px;

        transition: width 2s ease;

        .year-version-column{
            text-align: center !important;
        }
        
        .accordion-header{
            button{
                background-color: transparent;
                font-size: 16px;
                color: #7F7F8A;
                font-weight: bold;
                border: 0;
                width: 100%;
                text-align: left;
                padding-left: 0;
                cursor: pointer;
                display: flex;

                .company-name{
                    padding-top: 2px;
                }

                span{
                    margin-left: 7px;
                    vertical-align: middle;
                        display: flex;
                        justify-content: center;

                    &.status{
                        padding-top: 0;
                        text-align: center;
                        border-radius: 13px;
                        padding: 5px 15px;
                        font-size: 12px;

                        &.complete{
                            background-color: #ABF0B0;
                        }

                        &.pending{
                            background-color: #FFDA7E;
                        }

                        &.rejected{
                            background-color: #FB9D9D;
                        }

                        &.notStarted{
                            background-color: #e6e6e6;
                        }
                    }
                }

                &::after{
                    display: none;
                }

                &.collapsed{
                    color: #102A63;
                    transition: 0.5s;

                    svg{
                        color: #102A63;
                        transform: rotate(180deg);
                    }
                }

                &:hover{
                    color: #102A63;
                    transition: 0.5s;
                }
            }
        }

        .accordion-body{
            padding: 0;
            padding-top: 20px;

            .no-record-found{
                margin-bottom: 10px;
            }

            .cards-header{
                margin-bottom: 14px;
                padding-left: 27px;
                padding-right: 27px;
                display: flex;

                div{
                    padding-bottom: 15px;
                    text-align: left;
                    font-size: 13px;
                    letter-spacing: 0px;
                    color: #7F7F8A;
                    text-shadow: 0px 3px 20px #0000000D;
                    padding: 0;
                }
            }
        }
    }
`
