const GetTypeInput = (unitId, unitType) => {
    var unitMeasure = {
        inputType: "",
        decimalSeparator: "",
        suffix: "",
        decimalPrecision: 0
    }

    if (unitId == 6)
        unitMeasure.inputType = "input";
    if (unitId == 1 || unitId == 9 || unitId == 10 || unitId == 11
        || unitId == 12 || unitId == 13 || unitId == 16 || unitId == 19 || unitId == 20
        || unitId == 21 || unitId == 23 || unitId == 29 || unitId == 30 || unitId == 33
        || unitId == 41 || unitId == 42 || unitId == 44 || unitId == 45 || unitId == 47
        || unitId == 49 || unitId == 52)
        unitMeasure.inputType = "number";
    else if (unitId == 2 || unitId == 3 || unitId == 4 || unitId == 5 || unitId == 7 || unitId == 8
        || unitId == 14 || unitId == 15 || unitId == 22 || unitId == 34 || unitId == 35
        || unitId == 36 || unitId == 37 || unitId == 38 || unitId == 39 || unitId == 40
        || unitId == 43 || unitId == 46 || unitId == 48 || unitId == 50 || unitId == 51
        || unitId == 53 || unitId == 54 || unitId == 55 || unitId == 56 || unitId == 58 || unitId == 59
        || unitId == 61 || unitId == 62 || unitId == 63 || unitId == 64 || unitId == 65 || unitId == 66 || unitId == 73 || unitId == 76)
        unitMeasure.inputType = "decimal";
    else if (unitId == 74)
        unitMeasure.inputType = 'targetBAT'
    else if (unitId == 17)
        unitMeasure.inputType = "selectyn";
    else if (unitId == 18)
        unitMeasure.inputType = "selectynu";
    else if (unitId == 24)
        unitMeasure.inputType = "selectcustom";
    else if (unitId == 77)
        unitMeasure.inputType = "multiselectcustom";
    else if (unitId == 25)
        unitMeasure.inputType = "selectcharge";
    else if (unitId == 27)
        unitMeasure.inputType = "selectcountry";
    else if (unitId == 67)
        unitMeasure.inputType = "selecttobacco";
    else if (unitId == 68)
        unitMeasure.inputType = "selectfuel";
    else if (unitId == 28)
        unitMeasure.inputType = "selecthousing";
    else if (unitId == 29)
        unitMeasure.inputType = "selectasnn";
    else if (unitId == 30)
        unitMeasure.inputType = "selectasn";
    else if (unitId == 57)
        unitMeasure.inputType = "date";
    else
        unitMeasure.inputType = "";

    if (unitType == "Natural Number") {
        unitMeasure.decimalSeparator = "";
        unitMeasure.suffix = "";
        unitMeasure.decimalPrecision = 0;
    } else if (unitType == "%, no decimal") {
        unitMeasure.decimalSeparator = "";
        unitMeasure.suffix = "%";
        unitMeasure.decimalPrecision = 0;
    } else if (unitType == "%, one decimal place") {
        unitMeasure.decimalSeparator = ".";
        unitMeasure.suffix = "%";
        unitMeasure.decimalPrecision = 1;
    } else if (unitType == "%, two decimal cases") {
        unitMeasure.decimalSeparator = ".";
        unitMeasure.suffix = "%";
        unitMeasure.decimalPrecision = 2;
    } else if (unitType == "%, four decimal cases") {
        unitMeasure.decimalSeparator = ".";
        unitMeasure.suffix = "%";
        unitMeasure.decimalPrecision = 4;
    } else if (unitType == "No decimal") {
        unitMeasure.decimalSeparator = ".";
        unitMeasure.suffix = "";
        unitMeasure.decimalPrecision = 0;
    } else if (unitType == "One decimal place") {
        unitMeasure.decimalSeparator = ".";
        unitMeasure.suffix = "";
        unitMeasure.decimalPrecision = 1;
    } else if (unitType == "Two decimal places") {
        unitMeasure.decimalSeparator = ".";
        unitMeasure.suffix = "";
        unitMeasure.decimalPrecision = 2;
    } else if (unitType == "Three decimal places") {
        unitMeasure.decimalSeparator = ".";
        unitMeasure.suffix = "";
        unitMeasure.decimalPrecision = 3;
    } else if (unitType == "Four decimal places") {
        unitMeasure.decimalSeparator = ".";
        unitMeasure.suffix = "";
        unitMeasure.decimalPrecision = 4;
    } else if (unitType == "Six decimal places") {
        unitMeasure.decimalSeparator = ".";
        unitMeasure.suffix = "";
        unitMeasure.decimalPrecision = 6;
    } else {
        unitMeasure.decimalSeparator = "";
        unitMeasure.suffix = "";
        unitMeasure.decimalPrecision = 0;
    }

    return unitMeasure;
}

export default GetTypeInput;
