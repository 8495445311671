import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from './style';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { useAuth } from '../../hooks/auth';
import Swal from 'sweetalert2';

const ThriveScreenNavBar = ({ operationID, moduleID, reportYearID = null, companyID = null}) => {
    const history = useHistory();
    const { tobaccoTypeID } = useParams();
    const [modules, setModules] = useState(null);
    const [columnsWidth, setColumnsWidth] = useState(null);
    const [loading, setLoading] = useState(true);
    const { userRoles, user } = useAuth();

    useEffect(() => {
        if(loading && modules == null){
            loadOperationModules();
        }
    }, [])

    const loadOperationModules = async() => {
        var url;

        if(reportYearID != null && companyID != null){ //Operation = Living Wage & Living Income
            url = `ThriveValue/GetAllModuleByOperationID?operationID=${operationID}&reportYearID=${reportYearID}&companyID=${companyID}`;
        }else if(reportYearID == null && companyID != null){ //Operation = Glidepath
            url = `ThriveValue/GetAllModuleByOperationID?operationID=${operationID}&companyID=${companyID}`;
        }
        else{
            url = `ThriveValue/GetAllModuleByOperationID?operationID=${operationID}`;
        }

        Api.get(url).then((result) => {
            if(result && result.data){
                setModules(result.data.response);
                setColumnsWidth(100 / result.data.response.length);
                setLoading(false);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const redirectToTab = (module) => {        
        var url = "";

        if(module.moduleID == 24 || module.moduleID == 25 || module.moduleID == 26 || module.moduleID == 27){
            url = `/${module.operationDescription.replaceAll(' ', '').replaceAll('&','').toLowerCase()}/${module.moduleDescription.replaceAll(' ', '').replaceAll('&','').toLowerCase()}`;
        }
        else {
            url = `/report/${module.operationDescription.replaceAll(' ', '').replaceAll('&','').toLowerCase()}/${module.moduleDescription.replaceAll(' ', '').replaceAll('&','').toLowerCase()}${module.tobaccoTypeID != null ? '/'+ module.tobaccoTypeID : ""}`;
        }

        if(userRoles.includes('RoleInfoHolder')){
            Swal.fire({
                title: 'Are you sure?',
                text: "To avoid data loss, confirm that you have saved all data correctly",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#B0B0BB',
                cancelButtonColor: '#112A61',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    history.push(url);
                    window.location.reload(false);
                }                
            })
        }
        else{
            history.push(url);
            window.location.reload(false);
        }
    }

    return (
        <Container className="col-12 pt-4">
            <div className="row">
                {
                    !loading && modules &&
                        modules.map((module, index) => {
                            var moduleNameFragments;
                            
                            if(module.tobaccoTypeID != null){
                                moduleNameFragments = module.tobaccoTypeName.split(" ");
                            }else{
                                moduleNameFragments = module.moduleDescription.split(" ");
                            }

                            return <div style={{ width: `${columnsWidth}%` }} key={`module_${index}`} className={`col-2 tab ${module.moduleID == moduleID && module.tobaccoTypeID == tobaccoTypeID ? 'tab-active' : ''}`} onClick={() => {redirectToTab(module)}}>
                                <div className="tab-header">
                                    <span>
                                        {
                                            moduleNameFragments.map((name, index) => {
                                                if(moduleNameFragments.length > 2 && index != 0 && (index % 2) != 0){
                                                    return <span key={`fragment_${index}`}>{name.toUpperCase()} <br/></span>   
                                                }else{
                                                    if(moduleNameFragments.length == 2){
                                                        return <span key={`fragment_${index}`}>{name.toUpperCase()} <br/></span>   
                                                    }else{
                                                        return <span key={`fragment_${index}`}>{name.toUpperCase()} </span>   
                                                    }
                                                }    
                                            })
                                        }
                                    </span>
                                </div>
                                <div className="tab-bar"></div>
                            </div>
                        })
                }
            </div>
        </Container>
    );
}

export default ThriveScreenNavBar;
