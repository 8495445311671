import React, { useState, useEffect } from "react";

import { ContainerBody } from "../styles";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { FiEdit2 } from "react-icons/fi";
import SimpleRuleModalComponent from "../SimpleRuleModal";
import NumberFormat from "react-number-format";
import { Container, Field, Table } from "./styles";

const AutomatedReviewRuleTable = ({ tableHead, tableDataType, tableData, handleEdit, handleDelete }) => {

    function formatDecimal(input) {
        // Check if input is a valid decimal number
        if (!isNaN(input) && !isNaN(parseFloat(input))) {
            // Convert to number to handle inputs like "000123.45000"
            const number = parseFloat(input);
            // Format the number with thousand separators and two decimal places
            return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        } else {
            // Return original input if it's not a valid decimal
            return input;
        }
    }

    return (
        <Container>
            <Table style={{ marginTop: '16px' }}>
                <thead className='second-header'>

                    {
                        tableHead?.map((head) => (
                            <th key={head.description} style={{ width: head.width ? head.width : "5%" }} className="column-head">
                                {head.description}
                            </th>
                        ))
                    }
                    <th style={{ width: "5%" }} className="column-head"></th>
                </thead>
                <tbody>
                    {
                        tableDataType == 0 ? <>
                            {
                                tableData?.map((data) => (
                                    <tr key={`srule_${data.ruleRegisterID}`}>
                                        <td className="column-cell-center">{data.groupIdentifier}</td>
                                        <td className="column-cell-center">{data.ruleRegisterID}</td>
                                        <td className="column-cell-center">{data.subSectionKPIRefNumber}</td>
                                        <td className="column-cell-center"><textarea className='input-text-field' type="text" value={data.subSectionKPIColumnDesc} disabled={true} /></td>
                                        <td className="column-cell"><textarea className='input-text-field' type="text" value={data.ruleDesc} disabled={true} /></td>
                                        <td className="column-cell-center">{formatDecimal(data.lowerLimit)}</td>
                                        <td className="column-cell-center">{formatDecimal(data.upperLimit)}</td>
                                        <td className="column-cell-center">{data.comparisonOperatorDescription}</td>
                                        <td className="column-cell-center">{formatDecimal(data.targetValue)}</td>
                                        <td className="column-cell-center">{data.linkedKPI}</td>
                                        <td className="column-cell"><textarea className='input-text-field' type="text" value={data.discussion} disabled={true} /></td>
                                        <td>
                                            <div className="action-icon" onClick={() => handleEdit(data)}>
                                                <FiEdit2></FiEdit2>
                                            </div>
                                            {!data.isUsedByOtherRule && <div className="action-icon" onClick={() => handleDelete(data)}>
                                                <FaTrashAlt></FaTrashAlt>
                                            </div>}
                                        </td>
                                    </tr>
                                ))
                            }
                        </> : tableDataType == 1 ? <>
                            {
                                tableData.filter(x => x.compoundType == 1)?.map((data) => (
                                    <tr key={`crule_${data.compoundRuleRegisterID}`}>
                                        <td className="column-cell-center">{data.groupIdentifier}</td>
                                        <td className="column-cell-center"> {data.compoundRuleRegisterID} </td>
                                        <td className="column-cell"> {data.rulesDescription} </td>
                                        <td className="column-cell"> <textarea className='input-text-field' type="text" value={data.discussion} disabled={true} /> </td>
                                        <td className="column-cell-center"> {data.logicOperator == 1 ? 'AND' : 'OR'} </td>
                                        <td>
                                            <div className="action-icon" onClick={() => handleEdit(data)}>
                                                <FiEdit2></FiEdit2>
                                            </div>
                                            {!data.isUsedByOtherRule && <div className="action-icon" onClick={() => handleDelete(data)}>
                                                <FaTrashAlt></FaTrashAlt>
                                            </div>}
                                        </td>
                                    </tr>
                                ))
                            }
                        </> : tableDataType == 2 ? <>
                            {
                                tableData.filter(x => x.compoundType == 2)?.map((data) => (
                                    <tr key={`ccrule_${data.compoundRuleRegisterID}`}>
                                        <td className="column-cell-center">{data.groupIdentifier}</td>
                                        <td className="column-cell-center"> {data.compoundRuleRegisterID} </td>
                                        <td className="column-cell"> {data.rulesDescription} </td>
                                        <td className="column-cell"> <textarea className='input-text-field' type="text" value={data.discussion} disabled={true} /> </td>
                                        <td className="column-cell-center"> {data.logicOperator == 1 ? 'AND' : 'OR'} </td>
                                        <td>
                                            <div className="action-icon" onClick={() => handleEdit(data)}>
                                                <FiEdit2></FiEdit2>
                                            </div>
                                            <div className="action-icon" onClick={() => handleDelete(data)}>
                                                <FaTrashAlt></FaTrashAlt>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </> : <></>
                    }
                </tbody>
            </Table>
        </Container>
    );
};

export default AutomatedReviewRuleTable
