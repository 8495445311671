import styled from 'styled-components';

export const Container = styled.div`  
    width: 100%;
    padding: 1rem;
  
  .d-block {
    display: block;
    width: 100%;
  }

  .d-block > div > p {
    position: absolute;
    right: 10px;
    top: -40px;
  }

  .d-block > div > div > div {
    height: 4px !important;
  }
`;

export const Header = styled.div`
    font-size: 14px;
    margin-bottom:0;
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
`;

export const ContentTitle = styled.div`
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #112A61;
    padding: 1rem 0;
    font-weight: 500;
`;

export const CommentText = styled.div`
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #000000;
    padding: 0rem 1rem 2rem; 
    font-style: italic; 
`;

export const PasswordInput = styled.input`
    background: #F6F8FA 0% 0% no-repeat padding-box;
    border: 1px solid #D3D2E4;
    border-radius: 4px;
    resize: vertical;
    width: 100%;
    margin: 0.5rem 0rem;
    position: relative;
    padding: 0.2rem;
`;

export const CancelButton = styled.button`
  background: #B0B0BB;
  border: 0;
  border-radius: 0.25rem;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  padding: 0.375rem 0.75rem;
  transition: background-color 0.2s;
  text-transform: uppercase;
  text-decoration: none;
  margin-right: 0.5rem!important;
  margin-left: auto!important;

  &:hover {
    background: #96969c;
  }

  &:focus{
    background: #96969c;
  }

  &:active{
    background: #96969c;
  }
`;


