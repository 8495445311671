import React, { useState } from 'react';
import TableCrud from '../../components/TableCrud';

import { Spreadsheet, createEmptyMatrix } from 'react-spreadsheet';
import { useEffect } from 'react';
import { FaNetworkWired } from 'react-icons/fa';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { useParams } from 'react-router-dom';
import { Container, ContainerHeader } from './styles';
import Button from '../../components/Button';
import { success_message, info_message, error_message } from '../../components/Toast/index';
import TableLoading from '../../components/TableLoading';
import NumberFormat from 'react-number-format';
import { useAuth } from '../../hooks/auth';
import Swal from 'sweetalert2';
import { FaTrashAlt } from 'react-icons/fa';
import optionsLogo from '../../assets/icons/options.svg';


const AdditionalDataFormIndex = () => {
    const columns = ["ID", "Supplier", "Volume - FCV", "Volume - BY", "Volume - DFC", "Volume - Others", "Volume - Total", " "]

    //Crop Year Select Variables
    const { userRoles } = useAuth();
    const [reportYears, setReportYears] = useState([]);
    const [volumeTypes, setVolumeTypes] = useState([]);
    const [version, setVersion] = useState(null);
    const [isEditable, setIsEditable] = useState(null);
    const [showButtons, setShowButtons] = useState(null);
    const [selectedReportYearID, setSelectedReportYearID] = useState(null);
    const [selectedVolumeTypeID, setSelectedVolumeTypeID] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [rowLabels, setRowLabels] = useState([]);
    const [dataSheet, setDataSheet] = useState([
        [],
        [],
    ]);


    const removeRow = (id) => {
        Swal.fire({
            title: 'Are you sure you want delete the register?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonColor: '#102A63',
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Yes'
        }).then((result) => {
        if (result.isConfirmed) {
            Api.post(`AdditionalData/delete?id=${id}`).then((result) => {
                if (selectedReportYearID && selectedVolumeTypeID) {
                    loadDataByReportYear(selectedReportYearID, selectedVolumeTypeID)
                }
            })
        }
        })
     }

     const deleteAll = () => {
        Swal.fire({
            title: 'Are you sure, you want delete all?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonColor: '#102A63',
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Yes'
        }).then((result) => {
        if (result.isConfirmed) {
            Api.post(`AdditionalData/deleteAll?reportYearID=${selectedReportYearID}&volumeTypeID=${selectedVolumeTypeID}`).then((result) => {                
                if(result.data.response == true) {
                    if (selectedReportYearID && selectedVolumeTypeID) {
                        success_message("All values was deleted successfully!")
                        setTimeout(() => {
                            loadDataByReportYear(selectedReportYearID, selectedVolumeTypeID)
                        },2000)
                        
                    }
                }
                else {
                    error_message("Some error was happened. Please try again or contact the administrator.")
                }
            })
        }
        })
     }


    const InputNumberEdit = ({ cell, onChange }) => {
        console.log(cell.className)
        var rowID = cell.className.split("-");
        console.log(rowID[1])

        return (
            <div style={{ padding: '0.5rem 1rem', textAlign:"center"}}  onClick={() => removeRow(rowID[1])}>
                <FaTrashAlt />
            </div>
        );
    };

    const InputOptions = ({ cell, onChange }) => {

        return (
            <div style={{ padding: '0.5rem 1rem'}}>
                <img src={optionsLogo} alt="" />
            </div>
        );
    };


    useEffect(() => {
        loadReportYears()
        loadAllVolumesTypes()
    }, [])


    useEffect(() => {
        if (selectedReportYearID && selectedVolumeTypeID) {
            loadDataByReportYear(selectedReportYearID, selectedVolumeTypeID)
            setIsLoading(true);
        }
    }, [selectedReportYearID, selectedVolumeTypeID])

    const loadReportYears = (userAux) => {
        setIsLoading(true)
        Api.get(`thriveValue/getAllCropsYears`).then((result) => {
            if (result && result.data) {
                setReportYears(result.data.response);
                setSelectedReportYearID(result.data.response[0].reportYearID);
                setIsLoading(false)
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadAllVolumesTypes = (userAux) => {
        setIsLoading(true)
        Api.get(`VolumeType/getAll`).then((result) => {
            if (result && result.data) {
                setVolumeTypes(result.data.response);
                setSelectedVolumeTypeID(result.data.response[0].volumeTypeID);
                setIsLoading(false)

            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const onChangeCropID = (value) => {
        setSelectedReportYearID(value);
    }

    const onChangeVolumeID = (value) => {
        setSelectedVolumeTypeID(value);
    }

    const handleApprove = async (event) => {
        event.preventDefault();

        Swal.fire({
            title: 'Are you sure you want to approve this version?',
            showCancelButton: true,
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Yes',
            confirmButtonColor: '#112A61',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
            if (result.isConfirmed) {
                setIsLoading(true);

                const objToRequest = {
                    "reportYearID": selectedReportYearID,
                    "volumeTypeID": selectedVolumeTypeID
                }

                await Api.post(`AdditionalData/approve`, objToRequest).then((result) => {
                    success_message("The data was approved successfully!");
                    setIsLoading(false);
                    loadDataByReportYear(selectedReportYearID, selectedVolumeTypeID);

                }).catch((error) => {
                    exceptionNotificationAPI(error);
                    setIsLoading(false);

                });
            }
        })
    }

    const handleSend = async (event) => {
        event.preventDefault();

        Swal.fire({
            title: 'Are you sure you want to submit this version?',
            text: "The version will be sent for approval.",
            icon: 'warning',
            showDenyButton: true,
            confirmButtonColor: '#112A61',
            denyButtonColor: '#B0B0BB',
            denyButtonText: 'No',
            confirmButtonText: 'Yes',
            showCloseButton: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                var objAdditionalData = {
                    reportYearID: selectedReportYearID,
                    data: dataSheet,
                    volumeTypeID: selectedVolumeTypeID
                }
                setIsLoading(true);
                console.log(objAdditionalData)

                Api.post(`AdditionalData/send`, objAdditionalData).then((result) => {
                    success_message("The version was sent successfully!");
                    setIsLoading(false);
                    loadDataByReportYear(selectedReportYearID, selectedVolumeTypeID);

                }).catch((error) => {
                    exceptionNotificationAPI(error);
                    setIsLoading(false);
                });

            }

        })

    }

    const handleReactive = async (event) => {
        event.preventDefault();

        Swal.fire({
            title: 'Are you sure you want to reactive this version?',
            showCancelButton: true,
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Yes',
            confirmButtonColor: '#112A61',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
            if (result.isConfirmed) {
                setIsLoading(true);

                const objToRequest = {
                    "reportYearID": selectedReportYearID,
                    "volumeTypeID": selectedVolumeTypeID
                }

                await Api.post(`AdditionalData/reactive`, objToRequest).then((result) => {
                    success_message("The data was reactivated successfully!");
                    setIsLoading(false);
                    loadDataByReportYear(selectedReportYearID, selectedVolumeTypeID);

                }).catch((error) => {
                    exceptionNotificationAPI(error);
                    setIsLoading(false);

                });
            }
        })

    }

    const handleReject = async (event) => {
        event.preventDefault();

        Swal.fire({
            title: 'Are you sure you want to reject this version?',
            showCancelButton: true,
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Yes',
            confirmButtonColor: '#112A61',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
            if (result.isConfirmed) {
                setIsLoading(true);

                const objToRequest = {
                    "reportYearID": selectedReportYearID,
                    "volumeTypeID": selectedVolumeTypeID
                }

                await Api.post(`AdditionalData/reject`, objToRequest).then((result) => {
                    success_message("The data was rejected successfully!");
                    setIsLoading(false);
                    loadDataByReportYear(selectedReportYearID, selectedVolumeTypeID);

                }).catch((error) => {
                    exceptionNotificationAPI(error);
                    setIsLoading(false);

                });
            }
        })

    }

    const loadDataByReportYear = (selectedReportYear, selectedVolumeTypeID) => {
        if (selectedReportYear) {
            Api.get(`AdditionalData/getAllByCrop?reportYearID=${selectedReportYear}&volumeTypeID=${selectedVolumeTypeID}`).then((result) => {
                if (result && result.data) {
                    console.log(result.data.response)
                    setVersion(result.data.response.version)
                    var isDataEditable = false;
                    var resultList = result.data.response.data;
                    var newLine = [
                        { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }
                    ]
                    resultList.push(newLine)


                    if (userRoles.includes("RoleAdmin")) {
                        if (result.data.response.version != null) {
                            if (result.data.response.version.status == 4) {
                                setIsEditable(false);
                                setShowButtons(true);
                            }
                            else {
                                setIsEditable(false);
                                setShowButtons(false);
                            }
                        }
                        else {
                            setIsEditable(false);
                            setShowButtons(false);
                        }
                    } else if (userRoles.includes("RoleVolumeDataInfoHolder")) {
                        if (result.data.response.version != null) {
                            if (result.data.response.version.status == 1 || result.data.response.version.status == 3 || result.data.response.version.status == 5) {
                                setIsEditable(true);
                                setShowButtons(true);
                                isDataEditable = true;
                            }
                            else if (result.data.response.version.status == 2 || result.data.response.version.status == 4) {
                                setIsEditable(false);
                                setShowButtons(false);
                            }
                        }
                        else {
                            setIsEditable(true);
                            setShowButtons(true);
                            isDataEditable = true;
                        }
                    } else if (userRoles.includes("RoleVolumeDataApprover")) {
                        if (result.data.response.version != null) {
                            if (result.data.response.version.status == 2) {
                                setIsEditable(false);
                                setShowButtons(true);
                            }
                            else {
                                setIsEditable(false);
                                setShowButtons(false);
                            }
                        }
                        else {
                            setIsEditable(false);
                            setShowButtons(false);
                        }
                    }


                    try {
                        resultList.map((row) => {
                            var newRow = row.map((col) => {
                                var newCol = { ...col };
                                return newCol;
                            })
                            return newRow;
                        })

                        try {
                            resultList.map((row) => {
                                row[0] = { value: row[0].value, readOnly: true, active: false }
                                var col1 = row[2].value != "" ? parseFloat(row[2].value.replaceAll(',', '')) + "" : "0";
                                var col2 = row[3].value != "" ? parseFloat(row[3].value.replaceAll(',', '')) + "" : "0";
                                var col3 = row[4].value != "" ? parseFloat(row[4].value.replaceAll(',', '')) + "" : "0";
                                var col4 = row[5].value != "" ? parseFloat(row[5].value.replaceAll(',', '')) + "" : "0";



                                var expression = col1 + "+" + col2 + "+" + col3 + "+" + col4;
                                console.log(expression);

                                row[6] = { value: !isNaN(expression) ? "0" : eval(expression).toLocaleString('en') };

                                row[7] = { value: " ", readOnly: false, className: 'remove-' +row[0].value, onClick: () => removeRow(row[0].value)};
                                row[7].DataViewer = InputOptions;
                                row[7].DataEditor = InputNumberEdit;

                                if (!isDataEditable) {
                                    row.map((item) => {
                                        item.readOnly = true;
                                    })
                                }
                            })

                        } catch { }
                        setDataSheet(resultList);
                        setIsLoading(false)
                    } catch (e) {
                        console.log(e)
                    }

                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }

    }

    const save = () => {
        var objAdditionalData = {
            reportYearID: selectedReportYearID,
            data: dataSheet,
            volumeTypeID: selectedVolumeTypeID
        }
        setIsLoading(true);
        console.log(objAdditionalData)

        Api.post(`AdditionalData/New`, objAdditionalData).then((result) => {
            if (result && result.data) {
                setIsLoading(false);
                if (result.data.response == true) {
                    success_message("The data was saved!");
                    loadDataByReportYear(selectedReportYearID, selectedVolumeTypeID)
                }
                else {
                    info_message("No data to saved!");
                }
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const onChangeTable = (e) => {
        try {
            setDataSheet(e);
        } catch { }
    }


    const onBlurTable = () => {
        try {
            var nesw = dataSheet.map((row) => {
                var newRow = row.map((col) => {
                    var newCol = { ...col };
                    return newCol;
                })
                return newRow;
            })

            try {
                nesw.map((row) => {
                    row[0] = { value: row[0].value, readOnly: true, active: false}
                    var col1 = row[2].value != "" ? parseFloat(row[2].value.replaceAll(',', '')) + "" : "0";
                    var col2 = row[3].value != "" ? parseFloat(row[3].value.replaceAll(',', '')) + "" : "0";
                    var col3 = row[4].value != "" ? parseFloat(row[4].value.replaceAll(',', '')) + "" : "0";
                    var col4 = row[5].value != "" ? parseFloat(row[5].value.replaceAll(',', '')) + "" : "0";

                    var expression = col1 + "+" + col2 + "+" + col3 + "+" + col4;
                    console.log(expression);

                    row[6] = { value: !isNaN(expression) ? "0" : eval(expression).toLocaleString('en') };
                    row[7] = { value: " ", readOnly: false, className: 'remove-' +row[0].value, onClick: () => removeRow(row[0].value)};
                    row[7].DataViewer = InputOptions;
                    row[7].DataEditor = InputNumberEdit;

                    if (!isEditable) {
                        row.map((item) => {
                            item.readOnly = true;
                        })
                    }
                })

            } catch { }
            setDataSheet(nesw);
        } catch (e) {
            console.log(e)
        }
    }



    return (
        <Container className="container">
            <div style={{ margin: '2rem' }}>
                <div className='row'>
                    <div className="col-7 d-flex header">
                        <h3>Additional Data - Volumes</h3>
                        {version != null && (version.status == 1 || version.status == 2 || version.status == 4) ?
                            <p className='status' style={{ backgroundColor: '#abf0b0' }}>{version != null ? version.statusName : ""}</p>
                            : version != null && (version.status == 3 || version.status == 5) ?
                                <p className='status' style={{ backgroundColor: '#fb9d9d' }}>{version != null ? version.statusName : ""}</p>
                                :
                                <p className='status' style={{ backgroundColor: '#e6e6e6' }}>{"Not Started"}</p>
                        }

                    </div>
                    {userRoles.includes("RoleVolumeDataApprover") && showButtons && !isEditable && //Approver
                        <div className='col-5 d-flex justify-content-end'>
                            <div className="reject-btn">
                                <button className="" type="button" onClick={(e) => handleReject(e)}>REJECT</button>
                            </div>
                            <div className="approve-btn">
                                <button className="" type="button" onClick={(e) => handleApprove(e)}>APPROVE</button>
                            </div>
                            
                        </div>
                    }
                    {userRoles.includes("RoleAdmin") && showButtons && !isEditable && //Admin
                        <div className='col-5'>
                            <div className="approve-btn">
                                <button className="approve-btn" type="button" onClick={(e) => handleReactive(e)}>REACTIVATE</button>
                            </div>
                        </div>
                    }
                    {userRoles.includes("RoleVolumeDataInfoHolder") && showButtons &&
                        <div className='col-5 d-flex justify-content-end'>
                            <div className="send-btn">
                                <button className='' onClick={(e) => handleSend(e)}>SEND</button>
                            </div>
                            <div className="save-btn">
                                <button className='' onClick={() => save()}>SAVE DRAFT</button>
                            </div>
                            {version != null && version.status == 1 &&
                            <div className="send-btn">
                                <button className="" type="button" onClick={(e) => deleteAll()}>DELETE ALL</button>
                            </div>
                            }
                        </div>
                    }
                </div>
            </div>
            <div className="row mt-2 mx-4">
                <div className="col-md-6 mt-2">
                    <label className='label'>Select Reporting Year</label>
                    <select className="col-md-12 mt-2" onChange={(x) => onChangeCropID(x.target.value)} value={selectedReportYearID ? selectedReportYearID : ""}>
                        {
                            reportYears ?
                                reportYears.map(reportYear => {
                                    return <option key={reportYear.year} value={reportYear.reportYearID}>{reportYear.year}</option>
                                })
                                :
                                <></>
                        }
                    </select>
                </div>
                <div className="col-md-6 mt-2">
                    <label className='label'>Select Volume Type</label>
                    <select className="col-md-12 mt-2" onChange={(x) => onChangeVolumeID(x.target.value)} value={selectedVolumeTypeID ? selectedVolumeTypeID : ""}>
                        {
                            volumeTypes ?
                                volumeTypes.map(volume => {
                                    return <option key={volume.volumeTypeID} value={volume.volumeTypeID}>{volume.name}</option>
                                })
                                :
                                <></>
                        }
                    </select>
                </div>
            </div>
            {!isLoading ?
                <Spreadsheet data={dataSheet} columnLabels={columns} rowLabels={rowLabels} onBlur={() => onBlurTable()} onChange={(e) => onChangeTable(e)} />
                :
                <TableLoading />
            }

        </Container>

    )
}

export default AdditionalDataFormIndex;
