import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import api, { exceptionNotificationAPI } from '../../services/api';
import { success_message } from '../../components/Toast/index.jsx';
import PasswordStrengthBar from 'react-password-strength-bar';
import { Container, PasswordInput } from './styles';

const ForgotPassword = () => {
    const { signOut } = useAuth();
    const history = useHistory();
    const { token, id } = useParams();
    const [password, setPassword] = useState();
    const [passwordCfr, setPasswordCfr] = useState();

    const handleSubmit = async () => {
        try {
            const userPasswordRequest = {Id: id, Password:password, ConfirmPassword:passwordCfr, Token: token}

            const result = await api.post('/forgotPassword', userPasswordRequest);

            if(result.data.response){
                success_message("Password updated successfuly");
                setTimeout(() => {
                    signOut(true)
                }, 1000);
            }
            
        } catch (error) {
            exceptionNotificationAPI(error);
        }
    };

    const onEnterPress = (e) => {
        if(e.key == 'Enter'){
            handleSubmit();
        }
    }

    return (
        <Container>
            <div className="main-div container" align="center">
                <div className="card">
                    <div className="card-body">
                        <div className="form-group">
                        <label>New Password</label>
                            <PasswordInput type="password" name="Password" id="newPassword" 
                                onChange={(e) => {setPassword(e.target.value);}}></PasswordInput>
                            <PasswordStrengthBar password={password} minLength={10} />
                        </div>                   
                        <div className="form-group">
                        <label>Confirm New Password</label>
                            <PasswordInput type="password" name="ConfirmPassword" id="confirmNewPassword"
                              onChange={(e) => {
                                  setPasswordCfr(e.target.value);}}></PasswordInput>
                            <PasswordStrengthBar password={passwordCfr} minLength={10} />
                        </div>                   
                        <button className="btn" onClick={handleSubmit}>Change password</button>
                    </div>
                </div>
                <div className="card card-terms mb-2">
                    <div className="terms">
                            <p>Third parties' representatives accessing this tool can learn more about how BAT is processing personal data for business relationships from <a href="https://www.bat.com/group/sites/UK__9D9KCY.nsf/vwPagesWebLive/DOBB5H9Z" target="_blank" rel="noreferrer">here</a></p>
                            <p>BAT employees accessing this tool can learn more about how their personal data is processed by checking the local BAT Employees Privacy Notice where applicable. In case of queries, please contact your local LEX team.</p>
                        </div>
                </div>                
            </div>
        </Container>
    );
};

export default ForgotPassword;
