import React from 'react';
import TableCrud from '../../components/TableCrud';

const SubSectionIndex = () => {
    const columns = [
        {
            label: "Sub Section",
            name: "subSection.description"
        },
        {
            label: "Ref Number",
            name: "refNumber"
        },
        {
            label: "KPI",
            name: "kpi.description"
        },
        {
            label: "Column Row",
            name: "column.name"
        },
        {
            label: "Report Year",
            name: "reportYear.year"
        },
        {
            label: "Active",
            name: "isActive"
        },
        {
            label: "Editable",
            name: "isDeleted"
        }
    ]

    return (
        <TableCrud title={"Sub Section KPI"} table={"subSectionKPI"} columns={columns}/>
    );
}

export default SubSectionIndex;
