
import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

export const Container = styled.div`
  align-items: center;
  background: transparent;
  justify-content: center;
  color: #495057;
  display: flex;
  width: 100%;

  & + div {
    margin-top: 8px;
  }

  .d-block {
    display: block;
    width: 100%;
  }

  .d-block > div > p {
    position: absolute;
    right: 10px;
    top: -35px;
  }

  .d-block > div > div > div {
    height: 4px !important;
  }
  
  .form-check-input[type=checkbox] {
    flex: 0 !important;
    height: 1.5rem;
  }

  input, select, textarea {
    background: transparent;
    color: #495057;
    background-color: #FFFFFF;
    flex: 1;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    width: 100%;
    
    ${props =>
    props.isErrored &&
    css`
        border-color: #ff0000;
      `}
    ${props =>
    props.isFilled &&
    css`
      color: #333333;
    `}

    &::placeholder {
      color: #e2e2e2;
    }

    &:focus{
      box-shadow: 0;
    }

    &:active{
      box-shadow: 0;
    }

    &:focus-visible{
      box-shadow: 0;
      outline: none;
    }

    &:disabled{
      background-color: #e9ecef;
    }

    option{
      background-color: #FFFFFF;
    }
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 8px;
  text-align: center;
  opacity: 0;

  ${props =>
    props.title &&
    css`
    opacity: 1;
  `}

  svg {
    margin: 0;
    display: table-cell;
    vertical-align: middle;
    background-color: #FFFFFF;
  }

  span {
    background: #e71700;
    color: #FFFFFF;
    &::before {
      border-color: #e71700 transparent;
    }
  }

  

  &.hide{
    display: none;
  }
`;
