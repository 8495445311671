import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { useHistory } from "react-router";
import Select from 'react-select';
import { Container, Header } from './styles';

export default function ModalSimulation({ continueCuringSimulations, suppliers, groups, typeSimulation, simpleGroup, companies, jump, ...props }) {
  const history = useHistory();
  const [selectedType, setSelectedType] = useState(typeSimulation != null ? (typeSimulation == 1 ? { value: 1, label: "Simple" } : typeSimulation == 2 ? { value: 2, label: "Grouped" } : null) : null);
  const [selectedSuppliers, setSelectedSuppliers] = useState(typeSimulation != null && typeSimulation == 1 ? simpleGroup?.map(x => ({ value: x.companyID, label: x.companyName })) : null);
  const [selectedGroups, setSelectedGroups] = useState(typeSimulation != null && typeSimulation == 2 ? simpleGroup?.map(x => ({ value: x.groupID, label: x.groupName })) : null);
  const optionsType = [{ value: 0, label: "Select the simulation type" }, { value: 1, label: "Simple" }, { value: 2, label: "Grouped" }];

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <ModalHeader closeButton>
        <ModalTitle id="contained-modal-title-vcenter">
          <Header>{props.title}</Header>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Container>
          <label className='label'>Simulation type:</label>
          <Select
            onChange={(x) => {
              setSelectedType(x);
              setSelectedGroups(null);
              setSelectedSuppliers(null);
            }}
            value={selectedType}
            isDisabled={typeSimulation != null}
            options={optionsType}
            closeMenuOnSelect={true}
            className="col-md-12 multi-select"
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          />
          {
            selectedType && <React.Fragment>
              {
                selectedType.value == 1 &&
                <>
                  <label style={{ marginTop: 20 }} className='label'>Select supplier:</label>
                  <Select
                    options={suppliers?.filter(x => companies.includes(x.value)).map((option) => {
                      var data = {
                        value: option.value,
                        label: option.text
                      }
                      return data;
                    })}
                    value={selectedSuppliers}
                    isMulti
                    closeMenuOnSelect={false}
                    onChange={(a) => { setSelectedSuppliers(a) }}
                    className="col-md-12 multi-select"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  />
                </>
              }
              {selectedType.value == 2 &&
                <>
                  <label style={{ marginTop: 20 }} className='label'>Select group:</label>
                  <Select
                    options={groups?.map((option) => {
                      var data = {
                        value: option.value,
                        label: option.text
                      }
                      return data;
                    })}
                    value={selectedGroups}
                    isMulti
                    closeMenuOnSelect={false}
                    onChange={(a) => { setSelectedGroups(a) }}
                    className="col-md-12 multi-select"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  />
                </>
              }
            </React.Fragment>
          }
          <div className="div-buttons">
            <button type="button" style={{ margin: "2.92rem 0rem 0rem 0" }} onClick={() => {
              continueCuringSimulations(selectedType, selectedGroups, selectedSuppliers, jump)
            }} className="btn btn-custom">Continue</button>
          </div>
        </Container>
      </ModalBody>
    </Modal>
  );
}

