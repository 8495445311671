import React from 'react';
import { CustomCard } from './styles'; // Import your styles from the styles file
import { Container } from 'react-bootstrap';
import { useState } from 'react';
import { useEffect } from 'react';
import Api, { exceptionNotificationAPI } from '../../services/api';
import AutomatedReviewRules from '../../components/AutomatedReviewRules';
import Select from 'react-select';
import TableLoading from '../../components/TableLoading';

const AutomatedReviewForm = () => {
    const [reportYearList, setReportYearList] = useState([]);
    const [selectedReportYear, setSelectedReportYear] = useState();
    const [subSectionKpis, setSubSectionKpis] = useState([]);
    const [typeValues, setTypeValues] = useState([]);
    const [rules, setRules] = useState([]);
    const [modules, setModules] = useState([]);
    const [selectedModule, setSelectedModule] = useState();

    const [simpleRuleList, setSimpleRuleList] = useState([]);
    const [compoundRuleList, setCompoundRuleList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const typeOptions = [
        { value: 1, description: "Simple" },
        { value: 2, description: "Country" },
        { value: 3, description: "FuelType" },
        { value: 4, description: "TobaccoType" },
        { value: 6, description: "Curing Barn" }
    ];

    const operatorOptions = [
        { value: 1, description: "+-" },
        { value: 2, description: "<" },
        { value: 3, description: "<>" },
        { value: 4, description: "=" },
        { value: 5, description: ">=" }
    ];

    const logicOptions = [
        { value: 1, label: "AND" },
        { value: 2, label: "OR" },
    ];

    // Report year data
    const loadReportYearList = (userObjectAux) => {
        Api.get(`Dashboard/getAllCropsYears`).then((result) => {
            if (result && result.data) {
                let reportYearData = result.data?.response?.map(year => ({
                    value: year.reportYearID,
                    label: year.year
                }));
                setReportYearList(reportYearData);
                var reportYear = result.data.response.find(x => x.year == ("" + new Date().getFullYear()));
                if (reportYear != null) {
                    setSelectedReportYear(reportYear.reportYearID);
                    loadModules(reportYear.reportYearID);
                }
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    // Modules data
    const loadModules = (reportYearID) => {
        Api.get(`Dashboard/GetAllByReportYear?reportYearID=${reportYearID}`).then((result) => {
            if (result && result.data) {
                let moduleData = result.data?.response?.map(year => ({
                    value: year.moduleID,
                    label: year.description
                }));
                setModules(moduleData);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    // Types data
    const loadTypeValues = () => {
        Api.get(`Rule/GetAllTypeValues`).then((result) => {
            if (result && result.data) {
                setTypeValues(result.data.response);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    // Rules data
    const loadRules = () => {
        Api.get(`Rule/GetAllRules`).then((result) => {
            if (result && result.data) {
                setRules(result.data.response);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    // Simple Rules data
    const loadSimpleRules = (yearID, modID) => {
        setIsLoading(true);
        Api.get(`Rule/getSimpleRules?reportYearID=${yearID}&moduleID=${modID}`).then((result) => {
            if (result && result.data) {
                setSimpleRuleList(result.data.response);
                setIsLoading(false);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
            setIsLoading(false);
        });
    }

    // Simple Rules data
    const loadCompoundRules = (yearID, modID) => {
        setIsLoading(true);
        Api.get(`Rule/getCompoundRules?reportYearID=${yearID}&moduleID=${modID}`).then((result) => {
            if (result && result.data) {
                setCompoundRuleList(result.data.response);
                setIsLoading(false);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
            setIsLoading(false);
        });
    }

    const handleReportYearChange = (event) => {
        const selectedYear = event.value;
        setSelectedReportYear(selectedYear);
        setSelectedModule(null);
        loadModules(selectedYear);
    };


    const handleModuleChange = (event) => {
        const selectedModuleID = event.value;
        setSelectedModule(selectedModuleID);
        Api.get(`Rule/getAllSskByModule?reportYearID=${selectedReportYear}&moduleID=${selectedModuleID}`).then((result) => {
            if (result && result.data) {
                setSubSectionKpis(result.data.response);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
        loadSimpleRules(selectedReportYear, selectedModuleID);
        loadCompoundRules(selectedReportYear, selectedModuleID);
    };

    useEffect(() => {
        var userObjectAux = JSON.parse(localStorage.getItem('@Thrive:user'));
        loadReportYearList(userObjectAux);
        loadTypeValues();
        loadRules();
    }, []);

    return (
        <Container>
            <CustomCard>
                <header className="header">
                <h2>Automated Review Rules</h2>
                </header>
                <div className='row filters-section'>
                    <div className='col-md-2'>
                        <labe>
                            Report Year
                        </labe>
                        <Select
                            name="reportYear"
                            options={reportYearList}
                            className="basic-select"
                            classNamePrefix="select"
                            value={reportYearList.find(x => x.value == selectedReportYear)}
                            onChange={handleReportYearChange}
                        />
                    </div>
                    <div className='col-md-4'>
                        <labe>
                            Module
                        </labe>
                        <Select
                            name="module"
                            options={modules}
                            className="basic-select"
                            classNamePrefix="select"
                            value={modules.find(x => x.value == selectedModule) || null}
                            onChange={handleModuleChange}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div>
                        { isLoading &&  <TableLoading/> }
                    </div>
                    {
                        !isLoading && selectedReportYear && selectedModule ?
                        <AutomatedReviewRules
                            reportYearList={reportYearList}
                            reportYearID={selectedReportYear}
                            moduleList={modules}
                            moduleID={selectedModule}
                            subSectionKpisList={subSectionKpis}
                            typeOptions={typeOptions}
                            typeValues={typeValues}
                            rules={rules}
                            logicOperators={operatorOptions}
                            simpleRuleList={simpleRuleList}
                            loadSimpleRules={loadSimpleRules}
                            compoundRuleList={compoundRuleList}
                            loadCompoundRules={loadCompoundRules}
                            logicOptions={logicOptions}
                        />
                        :
                        <></>
                    }
                </div>
            </CustomCard>
        </Container>
    );
};

export default AutomatedReviewForm;
