import React, { useEffect, useState } from 'react';

import Api, { exceptionNotificationAPI } from '../../services/api';
import FormCrud from '../../components/FormCrud';

const ReportYearForm = () => {

    const activeOptions = [
        { value: true, text: "Yes" },
        { value: false, text: "No" }
    ];

    const fields = [
        {
            label: "Report Year",
            name: "year",
            type: "input"
        },
        {
            label: "Start Date",
            name: "beginDate",
            type: "date"
        },
        {
            label: "End Date",
            name: "endDate",
            type: "date"
        },
        {
            label: "ESG Companies",
            name: "reportYearCompany",
            type: "select",
            required: false,
            multiple: true
        },
        {
            label: "Is Active",
            name: "isActive",
            type: "select",
            options: activeOptions
        },
    ];

    return (
        <FormCrud formName={"reportYear"} title={"Report Year"} fields={fields}/>
    );
}

export default ReportYearForm;
