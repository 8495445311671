import React from 'react';
import TableCrud from '../../components/TableCrud';

const ReadConsentIndex = () => {
    const columns = [
        {
            label: "User",
            name: "userName",
            type: "text"
        }
    ]

    return (
        <TableCrud title={"Read Consent"} table={"readConsent"} columns={columns}/>
    );
}

export default ReadConsentIndex;
