import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const ContainerHeader = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-bottom: 25px;
`;

export const Title = styled.h3`
    color: #27b5e5;
    text-align: left;
    font: normal normal bold 22px Segoe UI;
    letter-spacing: 1.1px;
`;

export const Actions = styled.div`
    display: flex;
`;

export const Table = styled.table`
    border-radius: 7px!important;
    background: white;
    width: 100%;
    border-spacing: 0px;
    border-collapse: separate;
    border: 1px solid #5E7BB9;
    border-radius: 8px 8px 8px 7px;
    opacity: 1;

    tr:nth-child(odd) > td,
    tr:nth-child(odd) > th {
        background-color: rgba(0,0,0,.05);
    }

    tr:nth-child(even) > td,
    tr:nth-child(even) > th {
        background-color: white;
    }

    th {
        text-align: left!important;
        font: normal normal medium 12px Segoe UI!important;
        letter-spacing: 0px!important;
        opacity: 1!important;
        background-color: #234285!important;
        color: #D2D9E6!important;
    }

    tr:last-child {
        td:first-child {
            border-bottom-left-radius: 7px;
        }

        td:last-child {
            border-bottom-right-radius: 7px;
        }
    }

    th:first-child {
        border-top-left-radius: 7px;
    }

    th:last-child {
        border-top-right-radius: 7px;
    }

    td:first-child {
        padding-top: 20px!important;
    }

    th, td {
        border-top: 0px!important;
        padding-left: 35px!important;
        padding-top: 15px!important;
        padding-bottom: 12px!important;
        padding-right: 12px!important;
        font-size: 14px;
        letter-spacing: 0.7px;
        opacity: 1;
        border: 0;
    }
`;


export const ContainerHeaderDetail = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-bottom: 25px;
    margin-top: 42px;
`;

export const TableDetail = styled.table`
    font-size: 13px;

    thead{
        background-color: #102A63;
        height: 40px;

        tr{
            th{
                text-align: center;
                font-weight: bold;
                color: #FFFFFFFF;
                padding: 14px;
            }

            th:first-child{
                border-radius: 8px 0px 0px 0px;
            }

            th:last-child{
                border-radius: 0px 8px 0px 0px;
            }
        }
    }

    tbody{
        background-color: #FFFFFFFF;
        box-shadow: 0px 3px 20px #0000000d;

        tr{
            border: 1px solid #ECECF3;
            border-left: #FFFFFF;
            border-right: #FFFFFF;

            td{
                text-align: center;
                padding: 14px;

                &:last-child{
                    .btn{
                        background-color: transparent;
                        border: 0;
                        padding: 0;
                        margin: 0;

                        svg{
                            color: #B0B0BB;
                            
                            &:hover{
                                color: #102A63;
                            }
                        }

                        &:focus{
                            background-color: transparent;
                            border: 0;
                            padding: 0;
                            margin: 0;
                            box-shadow: 0 0 0 0 transparent;
                        }

                        &:active{
                            background-color: transparent;
                            border: 0;
                            padding: 0;
                            margin: 0;
                            box-shadow: 0 0 0 0 transparent;
                        }
                    }

                    .dropdown-menu{
                        box-shadow: 0px 3px 20px #00000026;
                        border: 1px solid #ECECF3;
                        border-radius: 4px;
                        font-size: 13px;

                        .dropdown-item{
                            padding-left: 19px;
                            padding-right: 19px;

                            &:active{
                                background-color: #102A63;
                            }
                        }
                    }
                }
            }

            .no-records{
                text-align: center;
            }

            &:last-child{
                td:first-child{
                    border-radius: 0px 0px 0px 8px;
                }

                td:last-child{
                    border-radius: 0px 0px 8px 0px;
                }

                border-bottom: #F6F8FA;
            }
        }

        
    }
`;