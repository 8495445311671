import TableCrud from '../../components/TableCrud';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/auth';
import { useParams } from 'react-router-dom';
import TableLoading from '../../components/TableLoading/index';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { Container, Table, Card, textarea } from './styles';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FaAngleDown, FaAngleUp, FaTrashAlt } from 'react-icons/fa';
import { Input, TextField } from '@material-ui/core';
import { error_message, success_message } from '../../components/Toast/index';
const RoleYearConfig = () => {
  const { user, userRoles } = useAuth();
  const [loadingView, setLoadingView] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showAll, setShowAll] = useState(false);
  const [roleConfigList, setRoleConfigList] = useState([]);

  const loadRoles = () => {
    setLoadingView(true);
    Api.get(`RoleYearConfiguration/getRoles`)
      .then(result => {
        if (result && result.data) {
          setRoleConfigList(result.data.response);
        }
        setLoadingView(false);
      })
      .catch(error => {
        exceptionNotificationAPI(error);
        setLoadingView(false);
      });
  };

  function updateTurningDate(roleIDToUpdate, newTurningDate) {
    var newRoleConfigList = roleConfigList.map(role => {
      if (role.roleID === roleIDToUpdate) {
        // Update the turningDate for the specified roleID
        return { ...role, turningDate: newTurningDate };
      }
      return role;
    });
    setRoleConfigList(newRoleConfigList);
  }

  const save = async () => {
    setLoadingView(true);
    await Api.post('RoleYearConfiguration/save', roleConfigList)
      .then(async result => {
        if (result && result.data) {
          loadRoles();
          setLoadingView(false);
          success_message('The data was saved!');
        }
      })
      .catch(error => {
        exceptionNotificationAPI(error);
        setLoadingView(false);
      });
  };

  useEffect(() => {
    loadRoles();
  }, []);

  return (
    <Container className="container">
      <Card className="show">
        <div className="card">
          <div className="card-row">
            <div className="card-body">
              <div className="row my-2" style={{ width: '100%' }}>
                <h4 style={{ fontWeight: 900 }}>
                  Role default year configuration
                </h4>
                <hr />

                {roleConfigList.map(role => {
                  return (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          marginBottom: '10px',
                          paddingLeft: '0',
                        }}
                      >
                        <div
                          className="col-md-10"
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '2.7rem',
                            marginRight: '1rem',
                          }}
                        >
                          <input
                            autoComplete={'off'}
                            type={'text'}
                            disabled={'true'}
                            defaultValue={role.roleName}
                            placeholder={'Description'}
                          />
                        </div>

                        <div
                          className="col-md-2"
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: '2.7rem',
                            marginRight: '1rem',
                          }}
                        >
                          <div className="row">
                            <div className="col-md-10">
                              <DatePicker
                                name={'roleDate'}
                                id={'roleDate'}
                                selected={
                                  role.turningDate
                                    ? new Date(role.turningDate)
                                    : null
                                }
                                autoComplete="off"
                                dateFormat="dd/MM/yyyy"
                                className={`react-datepicker-popper col w-100`}
                                onChange={date => {
                                  updateTurningDate(role.roleID, date);
                                }}
                              />
                            </div>
                            <div
                              className="col-md-2"
                              onClick={() => {
                                updateTurningDate(role.roleID, null);
                              }}
                            >
                              <FaTrashAlt
                                size={'1.25rem'}
                                style={{ margin: '8px 4px 0 5px' }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div
          className="row mt-2"
          style={{ paddingLeft: '10px', marginTop: '10px' }}
        >
          {loadingView ? (
            <div className="mt-2" style={{ padding: 0 }}>
              <TableLoading />
            </div>
          ) : (
            <button className="col apply-btn" onClick={() => save()}>
              Apply
            </button>
          )}
        </div>
      </Card>
    </Container>
  );
};

export default RoleYearConfig;
