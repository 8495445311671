// StyledModal.js
import styled from 'styled-components';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Ensures the backdrop is above other content */
`;

export const ModalContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 50%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 11; /* Ensures the modal content is above the backdrop */
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 500px;

  .modal-header {
    position: sticky;
    top: -20px;
    background-color: white; /* Or any color that matches your modal's theme */
    z-index: 12;
    margin-bottom: 2rem;
    border-bottom: 1px solid #112A61;
    padding-bottom: 1rem;
    padding-top: 1rem;
    h2{
      color: #112A61;
      font-weight: bold;
    }
  }

  .remove-icon{
    display: flex;
    height: 2.5rem;
    width: 2.5rem;
    padding: 0.5rem;
    border-radius: 1.5rem;
    float: right;
    margin-right: -5px;
    rotate: 45deg;
    &:hover {
      background: rgba(0,0,0,0.1);
    }
  }
`;


export const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  /* Add additional styling for the form */

`;

export const ModalButton = styled.button`
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  background-color: #112A61;
  color: white;
  border-radius: 5px;
  font-weight: 600;
  width: 100px;
  cursor: pointer;

  &:first-of-type {
    background-color: #6c757d; /* Cancel button color */
    margin-right: 10px;
  }
`;

export const Field = styled.div`
    position: relative;

    .required-icon {
    display: inline-block;
    vertical-align: middle;
    margin: -0.25em 0.25em 0em;
    background-color: transparent;
    padding: 0.5em 0.8em;
    color: rgba(0, 0, 0, 0);
    text-transform: uppercase;
    font-weight: normal;
    border-radius: 0.325em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 75%;
}`;

export const ContainerBody = styled.div`
  /* Add styles to the Container if needed */
  .container-body{
    margin: 16px;
  }

  .footer-buttons{
    float: right;
    margin: 10px 0 10px 0;
  }

  .select-input{
    margin: 0 0 0 0;
    height: 34px;
    padding: 4px;
  }

  th.column-head {
    /* Customize styles for the column head here */
    padding: 16px; /* Example padding */
    /* Add any other styles as needed */
  }

  td.column-cell {
    /* Customize styles for the column head here */
    padding-left: 16px; /* Example padding */
    vertical-align: middle;
    /* Add any other styles as needed */
  }

  .select-field{
    margin-top: 16px;
  }

  .input-field{
    border: 1px solid #ececf3;
    border-radius: 5px;
    display: flex;
    height: 32px;
    width: inherit;
    padding: 6px;
    &:focus-visible {
      outline: 2px solid rgba(17, 42, 97, 0.06);
    }
  }

  .input-text-field{
    border: 1px solid #ececf3;
    border-radius: 5px;
    display: flex;
    height: 32px;
    width: inherit;
    padding: 6px;
    height: 5rem;

    &:focus-visible {
      outline: 2px solid rgba(17, 42, 97, 0.06);
    }
  }

  .input-text-field-cs{
    border: 1px solid #ececf3;
    border-radius: 5px;
    display: flex;
    height: 35px;
    width: inherit;
    padding: 6px;
    margin-top: 5px

    &:focus-visible {
      outline: 2px solid rgba(17, 42, 97, 0.06);
    }
  }
`;
