import React, { useState, useEffect, useRef } from 'react';
import { Container } from './styles';
import { useAuth } from '../../hooks/auth';
import Api, { exceptionNotificationAPI } from '../../services/api';
import Select from 'react-select';
import { info_message } from '../../components/Toast/index.jsx';
import TableLoading from '../../components/TableLoading/index';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ExportLogging = () => {
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);

    const { user, userRoles } = useAuth();
    const [userObject, setUserObject] = useState(null);
    //Company Select Variables
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);

    //date variables
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    //Crop Year Select Variables
    const [cropYears, setCropYears] = useState([]);
    const [selectedReportYears, setSelectedReportYears] = useState([]);
    //Excel Types to export
    const [selectedExcelTypes, setSelectedExcelTypes] = useState(null);

    const [loading, setLoading] = useState(true);
    const [send, setSend] = useState(false);

    useEffect(() => {
        cropYears.length > 0 && setLoading(false);
    }, [cropYears]);

    const handleDateSelect = (intType) => {
        return intType == 1 ? startDate : endDate;
    }

    useEffect(() => {
        var userAux = typeof (user) == "string" ? JSON.parse(user) : user;
        setUserObject(userAux);

        if (!userAux.companyID || userAux.groupID) {
            loadCompanies(userAux.groupID);
        } else {
            setCompanies([{
                name: userAux.company.name,
                companyID: userAux.companyID
            }]);
            setSelectedCompany(userAux.companyID);

        }
        loadCropYears(userAux);

        setLoading(false);
    }, []);


    const loadCompanies = (groupID = null) => {
        if (groupID != null) {
            Api.get(`Dashboard/getAllCompaniesFilteredByGroup`).then((result) => {
                if (result && result.data) {
                    setCompanies([{
                        name: "Select all",
                        companyID: "*"
                    }, ...result.data.response]);
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
        else {
            Api.get(`company/getAll`).then((result) => {
                result && result.data && setCompanies([{
                    name: "Select all",
                    companyID: "*"
                }, ...result.data.response]);
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
    }



    const loadCropYears = () => {
        Api.get(`export/getAllYears`).then((result) => {

            result && result.data && setCropYears([{
                year: "Select all",
                year: "*"
            }, ...result.data.response]);
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const sendExport = () => {
        setSend(true);
        setLoading(true);
        var data = {
            StartDate: startDate,
            EndDate: endDate
        };

        console.log('tentando requisitar', data);

        Api.post(`Logging/ExportAdministrativeLogging`, data, { responseType: 'blob' })
            .then((response) => {
                if (response.data && response.data.size > 0) {
                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        window.navigator.msSaveBlob(response.data, "Thrive_AdmLogs.xlsx");
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Thrive_AdmLogs.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(url);
                    }
                } else {
                    console.error("O arquivo gerado está vazio ou corrompido.");
                }

                setLoading(false);
                setSend(false);
            })
            .catch((error) => {
                exceptionNotificationAPI(error);
                setLoading(false);
            });
    }

    return (
        loading ? <Container className='container'>  {send && <div className="mt-2"><TableLoading /></div>} </Container> :
            <Container className="container">

                <div className="row mt-3">
                    <label>
                        Start Date
                        <div className="col-6 pl-0 pr-0 mt-2"> <DatePicker
                            className='datepicker'
                            onChange={(x) => setStartDate(x)}
                            onSelect={(x) => setStartDate(x)}
                            selected={startDate}
                            dateFormat="dd/MM/yyyy"
                            placeholderText={"Date"}
                        /></div>
                    </label>
                </div>
                <div className="row mt-2">
                    <label>
                        End Date
                        <div className="col-6 pl-0 pr-0 mt-2">  <DatePicker
                            className='datepicker'
                            onChange={(x) => setEndDate(x)}
                            onSelect={(x) => setEndDate(x)}
                            selected={endDate}
                            dateFormat="dd/MM/yyyy"
                            placeholderText={"Date"}
                        /></div>
                    </label>

                </div>


                <div className="col-12 pl-0 pr-0 mt-4">
                    <button className="col-md-12 apply-btn" onClick={() => sendExport()}>Export</button>
                </div>
            </Container>
    );
}

export default ExportLogging;
