import React from 'react';
import TableCrud from '../../components/TableCrud';

const YearIndex = () => {
    const columns = [
        {
            label: "Year",
            name: "yearNumber",
            type: "number"
        },
        {
            label: "Start Date",
            name: "beginDate",
            type: "date"
        },
        {
            label: "End Date",
            name: "endDate",
            type: "date"
        },
        {
            label: "Active",
            name: "isActive"
        },
        {
            label: "Editable",
            name: "isEditable"
        }
    ]

    return (
        <TableCrud title={"Year"} table={"year"} columns={columns}/>
    );
}

export default YearIndex;
