//React Imports
import React, { useEffect, useState } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import NumberFormat from 'react-number-format';
import {
  AiFillCloseCircle,
  AiFillFileWord,
  AiFillFilePdf,
  AiFillFileExcel,
  AiFillFileImage,
} from 'react-icons/ai';
import { FaAngleDown, FaAngleUp, FaFileAlt } from 'react-icons/fa';
import { removeFile } from '../../services/functions';
import { MdChat } from 'react-icons/md';

import Select, { ActionMeta, OnChangeValue } from 'react-select';
import makeAnimated from 'react-select/animated';

import Collapse from 'react-bootstrap/Collapse';
import DatePicker from 'react-datepicker';
import moment from 'moment';

//Components
import TooltipComponent from '../TooltipComponent';
import TooltipAlertComponent from '../TooltipAlertComponent';
import GetTypeInput from '../FormInput/set';
import FormInput from '../FormInput/index';
import { CommentTextArea, Field, IconNewDiscussion } from './styles';
import ChatButton from '../ChatButton/index';

//Style
import { FaTrashAlt } from 'react-icons/fa';
import Api from '../../services/api';
import EvidenceButton from '../EvidenceButton';

function getFormattedDate(date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '/' + day + '/' + year;
}

const ColumnSimpleGrouped = ({
  calculations,
  sskList,
  descriptionFragments,
  subSectionGroupColumnInfo,
  moduleObject,
  setModuleObject,
  setNumber,
  setText,
  funcCollapse,
  collapse,
  collapseText,
  collapseCol,
  setComment,
  uploadFile,
  getFile,
  numberOfColumns,
  userRoles,
  showDescriptionColumn,
  totalColumns,
  showUnitColumn,
  showSecondaryUnitsColumn,
  numberOfRows,
  row,
  blockedField,
  addNewLine,
  kpiEvidences,
  setKPIEvidences,
  removeLastLine,
  hasAddandRemoveButtons,
  MODULE_ID,
  setChatRequest,
  setShowChat,
  chatRequest,
  defaultChatRequest,
  defaultEvidenceRequest,
  evidenceRequest,
  setShowUploadEvidence,
  setEvidenceRequest,
  kpiDiscussions,
  tobaccoTypeID = null,
  subSectionKPIsToBlock,
  loading,
  kpi,
  subSectionKPIs,
  changeSelectedTobaccoType,
  selectedTobaccoTypes,
  changeSelectedCountry,
  selectedCountries,
  changeSelectedFuelType,
  selectedFuelTypes,
  columnRows,
  reordenateColumns,
  loadRefNumbersAndCalculations,
  rejectedKpisList,
  fixedKpisList,
  subSectionID,
  defaultValues,
  setSubsectionKPIs
}) => {
  const animatedComponents = makeAnimated();
  const [multipleValues, setMultipleValues] = useState([]);
  const [selectCustomValue, setSelectCustomValue] = useState([]);
  const [selectCustomInput, setSelectCustomInput] = useState('');
  const [disabledCustomSelect, setDisabledCustomSelect] = useState(true);
  const [isColumnOverload, setisColumnOverload] = useState(totalColumns > 12);
  const [userObject, setUserObject] = useState(
    JSON.parse(localStorage.getItem('@Thrive:user')),
  );

  const formatValuesInTooltip = (
    value,
    decimalSeparator,
    decimalScale,
    suffix,
    isEditable,
    isCalculation,
    isHighlightField,
    isHighLightBorderColor,
    borderColor,
  ) => {
    const borderStyle = {
      border:
        isHighLightBorderColor && value ? `2px solid ${borderColor}` : 'none',
    };
    return isCalculation || (value != null && typeof value == 'number') ? (
      <NumberFormat
        value={value}
        thousandsGroupStyle="thousand"
        decimalSeparator={decimalSeparator}
        decimalScale={decimalScale}
        suffix={suffix}
        displayType="input"
        type="text"
        style={
          isHighlightField
            ? { border: '2px solid #FBBA00' }
            : isHighLightBorderColor
              ? { border: `2px solid ${borderColor}` }
              : {}
        }
        disabled={isEditable ? false : true}
        className={
          !isEditable && isCalculation ? 'read-only-total' : 'read-only'
        }
        thousandSeparator={true}
        allowNegative={true}
        allowLeadingZeros={false}
        allowEmptyFormatting={true}
      />
    ) : (
      <>
        <input
          type="text"
          style={{
            border: !isEditable ? 'transparent' : '',
            backgroundColor: !isEditable ? 'transparent' : '',
          }}
          value={value}
          disabled={!isEditable}
        >
          {value}
        </input>
      </>
    );
  };

  const IconDiscussionToAnswer = kpiDiscussion => {
    if (kpiDiscussion != null) {
      if (
        kpiDiscussion.messages != null &&
        kpiDiscussion.messages != undefined
      ) {
        if (kpiDiscussion.messages.length > 0) {
          if (
            kpiDiscussion.messages[kpiDiscussion.messages.length - 1]
              .createdBy != userObject.id
          ) {
            if (kpiDiscussion.lastDiscussionStatus == 'Open')
              return (
                <IconNewDiscussion className="icon-newdiscussion"></IconNewDiscussion>
              );
          }
        }
      }
    }
  };

  const formatValuesInTooltipNumber = (
    value,
    decimalSeparator,
    decimalScale,
    suffix,
    isCalculation,
    isHighLightBorderColor,
    borderColor,
  ) => {
    const borderStyle = {
      border:
        isHighLightBorderColor && value ? `2px solid ${borderColor}` : 'none',
    };

    return isCalculation || (value != null && typeof value == 'number') ? (
      <NumberFormat
        value={value}
        thousandsGroupStyle="thousand"
        decimalSeparator={decimalSeparator}
        decimalScale={decimalScale}
        suffix={suffix}
        displayType="text"
        type="text"
        style={
          isHighLightBorderColor ? { border: `2px solid ${borderColor}` } : {}
        }
        thousandSeparator={true}
        allowNegative={true}
        allowLeadingZeros={false}
        allowEmptyFormatting={true}
      />
    ) : (
      <>
        <input type="text" style={borderStyle} value={value}>
          {value}
        </input>
      </>
    );
  };

  const getFirstSSKToShowUnitMeasure = kpi => {
    var allColumns = columnRows.filter(
      x => x.subSectionID == subSectionID && x.isParentColumn == false,
    );

    var columnsSorted = allColumns.sort((a, b) =>
      a.columnRowOrder > b.columnRowOrder ? 1 : -1,
    );

    var result = kpi.subSectionKPIs.find(
      x => x.columnRowID == columnsSorted[0].columnRowID,
    );

    if (result != null) {
      return result.unitMeasure.description;
    } else {
      columnsSorted.map(col => {
        var r = kpi.subSectionKPIs.find(x => x.columnRowID == col.columnRowID);

        if (r != null) {
          result = r;
        }
      });

      return result.unitMeasure.description;
    }
  };

  const isValidJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <>
      <tr key={`KPI_${kpi.kpiid}`}>
        {isColumnOverload ? (
          <>
            <td className="fixed-col">
              <TooltipComponent
                style={{
                  backgroundColor: kpi.guidance ? '#112A61' : '#e2e2e2',
                }}
                guidance={kpi.guidance}
                size={14}
                isColumnOverlap={true}
              />
            </td>
            <td className="fixed-col" style={{ minWidth: '200px', left: '4%' }}>
              {kpi.description}
            </td>
          </>
        ) : (
          <>
            <td>
              <TooltipComponent
                style={{
                  backgroundColor: kpi.guidance ? '#112A61' : '#e2e2e2',
                }}
                guidance={kpi.guidance}
                size={14}
                isColumnOverlap={false}
              />
            </td>
            <td>{kpi.description}</td>
          </>
        )}
        <td className="text-center">
          {kpi != null && kpi.subSectionKPIs[0] != null
            ? getFirstSSKToShowUnitMeasure(kpi)
            : ''}
        </td>
        {
          // kpi.subSectionKPIs.map((sskpi, i) => {
          columnRows
            .filter(x => x.isParentColumn == false)
            .map((columnRow, index) => {
              var sskpi = kpi.subSectionKPIs.find(
                x => x.columnRowID == columnRow.columnRowID,
              );
              if (sskpi) {
                var numberOfColumns = columnRow.minimumQuantity;
                return [
                  ...Array(numberOfColumns > 1 ? numberOfColumns : 1),
                ].map((col, indCol) => {
                  var unitMeasure = GetTypeInput(
                    sskpi.unitMeasureID,
                    sskpi.unitMeasure.type,
                  );
                  var isNumber =
                    unitMeasure.inputType == 'number' ||
                      unitMeasure.inputType == 'decimal'
                      ? true
                      : false;
                  var parentColumn = columnRows.find(
                    x => x.columnRowOrder == columnRow.parentColumnRowID,
                  );
                  var yearID =
                    parentColumn != null && parentColumn.type == 5
                      ? parentColumn?.yearID
                      : null;
                  var isTargetBAT =
                    unitMeasure.inputType == 'targetBAT' ? true : false;
                  var calculation =
                    sskpi.inputCalc == 'Calculation' ? sskpi.calculation : null;
                  var isCalculation = false;
                  var alertMessage = sskpi.alertMessage;
                  var selectedTobaccoTypeID =
                    tobaccoTypeID == null && columnRow.type == 4
                      ? selectedTobaccoTypes[indCol]?.value
                      : tobaccoTypeID;
                  var colDisabled = false;
                  var index = null;
                  var op = `subSectionKPI_${sskpi.subSectionKPIID}_${sskpi.columnRowID}`;
                  var isGlidepathCalculation = false;
                  var glidePathYear = '';
                  var glidepathTargetRefNumber = '';

                  if (
                    sskpi.calculation &&
                    sskpi.calculation.includes('GLIDEPATH')
                  ) {
                    // Regular expression pattern to match four digits after ';'
                    const pattern = /;(\d{4})/g;
                    const finds = sskpi.calculation.match(pattern);
                    if (finds && finds.length >= 1) {
                      const extractedDigits = finds[0].match(/\d{4}/)[0];
                      isGlidepathCalculation = true;
                      glidePathYear = extractedDigits;
                      let regex = /\[(\d+)\]/g;
                      let matches = sskpi.calculation.match(regex);
                      if (matches) {
                        glidepathTargetRefNumber = matches[0]?.match(/\d+/)[0];
                      }
                    }
                  }

                  glidepathTargetRefNumber += `.${glidePathYear}`;

                  if (numberOfColumns > 1) {
                    index = indCol;
                    op = `subSectionKPI_${sskpi.subSectionKPIID}_${sskpi.columnRowID}_c${indCol}`;
                  }

                  if (calculation != null && sskpi.inputCalc == 'Calculation') {
                    isCalculation = true;
                  }

                  var findValue = null;
                  var file = null;
                  var comment = '';
                  var value = null;
                  var country = null;
                  var disabledField = false;
                  var isFromFSMIntegration = null;
                  var highlightField = false;
                  var highlightBorderColor = false;

                  if (numberOfColumns > 1) {
                    findValue = moduleObject.answers.find(
                      x =>
                        x.subSectionKPIID == sskpi.subSectionKPIID &&
                        x.refNumber == sskpi.refNumber &&
                        x.column == indCol,
                    );
                  } else {
                    if (isGlidepathCalculation) {
                      findValue = moduleObject.answers.find(
                        x => x.refNumber == glidepathTargetRefNumber,
                      );
                    } else {
                      findValue = moduleObject.answers.find(
                        x =>
                          x.subSectionKPIID == sskpi.subSectionKPIID &&
                          x.refNumber == sskpi.refNumber,
                      );
                    }
                  }

                  if (sskpi.borderColor) {
                    highlightBorderColor = true;
                  }

                  if (sskpi.valueFromLastYear != null) {
                    //Generates Answer
                    var existsAnswer = moduleObject.answers.find(
                      x => x.subSectionKPIID == sskpi.subSectionKPIID,
                    );
                    if (existsAnswer == null) {
                      var valueToUse = sskpi.valueFromLastYear;
                      var isString = true;
                      if (typeof valueToUse === 'number') {
                        isString = false; // It can be cast to an integer
                      }
                      value = valueToUse;
                      if (!isString) {
                        setNumber({
                          subSectionKPI: sskpi,
                          ref: sskpi.refNumber,
                          value: value,
                          column: numberOfColumns > 1 ? indCol : null,
                          moduleObject: moduleObject,
                          setModuleObject: setModuleObject,
                          subSectionKPIs: subSectionKPIs,
                          tobaccoTypeID: selectedTobaccoTypeID ?? null,
                          yearID: yearID,
                          kpiEvidences: kpiEvidences,
                          setKPIEvidences: setKPIEvidences,
                          setSubsectionKPIs: setSubsectionKPIs,
                        });
                      } else {
                        setText({
                          subSectionKPI: sskpi,
                          ref: sskpi.refNumber,
                          value: value,
                          column: numberOfColumns > 1 ? indCol : null,
                          moduleObject: moduleObject,
                          setModuleObject: setModuleObject,
                          subSectionKPIs: subSectionKPIs,
                          setSubsectionKPIs: setSubsectionKPIs,
                        });
                      }
                    }
                  }

                  if (findValue != undefined && findValue != null) {
                    value = isNumber
                      ? findValue.numberValue
                      : findValue.textValue;
                    if (!value && findValue.textValue && isNumber) {
                      const number = parseFloat(findValue.textValue);
                      value = number ? number : null;
                    }
                    file = findValue.file;
                    comment = findValue.comment;
                    isFromFSMIntegration = findValue.isFromFSMIntegration;
                  }

                  if (sskpi.unitMeasure.type == 'Date' && value != null) {
                    value = new Date(moment(value, 'MM/DD/YYYY'));
                  }

                  if (columnRow.type == 2) {
                    if (
                      (selectedCountries[index] == null ||
                        selectedCountries[index]?.text == '') &&
                      !(columnRow.kpiReference == sskpi.refNumber)
                    ) {
                      disabledField = true;
                      colDisabled = true;
                    }

                    if (
                      columnRow.kpiReference == sskpi.refNumber &&
                      !moduleObject.isEditable
                    ) {
                      value = selectedCountries[index]?.text;
                    }
                  } else if (columnRow.type == 3) {
                    if (
                      (selectedFuelTypes[index] == null ||
                        selectedFuelTypes[index]?.text == '') &&
                      !(columnRow.kpiReference == sskpi.refNumber)
                    ) {
                      disabledField = true;
                      colDisabled = true;
                    }

                    if (
                      columnRow.kpiReference == sskpi.refNumber &&
                      !moduleObject.isEditable
                    ) {
                      value = selectedFuelTypes[index]?.text;
                    }
                  } else if (columnRow.type == 4) {
                    if (
                      (selectedTobaccoTypes[index] == null ||
                        selectedTobaccoTypes[index]?.text == '') &&
                      !(columnRow.kpiReference == sskpi.refNumber)
                    ) {
                      disabledField = true;
                      colDisabled = true;
                    }

                    if (
                      columnRow.kpiReference == sskpi.refNumber &&
                      !moduleObject.isEditable
                    ) {
                      value = selectedTobaccoTypes[index]?.text;
                    }
                  } //If it's a country select field
                  else if (
                    sskpi.unitMeasure?.type == 'selectcountry' &&
                    !moduleObject.isEditable
                  ) {
                    value = moduleObject.countriesOptions.find(
                      x => x.value == value,
                    )?.text;
                  } else if (isCalculation) {
                    disabledField = true;
                  }

                  if (
                    subSectionKPIsToBlock &&
                    subSectionKPIsToBlock?.some(
                      x => x.refNumber == sskpi.refNumber,
                    )
                  ) {
                    disabledField = true;
                  }

                  //TODO - Set diasbledField based on reject Partial status
                  if (
                    rejectedKpisList != null &&
                    rejectedKpisList?.length > 0
                  ) {
                    disabledField = true;
                  }

                  if (numberOfColumns > 1) {
                    if (
                      rejectedKpisList?.some(
                        x =>
                          x.subSectionKPIID == sskpi.subSectionKPIID &&
                          x.columnIndex == indCol,
                      )
                    ) {
                      disabledField = false;
                    }
                  } else {
                    if (
                      rejectedKpisList?.some(
                        x => x.subSectionKPIID == sskpi.subSectionKPIID,
                      )
                    ) {
                      disabledField = false;
                    }
                  }

                  var hasDiscussion = kpiDiscussions.find(
                    x => x.subSectionKPIID == sskpi.subSectionKPIID,
                  );
                  var discussionCollor = '';
                  if (hasDiscussion) {
                    if (
                      hasDiscussion?.column != null ||
                      hasDiscussion?.row != null
                    ) {
                      if (
                        (hasDiscussion?.column != null
                          ? hasDiscussion?.column == indCol
                          : true) &&
                        (hasDiscussion?.row != null
                          ? hasDiscussion?.row == index
                          : true)
                      ) {
                        discussionCollor =
                          hasDiscussion.lastDiscussionStatus == 'Open'
                            ? '#FFDA7E'
                            : hasDiscussion.lastDiscussionStatus == 'Closed'
                              ? '#ABF0B0'
                              : '';
                      } else {
                        hasDiscussion = null;
                      }
                    } else {
                      discussionCollor =
                        hasDiscussion.lastDiscussionStatus == 'Open'
                          ? '#FFDA7E'
                          : hasDiscussion.lastDiscussionStatus == 'Closed'
                            ? '#ABF0B0'
                            : '';
                    }
                  }
                  var userObj = JSON.parse(
                    localStorage.getItem('@Thrive:user'),
                  );
                  var isApproving = false;
                  //Validate if user is local approver or global
                  if (
                    (moduleObject?.version?.status == 2 &&
                      userObj?.companyID != null &&
                      userRoles.includes('RoleApprover')) ||
                    (moduleObject?.version?.status == 4 &&
                      userObj?.companyID == null &&
                      userRoles.includes('RoleApprover'))
                  ) {
                    isApproving = true;
                  }

                  if (numberOfColumns > 1) {
                    if (
                      fixedKpisList?.some(
                        x =>
                          x.subSectionKPIID == sskpi.subSectionKPIID &&
                          x.columnIndex == indCol,
                      ) &&
                      !moduleObject.isEditable &&
                      isApproving
                    ) {
                      highlightField = true;
                      highlightBorderColor = true;
                    }
                  } else {
                    if (
                      fixedKpisList?.some(
                        x => x.subSectionKPIID == sskpi.subSectionKPIID,
                      ) &&
                      !moduleObject.isEditable &&
                      isApproving
                    ) {
                      highlightField = true;
                      highlightBorderColor = true;
                    }
                  }

                  if (defaultValues != null && defaultValues?.length > 0) {
                    var defaultValue = defaultValues.find(
                      val => val.subSectionKPIID == sskpi.subSectionKPIID,
                    );
                    if (defaultValue) {
                      var valueToUse =
                        defaultValue.numberValue != null
                          ? defaultValue.numberValue
                          : defaultValue.textValue;
                      disabledField = true;
                      value = valueToUse;
                    }
                  }

                  let targetKey = null;
                  Object.entries(moduleObject.dynamicBlockKpis).some(([key, blocks]) => {
                    return blocks.some(block => {
                      if (block.refNumbersToBlock.includes(sskpi.refNumber) && block.answerToBlock == "null") {
                        targetKey = key;
                        return true; // Exit the inner loop
                      }
                      return false;
                    });
                  });
                  if (targetKey != null) {
                    var blockTargetAnswer = moduleObject.answers.find(x => x.refNumber == targetKey);
                    if (blockTargetAnswer == null) {
                      disabledField = true;
                    }
                  }

                  if (sskpi.refNumber == 2103)
                    console.log(sskpi, moduleObject)

                  return (
                    <>
                      <td
                        style={isColumnOverload ? { minWidth: '100px' } : {}}
                        key={`subSectionKPI_td_${indCol}`}
                        colSpan={1}
                      >
                        <div className="row" style={{ position: 'relative' }}>
                          <div className={'col-9'} style={{ padding: 0 }}>
                            {isTargetBAT ? (
                              <>
                                <OverlayTrigger
                                  delay={{ hide: 0, show: 100 }}
                                  overlay={props =>
                                    sskpi.targetBAT != null ||
                                      sskpi.inputMessage != null ? ( //validate overlay when field is text on Prompt Actions Modules
                                      <Tooltip {...props}>
                                        {sskpi.targetBAT != null
                                          ? sskpi.targetBAT
                                          : sskpi.inputMessage != null &&
                                            sskpi.inputMessage != ''
                                            ? sskpi.inputMessage
                                            : ''}
                                      </Tooltip>
                                    ) : (
                                      <></>
                                    )
                                  }
                                  placement={'left'}
                                >
                                  <input
                                    name={op}
                                    id={op}
                                    autoComplete="off"
                                    style={
                                      isFromFSMIntegration == true
                                        ? { border: '2px solid #50af47' }
                                        : highlightField
                                          ? { border: '2px solid #FBBA00' }
                                          : {}
                                    }
                                    value={
                                      sskpi.targetBAT != null
                                        ? sskpi.targetBAT
                                        : ''
                                    }
                                    title={''}
                                    inputType={'text'}
                                    className={'read-only'}
                                    disabled={true}
                                  />
                                </OverlayTrigger>
                              </>
                            ) : moduleObject.isEditable ? (
                              isNumber && !isCalculation ? (
                                <>
                                  {loadRefNumbersAndCalculations(sskpi)}
                                  {sskpi.toleranceType ? (
                                    <TooltipAlertComponent
                                      id={`${op}_alert`}
                                      message={sskpi.alertMessage}
                                      className="alert-column hide"
                                      placement={'left'}
                                    />
                                  ) : null}
                                  <OverlayTrigger
                                    delay={{ hide: 0, show: 100 }}
                                    overlay={props =>
                                      value != null ||
                                        sskpi.inputMessage != null ? ( //validate overlay when field is text on Prompt Actions Modules
                                        <Tooltip {...props}>
                                          {value != null
                                            ? value
                                            : sskpi.inputMessage
                                              ? sskpi.inputMessage
                                              : ''}
                                        </Tooltip>
                                      ) : (
                                        <></>
                                      )
                                    }
                                    placement={'left'}
                                  >
                                    <Field>
                                      <NumberFormat
                                        name={op}
                                        id={op}
                                        autoComplete="off"
                                        className={
                                          moduleObject.isEditable
                                            ? 'ddd'
                                            : 'read-only ddd'
                                        }
                                        disabled={
                                          moduleObject.isEditable
                                            ? blockedField
                                              ? true
                                              : disabledField
                                            : true
                                        }
                                        title={''}
                                        thousandsGroupStyle="thousand"
                                        value={value != null ? value : ''}
                                        decimalSeparator={
                                          unitMeasure.decimalSeparator != ''
                                            ? unitMeasure.decimalSeparator
                                            : '.'
                                        }
                                        decimalScale={
                                          unitMeasure.decimalPrecision
                                        }
                                        suffix={unitMeasure.suffix}
                                        style={
                                          isFromFSMIntegration == true
                                            ? { border: '2px solid #50af47' }
                                            : highlightField
                                              ? { border: '2px solid #50af47' }
                                              : highlightBorderColor
                                                ? {
                                                  border: `2px solid ${sskpi.borderColor}`,
                                                }
                                                : {}
                                        }
                                        displayType="input"
                                        type="text"
                                        thousandSeparator={true}
                                        allowNegative={true}
                                        allowLeadingZeros={false}
                                        allowEmptyFormatting={false}
                                        placeholder="00.00"
                                        onValueChange={(values, sourceInfo) => {
                                          setNumber({
                                            subSectionKPI: sskpi,
                                            ref: sskpi.refNumber,
                                            value:
                                              values.formattedValue != ''
                                                ? parseFloat(values.floatValue)
                                                : '',
                                            column:
                                              numberOfColumns > 1
                                                ? indCol
                                                : null,
                                            moduleObject: moduleObject,
                                            setModuleObject: setModuleObject,
                                            subSectionKPIs: subSectionKPIs,
                                            tobaccoTypeID:
                                              selectedTobaccoTypeID ?? null,
                                            yearID: yearID,
                                            kpiEvidences: kpiEvidences,
                                            setKPIEvidences: setKPIEvidences,
                                            setSubsectionKPIs: setSubsectionKPIs
                                          });
                                        }}
                                      />
                                      {sskpi.mandatory == true && (
                                        <div class="required-icon">
                                          <div class="text">*</div>
                                        </div>
                                      )}
                                    </Field>
                                  </OverlayTrigger>
                                </>
                              ) : isNumber && isCalculation ? (
                                <>
                                  {loadRefNumbersAndCalculations(sskpi)}
                                  {sskpi.toleranceType ? (
                                    <TooltipAlertComponent
                                      id={`${op}_alert`}
                                      message={sskpi.alertMessage}
                                      className="alert-column hide"
                                      placement={'left'}
                                    />
                                  ) : null}
                                  <OverlayTrigger
                                    delay={{ hide: 0, show: 100 }}
                                    overlay={props =>
                                      value != null ||
                                        sskpi.inputMessage != null ? ( //validate overlay when field is text on Prompt Actions Modules
                                        <Tooltip {...props}>
                                          {value != null
                                            ? formatValuesInTooltipNumber(
                                              value,
                                              unitMeasure.decimalSeparator,
                                              unitMeasure.decimalPrecision,
                                              unitMeasure.suffix,
                                              isCalculation,
                                            )
                                            : sskpi.inputMessage
                                              ? sskpi.inputMessage
                                              : ''}
                                        </Tooltip>
                                      ) : (
                                        <></>
                                      )
                                    }
                                    placement={'left'}
                                  >
                                    <Field>
                                      <NumberFormat
                                        name={op}
                                        id={op}
                                        autoComplete="off"
                                        className="read-only-total"
                                        disabled
                                        title={''}
                                        thousandsGroupStyle="thousand"
                                        value={value != null ? value : ''}
                                        style={
                                          isFromFSMIntegration == true
                                            ? { border: '2px solid #50af47' }
                                            : highlightField
                                              ? { border: '2px solid #FBBA00' }
                                              : highlightBorderColor
                                                ? {
                                                  border: `2px solid ${sskpi.borderColor}`,
                                                }
                                                : {}
                                        }
                                        decimalSeparator={
                                          unitMeasure.decimalSeparator != ''
                                            ? unitMeasure.decimalSeparator
                                            : '.'
                                        }
                                        decimalScale={
                                          unitMeasure.decimalPrecision
                                        }
                                        suffix={
                                          value != null
                                            ? unitMeasure.suffix
                                            : ''
                                        }
                                        displayType="input"
                                        type="text"
                                        thousandSeparator={true}
                                        allowNegative={true}
                                        allowLeadingZeros={false}
                                        allowEmptyFormatting={true}
                                      />
                                      {sskpi.mandatory == true && (
                                        <div class="required-icon">
                                          <div class="text">*</div>
                                        </div>
                                      )}
                                    </Field>
                                  </OverlayTrigger>
                                </>
                              ) : sskpi.unitMeasure.type === 'selecttobacco' ? (
                                <>
                                  {loadRefNumbersAndCalculations(sskpi)}
                                  <TooltipAlertComponent
                                    id={`${op}_alert`}
                                    message={sskpi.alertMessage}
                                    className="alert-column hide"
                                    placement={'left'}
                                  />
                                  <OverlayTrigger
                                    delay={{ hide: 0, show: 100 }}
                                    overlay={props =>
                                      value != null ||
                                        sskpi.inputMessage != null ? ( //validate overlay when field is text on Prompt Actions Modules
                                        <Tooltip {...props}>
                                          {value != null
                                            ? selectedTobaccoTypes[index]?.text
                                            : sskpi.inputMessage
                                              ? sskpi.inputMessage
                                              : ''}
                                        </Tooltip>
                                      ) : (
                                        <></>
                                      )
                                    }
                                    placement={'left'}
                                  >
                                    <Field>
                                      {moduleObject.tobaccoTypesOptions.length >
                                        0 && (
                                          <>
                                            <FormInput
                                              disabled={disabledField}
                                              style={
                                                highlightField
                                                  ? {
                                                    border: '2px solid #FBBA00',
                                                  }
                                                  : highlightBorderColor
                                                    ? {
                                                      border: `2px solid ${sskpi.borderColor}`,
                                                    }
                                                    : {}
                                              }
                                              options={
                                                moduleObject.tobaccoTypesOptions
                                              }
                                              name={op}
                                              id={op}
                                              inputType={sskpi.unitMeasure.type}
                                              autoComplete="off"
                                              value={value != null ? value : ''}
                                              onChange={f => {
                                                changeSelectedTobaccoType(
                                                  f.target.value,
                                                  sskpi,
                                                  indCol,
                                                  columnRow,
                                                );
                                              }}
                                            />
                                          </>
                                        )}
                                      {sskpi.mandatory == true &&
                                        indCol == 0 && (
                                          <div class="required-icon">
                                            <div class="text">*</div>
                                          </div>
                                        )}
                                    </Field>
                                  </OverlayTrigger>
                                </>
                              ) : unitMeasure.inputType == 'date' ? (
                                <>
                                  {loadRefNumbersAndCalculations(sskpi)}
                                  <DatePicker
                                    name={op}
                                    id={op}
                                    autoComplete="off"
                                    selected={value != null ? value : ''}
                                    dateFormat="MM/dd/yyyy"
                                    className={`react-datepicker-popper ${moduleObject.isEditable
                                      ? 'react-datepicker-popper'
                                      : 'read-only'
                                      }`}
                                    disabled={
                                      moduleObject.isEditable
                                        ? blockedField
                                          ? true
                                          : disabledField
                                        : true
                                    }
                                    onChange={date => {
                                      var formattedDate =
                                        date != null
                                          ? moment(date).format('MM/DD/YYYY')
                                          : '';

                                      setText({
                                        subSectionKPI: sskpi,
                                        ref: sskpi.refNumber,
                                        value: formattedDate,
                                        column:
                                          numberOfColumns > 1 ? indCol : null,
                                        moduleObject: moduleObject,
                                        setModuleObject: setModuleObject,
                                        subSectionKPIs: subSectionKPIs,
                                        setSubsectionKPIs: setSubsectionKPIs,
                                      });
                                    }}
                                  />
                                </>
                              ) : sskpi.unitMeasure.type === 'selectcountry' ? (
                                <>
                                  {loadRefNumbersAndCalculations(sskpi)}
                                  <TooltipAlertComponent
                                    id={`${op}_alert`}
                                    message={sskpi.alertMessage}
                                    className="alert-column hide"
                                    placement={'left'}
                                  />
                                  <OverlayTrigger
                                    delay={{ hide: 0, show: 100 }}
                                    overlay={props =>
                                      value != null || sskpi.inputMessage ? ( //validate overlay when field is text on Prompt Actions Modules
                                        <Tooltip {...props}>
                                          {value != null
                                            ? value
                                            : sskpi.inputMessage
                                              ? sskpi.inputMessage
                                              : ''}
                                        </Tooltip>
                                      ) : (
                                        <></>
                                      )
                                    }
                                    placement={'left'}
                                  >
                                    <Field>
                                      {moduleObject.countriesOptions.length >
                                        0 && (
                                          <>
                                            <FormInput
                                              style={
                                                highlightField
                                                  ? {
                                                    border: '2px solid #FBBA00',
                                                  }
                                                  : highlightBorderColor
                                                    ? {
                                                      border: `2px solid ${sskpi.borderColor}`,
                                                    }
                                                    : {}
                                              }
                                              options={
                                                moduleObject.countriesOptions
                                              }
                                              name={op}
                                              id={op}
                                              inputType={sskpi.unitMeasure.type}
                                              autoComplete="off"
                                              value={value != null ? value : ''}
                                              onChange={f => {
                                                setText({
                                                  subSectionKPI: sskpi,
                                                  ref: sskpi.refNumber,
                                                  value: f.target.value,
                                                  column:
                                                    numberOfColumns > 1
                                                      ? indCol
                                                      : null,
                                                  moduleObject: moduleObject,
                                                  setModuleObject:
                                                    setModuleObject,
                                                  subSectionKPIs: subSectionKPIs,
                                                  setSubsectionKPIs: setSubsectionKPIs,
                                                });
                                              }}
                                            />
                                          </>
                                        )}
                                      {sskpi.mandatory == true && (
                                        <div class="required-icon">
                                          <div class="text">*</div>
                                        </div>
                                      )}
                                    </Field>
                                  </OverlayTrigger>
                                </>
                              ) : unitMeasure.inputType ==
                                'multiselectcustom' ? (
                                <>
                                  {loadRefNumbersAndCalculations(sskpi)}
                                  <OverlayTrigger
                                    delay={{ hide: 0, show: 100 }}
                                    overlay={props =>
                                      value != null ||
                                        sskpi.inputMessage != null ? ( //validate overlay when field is text on Prompt Actions Modules
                                        <Tooltip {...props}>
                                          {value != null
                                            ? unitMeasure.inputType ==
                                              'multiselectcustom'
                                              ? value.startsWith('[') &&
                                                value.endsWith(']')
                                                ? JSON.parse(value)
                                                  ?.map(e => {
                                                    return e.value;
                                                  })
                                                  .join(';')
                                                : value
                                              : value
                                            : sskpi.inputMessage
                                              ? sskpi.inputMessage
                                              : ''}
                                        </Tooltip>
                                      ) : (
                                        <></>
                                      )
                                    }
                                    placement={'left'}
                                  >
                                    <Field>
                                      <FormInput
                                        name={op}
                                        id={op}
                                        autoComplete="off"
                                        value={
                                          value != null
                                            ? value.startsWith('[') &&
                                              value.endsWith(']')
                                              ? JSON.parse(value)
                                              : sskpi.customOptions
                                                .split(';')
                                                .map(option => {
                                                  var data = {
                                                    value: option,
                                                    label: option,
                                                  };
                                                  return data;
                                                })
                                                .filter(e => e.value == value)
                                            : ''
                                        }
                                        inputType={unitMeasure.inputType}
                                        //style={isFromFSMIntegration == true ? { border: "2px solid #50af47" } : highlightField ? { border: "2px solid #FBBA00" } : {}}
                                        customOptions={sskpi.customOptions}
                                        //className={moduleObject.isEditable ? "ddd" : "read-only ddd"}
                                        disabled={
                                          moduleObject.isEditable
                                            ? blockedField
                                              ? true
                                              : disabledField
                                            : true
                                        }
                                        onChange={f => {
                                          setText({
                                            subSectionKPI: sskpi,
                                            ref: sskpi.refNumber,
                                            value: JSON.stringify(f),
                                            column:
                                              numberOfColumns > 1
                                                ? indCol
                                                : null,
                                            moduleObject: moduleObject,
                                            setModuleObject: setModuleObject,
                                            subSectionKPIs: subSectionKPIs,
                                            tobaccoTypeID:
                                              selectedTobaccoTypeID ?? null,
                                            setSubsectionKPIs: setSubsectionKPIs,
                                          });
                                        }}
                                      />
                                      {sskpi.mandatory == true && (
                                        <div class="required-icon">
                                          <div class="text">*</div>
                                        </div>
                                      )}
                                    </Field>
                                  </OverlayTrigger>
                                </>
                              ) : (
                                <>
                                  {loadRefNumbersAndCalculations(sskpi)}
                                  <OverlayTrigger
                                    delay={{ hide: 0, show: 100 }}
                                    overlay={props =>
                                      value != null ||
                                        sskpi.inputMessage != null ? ( //validate overlay when field is text on Prompt Actions Modules
                                        <Tooltip {...props}>
                                          {value != null
                                            ? value
                                            : sskpi.inputMessage
                                              ? sskpi.inputMessage
                                              : ''}
                                        </Tooltip>
                                      ) : (
                                        <></>
                                      )
                                    }
                                    placement={'left'}
                                  >
                                    <Field>
                                      <FormInput
                                        name={op}
                                        id={op}
                                        autoComplete="off"
                                        value={value != null ? value : ''}
                                        inputType={unitMeasure.inputType}
                                        style={
                                          isFromFSMIntegration == true
                                            ? { border: '2px solid #50af47' }
                                            : highlightField
                                              ? { border: '2px solid #FBBA00' }
                                              : highlightBorderColor
                                                ? {
                                                  border: `2px solid ${sskpi.borderColor}`,
                                                }
                                                : {}
                                        }
                                        customOptions={sskpi.customOptions}
                                        className={
                                          moduleObject.isEditable
                                            ? ''
                                            : 'read-only'
                                        }
                                        disabled={
                                          moduleObject.isEditable
                                            ? blockedField
                                              ? true
                                              : disabledField
                                            : true
                                        }
                                        onChange={f => {
                                          setText({
                                            subSectionKPI: sskpi,
                                            ref: sskpi.refNumber,
                                            value: f.target.value,
                                            column:
                                              numberOfColumns > 1
                                                ? indCol
                                                : null,
                                            moduleObject: moduleObject,
                                            setModuleObject: setModuleObject,
                                            subSectionKPIs: subSectionKPIs,
                                            tobaccoTypeID:
                                              selectedTobaccoTypeID ?? null,
                                            setSubsectionKPIs: setSubsectionKPIs,
                                          });
                                        }}
                                      />
                                      {sskpi.mandatory == true && (
                                        <div class="required-icon">
                                          <div class="text">*</div>
                                        </div>
                                      )}
                                    </Field>
                                  </OverlayTrigger>
                                </>
                              )
                            ) : isNumber ? (
                              <>
                                {loadRefNumbersAndCalculations(sskpi)}
                                <OverlayTrigger
                                  delay={{ hide: 0, show: 100 }}
                                  overlay={props =>
                                    value != null ||
                                      sskpi.inputMessage != null ? ( //validate overlay when field is text on Prompt Actions Modules
                                      <Tooltip {...props}>
                                        {value != null
                                          ? value
                                          : sskpi.inputMessage
                                            ? sskpi.inputMessage
                                            : ''}
                                      </Tooltip>
                                    ) : (
                                      <></>
                                    )
                                  }
                                  placement={'left'}
                                >
                                  <div className="text-center">
                                    {formatValuesInTooltip(
                                      value,
                                      unitMeasure.decimalSeparator,
                                      unitMeasure.decimalPrecision,
                                      unitMeasure.suffix,
                                      moduleObject.isEditable,
                                      isCalculation,
                                      highlightField,
                                    )}
                                  </div>
                                </OverlayTrigger>
                              </>
                            ) : unitMeasure.inputType === '' ||
                              unitMeasure.inputType === 'text' ||
                              (unitMeasure.inputType === 'multiselectcustom' &&
                                !moduleObject.isEditable) ? (
                              <>
                                {loadRefNumbersAndCalculations(sskpi)}
                                <OverlayTrigger
                                  delay={{ hide: 0, show: 100 }}
                                  overlay={props =>
                                    value != null ||
                                      sskpi.inputMessage != null ? ( //validate overlay when field is text on Prompt Actions Modules
                                      <Tooltip {...props}>
                                        {value != null
                                          ? unitMeasure.inputType === 'multiselectcustom'
                                            ? isValidJSON(value)
                                              ? JSON.parse(value)
                                                ?.map(e => e.value)
                                                .join(';')
                                              : value
                                            : value
                                          : sskpi.inputMessage
                                            ? sskpi.inputMessage
                                            : ''}
                                      </Tooltip>
                                    ) : (
                                      <></>
                                    )
                                  }
                                  placement={'left'}
                                >
                                  <textarea
                                    name={op}
                                    id={op}
                                    autoComplete="off"
                                    style={
                                      isFromFSMIntegration == true
                                        ? { border: '2px solid #50af47' }
                                        : highlightField
                                          ? { border: '2px solid #FBBA00' }
                                          : {}
                                    }
                                    value={
                                      value != null
                                        ? unitMeasure.inputType == 'date'
                                          ? getFormattedDate(value)
                                          : unitMeasure.inputType == 'multiselectcustom'
                                            ? isValidJSON(value)
                                              ? JSON.parse(value)
                                                ?.map(e => e.value)
                                                .join(';')
                                              : value
                                            : value
                                        : ''
                                    }
                                    title={
                                      moduleObject.isEditable
                                        ? sskpi.inputMessage
                                        : ''
                                    }
                                    inputType={'text'}
                                    className={
                                      isNumber && isCalculation
                                        ? 'read-only-total'
                                        : 'read-only'
                                    }
                                    disabled={
                                      moduleObject.isEditable ? false : true
                                    }
                                  />
                                </OverlayTrigger>
                              </>
                            ) : (
                              <>
                                {loadRefNumbersAndCalculations(sskpi)}
                                <OverlayTrigger
                                  delay={{ hide: 0, show: 100 }}
                                  overlay={props =>
                                    value != null ||
                                      sskpi.inputMessage != null ? ( //validate overlay when field is text on Prompt Actions Modules
                                      <Tooltip {...props}>
                                        {value != null
                                          ? value
                                          : sskpi.inputMessage
                                            ? sskpi.inputMessage
                                            : ''}
                                      </Tooltip>
                                    ) : (
                                      <></>
                                    )
                                  }
                                  placement={'left'}
                                >
                                  <input
                                    name={op}
                                    id={op}
                                    autoComplete="off"
                                    style={
                                      isFromFSMIntegration == true
                                        ? { border: '2px solid #50af47' }
                                        : highlightField
                                          ? { border: '2px solid #FBBA00' }
                                          : highlightBorderColor
                                            ? {
                                              border: `2px solid ${sskpi.borderColor}`,
                                            }
                                            : {}
                                    }
                                    value={
                                      value != null
                                        ? unitMeasure.inputType == 'date'
                                          ? getFormattedDate(value)
                                          : value
                                        : ''
                                    }
                                    title={
                                      moduleObject.isEditable
                                        ? sskpi.inputMessage
                                        : ''
                                    }
                                    inputType={'text'}
                                    className={
                                      isNumber && isCalculation
                                        ? 'read-only-total'
                                        : 'read-only'
                                    }
                                    disabled={
                                      moduleObject.isEditable ? false : true
                                    }
                                  />
                                </OverlayTrigger>
                              </>
                            )}
                          </div>
                          <div
                            className="col-1"
                            style={{ paddingTop: 0, paddingLeft: 0 }}
                          >
                            {hasDiscussion ? (
                              <a
                                href="#"
                                style={{
                                  marginLeft: '0.25rem',
                                  pointerEvents: 'none',
                                }}
                              >
                                <MdChat
                                  color={
                                    discussionCollor != ''
                                      ? discussionCollor
                                      : '#fff'
                                  }
                                ></MdChat>
                                {IconDiscussionToAnswer(hasDiscussion)}
                              </a>
                            ) : (
                              <></>
                            )}
                            <a
                              id={op + '_comment'}
                              name={op + '_comment'}
                              onClick={() => {
                                funcCollapse(
                                  sskpi.subSectionKPIID,
                                  op,
                                  null,
                                  numberOfColumns > 1 ? indCol : null,
                                );
                              }}
                            >
                              {collapse == sskpi.subSectionKPIID &&
                                (numberOfColumns > 1
                                  ? collapseCol == indCol
                                  : true) ? (
                                <FaAngleUp
                                  size={20}
                                  color={
                                    (comment != '' && comment != null) ||
                                      (file != '' && file != null)
                                      ? 'green'
                                      : (sskpi.comment == 'Mandatory' ||
                                        sskpi.evidence == 'Mandatory') &&
                                        (isCalculation || !disabledField) &&
                                        !colDisabled
                                        ? 'red'
                                        : ''
                                  }
                                />
                              ) : (
                                <FaAngleDown
                                  size={20}
                                  color={
                                    (comment != '' && comment != null) ||
                                      (file != '' && file != null)
                                      ? 'green'
                                      : (sskpi.comment == 'Mandatory' ||
                                        sskpi.evidence == 'Mandatory') &&
                                        (isCalculation || !disabledField) &&
                                        !colDisabled
                                        ? 'red'
                                        : ''
                                  }
                                />
                              )}
                            </a>
                          </div>
                        </div>
                      </td>
                    </>
                  );
                });
              } else {
                //return <></>
                return <td key={`subSectionKPI_td`} colSpan={1}></td>;
              }
            })
        }
      </tr>
      {columnRows
        .filter(x => x.isParentColumn == false)
        .map((columnRow, index) => {
          var sskpi = kpi.subSectionKPIs.find(
            x => x.columnRowID == columnRow.columnRowID,
          );
          if (sskpi) {
            var numberOfColumns = columnRow.minimumQuantity;
            return [...Array(numberOfColumns > 1 ? numberOfColumns : 1)].map(
              (x, i) => {
                var unitMeasure = GetTypeInput(
                  sskpi.unitMeasureID,
                  sskpi.unitMeasure.type,
                );
                var isNumber =
                  unitMeasure.inputType == 'number' ||
                    unitMeasure.inputType == 'decimal'
                    ? true
                    : false;
                var findValue = null;
                var file = undefined;
                var selectedTobaccoTypeID =
                  tobaccoTypeID == null
                    ? selectedTobaccoTypes[i]?.value
                    : tobaccoTypeID;
                var comment = '';
                var value;

                var op = `subSectionKPI_${sskpi.subSectionKPIID}_${sskpi.columnRowID}`;

                if (numberOfColumns > 1) {
                  op = `subSectionKPI_${sskpi.subSectionKPIID}_${sskpi.columnRowID}_c${i}`;
                }

                if (numberOfColumns > 1) {
                  findValue = moduleObject.answers.find(
                    x =>
                      x.subSectionKPIID == sskpi.subSectionKPIID &&
                      x.refNumber == sskpi.refNumber &&
                      x.column == i,
                  );
                } else {
                  findValue = moduleObject.answers.find(
                    x =>
                      x.subSectionKPIID == sskpi.subSectionKPIID &&
                      x.refNumber == sskpi.refNumber,
                  );
                }

                if (findValue != undefined && findValue != null) {
                  file = findValue.file;
                  comment = findValue.comment;
                  value = isNumber
                    ? findValue.numberValue
                    : findValue.textValue;
                }

                var sskEvidence = kpiEvidences.find(
                  x => x.subSectionKPIID == sskpi.subSectionKPIID,
                );
                // && (numberOfColumns > 1 ? x.column == i : true)
                // && (selectedTobaccoTypeID && columnRow.type == 4 ? selectedTobaccoTypeID == x.tobaccoTypeID: null))

                return (
                  <Collapse
                    in={collapse == sskpi.subSectionKPIID && collapseText == op}
                  >
                    <tr
                      colSpan={totalColumns + 3}
                      style={{
                        verticalAlign: 'top',
                        backgroundColor: 'rgb(250 250 250)',
                      }}
                    >
                      <td
                        colSpan={totalColumns + 3}
                        style={{ textAlign: 'left', padding: 25 }}
                      >
                        <div className="row">
                          <div className="col-lg-6">
                            <p>
                              Comment{' '}
                              {sskpi.comment == 'Mandatory' && (
                                <span className="required-kpi">*</span>
                              )}
                            </p>
                            <CommentTextArea
                              name={op + '_commentText'}
                              id={op + '_commentText'}
                              rows="5"
                              style={{
                                margin: 0,
                                width: '100%',
                                backgroundColor: '#fff',
                              }}
                              defaultValue={comment}
                              disabled={
                                moduleObject.isEditable &&
                                  findValue != undefined &&
                                  findValue != null
                                  ? false
                                  : true
                              }
                              className={
                                moduleObject.isEditable ? '' : 'block-comment'
                              }
                              onBlur={e =>
                                setComment({
                                  subSectionKPI: sskpi,
                                  ref: sskpi.refNumber,
                                  value: e.target.value,
                                  column: numberOfColumns > 1 ? i : null,
                                  row: null,
                                  moduleObject: moduleObject,
                                  setModuleObject: setModuleObject,
                                  selectedTobaccoTypes: selectedTobaccoTypes,
                                  subSectionKPIs: subSectionKPIs,
                                })
                              }
                            ></CommentTextArea>
                          </div>

                          <div className="col-lg-2">
                            <ChatButton
                              kpiDiscussions={kpiDiscussions}
                              setChatRequest={setChatRequest}
                              setShowChat={setShowChat}
                              request={{
                                ...defaultChatRequest,
                                subSectionKPIID: sskpi.subSectionKPIID,
                                kpiId: sskpi.kpiid,
                                tobaccoTypeID:
                                  selectedTobaccoTypeID && columnRow.type == 4
                                    ? selectedTobaccoTypeID
                                    : null,
                                column: numberOfColumns > 1 ? i : null,
                              }}
                            />
                            <EvidenceButton
                              kpiEvidences={kpiEvidences}
                              setEvidenceRequest={setEvidenceRequest}
                              setShowUploadEvidence={setShowUploadEvidence}
                              isMandatory={
                                sskpi.evidence == 'Mandatory' ? true : false
                              }
                              moduleObject={moduleObject}
                              evidenceRequest={{
                                ...defaultEvidenceRequest,
                                subSectionKPIID: sskpi.subSectionKPIID,
                                tobaccoTypeID:
                                  selectedTobaccoTypeID && columnRow.type == 4
                                    ? selectedTobaccoTypeID
                                    : null,
                                column: numberOfColumns > 1 ? i : null,
                                subSectionKPIValue: value ?? 0,
                                file: sskEvidence?.file,
                                value: sskEvidence?.value,
                                farmersCount: sskEvidence?.farmersCount,
                                isValidated: sskEvidence?.isValidated,
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <p>Files</p>
                            {moduleObject.isEditable &&
                              userRoles.includes('RoleInfoHolder') && (
                                <>
                                  <div className="upload-btn-wrapper">
                                    <button
                                      id={op + '_file'}
                                      className="btn-custom"
                                      disabled={
                                        findValue != undefined &&
                                          findValue != null
                                          ? false
                                          : true
                                      }
                                    >
                                      Upload a file (need to save the form)
                                    </button>
                                    <input
                                      type="file"
                                      disabled={
                                        findValue != undefined &&
                                          findValue != null
                                          ? false
                                          : true
                                      }
                                      onChange={files => {
                                        uploadFile({
                                          subSectionKPI: sskpi,
                                          ref: sskpi.refNumber,
                                          files: files,
                                          column:
                                            numberOfColumns > 1 ? i : null,
                                          row: null,
                                          moduleObject: moduleObject,
                                          setModuleObject: setModuleObject,
                                          selectedTobaccoTypes:
                                            selectedTobaccoTypes,
                                          subSectionKPIs: subSectionKPIs,
                                        });
                                      }}
                                    />
                                    {/* <input type="file" onChange={(files) => uploadFile(sskpi, sskpi.refNumber, files, numberOfColumns > 1 ? i : null, numberOfColumns > 1 ? tobaccoTypeID : null, null)} /> */}
                                  </div>
                                  <br />
                                </>
                              )}
                            {file != '' &&
                              file != null &&
                              file.length > 0 &&
                              file.filter(e => !e.isDeleted).length > 0 ? (
                              Array.isArray(file) ? (
                                file
                                  .filter(e => !e.isDeleted)
                                  .map(e => {
                                    return (
                                      <>
                                        {e.file
                                          .toLowerCase()
                                          .includes('docx') ? (
                                          <AiFillFileWord
                                            style={{ marginLeft: 20 }}
                                            title={e.file}
                                            size={25}
                                            onClick={() => getFile(e.file)}
                                          ></AiFillFileWord>
                                        ) : e.file
                                          .toLowerCase()
                                          .includes('pdf') ? (
                                          <AiFillFilePdf
                                            style={{ marginLeft: 20 }}
                                            title={e.file}
                                            size={25}
                                            onClick={() => getFile(e.file)}
                                          ></AiFillFilePdf>
                                        ) : e.file
                                          .toLowerCase()
                                          .includes('png') ||
                                          e.file
                                            .toLowerCase()
                                            .includes('jpg') ||
                                          e.file
                                            .toLowerCase()
                                            .includes('jfif') ? (
                                          <AiFillFileImage
                                            style={{ marginLeft: 20 }}
                                            title={e.file}
                                            size={25}
                                            onClick={() => getFile(e.file)}
                                          ></AiFillFileImage>
                                        ) : e.file
                                          .toLowerCase()
                                          .includes('xlsx') ? (
                                          <AiFillFileExcel
                                            style={{ marginLeft: 20 }}
                                            title={e.file}
                                            size={25}
                                            onClick={() => getFile(e.file)}
                                          ></AiFillFileExcel>
                                        ) : (
                                          <FaFileAlt
                                            style={{ marginLeft: 20 }}
                                            title={e.file}
                                            size={25}
                                            onClick={() => getFile(e.file)}
                                          ></FaFileAlt>
                                        )}
                                        {userRoles.includes('RoleInfoHolder') &&
                                          moduleObject.isEditable && (
                                            <AiFillCloseCircle
                                              className="delete-button"
                                              size={15}
                                              onClick={() => {
                                                removeFile({
                                                  subSectionKPI: sskpi,
                                                  ref: sskpi.refNumber,
                                                  value: e.file,
                                                  column:
                                                    numberOfColumns > 1
                                                      ? i
                                                      : null,
                                                  row: null,
                                                  moduleObject: moduleObject,
                                                  setModuleObject:
                                                    setModuleObject,
                                                  selectedTobaccoTypes:
                                                    selectedTobaccoTypes,
                                                  subSectionKPIs:
                                                    subSectionKPIs,
                                                  deleteFile: true,
                                                });
                                              }}
                                            ></AiFillCloseCircle>
                                          )}
                                      </>
                                    );
                                  })
                              ) : (
                                file
                              )
                            ) : (
                              <>No file attached</>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                    {/* {evidenceRequest.file != null &&
                                <tr colSpan={totalColumns + 3} style={{ verticalAlign: "top", backgroundColor: "rgb(250 250 250)" }}>

                                </tr>


                                } */}
                  </Collapse>
                );
              },
            );
          }
        })}
    </>
  );
};

export default ColumnSimpleGrouped;
