import React from 'react';
import FormCrud from '../../components/FormCrud';

const VolumeTypeForm = () => {
    const fields = [
        {
            label: "Name",
            name: "name",
            type: "input"
        }
    ]

    return (
        <FormCrud formName={"volumeType"} title={"Volume Type"} fields={fields}/>
    );
}

export default VolumeTypeForm;
