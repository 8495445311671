import styled from 'styled-components';

export const Container = styled.div`
    h3{
        margin-top: 42px;
        padding-bottom: 25px;
    }

    table{
        button{
            background: #102A63;
            border: 0;
            border-radius: 0.25rem;
            color: #fff;
            font-size: 13px;
            font-weight: 400;
            padding: 0.375rem 0.75rem;
            text-transform: uppercase;
            -webkit-transition: background-color 0.2s;
            transition: background-color 0.2s;
            min-width: 70px;
            margin-left: 15px;
        }

        th:nth-child(n+2) {
            text-align: center;
        }
        tbody{
            box-shadow: 0px 3px 20px #0000000d;

            tr{
                td{
                    display: table-cell;
                    padding: 10px;
                    vertical-align: middle;

                    .required{
                        color: red;
                    }
                }

                &:first-child{
                    td:first-child{
                        border-radius: 8px 0 0 0;
                    }

                    td:last-child{
                        border-radius: 0 8px 0 0;
                    }
                }

                &:last-child{
                    td:first-child{
                        border-radius: 0 0 0 8px;
                    }

                    td:last-child{
                        border-radius: 0 0 8px 0;
                    }

                    border-bottom: transparent;
                }

                &.hide{
                    display: none;
                }
            }
        }
    }
`

export const ContainerHeader = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-bottom: 25px;
`;

export const Title = styled.h3`
    color: #27b5e5;
    text-align: left;
    font: normal normal bold 22px Segoe UI;
    letter-spacing: 1.1px;
`;

export const Actions = styled.div`
    display: flex;
`;

export const Table = styled.table`
    border-radius: 7px!important;
    background: white;
    width: 100%;
    border-spacing: 0px;
    border-collapse: separate;
    border: 1px solid #5E7BB9;
    border-radius: 8px 8px 8px 7px;
    opacity: 1;

    tr:nth-child(odd) > td,
    tr:nth-child(odd) > th {
        background-color: rgba(0,0,0,.05);
    }

    tr:nth-child(even) > td,
    tr:nth-child(even) > th {
        background-color: white;
    }

    th {
        text-align: center!important;
        font: normal normal medium 12px Segoe UI!important;
        letter-spacing: 0px!important;
        opacity: 1!important;
        background-color: #234285!important;
        color: #D2D9E6!important;
    }

    .text-center {
        text-align: center!important;
    }

    tr:last-child {
        td:first-child {
            border-bottom-left-radius: 7px;
        }

        td:last-child {
            border-bottom-right-radius: 7px;
        }
    }

    th:first-child {
        border-top-left-radius: 7px;
    }

    th:last-child {
        border-top-right-radius: 7px;
    }

    td:first-child {
        padding-top: 20px!important;
    }

    th, td {
        border-top: 0px!important;
        padding-left: 35px!important;
        padding-top: 15px!important;
        padding-bottom: 12px!important;
        padding-right: 12px!important;
        font-size: 14px;
        letter-spacing: 0.7px;
        opacity: 1;
        border: 0;
    }
`;


