import React, { useState, useEffect } from 'react';
import { Container, Table } from './styles';
import { useAuth } from '../../hooks/auth';
import { useParams } from 'react-router-dom';
import Api, { exceptionNotificationAPI } from '../../services/api';
import TableLoading from '../../components/TableLoading/index';
import { GoLinkExternal, GoChevronDown } from 'react-icons/go';
import { Checkbox } from 'react-bootstrap';
import Select from 'react-select';
import { padding } from 'polished';
import { error_message, success_message } from '../../components/Toast';

const AutomatedReviewDashboard = () => {
  const { user, userRoles } = useAuth();
  const { companyID, reportYearID, operationID, moduleID } = useParams();
  const [userObject, setUserObject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [companiesList, setCompaniesList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(companyID);
  const [selectedOperation, setSelectedOperation] = useState(operationID);
  const [modulesList, setModulesList] = useState([]);
  const [modulesFiltered, setModulesFiltered] = useState([]);
  const [selectedModule, setSelectedModule] = useState(moduleID);
  const [reportYearList, setReportYearList] = useState([]);
  const [selectedReportYear, setSelectedReportYear] = useState(reportYearID);
  const [selectedCompanyIsSourcingGroup, setSelectedCompanyIsSourcingGroup] =
    useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editedDiscussions, setEditedDiscussions] = useState({});
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [selectedCheckbox, setSelectedCheckbox] = useState({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [listRules, setListRules] = useState([]);

  const [listExistent, setListExistent] = useState([]);

  useEffect(() => {
    var userAux = typeof user == 'string' ? JSON.parse(user) : user;
    setUserObject(userAux);

    if (!userAux.companyID || userAux.groupID) {
      loadCompanies(userAux.groupID);
    } else {
      setSelectedCompany(userAux.companyID);
    }
    loadReportYearList();
    setLoading(false);
  }, []);

  const loadCompanies = groupID => {
    if (groupID != null) {
      Api.get(`Dashboard/getAllCompaniesFilteredByGroup`)
        .then(result => {
          if (result && result.data) {
            let companiesData = result.data?.response?.map(company => ({
              value: company.companyID,
              label: company.name,
            }));
            setCompaniesList(companiesData);
          }
        })
        .catch(error => {
          exceptionNotificationAPI(error);
          error_message(`Error loading modules - ${error}`);
        });
    } else {
      Api.get(`company/getAll`)
        .then(result => {
          if (result && result.data) {
            let companiesData = result.data?.response?.map(company => ({
              value: company.companyID,
              label: company.name,
            }));
            setCompaniesList(companiesData);
            if (companyID) setSelectedCompany(companyID);
            else if (userObject?.companyID)
              setSelectedCompany(userObject.companyID);
            else setSelectedCompany(result.data.response[0].companyID);
          }
        })
        .catch(error => {
          exceptionNotificationAPI(error);
          error_message(`Error loading companies - ${error}`);
        });
    }
  };

  function formatDecimal(input) {
    // Check if input is a valid decimal number
    if (!isNaN(input) && !isNaN(parseFloat(input))) {
      // Convert to number to handle inputs like "000123.45000"
      const number = parseFloat(input);
      // Format the number with thousand separators and two decimal places
      return number.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      // Return original input if it's not a valid decimal
      return input;
    }
  }

  // Report year data
  const loadReportYearList = userObjectAux => {
    setLoading(true);
    Api.get(`Dashboard/getAllCropsYears`)
      .then(result => {
        if (result && result.data) {
          let reportYearData = result.data?.response?.map(year => ({
            value: year.reportYearID,
            label: year.year,
          }));
          setReportYearList(reportYearData);
          var reportYear = result.data.response.find(
            x => x.year == '' + new Date().getFullYear(),
          );
          if (reportYear != null) {
            setSelectedReportYear(reportYear.reportYearID);
            loadModules(reportYear.reportYearID);
          }
        }
        setLoading(false);
      })
      .catch(error => {
        error_message(`Error loading reporting years- ${error}`);
        exceptionNotificationAPI(error);
        setLoading(false);
      });
  };

  // Modules data
  const loadModules = reportYearID => {
    Api.get(`Dashboard/GetAllByReportYear?reportYearID=${reportYearID}`)
      .then(result => {
        if (result && result.data) {
          let moduleData = result.data?.response?.map(year => ({
            value: year.moduleID,
            label: year.description,
          }));
          setModulesList(moduleData);
        }
      })
      .catch(error => {
        error_message(`Error loading modules - ${error}`);
        exceptionNotificationAPI(error);
      });
  };

  const handlePublish = async () => {
    if (Object.keys(selectedCheckboxes).length > 0) {
      const trueCheckboxes = Object.keys(selectedCheckboxes).reduce(
        (accumulator, key) => {
          if (selectedCheckboxes[key] === true) {
            accumulator[key] = true;
          }
          return accumulator;
        },
        {},
      );

      let rulesToCreateDiscussion = listRules.filter(
        rule => trueCheckboxes[rule.label] && rule.thriveValue != null,
      );
      let discussionsToCreate = rulesToCreateDiscussion.map(rule => ({
        reportYearID: selectedReportYear,
        companyId: selectedCompany,
        moduleId: selectedModule,
        kpiId: rule.subSectionKPI.kpiid,
        subSectionKPIID: rule.thriveValue.subSectionKPIID,
        tobaccoTypeID: rule.thriveValue.tobaccoTypeID,
        fuelTypeId: rule.thriveValue.fuelTypeID,
        curingBarnID: rule.thriveValue.curingBarnID,
        countryId: rule.thriveValue.countryID,
        row: rule.thriveValue.row,
        column: rule.thriveValue.column,
        appendedMessage: rule.name,
      }));
      await Api.post(`Discussion/createList`, discussionsToCreate)
        .then(async result => {
          if (result && result.data) {
            if (result.data.response != null) {
              handleProcessing();
              setSelectedCheckboxes({});
              success_message('Discussions and Messages published!');
            }
          }
        })
        .catch(error => {
          setSelectedCheckboxes({});
          error_message(`Error publishing discussions - ${error}`);
          exceptionNotificationAPI(error);
        });
    }
  };

  const handleSendMessage = async () => {
    if (Object.keys(selectedCheckboxes).length > 0) {
      const trueCheckboxes = Object.keys(selectedCheckboxes).reduce(
        (accumulator, key) => {
          if (selectedCheckboxes[key] === true) {
            accumulator[key] = true;
          }
          return accumulator;
        },
        {},
      );
      let rulesToCreateDiscussion = listExistent.filter(
        rule => trueCheckboxes[rule.label] && rule.thriveValue != null,
      );
      let discussionsToCreate = rulesToCreateDiscussion.map(rule => ({
        reportYearID: selectedReportYear,
        companyId: selectedCompany,
        moduleId: selectedModule,
        kpiId: rule.subSectionKPI.kpiid,
        subSectionKPIID: rule.thriveValue.subSectionKPIID,
        tobaccoTypeID: rule.thriveValue.tobaccoTypeID,
        fuelTypeId: rule.thriveValue.fuelTypeID,
        curingBarnID: rule.thriveValue.curingBarnID,
        countryId: rule.thriveValue.countryID,
        row: rule.thriveValue.row,
        column: rule.thriveValue.column,
        appendedMessage: rule.name,
      }));
      await Api.post(`Discussion/createList`, discussionsToCreate)
        .then(async result => {
          if (result && result.data) {
            if (result.data.response != null) {
              setSelectedCheckboxes({});
              success_message('Messages sent to discussions!');
            }
          }
        })
        .catch(error => {
          setSelectedCheckboxes({});
          error_message(`Error sending messages - ${error}`);
          exceptionNotificationAPI(error);
        });
    }
  };

  const handleRowClick = title => {
    setSelectedItem(prevSelectedItem =>
      prevSelectedItem === title ? null : title,
    );
  };

  const handleProcessing = () => {
    if (
      selectedReportYear != null &&
      selectedModule != null &&
      selectedCompany != null
    ) {
      setIsLoading(true);
      Api.get(
        `Rule/AutomateReview?reportYearID=${selectedReportYear}&moduleID=${selectedModule}&companyId=${selectedCompany}`,
      )
        .then(result => {
          if (result && result.data) {
            let dataNewDiscussionList = result.data?.response
              ?.filter(x => x.discussionID == null)
              .map((item, index) => ({
                label:
                  (item.ruleRegisterID != null
                    ? `R${item.ruleRegisterID} - `
                    : item.compoundType == 1
                      ? `CR${item.compoundRuleRegisterID} - `
                      : `CRE${item.compoundRuleRegisterID} - `) + item.label,
                name: item.discussionText,
                value: item.value,
                lastYearValue: item.lastYearValue,
                valueVariance: item.answerVariance,
                discussionID: item.discussionID,
                thriveValue: item.thriveValue,
                subSectionKPI: item.subSectionKPI,
              }));
            setListRules(dataNewDiscussionList);
            let dataExistentDiscussionList = result.data?.response
              ?.filter(x => x.discussionID != null)
              .map((item, index) => ({
                label:
                  (item.ruleRegisterID != null
                    ? `R${item.ruleRegisterID} - `
                    : item.compoundType == 1
                      ? `CR${item.compoundRuleRegisterID} - `
                      : `CRE${item.compoundRuleRegisterID} - `) + item.label,
                name: item.discussionText,
                value: item.value,
                lastYearValue: item.lastYearValue,
                valueVariance: item.answerVariance,
                discussionID: item.discussionID,
                thriveValue: item.thriveValue,
                subSectionKPI: item.subSectionKPI,
              }));
            setListExistent(dataExistentDiscussionList);
            setIsDataLoaded(true);
            setIsLoading(false);
          }
        })
        .catch(error => {
          exceptionNotificationAPI(error);
          error_message(`Error while processing - ${error}`);
          setIsLoading(false);
        });
    } else {
      error_message(`Please select all filters to initiate processing!`);
    }
  };

  const handleChange = label => {
    setSelectedCheckboxes(changeSelectedCheckboxes => ({
      ...changeSelectedCheckboxes,
      [label]: !changeSelectedCheckboxes[label],
    }));
  };

  const handleDiscussionChange = (label, name, value) => {
    setEditedDiscussions(editedDiscussions => ({
      ...editedDiscussions,
      [label]: {
        ...editedDiscussions[label],
        name: value,
      },
    }));
    const updateList = prevList =>
      prevList.map(rule =>
        rule.label === label ? { ...rule, name: value } : rule,
      );

    setListRules(prevListRules => updateList(prevListRules));
    setListExistent(prevListExistent => updateList(prevListExistent));
  };

  const handleReportYearChange = event => {
    const selectedYear = event.value;
    setSelectedReportYear(selectedYear);
    setSelectedModule(null);
    loadModules(selectedYear);
  };

  const handleModuleChange = event => {
    const selectedModuleID = event.value;
    setSelectedModule(selectedModuleID);
  };

  const handleCompanyChange = event => {
    const selectedCompanyID = event.value;
    setSelectedCompany(selectedCompanyID);
  };

  return (
    <>
      {companiesList && (
        <Container>
          <div className="container dashboard-body">
            <div
              className="dashboard-body-filters row"
              style={{ alignItems: 'flex-end' }}
            >
              <div className="col-md-2">
                <label>Report Year</label>
                <Select
                  name="reportYear"
                  options={reportYearList}
                  className="basic-select"
                  classNamePrefix="select"
                  value={
                    reportYearList.find(x => x.value == selectedReportYear) ||
                    null
                  }
                  onChange={handleReportYearChange}
                />
              </div>
              <div className="col-md-4">
                <label>Company</label>
                <Select
                  name="company"
                  options={companiesList}
                  className="basic-select"
                  classNamePrefix="select"
                  value={
                    companiesList.find(x => x.value == selectedCompany) || null
                  }
                  onChange={handleCompanyChange}
                />
              </div>
              <div className="col-md-4">
                <label>Module</label>
                <Select
                  name="module"
                  options={modulesList}
                  className="basic-select"
                  classNamePrefix="select"
                  value={
                    modulesList.find(x => x.value == selectedModule) || null
                  }
                  onChange={handleModuleChange}
                />
              </div>
              <div className="col-md-2">
                <button
                  className="blue-dark"
                  type="button"
                  onClick={handleProcessing}
                  style={{ marginBottom: '2px', width: '100%' }}
                >
                  {' '}
                  Process
                </button>
              </div>
            </div>
          </div>
          <div className="rules-discussion container">
            {isLoading && <TableLoading />}
            {isDataLoaded && !isLoading && (
              <div className="dashboard-rules">
                <div className="dashboard-title">
                  <h3>New Discussions</h3>
                  <div
                    className="buttons-container"
                    style={{ display: listRules?.length > 0 ? '' : 'none' }}
                  >
                    <div className="buttons">
                      <button
                        className="blue-dark"
                        type="submit"
                        onClick={handlePublish}
                      >
                        {' '}
                        Publish
                      </button>
                    </div>
                  </div>
                </div>
                {listRules?.length > 0 ? (
                  <table className="form-rules">
                    <thead>
                      <tr className="text">
                        <div className="row" style={{ alignItems: 'center' }}>
                          <div
                            className="col-md-1"
                            style={{ width: '3.5rem', height: 'fit-content' }}
                          ></div>
                          <div className="col-md-7">
                            <th className="form">Form</th>
                          </div>
                          <div className="col-md-1">
                            <th className="value">Value</th>
                          </div>
                          <div className="col-md-1">
                            <th className="value">Value LY</th>
                          </div>
                          <div className="col-md-1">
                            <th className="value">Variance</th>
                          </div>
                          <div className="col-md-1" style={{ marginLeft: '1rem' }}>
                            <th className="value">%</th>
                          </div>
                        </div>
                      </tr>
                    </thead>
                    {listRules?.map((row, index) => (
                      <React.Fragment key={row.label}>
                        <tbody className="options">
                          <tr>
                            <td className="form-title">
                              <div
                                className="row"
                                style={{ alignItems: 'center' }}
                              >
                                <div
                                  className="col-md-1"
                                  style={{
                                    width: '3.5rem',
                                    height: 'fit-content',
                                    marginTop: '-15px',
                                  }}
                                >
                                  <span
                                    data-toggle="collapse"
                                    href={`#collapse-${row.label}`}
                                    aria-expanded={selectedItem === row.label}
                                    aria-controls={`collapse-${row.label}`}
                                    role="button"
                                    onClick={() => handleRowClick(row.label)}
                                  >
                                    <GoChevronDown size={20} className="icon" />
                                  </span>
                                </div>
                                <div className="col-md-7">
                                  <div className="row">
                                    <span>{row.label}</span>
                                  </div>
                                  <div className="row">
                                    <p>{row.name}</p>
                                  </div>
                                </div>
                                <div
                                  className="col-md-1"
                                  style={{ paddingLeft: '0rem' }}
                                >
                                  <td>{formatDecimal(row.value)}</td>
                                </div>
                                <div
                                  className="col-md-1"
                                >
                                  <td>{formatDecimal(row.lastYearValue)}</td>
                                </div>
                                <div
                                  className="col-md-1"
                                >
                                  <td>{formatDecimal(row.valueVariance)}</td>
                                </div>
                                <div
                                  className="col-md-1"
                                >
                                  <td>{row.lastYearValue != 0 ? `${formatDecimal((row.valueVariance * 100 / row.lastYearValue))}%` : '100%'}</td>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                className="checkbox"
                                checked={selectedCheckboxes[row.label]}
                                onChange={() => handleChange(row.label)}
                              />
                            </td>
                          </tr>
                          <tr
                            style={{
                              display:
                                selectedItem === row.label
                                  ? 'table-row'
                                  : 'none',
                            }}
                          >
                            <td colSpan="3">
                              <div
                                className={`discussion collapse${selectedItem === row.label ? ' show' : ''
                                  }`}
                                id={`collapse-${row.label}`}
                              >
                                <h5> Discussion:</h5>
                                <textarea
                                  className="col-md-12 textArea"
                                  key={`textarea-${row.label}`}
                                  value={
                                    (editedDiscussions[row.label] &&
                                      editedDiscussions[row.label].name) ||
                                    row.name
                                  }
                                  style={{ padding: '10px' }}
                                  onChange={e =>
                                    handleDiscussionChange(
                                      row.label,
                                      row.name,
                                      e.target.value,
                                    )
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          {index < listRules.length - 1 && (
                            <tr style={{ height: '10px' }}></tr>
                          )}
                        </tbody>
                      </React.Fragment>
                    ))}
                  </table>
                ) : (
                  <h3 className="no-event">No rules found!</h3>
                )}
                <div className="dashboard-title">
                  <h3>Existent Discussions</h3>
                  <div
                    className="buttons-container"
                    style={{ display: listExistent?.length > 0 ? '' : 'none' }}
                  >
                    <div className="buttons">
                      <button
                        className="blue-dark"
                        type="submit"
                        onClick={handleSendMessage}
                      >
                        {' '}
                        Send message
                      </button>
                    </div>
                  </div>
                </div>
                {listExistent.length > 0 ? (
                  <table className="form-rules">
                    <thead>
                      <tr className="text">
                        <div className="row" style={{ alignItems: 'center' }}>
                          <div
                            className="col-md-1"
                            style={{ width: '3.5rem', height: 'fit-content' }}
                          ></div>
                          <div className="col-md-7">
                            <th className="form">Form</th>
                          </div>
                          <div className="col-md-1">
                            <th className="value">Value</th>
                          </div>
                          <div className="col-md-1">
                            <th className="value">Value LY</th>
                          </div>
                          <div className="col-md-1">
                            <th className="value">Variance</th>
                          </div>
                          <div className="col-md-1" style={{ marginLeft: '1rem' }}>
                            <th className="value">%</th>
                          </div>
                        </div>
                      </tr>
                    </thead>
                    {listExistent.map((row, index) => (
                      <React.Fragment key={row.label}>
                        <tbody className="options">
                          <tr>
                            <td className="form-title">
                              <div
                                className="row"
                                style={{ alignItems: 'center' }}
                              >
                                <div
                                  className="col-md-1"
                                  style={{
                                    width: '3.5rem',
                                    height: 'fit-content',
                                    marginTop: '-15px',
                                  }}
                                >
                                  <span
                                    data-toggle="collapse"
                                    href={`#collapse-${row.label}`}
                                    aria-expanded={selectedItem === row.label}
                                    aria-controls={`collapse-${row.label}`}
                                    role="button"
                                    onClick={() => handleRowClick(row.label)}
                                  >
                                    <GoChevronDown size={20} className="icon" />
                                  </span>
                                </div>
                                <div className="col-md-7">
                                  <div className="row">
                                    <span>{row.label}</span>
                                  </div>
                                  <div className="row">
                                    <p>{row.name}</p>
                                  </div>
                                </div>
                                <div
                                  className="col-md-1"
                                  style={{ paddingLeft: '0rem' }}
                                >
                                  <td>{formatDecimal(row.value)}</td>
                                </div>
                                <div
                                  className="col-md-1"
                                >
                                  <td>{formatDecimal(row.lastYearValue)}</td>
                                </div>
                                <div
                                  className="col-md-1"
                                >
                                  <td>{formatDecimal(row.valueVariance)}</td>
                                </div>
                                <div
                                  className="col-md-1"
                                >
                                  <td>{row.lastYearValue != 0 ? `${formatDecimal((row.valueVariance * 100 / row.lastYearValue))}%` : '100%'}</td>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                className="checkbox"
                                checked={selectedCheckboxes[row.label]}
                                onChange={() => handleChange(row.label)}
                              />
                            </td>
                          </tr>
                          <tr
                            style={{
                              display:
                                selectedItem === row.label
                                  ? 'table-row'
                                  : 'none',
                            }}
                          >
                            <td colSpan="3">
                              <div
                                className={`discussion collapse${selectedItem === row.label ? ' show' : ''
                                  }`}
                                id={`collapse-${row.label}`}
                              >
                                <h5> Discussion:</h5>
                                <textarea
                                  className="col-md-12 textArea"
                                  key={`textarea-${row.label}`}
                                  value={
                                    (editedDiscussions[row.label] &&
                                      editedDiscussions[row.label].name) ||
                                    row.name
                                  }
                                  style={{ padding: '10px' }}
                                  onChange={e =>
                                    handleDiscussionChange(
                                      row.label,
                                      row.name,
                                      e.target.value,
                                    )
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          {index < listExistent.length - 1 && (
                            <tr style={{ height: '10px' }}></tr>
                          )}
                        </tbody>
                      </React.Fragment>
                    ))}
                  </table>
                ) : (
                  <h3 className="no-event">No rules found!</h3>
                )}
              </div>
            )}
          </div>
        </Container>
      )}
    </>
  );
};

export default AutomatedReviewDashboard;
