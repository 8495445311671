import React, { useState, useEffect } from 'react';
import { Container } from './styles';
import { useAuth } from '../../hooks/auth';
import Api, { exceptionNotificationAPI } from '../../services/api';
import Select from 'react-select';
import { info_message } from '../../components/Toast/index.jsx';
import TableLoading from '../../components/TableLoading/index';

const ExportAuditLog = () => {
    const { user, userRoles } = useAuth();
    const [userObject, setUserObject] = useState(null);
    //Company Select Variables
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);

    //Crop Year Select Variables
    const [cropYears, setCropYears] = useState([]);
    const [selectedReportYears, setSelectedReportYears] = useState([]);

    //Tobacco Types Select Variables
    const [tobaccoTypes, setTobaccoTypes] = useState([]);
    const [selectedTobaccoTypes, setSelectedTobaccoTypes] = useState(null);

    //Excel Types to export
    const [selectedExcelTypes, setSelectedExcelTypes] = useState(null);

    //Operation -> Modules
    const [modules, setModules] = useState([]);
    const [allModules, setAllModules] = useState([]);
    const [selectedModules, setSelectedModules] = useState(null);

    const [masterFile, setMasterFile] = useState(false);
    const [loading, setLoading] = useState(true);
    const [send, setSend] = useState(false);
    const [selectedCompanyIsSourcingGroup, setSelectedCompanyIsSourcingGroup] = useState(false);

    useEffect(() => {
        tobaccoTypes.length > 0 && cropYears.length > 0 && companies.length > 0 && setLoading(false);
    }, [tobaccoTypes, cropYears, companies]);

    useEffect(() => {
        if (masterFile && modules.length == 0) {
            loadModules(selectedCompanyIsSourcingGroup);
            setLoading(true);
        } else {
            setSelectedModules(null);
        }
    }, [masterFile]);

    /*
    useEffect(() => {
        if (selectedCompany) {
            Api.get(`ThriveValue/CheckIfSupplierIsSourcingGroup?companyID=${selectedCompany}`)
                .then((result) => {
                    if (result && result.data) {
                        setSelectedCompanyIsSourcingGroup(result.data.response);
                    }
                }).catch((error) => {
                    exceptionNotificationAPI(error);
                });
        }

    }, [selectedCompany])
    */
    useEffect(() => {
        var userAux = typeof (user) == "string" ? JSON.parse(user) : user;
        setUserObject(userAux);

        if (!userAux.companyID || userAux.groupID) {
            loadCompanies(userAux.groupID);
        } else {
            setCompanies([{
                name: userAux.company.name,
                companyID: userAux.companyID
            }]);
            setSelectedCompany(userAux.companyID);

        }
        loadCropYears(userAux);
        loadModules(selectedCompanyIsSourcingGroup);

        setLoading(false);
    }, []);

    useEffect(() => {
        modules.length > 0 && setLoading(false);
    }, [modules]);

    useEffect(() => {
        if (selectedExcelTypes != null && selectedExcelTypes.value == 1) {
            setMasterFile(true);
            loadTobaccoTypes()
        }
        else {
            setMasterFile(false)
            loadTobaccoTypes()
        }
    }, [selectedExcelTypes]);

    // useEffect(() => {
    //     if(selectedReportYears != null) {
    //         loadModules(selectedCompanyIsSourcingGroup)
    //     }
    // }, [selectedReportYears]);


    const loadCompanies = (groupID = null) => {
        if (groupID != null) {
            Api.get(`Dashboard/getAllCompaniesFilteredByGroup`).then((result) => {
                if (result && result.data) {
                    setCompanies([{
                        name: "Select all",
                        companyID: "*"
                    }, ...result.data.response]);
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
        else {
            Api.get(`company/getAll`).then((result) => {
                result && result.data && setCompanies([{
                    name: "Select all",
                    companyID: "*"
                }, ...result.data.response]);
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
    }

    const loadModules = (IsSourcingGroup) => {
        Api.get(`export/modules`).then((result) => {
            if (result && result.data) {
                var modulesFiltered = result.data.response;
                setAllModules(modulesFiltered)
                if (!IsSourcingGroup) {
                    modulesFiltered = result.data.response.filter(x => x.ModuleID != 27);
                }

                setModules([{
                    description: "Select all",
                    moduleExternalID: "*"
                }, ...modulesFiltered]);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadCropYears = () => {
        Api.get(`export/getAllYears`).then((result) => {

            result && result.data && setCropYears([{
                year: "Select all",
                year: "*"
            }, ...result.data.response]);
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadTobaccoTypes = () => {
        Api.get(`tobaccoType/getAll`).then((result) => {
            result && result.data && setTobaccoTypes([{
                name: "Select all",
                tobaccoTypeID: "*"
            }, ...result.data.response]);
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const sendExport = () => {
        if (selectedReportYears == null || selectedReportYears.length == 0) {
            info_message("Select an crop year");
            return;
        }
        setSend(true);
        setLoading(true);
        var data = {
            ReportYears: selectedReportYears ? selectedReportYears.map(x => x.value) : null,
            Companies: selectedCompany ? selectedCompany.map(x => x.value) : null,
            Tobaccos: selectedTobaccoTypes ? selectedTobaccoTypes.map(x => x.value) : null,
            Modules: selectedModules ? selectedModules.map(x => x.value) : null
        };

        Api.post(`export/getAuditLog`, data, { responseType: 'blob' })
            .then((response) => {
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    // Internet Explorer
                    window.navigator.msSaveBlob(response.data, "Export_AuditLog_Thrive.xlsx");
                } else {
                    // Outros navegadores
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Export_AuditLog_Thrive.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                }

                // Atualizar estados de carregamento
                setLoading(false);
                setSend(false);
            })
            .catch((error) => {
                // Lidar com erros
                exceptionNotificationAPI(error);
                setLoading(false);
            });
    }

    return (
        loading ? <Container className='container'>  {send && <div className="mt-2"><TableLoading /></div>} </Container> :
        <Container className="container">
            <div className="row mt-2">

                <div className="col-12 pl-0 pr-0 mt-2">
                    <Select
                        options={cropYears.map((o) => {
                            var data = {
                                value: o.year,
                                label: o.year == "*" ? "Select all" : o.year
                            }
                            return data;
                        })}
                        loading={loading}
                        isDisabled={loading}
                        placeholder={"Select report year(s)"}
                        value={selectedReportYears}
                        isMulti
                        hideSelectedOptions={false}
                        closeMenuOnSelect={false}
                        onChange={(selected) => {
                            if (selected !== null && selected.length > 0 && selected[selected.length - 1].value === "*") {
                                var crops = cropYears.map((o) => {
                                    var data = {
                                        value: o.year,
                                        label: o.year == "*" ? "Select all" : o.year
                                    }
                                    if (data != undefined && data.value != "*")
                                        return data;
                                });
                                setSelectedReportYears(crops.filter(a => a != undefined));
                            } else {
                                setSelectedReportYears(selected);
                            }
                        }}
                    />
                </div>

                <div className="col-12 pl-0 pr-0 mt-2">
                    <Select
                        options={companies.map((o) => {
                            var data = {
                                value: o.companyID,
                                label: o.name
                            }
                            return data;
                        })}
                        loading={loading}
                        isDisabled={loading}
                        placeholder={"Select supplier(s)"}
                        value={selectedCompany}
                        isMulti
                        hideSelectedOptions={false}
                        closeMenuOnSelect={false}
                        onChange={(selected) => {
                            if (selected !== null && selected.length > 0 && selected[selected.length - 1].value === "*") {
                                var com = companies.map((o) => {
                                    var data = {
                                        value: o.companyID,
                                        label: o.name
                                    }
                                    if (data != undefined && data.value != "*")
                                        return data;
                                })
                                setSelectedCompany(com.filter(a => a != undefined));
                            } else {
                                setSelectedCompany(selected);
                            }
                        }}
                    />
                </div>

                <div className="col-12 pl-0 pr-0 mt-2">
                    <hr style={{ marginTop: 10, marginBottom: 10, color: "#949494" }} />

                    {masterFile &&
                        <>
                            <Select
                                options={modules.map((o) => {
                                    var data = {
                                        value: o.moduleExternalID,
                                        label: o.description
                                    }
                                    return data;
                                })}
                                className="mt-2"
                                loading={loading}
                                isDisabled={loading}
                                placeholder={"Select module(s)"}
                                value={selectedModules}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                onChange={(selected) => {
                                    if (selected !== null && selected.length > 0 && selected[selected.length - 1].value === "*") {
                                        var mod = modules.map((o) => {
                                            var data = {
                                                value: o.moduleExternalID,
                                                label: o.description
                                            }
                                            if (data != undefined && data.value != "*")
                                                return data;
                                        });
                                        setSelectedModules(mod.filter(a => a != undefined));
                                    } else {
                                        setSelectedModules(selected);
                                    }
                                }}
                            />

                            {selectedModules != null && selectedModules.length > 0 && (selectedModules.map(p => p.value).indexOf(1) > -1 || selectedModules.map(p => p.value).indexOf(15000) > -1) && <Select
                                options={tobaccoTypes.map((o) => {
                                    var data = {
                                        value: o.tobaccoTypeID,
                                        label: o.name
                                    }
                                    return data;
                                })}
                                className="mt-2"
                                loading={loading}
                                isDisabled={loading}
                                placeholder={"Select tobacco type(s)"}
                                value={selectedTobaccoTypes}
                                isMulti
                                hideSelectedOptions={false}
                                closeMenuOnSelect={false}
                                onChange={(selected) => {
                                    if (selected !== null && selected.length > 0 && selected[selected.length - 1].value === "*") {
                                        var tob = tobaccoTypes.map((o) => {
                                            var data = {
                                                value: o.tobaccoTypeID,
                                                label: o.name
                                            }
                                            if (data != undefined && data.value != "*")
                                                return data;
                                        });
                                        setSelectedTobaccoTypes(tob.filter(a => a != undefined));
                                    } else {
                                        setSelectedTobaccoTypes(selected);
                                    }
                                }}
                            />}
                        </>
                    }
                </div>

                <div className="col-12 pl-0 pr-0 mt-2">
                    <button className="col-md-12 apply-btn" onClick={() => sendExport()}>Apply</button>
                </div>
            </div>
        </Container>
    );
}

export default ExportAuditLog;
