import React from 'react';

import FormCrud from '../../components/FormCrud';

const YearForm = () => {
    const booleanOptions = [
        { value: true, text: "Yes" },
        { value: false, text: "No" }
    ];

    const fields = [
        {
            label: "Year",
            name: "yearNumber",
            type: "number"
        },
        {
            label: "Start Date",
            name: "beginDate",
            type: "date"
        },
        {
            label: "End Date",
            name: "endDate",
            type: "date"
        },
        {
            label: "Active",
            name: "isActive",
            type: "select",
            options: booleanOptions
        },
        {
            label: "ESG Companies",
            name: "yearCompany",
            type: "select",
            required: false,
            multiple: true
        },
        {
            label: "Editable",
            name: "isEditable",
            type: "select",
            options: booleanOptions
        }
    ];

    return (
        <FormCrud formName={"year"} title={"Year"} fields={fields}/>
    );
}

export default YearForm;
