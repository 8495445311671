import React from 'react';
import {
    Route as ReactDOMRoute,
    Redirect,
} from 'react-router-dom';

import { useAuth } from '../hooks/auth';

import Authorized from '../pages/Layouts/Authorized';
import NotAuthorized from '../pages/Layouts/NotAuthorized';

const Route = ({ isPrivate = false, component: Component, allowedRoles, ...rest }) => {
    const { user, userRoles } = useAuth();

    if (user) {
        if (allowedRoles != null && !allowedRoles.some(role => userRoles?.includes(role))) {
            return <Redirect to="/" />;
        }
    }

    if (!user && isPrivate) {
        return <Redirect to="/signin" />;
    }

    var roles = [];
    if(userRoles != null){
        roles = userRoles.constructor === Array ? userRoles : JSON.parse(userRoles);
    }
    const Layout = user && roles.length > 0 ? Authorized : NotAuthorized;

    return (
        <ReactDOMRoute isPrivate={isPrivate} {...rest}>
            <Layout>
                <Component />
            </Layout>
        </ReactDOMRoute>
    );
};

export default Route;
